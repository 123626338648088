import * as React from 'react';

import { Divider, Box } from '../../../design-system';
import PickupStation from './PickupStation';
import { PickupStation as PickupStationType } from '../types';
import { Position } from '../../store-locator/types';
import { sortByDistance } from '../../store-locator/utils';

type Props = {
  pickupStations: PickupStationType[];
  selectedMarker: string;
  selectedStationId?: string;
  pickupStationRefs: object;
  currentPosition?: Position;
  isPositioned: Boolean;
  onClick: (id: string) => void;
  onMapLinkClick: (id: string) => void;
  onSelect?: (station: PickupStationType) => void;
  searchText: string;
};

export const PickupStationsList = ({
  pickupStations,
  selectedMarker,
  selectedStationId,
  pickupStationRefs,
  currentPosition,
  isPositioned,
  onClick,
  onSelect,
  onMapLinkClick,
  searchText,
}: Props) => {
  const [sortedPickupStations, setSortedPickupStations] = React.useState(pickupStations);

  React.useEffect(() => {
    if (currentPosition && (searchText || isPositioned)) {
      setSortedPickupStations(
        sortByDistance({ listToOrder: pickupStations, currentPosition }) as PickupStationType[]
      );
    } else {
      setSortedPickupStations(pickupStations);
    }
  }, [isPositioned, currentPosition, pickupStations]);

  return (
    <>
      {sortedPickupStations?.map((station, index) => (
        <Box key={station.id} ref={pickupStationRefs[index]}>
          <PickupStation
            isSelected={station.id === selectedStationId}
            isMarked={station.id === selectedMarker}
            index={index}
            pickupStationRefs={pickupStationRefs}
            station={station}
            currentPosition={currentPosition}
            onClick={onClick}
            onSelect={onSelect}
            onMapLinkClick={onMapLinkClick}
          />
          <Divider my="s" />
        </Box>
      ))}
    </>
  );
};
