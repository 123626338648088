import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Text } from '../../../design-system';
import { CmsHomepageBubble } from '../../cms/types';

type Props = {
  content: CmsHomepageBubble[];
};

const Bubbles = ({ content }: Props) => {
  const navigate = useNavigate();
  return (
    <Box
      display="grid"
      gridGap="12px"
      mt="m"
      pb="s"
      mx="auto"
      px="m"
      gridAutoFlow="column"
      overflow="auto"
    >
      {content.map((item, index) => {
        const { image, link, text } = item ?? {};

        const onCategoryClick = () => {
          if (link) {
            navigate(link);
          }
        };

        function getWidthWithText(text) {
          const result = text.length * (90 / 12);
          if (result <= 70) {
            return 70;
          }
          if (result >= 90) {
            return 90;
          }
          return result;
        }

        return (
          <Box
            key={index}
            width={`${getWidthWithText(text)}px`}
            id={`btn-hp-categories-${index}`}
            onClick={onCategoryClick}
            justifySelf="center"
          >
            <Box
              backgroundImage={`url(${image?.mobile?.url ?? ''})`}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              height="70px"
              maxWidth="70px"
              borderRadius="50%"
              backgroundSize="cover"
              ml={`${(getWidthWithText(text) - 70) / 2}px`}
            />
            {text && (
              <Box mt="xs" overflow="hidden">
                <Text preset="caption">{text}</Text>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Bubbles;
