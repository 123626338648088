import * as React from 'react';
import { Box, Stars, Anchor } from '../../../design-system';

type Props = {
  ratingAverage: string;
  ratingCount: string;
  reviewsRef: React.RefObject<HTMLDivElement>;
};
const Rating = ({ ratingAverage, ratingCount, reviewsRef }: Props) => {
  const onClick = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const rating = Number.parseFloat(ratingAverage);

  return (
    <Box display="flex" alignItems="center">
      <Stars size={14} rating={rating} identifier="product-stars-block" />
      <Box ml="s">
        <Anchor id="btn-pdp-scroll-to-reviews" type="button" onClick={onClick} preset="caption">
          {`${ratingCount} avis`}
        </Anchor>
      </Box>
    </Box>
  );
};

export default Rating;
