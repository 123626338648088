import * as React from 'react';

import PickupMap from './PickupMap';
import { Box, Field } from '../../../design-system';
import { PH_PICKUP_PHONE_PLACEHOLDER } from '../locale';
import { FormDelivery, Forms, FormFieldCallback } from '../../form/types';
import { useDevice } from '../../common/hooks/useDevice';
import { addSpacesForPhoneNumber, removeSpaces } from '../../form/utils';

type Props = {
  onFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  onFieldChange: ({ form, key, value }: FormFieldCallback) => void;
  form: FormDelivery;
};

const Pickup = ({ form, onFieldBlur, onFieldChange }: Props) => {
  const { focus, validation, values } = form;
  const divRef = React.useRef<HTMLInputElement>(null);
  const fieldKey = 'pickupPhone';
  const { isMobile } = useDevice();

  React.useEffect(() => {
    if (focus === fieldKey) {
      const node = divRef.current;
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [focus]);

  return (
    <Box display="grid" gridGap="m" width="100%">
      <form>
        <Box ref={divRef} maxWidth={isMobile ? 'auto' : '301px'} minHeight="50px">
          <Field
            id="pickup-phone-number-input-field"
            autoComplete="tel"
            type="tel"
            placeholder={PH_PICKUP_PHONE_PLACEHOLDER}
            value={addSpacesForPhoneNumber(values[fieldKey])}
            errMsg={validation[fieldKey]}
            onChange={(value: string) =>
              onFieldChange({ form: Forms.delivery, key: fieldKey, value: removeSpaces(value) })
            }
            onBlur={(value: string) =>
              onFieldBlur({ form: Forms.delivery, key: fieldKey, value: removeSpaces(value) })
            }
          />
        </Box>
      </form>
      <PickupMap />
    </Box>
  );
};

export default Pickup;
