import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import { Link } from 'react-router-dom';

import { Box, StyledLink, Text, ImageContainer, Image } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { CmsCustomTypes } from '../../cms/types';
import { loadCmsContent } from '../../cms/actions';
import { MSG_EMPTY_CART } from '../locale';
import wishlistLocale from '../../wishlist/locale';

type Props = {
  mini?: boolean;
  wishlist?: boolean;
};
export const EmptyCart = ({ mini, wishlist }: Props) => {
  const dispatch = useDispatch();

  const cmsContent = useSelector((state: RootState) => state.cms.emptycart) ?? {};

  const title = cmsContent.title ?? '';
  const recommendations = cmsContent.recommendations ?? [];

  React.useEffect(() => {
    if (!title) {
      dispatch(loadCmsContent(CmsCustomTypes.emptycart) as unknown as UnknownAction);
    }
  }, []);

  return (
    <Box
      textAlign="center"
      display="grid"
      gridGap="l"
      maxWidth="mwm"
      mx={mini ? 'initial' : 'auto'}
      my={mini ? 'l' : 'xl'}
      height={mini ? '1000px' : 'initial'}
      gridAutoRows={mini ? 'max-content' : 'initial'}
    >
      <Text preset="heading">
        {wishlist ? wishlistLocale.EMPTY_WISHLIST_TITLE : MSG_EMPTY_CART}
      </Text>
      <Text preset="subheading">{title}</Text>
      <Box
        display="grid"
        gridGap={['s', 'm']}
        gridTemplateColumns={mini ? 'repeat(2, 1fr)' : ['repeat(2, 1fr)', 'repeat(4, 1fr)']}
      >
        {recommendations.map(({ text, link, image }, index) => (
          <Box key={index} display="grid" gridGap="m">
            <Link id={`link-404-recommended-cat-img-${index}`} to={link ?? ''}>
              <ImageContainer preset="portrait">
                <Image src={image?.url ?? ''} isAbsolute alt="Recommended Category"></Image>
              </ImageContainer>
            </Link>
            <Box height="xl">
              <StyledLink id={`link-404-recommended-cat-${index}`} to={link ?? ''}>
                {text ?? ''}
              </StyledLink>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
