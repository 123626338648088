import { Amplify } from '@aws-amplify/core';
import { API, graphqlOperation } from '@aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { datadogLogs } from '@datadog/browser-logs';

import msg from './locale';
import {
  AWS_AUTH_REGION,
  AWS_APPSYNC_REGION,
  AWS_APPSYNC_API_URL,
  AWS_IDENTITY_POOL_ID,
  AWS_AUTH_USER_POOL_ID,
  AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
} from './constants';
import operations from './operations';
import { getJWT } from '../auth/utils';
import { Queries, Mutations } from './types';
import { Product } from '../product/types';

export async function hasToken(): Promise<boolean> {
  const token = await getJWT();
  return Boolean(token);
}

export function configureSession() {
  const conf = {
    Auth: {
      mandatorySignIn: false,
      region: AWS_AUTH_REGION,
      identityPoolId: AWS_IDENTITY_POOL_ID,
      userPoolId: AWS_AUTH_USER_POOL_ID,
      userPoolWebClientId: AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_appsync_region: AWS_APPSYNC_REGION,
    aws_appsync_graphqlEndpoint: AWS_APPSYNC_API_URL,
  };

  Amplify.configure(conf);
}

export async function getBFFData(type: Queries | Mutations, params?: any) {
  let operationType = '';
  try {
    const operation = operations[type];
    if (operation) {
      const headers = {};
      const token = await getJWT();
      if (token) {
        headers['x-cl-token'] = token;
      } else if (
        type.includes('getUser') ||
        type.includes('setCheckout') ||
        type === 'updateUser'
      ) {
        operationType = type;
        const lastAuthUser = localStorage.getItem(
          `CognitoIdentityServiceProvider.${AWS_AUTH_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
        );
        const accessTokenFromStorage = localStorage.getItem(
          `CognitoIdentityServiceProvider.${AWS_AUTH_USER_POOL_WEB_CLIENT_ID}.${lastAuthUser}.accessToken`
        );
        datadogLogs.logger.debug('DAR-3451 - JWT token issue', {
          operationType,
          lastAuthUser,
          accessTokenFromStorage,
        });
      }
      const response = await API.graphql(graphqlOperation(operation, { ...params }), headers);
      return { ok: true, data: (response as GraphQLResult).data };
    }
    return { ok: false, data: msg.ERROR_ENV };
  } catch (error) {
    if (error.errors && error.errors.length > 0) {
      return { ok: false, data: error.errors[0].message };
    }
    datadogLogs.logger.debug('DAR-3451 - JWT token issue', { operationType, error });
    return { ok: false, data: error };
  }
}

export const fetchProductFromBackend = async (recommendedProducts: string[]) => {
  try {
    const result = recommendedProducts
      .map((item) => {
        const cat = item.split('-');
        if (cat.length < 2) {
          return null;
        }
        return { productRef: cat[0], colorRef: cat[1] };
      })
      .filter((item) => item !== null) as { productRef: string; colorRef: string }[];

    const getAllProductsQueries = result.map((item, i: number) => {
      return `
    getProduct${i}: product(productRef: "${item.productRef}", colorRef: "${item.colorRef}") {
        productRef
        productName
        productDescription
        productComposition
        status
        eReservation
        colorRef
        colorLabel
        realColorRef
        detailedColorLabel
        cleaningAdvice
        originalPrice
        storePrice
        promotionPercentage
        promotionLabel
        promoLongLabel
        promoBColor
        promoTColor
        isOutlet
        images
        collectionName
        crossSellType
        categories {
          lvl0
          lvl1
          lvl2
          lvl3
        }
        colorVariants {
          colorRef
          colorLabel
          realColorRef
          detailedColorLabel
          status
          originalPrice
          storePrice
          promotionPercentage
          promotionLabel
          promoLongLabel
          promoBColor
          promoTColor
        }
        sizeVariants {
          sku
          originalPrice
          storePrice
          promotionPercentage
          stockQuantity
          cupSize
          bandSize
          size
          storesAvailable {
            quantity
            store
          }
        }
        model {
          position
          name
          size
        }
        moreDetails
        videoUrl
        isRecyclable
        isDigital
        contentRecycledMaterials
        recyclability
        rejectsMicrofibers
        toxicity
        traceability
        tax
      }
  `;
    });

    const results = (await API.graphql(
      graphqlOperation(`
  query getAllProducts {
    ${getAllProductsQueries}
  }
  `)
    )) as { data: { [key: string]: Product } };
    const arrayOfresults = [...Object.values(results.data)];

    return { ok: true, data: arrayOfresults };
  } catch (error) {
    if (!error.errors?.length) {
      return { ok: false, error };
    }
    if (!error.data) {
      return { ok: false, error: error.errors as string[] };
    }
    const dataArray = [...Object.values(error.data)];

    const validProduct = dataArray.filter((item) => item !== null);

    return validProduct.length > 0
      ? { ok: true, data: validProduct as Product[] }
      : { ok: false, error: error.errors as string[] };
  }
};

export const fetchProductForBannerFromBackend = async (productRef: string, colorRef: string) => {
  try {
    const getProductForBannerQuery = `
      query getProduct($productRef: String!, $colorRef: String!) {
        product(productRef: $productRef, colorRef: $colorRef) {
          productRef
          productName
          typology
          collectionName
          colorLabel
          colorRef
          originalPrice
          storePrice
        }
      }
  `;

    const results = (await API.graphql(
      graphqlOperation(getProductForBannerQuery, { productRef, colorRef })
    )) as { data: { product: Product } };

    return { ok: true, data: results.data.product };
  } catch (error) {
    if (!error.errors?.length) {
      return { ok: false, error };
    }

    return { ok: false, error: error.errors as string[] };
  }
};
