import * as React from 'react';

import { Box, colors } from '../../../design-system';
import RatingScale from './RatingScale';
import { Feedback } from '../types';
import { useDevice } from '../../common/hooks/useDevice';
import { LBL_SIZE } from '../../product/locale';
import Transition from 'react-transition-group/Transition';
import { durations, heights } from '../../common/constants';
import { customSubReviewsSort } from '../utils';

type Props = {
  feedback: Feedback;
};

const transitionStylesContent = {
  entering: { opacity: 0, maxHeight: 0 },
  entered: {
    opacity: 1,
    maxHeight: `${heights.PRODUCT_SELECTOR_MOBILE}px`,
    transition: `max-height ${durations.ENTER}ms ease-in, opacity ${durations.ENTER}ms ease-in`,
  },
  exiting: {
    opacity: 0,
    maxHeight: 0,
    transition: `max-height ${durations.EXIT}ms ease-out, opacity ${durations.EXIT}ms ease-out`,
  },
  exited: { opacity: 0, maxHeight: 0 },
};

const ReviewCard = ({ feedback }: Props) => {
  const { isMobile, isTablet } = useDevice();
  const [showMore, setShowMore] = React.useState(false);
  const feedbackDate = new Date(feedback.created_at);

  const subAnswers = feedback.sub_answers ? customSubReviewsSort(feedback.sub_answers) : null;

  return (
    <Box
      id={`pdp-reviews-card-${feedback.feedback_id}`}
      display="flex"
      flexDirection="column"
      width="100%"
      py="l"
      px={isMobile || isTablet ? 'na' : 'm'}
      borderBottom={`1px solid ${colors.LIGHT}`}
    >
      <Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            textTransform="capitalize"
            fontSize="1.4rem"
            lineHeight="110%"
            fontWeight={400}
            color="#5A5A5A"
            pb="xs"
          >
            {feedback.author?.toLowerCase()}
          </Box>
          <Box fontSize="1.4rem" lineHeight="110%" fontWeight={400} color="#5A5A5A">
            {feedbackDate.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Box>
        </Box>
        <RatingScale
          ratingAverage={feedback.rating}
          identifier={`reviews-cards-stars-${feedback.feedback_id}`}
        />
        <Box
          fontSize="1.2rem"
          lineHeight="110%"
          fontWeight={400}
          color="#5A5A5A"
          pt="s"
          pb={!feedback.verbatim && subAnswers ? 'm' : 'na'}
          borderBottom={
            !feedback.verbatim && subAnswers && showMore ? `1px solid ${colors.LIGHT}` : 'na'
          }
        >
          {LBL_SIZE}: {feedback.size}
        </Box>
      </Box>
      {feedback.verbatim && (
        <Box
          pt="m"
          pb={subAnswers ? 'm' : 'na'}
          fontSize="1.4rem"
          fontWeight={400}
          lineHeight="110%"
          color="#5A5A5A"
          borderBottom={showMore ? `1px solid ${colors.LIGHT}` : 'na'}
        >
          {feedback.verbatim}
        </Box>
      )}
      <Transition
        in={showMore}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            display="flex"
            flexDirection="column"
            style={{
              ...transitionStylesContent[state],
            }}
          >
            {subAnswers?.map((subAnswer, index) => {
              const rating =
                typeof subAnswer.sub_question_rating === 'number'
                  ? `${subAnswer.sub_question_rating}/5`
                  : subAnswer.sub_question_rating;
              return (
                <Box
                  key={`reviews-sub-answers-${index}`}
                  display="flex"
                  pr="m"
                  pt={index === 0 ? 'm' : 's'}
                  pb={index + 1 === subAnswers.length ? 'm' : 'na'}
                >
                  <Box
                    fontWeight={subAnswer.sub_question_title['fr'] === 'Taille juste' ? 700 : 400}
                    fontSize="1.4rem"
                    color={
                      subAnswer.sub_question_title['fr'] === 'Taille juste' ? '#000' : '#5A5A5A'
                    }
                    lineHeight="110%"
                    display="inline-block"
                  >
                    {subAnswer.sub_question_title['fr']} : {rating}
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Transition>
      {subAnswers && (
        <Box
          borderBottom="solid 1px #000"
          fontSize="1.4rem"
          width="max-content"
          id={`pdp-recommendations-show-${showMore ? 'less' : 'more'}-button-${
            feedback.feedback_id
          }`}
          data-cy={`pdp-reviews-card-show-${showMore ? 'less' : 'more'}-button-${
            feedback.feedback_id
          }`}
          onClick={() => setShowMore(!showMore)}
        >
          Voir {showMore ? 'moins' : 'plus'}
        </Box>
      )}
    </Box>
  );
};

export default ReviewCard;
