import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';
import BrandCta from './BrandCta';
import BrandText from './BrandText';
import { CmsText, CmsImage } from '../types';

type Props = {
  column: string;
  image: CmsImage;
  imageMobile?: CmsImage;
  body?: CmsText;
  ctaText?: string;
  ctaType?: string;
  ctaTypeMobile?: string;
  ctaPosition?: string;
  link?: string;
};

const BrandImage = ({
  body,
  column,
  image: imageDesktop,
  imageMobile,
  ctaText,
  ctaType,
  ctaTypeMobile,
  ctaPosition,
  link,
}: Props) => {
  const navigate = useNavigate();

  const { isDesktop } = useDevice();

  const maxImageWidth = !isDesktop
    ? column === 'quarter'
      ? '50vw'
      : '100vw'
    : column === 'full'
    ? '100vw'
    : column === 'half'
    ? 'calc(calc(100vw - 48px) / 2)'
    : column === 'one-third'
    ? 'calc(calc(100vw - 64px) / 3)'
    : 'calc(calc(100vw - 80px) / 4)';

  const image = !isDesktop && imageMobile?.url ? imageMobile : imageDesktop;

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <>
      <Box
        backgroundImage={`url(${image.url ?? ''})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="contain"
        width={image.dimensions?.width}
        height={image.dimensions?.height}
        maxWidth={maxImageWidth}
        maxHeight={`calc(${maxImageWidth} * ${
          (image.dimensions?.height ?? 0) / (image.dimensions?.width ?? 1)
        })`}
        mx="auto"
        position="relative"
        onClick={link ? handleClick : undefined}
      >
        {ctaText && link && ctaPosition === 'on-image' && (
          <Box position="absolute" bottom="m" left={0} right={0}>
            <BrandCta {...{ ctaText, ctaType, ctaTypeMobile, link, column }} />
          </Box>
        )}
      </Box>
      {body?.[0]?.text && (
        <Box width={image.dimensions?.width} maxWidth={maxImageWidth}>
          <BrandText {...{ body, column }} withImage />
        </Box>
      )}
      {ctaText && link && ctaPosition !== 'on-image' && (
        <BrandCta {...{ ctaText, ctaType, ctaTypeMobile, link, column }} />
      )}
    </>
  );
};

export default BrandImage;
