import * as React from 'react';

import { Box, Icon, Direction, ArrowButton } from '../../../design-system';
import { zIndex } from '../../common/constants';
import { Product } from '../../product/types';

type RecommendationsArrowProps = {
  isHomepage: boolean;
  onClick: () => void;
  hits: Product[];
  NUMBER_OF_ITEMS_SHOWN: number;
  activeIndex: number;
  direction: Direction;
};

export const RecommendationsArrow = ({
  isHomepage,
  onClick,
  hits,
  NUMBER_OF_ITEMS_SHOWN,
  activeIndex,
  direction,
}: RecommendationsArrowProps) => {
  const displayLeft = activeIndex !== 0;
  const displayRight = activeIndex + NUMBER_OF_ITEMS_SHOWN < hits.length;

  return isHomepage ? (
    <Box
      position="absolute"
      zIndex={zIndex.ARROW_SLIDER}
      top="50%"
      {...(direction === Direction.LEFT && { left: '-8px' })}
      {...(direction === Direction.RIGHT && { right: '-8px' })}
      transform="translateY(calc(-50% - 33px))"
    >
      <ArrowButton
        id={`pdp-cross-sale-chevron-${direction}`}
        iconName={`chevron${direction.charAt(0).toUpperCase() + direction.slice(1)}`}
        onClick={onClick}
        {...{ activeIndex }}
        totalItems={hits.length}
        direction={direction}
        numberOfItemsShown={NUMBER_OF_ITEMS_SHOWN}
      />
    </Box>
  ) : (
    <Box display="flex">
      <Box
        id={`pdp-cross-sale-chevron-${direction}`}
        alignItems="center"
        justifyContent="center"
        width="100%"
        onClick={onClick}
        display={
          (direction === Direction.LEFT && displayLeft) ||
          (direction === Direction.RIGHT && displayRight)
            ? 'flex'
            : 'none'
        }
      >
        <Icon name={`chevron${direction.charAt(0).toUpperCase() + direction.slice(1)}`} />
      </Box>
    </Box>
  );
};
