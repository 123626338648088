import * as React from 'react';
import { Box, Icon } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';
import { HorizontalSlider } from '../../common/components/Custom';
import { AffiliateTestimonial } from '../../cms/types';
import { useSwipeable } from 'react-swipeable';

type Props = {
  testimonials: AffiliateTestimonial[];
};

const TestimonialsBlock = ({ testimonials }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { isMobile, isTablet } = useDevice();
  const NUMBER_OF_ITEMS_SHOWN = 1;

  const onClickLeft = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onClickRight = () => {
    if (activeIndex + NUMBER_OF_ITEMS_SHOWN !== testimonials.length) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => (isMobile ? onClickRight() : null),
    onSwipedRight: () => (isMobile ? onClickLeft() : null),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Box
      id="testimonial-block"
      display="flex"
      flexDirection={['column', 'column', 'row']}
      mt={['48px', '64px', '120px']}
      mb={['16px', '64px', '120px']}
      width={['343px', '100%', '1358px']}
      ml={['na', 'na', '200px']}
      flexWrap="wrap"
    >
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        width={['343px', '100%', '100%']}
        alignItems={['center', 'center', 'flex-start']}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={['100%', '706px', '350px']}
          mr={['na', 'na', '64px']}
          zIndex={2}
        >
          <Box
            fontSize={['20px', '30px', '30px']}
            fontFamily="Ogg-text"
            fontWeight="700"
            lineHeight="110%"
          >
            Nous partageons{(isMobile || isTablet) && <br />} nos réussites
          </Box>
          <Box
            width="80px"
            border="solid 1px black"
            my="16px"
            alignSelf={['flex-start', 'inherit', 'inherit']}
          />
          <Box fontSize="16px" lineHeight="130%">
            Nous accordons également une attention toute particulière à votre autonomie en vous
            laissant une grande liberté pour organiser des évènements, gérer les réseaux sociaux...
            c’est ce co-travail qui participe à la réussite et vous permet de vivre la vie qui vous
            va.
          </Box>
          <Box
            mt={['48px', '32px', '54px']}
            flexDirection="row"
            display="flex"
            justifyContent={['na', 'flex-start', 'flex-end']}
            mb={['48px', '64px', 'na']}
          >
            <Box
              id="affiliate-slider-chevron-left"
              alignItems="center"
              justifyContent="center"
              mx={['na', 'na', '32px']}
              display={NUMBER_OF_ITEMS_SHOWN >= testimonials.length ? 'none' : 'flex'}
            >
              <Box
                padding="s"
                border="solid 1px GREY"
                borderRadius="50%"
                height="48px"
                width="48px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={onClickLeft}
                opacity={activeIndex === 0 ? 0.3 : 1}
                cursor={activeIndex === 0 ? 'default !important' : 'pointer !important'}
              >
                <Icon name="arrowLeft" size={16} />
              </Box>
            </Box>
            <Box
              id="affiliate-slider-chevron-right"
              alignItems="center"
              justifyContent="center"
              mx={['48px', '48px', '32px']}
              display={NUMBER_OF_ITEMS_SHOWN >= testimonials.length ? 'none' : 'flex'}
            >
              <Box
                padding="s"
                border="solid 1px GREY"
                borderRadius="50%"
                height="48px"
                width="48px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={onClickRight}
                opacity={activeIndex + NUMBER_OF_ITEMS_SHOWN === testimonials.length ? 0.3 : 1}
                cursor={
                  activeIndex + NUMBER_OF_ITEMS_SHOWN === testimonials.length
                    ? 'default !important'
                    : 'pointer !important'
                }
              >
                <Icon name="arrowRight" size={16} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          backgroundColor={!isMobile ? 'rgba(241, 237, 230, 0.4)' : 'na'}
          height={['auto', '467px', '467px']}
          width={['100%', '626px', '826px']}
          mt={!isMobile ? '-32px' : 'na'}
          ml={!isMobile ? '96px' : 'na'}
        >
          <Box
            id="testimonials-block"
            width={['100%', '722px', '922px']}
            overflow="hidden"
            position={!isMobile ? 'absolute' : 'na'}
            mt={!isMobile ? '32px' : 'na'}
            ml={!isMobile ? '-96px' : 'na'}
          >
            <HorizontalSlider
              display="grid"
              gridAutoFlow="column"
              gridAutoColumns={['353px', '453px', '453px']}
              activeIndex={activeIndex}
              length={testimonials.length}
              width={isMobile ? '353px' : '453px'}
              gridGap="16px"
              {...handlers}
            >
              {testimonials.length > 0 &&
                testimonials.map((testimonial, index) => {
                  if (testimonial.video_testimonial && testimonial.video_testimonial !== '') {
                    return (
                      <Box
                        key={`testimonial-video-${index}`}
                        height={['247px', '300px', '300px']}
                        width={['100%', '100%', '100%']}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                        zIndex={1}
                      >
                        <iframe
                          src={testimonial.video_testimonial}
                          height="100%"
                          width="100%"
                          frameBorder="0"
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                          object-fit="contain"
                        />
                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="16px"
                          backgroundColor="#F1EDE6"
                          borderRadius="30px"
                          px="8px"
                          py={['4px', '4px', '8px']}
                        >
                          <Box fontSize="16px" fontWeight="700">
                            {testimonial.author && testimonial.author !== '' && testimonial.author}
                          </Box>
                          <Box fontSize="16px" fontStyle="italic" fontWeight="500" ml="4px">
                            {testimonial.gender_testimonial &&
                              testimonial.gender_testimonial !== '' &&
                              `- ${testimonial.gender_testimonial}`}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                  if (
                    testimonial.image_testimonial.url &&
                    testimonial.image_testimonial.url !== ''
                  ) {
                    return (
                      <Box
                        key={`testimonial-image-${index}`}
                        display="flex"
                        flexDirection="column"
                        width={['343px', '435px', '453px']}
                        alignItems="flex-start"
                      >
                        <Box
                          width={['343px', '435px', '453px']}
                          height={['192px', '245px', '255px']}
                          backgroundImage={`url(${testimonial.image_testimonial.url})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          backgroundRepeat="no-repeat"
                        />
                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="16px"
                          backgroundColor="#F1EDE6"
                          borderRadius="30px"
                          px="8px"
                          py={['4px', '4px', '8px']}
                        >
                          <Box fontSize="16px" fontWeight="700">
                            {testimonial.author && testimonial.author !== '' && testimonial.author}
                          </Box>
                          <Box fontSize="16px" fontStyle="italic" fontWeight="500" ml="4px">
                            {testimonial.gender_testimonial &&
                              testimonial.gender_testimonial !== '' &&
                              `- ${testimonial.gender_testimonial}`}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                  if (testimonial.text_testimonial && testimonial.text_testimonial !== '') {
                    return (
                      <Box
                        key={`testimonial-text-${index}`}
                        display="flex"
                        flexDirection="column"
                        alignItems="flext-start"
                      >
                        <Box backgroundColor="#F2EDE6" width={['343px', '435px', '453px']} p="24px">
                          <Box
                            display="flex"
                            flexDirection="row"
                            mb="16px"
                            style={{ mixBlendMode: 'multiply' }}
                          >
                            <Icon name="quoteIconsAffiliate" size={30} />
                          </Box>
                          <Box fontSize="14px" lineHeight="130%">
                            {testimonial.text_testimonial}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            mt="16px"
                            flexWrap="wrap"
                            width="auto"
                          >
                            <Box fontSize="16px" fontWeight="700">
                              {testimonial.author &&
                                testimonial.author !== '' &&
                                testimonial.author}
                            </Box>
                            <Box fontSize="16px" fontStyle="italic" fontWeight="500" ml="4px">
                              {testimonial.gender_testimonial &&
                                testimonial.gender_testimonial !== '' &&
                                `- ${testimonial.gender_testimonial}`}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })}
            </HorizontalSlider>
          </Box>
          {isMobile && testimonials.length > 0 && (
            <Box mt="24px" display="flex" alignItems="center" justifyContent="center">
              {testimonials.map((_testimanial, index) => {
                return (
                  <Box
                    key={`bullet-point-testimonial-${index}`}
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    backgroundColor={activeIndex === index ? '#9B9B9B' : '#E6E6E6'}
                    transition="all 0.3s ease-in-out"
                    mx="4px"
                    mb="32px"
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialsBlock;
