import React, { useEffect, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { colors, durations, Icon, opacities } from '../../../design-system';
import { formatPrice } from '../../common/utils';
import { COUPON_ACCEPTED, LBL_ADD, LBL_APPLY, LBL_DELETE, LBL_PROMO_CODE } from '../locale';
import { RootState } from '../../../store/rootReducer';
import { addCoupon, deleteCoupon } from '../actions';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { useOutsideClick } from '../../common/hooks/useOutsideClick';

const StyledCouponWrapper = styled.div<{
  isError: boolean;
  isCursorPointer: boolean;
}>`
  .coupon-input-wrapper {
    height: 50px;
    position: relative;
    background-color: ${colors.WHITE};
    border: solid ${colors.GREY} 1px;
    border-bottom: ${(props) => `solid ${props.isError ? colors.MAIN_ERROR : colors.GREY} 1px`};
    padding: 12px;
    margin-bottom: 16px;
    cursor: ${(props) => (props.isCursorPointer ? 'pointer' : 'initial')};
  }
  .coupon-input-form {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .input-wrapper {
    width: 75%;
    padding: 0 8px;
  }
  .coupon-input {
    width: 100%;
    min-width: 100%;
    text-transform: uppercase;
    color: ${colors.BLACK};
    font-size: 1.6rem;
    border: none;
    cursor: pointer;
    background-color: ${colors.WHITE};

    &:focus {
      outline: none;
      cursor: initial;
      &::placeholder {
        color: ${colors.WHITE};
      }
    }

    &::placeholder {
      text-transform: initial;
      color: ${colors.BLACK};
      font-size: 1.6rem;
      opacity: 1;
    }
  }
  .coupon-input-content {
    width: 80%;
    height: 16px;
    display: flex;
    align-items: center;
  }
  .coupon-input-text {
    color: ${colors.BLACK};
    text-transform: uppercase;
    margin: 0 8px -2px 0;
    font-size: 1.6rem;
  }
  .coupon-cancel-button {
    cursor: pointer;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
  .coupon-input-results-button-wrapper {
    display: flex;
    width: 20%;
    justify-content: flex-end;
  }
  .coupon-code-promo {
    font-size: 1.6rem;
    color: ${colors.MAIN_ERROR};
    margin-bottom: -2px;
  }
  .coupon-apply-or-cancel-button,
  .coupon-cancel-button {
    border: none;
    background-color: unset;
    padding: 0;
  }
  .coupon-apply-or-cancel-button,
  .coupon-input-fake-focus-button {
    cursor: pointer;
    font-size: 1.6rem;
    color: ${colors.BLACK};
    text-decoration: underline;
    text-underline-offset: 8px;
    text-transform: capitalize;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
  .coupon-message-wrapper {
    margin: -8px 0 8px 0;
  }
  .coupon-message-error {
    color: ${colors.MAIN_ERROR};
  }
  .coupon-message-success {
    color: ${colors.MAIN_SUCCESS};
  }
  .coupon-message-error,
  .coupon-message-success {
    font-size: 1.2rem;
  }
`;

export const Coupon = () => {
  const dispatch = useDispatch();
  const { step, cart, couponCodeIsLoading } = useSelector((state: RootState) => state.cart);
  const { couponCode, couponAccepted, totalCouponAmount, rejectCouponMessage } = cart;
  const [promotionCode, setPromotionCode] = useState(couponCode);
  const couponRef = useRef<HTMLDivElement | null>(null);
  const couponInputRef = useRef<HTMLInputElement | null>(null);

  useOutsideClick(
    couponRef,
    !couponCode && promotionCode ? () => setPromotionCode('') : () => null
  );

  useEffect(() => {
    if (couponRef?.current) {
      couponRef.current.addEventListener('click', onInputClick);
    }
    return () => {
      if (couponRef?.current) {
        couponRef.current.removeEventListener('click', onInputClick);
      }
    };
  }, [couponRef]);

  useEffect(() => {
    if (!couponAccepted && couponCode) {
      dispatch(deleteCoupon(couponCode));
    }
    if (!couponAccepted) {
      setPromotionCode('');
    }
  }, [step]);

  useEffect(() => {
    if (!couponCodeIsLoading && promotionCode && !couponAccepted) {
      onInputClick();
    }
  }, [couponCodeIsLoading]);

  const applyPromotion = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (promotionCode) {
      dispatch(addCoupon(promotionCode));
    }
  };

  const cancelPromotion = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (couponCode) {
      dispatch(deleteCoupon(couponCode));
    }
    setPromotionCode('');
  };

  const onInputClick = () => {
    if (couponInputRef && couponInputRef.current) {
      couponInputRef.current.focus();
    }
  };

  return (
    <StyledCouponWrapper
      id="promo-code-block"
      ref={couponRef}
      isError={Boolean(!couponAccepted && promotionCode?.toUpperCase() === couponCode)}
      isCursorPointer={!couponAccepted}
    >
      <div className="coupon-input-wrapper">
        {couponCodeIsLoading ? (
          <LoadingScreen size={32} />
        ) : (
          <form
            className="coupon-input-form"
            onSubmit={
              couponCode === promotionCode?.toUpperCase() ? cancelPromotion : applyPromotion
            }
          >
            <Icon name="promocode" size={22} />
            <div className="input-wrapper">
              {!couponAccepted && (
                <input
                  className="coupon-input"
                  placeholder={LBL_PROMO_CODE}
                  value={promotionCode ?? ''}
                  onChange={(event) => setPromotionCode(event.target.value)}
                  ref={couponInputRef}
                />
              )}
              {couponAccepted && (
                <div className="coupon-input-content">
                  <p className="coupon-input-text">{couponCode}</p>
                  <button
                    type="submit"
                    className="coupon-cancel-button"
                    data-testid="promo-code-delete-button"
                  >
                    <Icon name="close" size={16} />
                  </button>
                </div>
              )}
            </div>
            <div className="coupon-input-results-button-wrapper">
              {couponAccepted && (
                <span className="coupon-code-promo">{`-${formatPrice(totalCouponAmount)}`}</span>
              )}
              {!couponAccepted && promotionCode && (
                <button
                  className="coupon-apply-or-cancel-button"
                  type="submit"
                  data-testid={`promo-code-${
                    couponCode === promotionCode?.toUpperCase() ? 'cancel' : 'apply'
                  }-button`}
                >
                  {couponCode === promotionCode?.toUpperCase() ? LBL_DELETE : LBL_APPLY}
                </button>
              )}
              {!couponAccepted && !promotionCode && (
                <div className="coupon-input-fake-focus-button">{LBL_ADD}</div>
              )}
            </div>
          </form>
        )}
      </div>
      {couponCode && promotionCode?.toUpperCase() === couponCode && (
        <div className="coupon-message-wrapper" id="promo-code-feed-back-message">
          {!couponAccepted && rejectCouponMessage && (
            <p className="coupon-message-error">{rejectCouponMessage}</p>
          )}
          {couponAccepted && <p className="coupon-message-success">{COUPON_ACCEPTED}</p>}
        </div>
      )}
    </StyledCouponWrapper>
  );
};
