import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import pathOr from 'ramda/src/pathOr';
import { useLocation } from 'react-router-dom';

import { StyledLink } from '../../../design-system';
import { SubMenu } from './Submenu';
import { RootState } from '../../../store/rootReducer';
import { makeMenuTree } from '../utils';
import { MenuTreeItem } from '../types';
import usePrevious from '../../common/hooks/usePrevious';

export const MAX_NUMBER_OF_FIRST_LEVEL = 12;

const StyledMenu = styled.nav`
  .menu__list-container {
    margin: 1px 0;
    display: flex;
    overflow: hidden;
    height: 46px;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0;
  }
  .menu__item {
    display: inline-block;
    list-style: none;
  }
`;

export const Menu = () => {
  const { key, pathname } = useLocation();

  const cmsContent = useSelector((state: RootState) => state.cms.menu);
  const items = pathOr([], ['items'], cmsContent);
  const tree = makeMenuTree(items);

  const [selected, setSelected] = React.useState<MenuTreeItem>();
  const [isOpen, setIsOpen] = React.useState(false);

  const closeSubmenu = () => {
    setSelected(undefined);
    setIsOpen(false);
  };

  const onMenuClick = () => {
    closeSubmenu();
  };

  const handleMouseEnter = (item: MenuTreeItem) => {
    const { columns = [], imageBlock = [] } = item;
    if (columns.length > 0 || imageBlock.length > 0) {
      setSelected(item);
      setIsOpen(true);
    } else {
      setSelected(item);
      setIsOpen(false);
    }
  };

  const prevKey = usePrevious(key);

  React.useEffect(() => {
    if (prevKey) {
      closeSubmenu();
    }
  }, [key]);

  return (
    <StyledMenu onMouseLeave={closeSubmenu}>
      <ul className="menu__list-container">
        {tree.slice(0, MAX_NUMBER_OF_FIRST_LEVEL).map((item, index) => {
          const isSelected = Boolean(
            selected?.name === item.name ||
              (item.link &&
                (pathname.startsWith(item.link) || pathname.startsWith(`/${item.link}`)))
          );
          return (
            <li key={`${item.name}-${index}`} className="menu__item">
              <UnderlineLink
                data-testid={`link-menu-${item.name}`}
                aria-label={`Lien vers la page ${item.name}`}
                className="link-menu"
                id={`link-menu-${item.name}`}
                decoration="none"
                onMouseEnter={() => handleMouseEnter(item)}
                onClick={onMenuClick}
                title={item.name}
                to={item.link ?? '#'}
                underline={isSelected ? 'true' : undefined}
              >
                {item.name}
              </UnderlineLink>
            </li>
          );
        })}
      </ul>
      <SubMenu isOpen={isOpen} selected={selected} />
    </StyledMenu>
  );
};

type UnderlineLinkProps = {
  underline: string | undefined;
};
const UnderlineLink = styled(StyledLink)<UnderlineLinkProps>(
  {
    '::before': {
      display: 'block',
      content: 'attr(title)',
      height: 0,
      overflow: 'hidden',
      visibility: 'hidden',
    },
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    color: '#FFF',
    margin: '2px 0',
    display: 'block',
    padding: '12px 0',
  },
  ({ underline }) => {
    return (
      underline && {
        '&:after': {
          transform: 'scaleX(1)!important',
          transformOrigin: '0 100%!important',
        },
      }
    );
  }
);

export default Menu;
