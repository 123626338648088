import * as React from 'react';

import { Box } from '../../../design-system';
import { Title, H2 } from '../../common/components/Custom';
import { RecommendationTypes } from '../types';

type Props = {
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  type: RecommendationTypes;
  title: string;
};

export const RecommendationsTitleT2S = ({ isFeedback, isDesktopFeedback, type, title }: Props) => {
  if (!title) {
    return null;
  }

  return (
    <Box
      mt={
        isFeedback
          ? 'm'
          : type === RecommendationTypes.upSell
          ? ['l', 'l', 'xl']
          : isDesktopFeedback
          ? 'm'
          : type === RecommendationTypes.crossSell
          ? 'l'
          : type === RecommendationTypes.home
          ? ''
          : ['xxl', 'xxl', 'xxxl']
      }
      mb={
        isFeedback
          ? 'm'
          : type === RecommendationTypes.upSell
          ? ['m', 'm', 'l']
          : type === RecommendationTypes.crossSell
          ? 'm'
          : type === RecommendationTypes.home
          ? ['16px', '24px']
          : ['l', 'l', 'xxl']
      }
      mx={['m', 'na']}
      textAlign={
        type === RecommendationTypes.cart || type === RecommendationTypes.crossSell || isFeedback
          ? 'left'
          : 'center'
      }
      pl={type === RecommendationTypes.cart ? 'xxl' : 'na'}
    >
      {type === RecommendationTypes.crossSell ? (
        <H2
          preset={
            isFeedback
              ? 'subheadingPromoBanner'
              : isDesktopFeedback
              ? 'desktopFeedbackModal'
              : 'heading'
          }
          fontFamily={isFeedback ? 'MaisonNeue' : '"Ogg-text", serif'}
          fontWeight={'700'}
        >
          {title}
        </H2>
      ) : (
        <Title>{title}</Title>
      )}
    </Box>
  );
};
