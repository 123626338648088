/* eslint-disable prefer-destructuring */
export const ADYEN_CLIENT_KEY = process.env.ADYEN_CLIENT_KEY || '';
export const ADYEN_GATEWAY_MERCHANT_ID = process.env.ADYEN_GATEWAY_MERCHANT_ID || '';
export const APPLE_LOGIN_APP_ID = process.env.APPLE_LOGIN_APP_ID || '';
export const AWS_APPSYNC_API_URL = process.env.AWS_APPSYNC_API_URL || '';
export const AWS_APPSYNC_REGION = process.env.AWS_APPSYNC_REGION || '';
export const AWS_AUTH_REGION = process.env.AWS_AUTH_REGION || '';
export const AWS_AUTH_USER_POOL_ID = process.env.AWS_AUTH_USER_POOL_ID || '';
export const AWS_AUTH_USER_POOL_WEB_CLIENT_ID = process.env.AWS_AUTH_USER_POOL_WEB_CLIENT_ID || '';
export const AWS_IDENTITY_POOL_ID = process.env.AWS_IDENTITY_POOL_ID || '';
export const CDN_URL = process.env.CDN_URL || '';
export const CDN_S3_BUCKET = process.env.CDN_S3_BUCKET || '';
export const FACEBOOK_DOMAIN_VERIFICATION = process.env.FACEBOOK_DOMAIN_VERIFICATION || '';
export const FACEBOOK_LOGIN_APP_ID = process.env.FACEBOOK_LOGIN_APP_ID || '';
export const GOOGLE_LOGIN_APP_ID = process.env.GOOGLE_LOGIN_APP_ID || '';
export const GOOGLE_MAP_API_KEY = process.env.GOOGLE_MAP_API_KEY || '';
export const GOOGLE_PAY_MERCHANT_ID = process.env.GOOGLE_PAY_MERCHANT_ID || '';
export const GOOGLE_SITE_VERIFICATION = process.env.GOOGLE_SITE_VERIFICATION || '';
export const GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID || '';
export const ORIGIN_URL = process.env.ORIGIN_URL || '';
export const OPEN_STREET_MAP_URL = process.env.OPEN_STREET_MAP_URL || '';
export const OPEN_STREET_MAP_REVERSE_URL = process.env.OPEN_STREET_MAP_REVERSE_URL || '';
export const PAYMENT_API_URL = process.env.PAYMENT_API_URL || '';
export const SEARCH_API_KEY = process.env.SEARCH_API_KEY || '';
export const SEARCH_APP_ID = process.env.SEARCH_APP_ID || '';
export const SENTRY_DSN = process.env.SENTRY_DSN || '';
export const SHIPUP_KEY = process.env.SHIPUP_KEY || '';
export const SOCLOZ_KEY = process.env.SOCLOZ_KEY || '';
export const STG_HTTP_ACCESS_CREDENTIALS = process.env.STG_HTTP_ACCESS_CREDENTIALS || '';
export const TARGET2SELL_URL = process.env.TARGET2SELL_URL || '';
export const TARGET2SELL_CUSTOMER_ID = process.env.TARGET2SELL_CUSTOMER_ID || '';
export const WEBSOCKET_URL = process.env.WEBSOCKET_URL || '';
export const PRISMIC_IMAGE_DOMAIN = process.env.PRISMIC_IMAGE_DOMAIN || '';
export const PRISMIC_STATICS_DOMAIN = process.env.PRISMIC_STATICS_DOMAIN || '';
export const APP_VERSION = process.env.npm_package_version || '';
export const DATADOG_APPLICATION_ID = process.env.DATADOG_APPLICATION_ID || '';
export const DATADOG_CLIENT_TOKEN = process.env.DATADOG_CLIENT_TOKEN || '';
