import { action, ActionType } from 'typesafe-actions';

import types from './actionTypes';

export const setOngoingPartialPayment = (payload: boolean) =>
  action(types.SET_ONGOING_PARTIAL_PAYMENT, payload);

export const setCardDeleted = (payload: string) => action(types.SET_CARD_DELETED, payload);

export const setForterToken = (payload: string) => action(types.SET_FORTER_TOKEN, payload);

export type PaymentActions = ActionType<
  typeof setOngoingPartialPayment | typeof setCardDeleted | typeof setForterToken
>;
