import * as React from 'react';
import { Box, Icon, Anchor } from '../../design-system';
import { useDispatch } from 'react-redux';
import { closeModal } from '../common/actions';
import { useDevice } from '../common/hooks/useDevice';
import { colors } from '../../design-system/systemprovider';
import { persistData } from '../common/utils';

export const PaypalExpressModalError = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();

  React.useEffect(() => {
    return () => {
      persistData('isPaypalECS', false);
      persistData('subscribePaypalECS', false);
    };
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      mx="m"
      mt="m"
      mb="xxl"
      flexDirection="column"
      id="paypal-express-modal-error"
    >
      <Box alignSelf="end">
        <Icon name="closeSmall" size={20} onClick={() => dispatch(closeModal())} />
      </Box>
      <Box textAlign="center" mt="m" mb="l" fontSize={['20px', '30px']} fontWeight={700}>
        Oups...
      </Box>
      <Box
        fontSize={['14px', '16px']}
        lineHeight={['18px', '20px']}
        textAlign="center"
        width="80%"
        mb="xl"
      >
        <Box>
          <Box mb="xs">
            <span style={{ color: colors.ERROR }}>Une erreur est survenue</span> lors de la
            validation du paiement avec le raccourci PayPal Express.
          </Box>
          Accédez à votre panier en{' '}
          <Anchor
            onClick={() => dispatch(closeModal())}
            id="paypal-express-modal-error-close"
            style={{
              fontSize: isMobile ? '14px' : '16px',
              lineHeight: isMobile ? '18px' : '20px',
              color: 'black',
              borderBottom: 'none',
              textDecoration: 'underline',
              paddingBottom: 0,
            }}
          >
            cliquant ici
          </Anchor>{' '}
          ou{' '}
          <Anchor
            id="paypal-express-modal-error-link-customer-services"
            href="mailto:contact.darjeeling@groupechantelle.com"
            target="_blank"
            style={{
              fontSize: isMobile ? '14px' : '16px',
              lineHeight: isMobile ? '18px' : '20px',
              color: 'black',
              borderBottom: 'none',
              textDecoration: 'underline',
              paddingBottom: 0,
            }}
          >
            contactez notre service client
          </Anchor>
          .
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <img
          height="20px"
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="PayPal"
        />
      </Box>
    </Box>
  );
};
