import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';

import { initDataLayer } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { pushToGTM } from '../utils';
import { Events } from '../types';
import { CartStep } from '../../cart/types';
import { getPersistedData } from '../../common/utils';

export default function useTracking() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const cartDetail = useSelector((state: RootState) => state.cart.cart);
  const cartStep = useSelector((state: RootState) => state.cart.step);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const userDetail = useSelector((state: RootState) => state.account.user);
  const product = useSelector((state: RootState) => state.product.product);
  const catalog = useSelector((state: RootState) => state.catalog.catalog);
  const dataLayer = useSelector((state: RootState) => state.tracking.dataLayer);

  const [hasReset, setHasReset] = React.useState(false);

  const UPDATE_DELAY = 2000;

  const reset = async () => {
    await new Promise((resolve) => setTimeout(resolve, UPDATE_DELAY));
    setHasReset(true);
  };

  React.useEffect(() => {
    reset();
  }, [pathname, search, cartStep]);

  React.useEffect(() => {
    if (hasReset) {
      setHasReset(false);
      dispatch(
        initDataLayer({
          pathname,
          search,
          cartStep,
          isLoggedIn,
          userInfo,
          userDetail,
          product,
          catalog,
          cartDetail: cartStep === CartStep.SUCCESS ? getPersistedData('orderDetail') : cartDetail,
        }) as unknown as UnknownAction
      );
    }
  }, [hasReset]);

  React.useEffect(() => {
    if (dataLayer.env_work) {
      pushToGTM(Events.updateDataLayer, dataLayer);
    }
  }, [dataLayer]);
}
