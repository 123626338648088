import { PaymentAction, PaymentAmount } from '@adyen/adyen-web/dist/types/types';

export enum PaymentResults {
  Authorised = 'Authorised',
  REDACTED = 'REDACTED',
  CANCELLED = 'CANCELLED',
  REFUSED = 'Refused',
}

export type State = {
  isOngoingPartialPayment: boolean;
  cardDeleted: string;
  forterToken: string;
};

export type StoredPayments = {
  holderName: string;
  expiryMonth: string;
  expiryYear: string;
};

export type PaymentMethod = {
  brands?: string[];
  configuration?: {
    merchantId: string;
    gatewayMerchantId?: string;
    intent?: string;
    merchantName?: string;
  };
  details?: {
    key: string;
    optional?: boolean;
    type: string;
  }[];
  name: string;
  type: string;
};

export type StoredPaymentMethod = {
  brand: string;
  expiryMonth: string;
  expiryYear: string;
  holderName: string;
  id: string;
  lastFour: string;
  name: string;
  supportedShopperInteractions: string[];
  type: string;
};

export type MakePaymentData = {
  riskData: {
    clientData: string;
  };
  paymentMethod: {
    type: string;
    subtype?: string;
    holderName?: string;
    encryptedCardNumber?: string;
    encryptedExpiryMonth?: string;
    encryptedExpiryYear?: string;
    encryptedSecurityCode?: string;
    brand?: string;
    storedPaymentMethodId?: string;
    googlePayToken?: string;
    googlePayCardNetwork?: string;
  };
  browserInfo?: {
    acceptHeader: string;
    colorDepth: number;
    language: string;
    javaEnabled: boolean;
    screenHeight: number;
    screenWidth: number;
    userAgent: string;
    timeZoneOffset: number;
  };
  clientStateDataIndicator: boolean;
  storePaymentMethod?: boolean;
};

export type OnSubmitState = {
  data: MakePaymentData;
  isValid: boolean;
};

export enum RefusalReason {
  EXPIRED_TOKEN = 'Invalid or expired token',
  DECLINED = 'Declined',
}

export type MakePaymentResponse = {
  resultCode: string;
  orderNumber: string;
  action?: PaymentAction;
  refusalReason?: string;
  error?: {
    message: string;
  };
  order?: { orderData: string; pspReference: string; remainingAmount: PaymentAmount };
};

export type AdditionalDetailsState = {
  details: {
    threeDSResult: string;
    paymentSource: string;
  };
  paymentData?: string;
};

export type PaypalDataEvent = {
  correlationID?: string;
};
