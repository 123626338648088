import React, { useEffect } from 'react';
import { CmsImage } from '../../cms/types';
import { Box } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';

type Props = {
  images: CmsImage[];
};

export const ImagesScrollEffect = ({ images }: Props) => {
  const [imgPosition, setImgPosition] = React.useState<DOMRect[]>([]);
  const { isTablet } = useDevice();

  if (!images) {
    return null;
  }

  const updatePosition = () => {
    const imgPosition = images.map((_image, index) => {
      const elem = document.getElementById(`image-animated-${index}`) as HTMLElement;
      return elem.getBoundingClientRect();
    });
    setImgPosition(imgPosition);
    return imgPosition;
  };

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);
    return () => {
      window.removeEventListener('scroll', updatePosition);
    };
  }, [updatePosition]);

  return (
    <>
      {images.map((image, index) => {
        let opacity: number;
        const height = isTablet ? 271 : 363;
        const offset = isTablet ? 150 : 200;
        if (index === images.length - 1) {
          opacity = 1;
        } else {
          if (isNaN(imgPosition[index + 1]?.top)) {
            opacity = 1;
          } else {
            opacity = (imgPosition[index + 1]?.top - offset) / height;
          }
        }

        return (
          <Box
            id={`image-animated-${index}`}
            key={`image-animated-${index}`}
            width={['343px', '403px', '571px']}
            height={['216px', '271px', '363px']}
            backgroundImage={`url(${image.url})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            my={['16px', '16px', '16px']}
            position="sticky"
            top={offset}
            opacity={opacity}
          />
        );
      })}
    </>
  );
};
