import * as React from 'react';

import { Carousel, Box } from '../../../design-system';
import { getImageList, isItGiftCard } from '../utils';
import { useDevice } from '../../common/hooks/useDevice';
import { BackButton } from './BackButton';
import { zIndex } from '../../common/constants';
import WishlistCta from '../../wishlist/components/WishlistCta';
import { ModelPDP, Product } from '../types';

type Props = {
  imagePositions: number[];
  productRef: string;
  colorRef: string;
  productName: string;
  colorLabel: string;
  product: Product;
  videoUrl?: string;
  cmsThumbnailImage?: string;
  breadcrumbItems: { label: string; value: string }[];
  model?: ModelPDP[] | null;
};

const Images = ({
  imagePositions,
  productRef,
  colorRef,
  productName,
  colorLabel,
  cmsThumbnailImage,
  videoUrl,
  product,
  breadcrumbItems,
  model,
}: Props) => {
  const images = getImageList({
    imagePositions,
    productRef,
    colorRef,
    productName,
    colorLabel,
  });
  const carouselImages = images.map((image) => image.default);

  const { isMobile } = useDevice();

  const video = {
    url: videoUrl,
    thumbnail: cmsThumbnailImage,
  };

  const fragments = {
    wishlist: (
      <Box
        position="absolute"
        top={isMobile ? 'initial' : 'l'}
        bottom={isMobile ? 'l' : 'initial'}
        right="l"
        zIndex={zIndex.PRODUCT_QUICK_ADD}
      >
        <WishlistCta
          productRef={productRef}
          colorRef={colorRef}
          product={product}
          objectID={`${productRef}/${colorRef}`}
        />
      </Box>
    ),
  };

  const isGiftCard = isItGiftCard(productRef);

  return (
    <Carousel
      images={carouselImages}
      model={model}
      {...{ video }}
      width={isMobile ? '100vw' : '50vw'}
      imagePositions={imagePositions}
    >
      {isMobile && <BackButton breadcrumbItems={breadcrumbItems} />}
      {!isGiftCard && fragments.wishlist}
    </Carousel>
  );
};

export default Images;
