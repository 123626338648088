import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { closeModal, openModal } from '../../common/actions';
import { getTrackingProduct, pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { formatPrice } from '../../common/utils';
import { getQuickAddModalPreset } from '../../quickadd/utils';
import { Product } from '../../product/types';

type Props = {
  product: Product;
  className: string;
};

export const DotProduct = ({ product, className }: Props) => {
  const { isMobile, isTablet } = useMediaQueries();
  const dispatch = useDispatch();
  const [showMobileQuickAdd, setShowMobileQuickAdd] = useState(false);

  const { productRef, colorRef, productName, storePrice, sizeVariants } = product ?? {};
  const objectID = `${productRef}/${colorRef}`;

  if (!product) {
    return null;
  }

  const onQuickAddClick = () => {
    if (isMobile) {
      setShowMobileQuickAdd(true);
    } else {
      dispatch(closeModal());
      dispatch(
        openModal({
          content: <QuickAdd productRef={productRef} colorRef={colorRef} />,
          preset: getQuickAddModalPreset({ sizeVariants: sizeVariants ?? [], isTablet }),
        })
      );
    }

    pushToGTM(Events.quickAddOpen, {
      product: getTrackingProduct({ product }),
    });
  };

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={onQuickAddClick}
        id={`product-card-dot-${objectID}`}
        data-cy={`product-card-dot-${objectID}`}
      >
        <div className={`top-ten-tool-tip__${className.split('__')?.[1]}`}>
          <p>
            {productName ?? 'Nom du produit'}
            <span>{formatPrice(storePrice)}</span>
          </p>
        </div>
      </button>
      <QuickAddMobile
        productRef={productRef}
        colorRef={colorRef}
        isActive={showMobileQuickAdd}
        onClose={() => setShowMobileQuickAdd(false)}
      />
    </>
  );
};
