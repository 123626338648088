import { hexToCSSFilter } from 'hex-to-css-filter';

import { CDN_S3_BUCKET } from '../../modules/api/constants';

export function getIconUrl(name: string): string {
  return `https://${CDN_S3_BUCKET}/icons/${name}.svg`;
}

export async function fetchIcon({ name, signal }: { name: string; signal?: AbortSignal }) {
  const url = getIconUrl(name);
  try {
    const response = await fetch(url, { signal });
    if (response.ok) {
      const text = await response.text();
      return text;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function getCSSFilterFromHex({
  color,
  theme,
}: {
  color: string;
  theme: { colors: { [key: string]: string } };
}) {
  try {
    const hex = theme.colors[color] ?? color;
    if (['#fff' || '#ffffff'].includes(hex)) {
      return 'invert(100%)';
    }
    if (['#000' || '#000000'].includes(hex)) {
      return undefined;
    }
    return hexToCSSFilter(hex)?.filter;
  } catch (error) {
    return undefined;
  }
}
