import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { useSelector } from 'react-redux';

import { Box, Text, Icon } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { CmsBox } from '../../common/components/Custom';

const PaymentToolTipModal = () => {
  const cmsContent = useSelector((state: RootState) => state.cms.payment);

  const {
    tooltip_icon: tooltipIcon,
    tooltip_reasons: tooltipReasons,
    tooltip_title: tooltipTitle,
  } = cmsContent ?? {};

  return (
    <Box display="grid" gridGap="s" my="m" mx={['na', 'xxxl', 'l']} color="BLACK" textAlign="left">
      <Box
        display="grid"
        gridGap="s"
        justifyContent="left"
        alignItems="center"
        gridAutoFlow="column"
      >
        <Text preset="subheading">{tooltipTitle}</Text>
        {tooltipIcon && <Icon name={tooltipIcon} />}
      </Box>
      <CmsBox>{tooltipReasons?.[0]?.text && <RichText render={tooltipReasons} />}</CmsBox>
    </Box>
  );
};

export default PaymentToolTipModal;
