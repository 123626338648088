import React from 'react';
import { RichText } from 'prismic-reactjs';
import { css } from '@emotion/core';

import { Box, breakpoints, StyledLink } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { CmsBox } from '../../common/components/Custom';
import { emptyText } from '../../cms/state';
import {
  CmsHomepagePromoCategoriesByButton,
  CmsHomepagePromoCategoriesByButtonItems,
} from '../../cms/types';
import { BannerCta } from '../components/BannerCta';

type Props = {
  id: string;
  content: CmsHomepagePromoCategoriesByButton;
  ctaList: CmsHomepagePromoCategoriesByButtonItems[];
};

export function PromoCategoriesByButton({ id, content, ctaList }: Props) {
  const { isMobile } = useMediaQueries();
  const {
    title,
    title_mobile,
    description,
    description_mobile,
    background_image,
    background_image_mobile,
    title_color,
    description_color,
  } = content ?? {};

  if (
    !background_image?.dimensions?.width ||
    !background_image_mobile?.dimensions?.width ||
    !background_image?.dimensions?.height ||
    !background_image_mobile?.dimensions?.height
  ) {
    return null;
  }

  return (
    <div
      id={id}
      css={css`
        position: relative;
      `}
    >
      <img
        src={isMobile ? background_image_mobile.url : background_image.url}
        width="100%"
        alt={
          isMobile
            ? background_image_mobile.alt ?? 'promo-categories-by-button-image-description-mobile'
            : background_image.alt ?? 'promo-categories-by-button-image-description'
        }
      />
      <div
        css={css`
          width: 100%;
          position: absolute;
          bottom: 32px;
          left: 50%;
          transform: translate(-50%, 0%);
          @media (min-width: ${breakpoints.S}px) {
            width: 512px;
            max-width: 512px;
          }
        `}
      >
        <CmsBox>
          <Box color={title_color}>
            <RichText render={isMobile ? title_mobile : title ?? [...emptyText]} />
          </Box>
          <Box color={description_color} paddingTop={'16px'}>
            <RichText render={isMobile ? description_mobile : description ?? [...emptyText]} />
          </Box>
        </CmsBox>
        <Box
          width={isMobile ? '100%' : '512px'}
          display={'grid'}
          gridTemplateColumns={isMobile || ctaList.length === 1 ? '1fr' : '1fr 1fr'}
          gridColumnGap={24}
          gridRowGap={isMobile ? 16 : 24}
          marginTop="32px"
          marginX={'auto'}
        >
          {ctaList.map((cta, i) => (
            <Box
              key={`hp-promo-categories-by-button-cta-${`${cta.cta_name}+${i}`}`}
              width={isMobile ? '100%' : '244px'}
              margin={'auto'}
              height={'44px'}
              paddingX={isMobile ? 16 : 0}
            >
              <StyledLink
                id={`hp-promo-categories-by-button-cta-link-${`${i}`}`}
                to={cta.link ?? ''}
              >
                <BannerCta
                  id={`hp-promo-categories-by-button-cta-${`${i}`}`}
                  type={cta.cta_type ?? 'black button'}
                  text={cta.cta_name ?? ''}
                  color={cta.cta_background_color}
                  fontColor={cta.cta_font_color}
                />
              </StyledLink>
            </Box>
          ))}
        </Box>
      </div>
    </div>
  );
}
