import * as React from 'react';
import { css } from '@emotion/core';

import { StyledLink, Icon, breakpoints, colors } from '../../../design-system';

type Props = {
  title: string;
  links: {
    icon?: string;
    text?: string;
    link?: string;
  }[];
  isTitleCenter: boolean;
};

const IconLinks = ({ title, links, isTitleCenter }: Props) => {
  return (
    <div
      id={`footer-icon-links-${title}`}
      css={css`
        display: grid;
        grid-gap: 8px;
        align-items: center;
        justify-content: left;
        @media (min-width: ${breakpoints.L}px) {
          grid-gap: 24px;
          justify-content: center;
        }
      `}
    >
      <h3
        css={css`
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          margin: ${isTitleCenter ? 'auto' : 'initial'};
        `}
      >
        {title}
      </h3>
      <div
        css={css`
          display: grid;
          grid-auto-flow: column;
          grid-gap: none;
          overflow-x: scroll;
          @media (min-width: ${breakpoints.L}px) {
            grid-gap: 40px;
            overflow-x: initial;
          }
        `}
      >
        {links.map((link, index) => (
          <StyledLink
            key={index}
            id={`footer-icon-links-${title}-link-${index}`}
            decoration="none"
            to={link?.link ?? ''}
          >
            <div
              css={css`
                display: grid;
                grid-gap: 8px;
                align-items: center;
                justify-content: center;
                border: 1px solid black;
                width: 122px;
                height: 70px;
                @media (min-width: ${breakpoints.L}px) {
                  border: initial;
                  width: auto;
                  height: auto;
                }
              `}
            >
              <div
                css={css`
                  display: grid;
                  justify-content: center;
                  align-self: end;
                  @media (min-width: ${breakpoints.L}px) {
                    align-self: initial;
                  }
                `}
              >
                <Icon name={link?.icon ?? ''} size={24} />
              </div>
              <p
                css={css`
                  color: ${colors.GREY};
                  font-size: 14px;
                  align-self: start;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  @media (min-width: ${breakpoints.L}px) {
                    align-self: initial;
                  }
                `}
              >
                {link?.text ?? ''}
              </p>
            </div>
          </StyledLink>
        ))}
      </div>
    </div>
  );
};

export default IconLinks;
