import * as React from 'react';

import { Box, Button, Anchor } from '../../../design-system';
import { PickupStation as PickupStationType } from '../types';
import { Position } from '../../store-locator/types';
import { SEE_ON_THE_MAP, SELECT_PICKUP_STATION, SELECTED_PICKUP_STATION } from '../locale';
import StationInfo from './StationInfo';
import { useDevice } from '../../common/hooks/useDevice';

export type Props = {
  station: PickupStationType;
  isMarked: boolean;
  isSelected: boolean;
  pickupStationRefs: object;
  index: number;
  isDelivery?: boolean;
  currentPosition?: Position;
  onClick: (id: string) => void;
  onMapLinkClick: (id: string) => void;
  onSelect?: (station: PickupStationType) => void;
};

const PickupStation = ({
  station,
  isMarked,
  isSelected,
  pickupStationRefs,
  index,
  currentPosition,
  onClick,
  onSelect,
  onMapLinkClick,
}: Props) => {
  const { isDesktop, isMobile } = useDevice();

  React.useEffect(() => {
    if (isMarked) {
      const { current } = pickupStationRefs[index];
      current.parentNode.scrollTop = current.offsetTop - current.parentNode.offsetTop - 16;
    }
  }, [isMarked]);

  React.useEffect(() => {
    if (isSelected && !isDesktop) {
      handleClick();
    }
  }, [isSelected, isDesktop]);

  const handleClick = () => {
    onClick(station.id);
  };

  const handleMapLinkClick = () => {
    isDesktop ? handleClick() : onMapLinkClick(station.id);
  };

  const handleSelect = () => {
    typeof onSelect === 'function' ? onSelect(station) : undefined;
  };

  return (
    <Box
      display="grid"
      gridGap={['na', 'na', 'm']}
      gridTemplateColumns={['1fr', '1fr', '1fr']}
      gridAutoRows="min-content"
      mx="s"
      my={['s', 's', 'm']}
      onClick={handleClick}
      id={`pickup-station-${index}-box`}
    >
      <StationInfo {...{ index, station, currentPosition }} />

      <Box alignSelf="left" display="flex" alignItems="left" flexDirection="column">
        <Box width={['100%', '100%', 'min-content']} my={['s', 'na', 'na']}>
          <Button
            id={`pickup-station-${index}-button`}
            preset={isSelected ? 'secondary' : 'subtle'}
            onClick={handleSelect}
          >
            {isSelected ? SELECTED_PICKUP_STATION : SELECT_PICKUP_STATION}
          </Button>
        </Box>
        {isMobile && (
          <Box mb="s" alignSelf="center">
            <Anchor
              id={`pickup-station-${index}-link`}
              type="button"
              onClick={handleMapLinkClick}
              preset="caption"
            >
              {SEE_ON_THE_MAP}
            </Anchor>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PickupStation;
