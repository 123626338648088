import * as React from 'react';

import Field, { Props } from '../Field';

const PasswordField = (props: Props) => {
  const [isHidden, setIsHidden] = React.useState(true);
  return (
    <Field
      label="Mot de passe*"
      type={isHidden ? 'password' : 'text'}
      icon={isHidden ? 'eyeOn' : 'eyeOff'}
      onIconClick={() => setIsHidden(!isHidden)}
      {...props}
    />
  );
};

export default PasswordField;
