import * as React from 'react';
import { useSelector } from 'react-redux';

import { Box, Icon, Text, Divider } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { numberOfItemsSelector } from '../../cart/selectors';
import { numberOfWishlistItemsSelector } from '../../wishlist/selectors';
import wishlistLocale from '../../wishlist/locale';
import { TITLE_CART } from '../locale';
import WishlistItemList from '../../wishlist/components/WishlistItemList';
import CartItemList from './CartItemList';

const MultiItemList = () => {
  const numberOfCartItems = useSelector((state: RootState) => numberOfItemsSelector(state));
  const numberOfWishlistItems = useSelector((state: RootState) =>
    numberOfWishlistItemsSelector(state)
  );

  const [open, setOpen] = React.useState(0);

  const content = [
    {
      title: TITLE_CART,
      icon: 'shoppingCart',
      numberOfItems: numberOfCartItems,
      component: <CartItemList />,
    },
    {
      title: wishlistLocale.WISHLIST,
      icon: 'heart',
      numberOfItems: numberOfWishlistItems,
      component: <WishlistItemList />,
    },
  ];

  const numberOfTabs = content.length;

  return (
    <Box display="grid" id="box-multilist">
      <Box
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns={`repeat(${numberOfTabs}, 1fr)`}
        alignItems="center"
        justifyContent={['stretch', 'left']}
      >
        {content.map((item, index) => (
          <Box key={index} onClick={() => setOpen(index)} display="grid">
            <Box
              display="grid"
              gridAutoFlow="column"
              gridGap="s"
              alignItems="center"
              justifyContent="center"
              id={`box-multilist-item-${index}`}
              mt="s"
              mb="m"
              mx="m"
            >
              <Icon size={16} name={item.icon} color={index === open ? 'BLACK' : 'GREY'} />
              <Text
                id={`text-multilist-item-${index}`}
                preset="subheading"
                fontWeight={index === open ? 'bold' : 'regular'}
                color={index === open ? 'BLACK' : 'GREY'}
              >
                {`${item.title} (${item.numberOfItems})`}
              </Text>
            </Box>
            {index === open && <Divider color="BLACK" />}
          </Box>
        ))}
      </Box>
      <Divider color="BLACK" />
      {content[open].component}
    </Box>
  );
};

export default MultiItemList;
