import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Text, Selector, breakpoints } from '../../../design-system';
import { LBL_QUANTITY } from '../locale';
import { updateQuantity } from '../actions';
import { Size } from '../types';
import styled from '@emotion/styled';

type Props = {
  objectID: string;
  quantity: number;
  size: Size;
  quantityAlreadyAddedOnCart: number;
  isVariantOne?: boolean;
};

const StyledQuantitySelector = styled.div<{ isVariantOne: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(props) => (props.isVariantOne ? '8px' : '16px')};
  @media (min-width: ${breakpoints.S}px) {
    margin-top: 0px;
  }
`;

export const QuantitySelector = ({
  objectID,
  quantity,
  size,
  quantityAlreadyAddedOnCart,
  isVariantOne = false,
}: Props) => {
  const dispatch = useDispatch();

  const handleQuantityChange = (newQuantity: string) => {
    dispatch(updateQuantity(Number.parseInt(newQuantity, 10)));
  };

  const resetQuantity = () => {
    dispatch(updateQuantity(1));
  };

  React.useEffect(() => {
    return () => {
      resetQuantity();
    };
  }, []);

  const { sku, stockQuantity } = size;

  const dropdownOptions = [...Array(10).keys()].map((index) => {
    const qty = index + 1;
    return {
      value: String(qty),
      label: String(qty),
      disabled: qty > stockQuantity - quantityAlreadyAddedOnCart,
    };
  });

  return (
    <StyledQuantitySelector isVariantOne={isVariantOne}>
      <Text whiteSpace="pre-wrap">{`${LBL_QUANTITY} : `}</Text>
      <Selector
        id={`select-quantity-pdp-${objectID}`}
        options={dropdownOptions}
        value={String(quantity)}
        onChange={handleQuantityChange}
        hasError={quantity > stockQuantity && Boolean(sku)}
        mini
      />
    </StyledQuantitySelector>
  );
};
