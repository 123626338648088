import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { breakpoints, colors, Divider } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { Newsletter } from './Newsletter';
import { TITLE_QUICK_ACCESS, TITLE_COLLECTIONS } from '../locale';
import IconLinks from './IconLinks';
import { Links } from './Links';
import { PaymentMethods } from './PaymentMethods';
import SocialLinks from './SocialLinks';
import { LegalLinks } from './LegalLinks';

const Footer = () => {
  const cmsContent = useSelector((state: RootState) => state.cms.footer);

  const mainLinks = cmsContent?.body ?? [];
  const collectionLinks = cmsContent?.collection ?? [];
  const quickAccessLinks = cmsContent?.quick_access ?? [];
  const socialLinks = cmsContent?.contact ?? [];
  const paymentsMethodsImages = cmsContent?.payment_methods ?? [];
  const isCollectionBlockToDisplay = collectionLinks.find((item) => item.icon);
  const isQuickAccessBlockToDisplay = quickAccessLinks.find((item) => item.icon);

  return (
    <footer id="darjeeling-footer">
      <Newsletter {...{ cmsContent }} />
      <div
        css={css`
          padding: 0 16px;
          margin: 0 auto;
          @media (min-width: ${breakpoints.S}px) {
            padding: 0 24px;
          }
        `}
      >
        {(isCollectionBlockToDisplay || isQuickAccessBlockToDisplay) && (
          <>
            <div
              css={css`
                display: grid;
                grid-auto-flow: row;
                grid-gap: 24px;
                justify-content: initial;
                align-items: center;
                margin: 16px 0;
                @media (min-width: ${breakpoints.L}px) {
                  grid-auto-flow: column;
                  grid-gap: 40px;
                  justify-content: center;
                }
              `}
            >
              {isCollectionBlockToDisplay && (
                <IconLinks
                  title={TITLE_COLLECTIONS}
                  links={collectionLinks}
                  isTitleCenter={Boolean(!isQuickAccessBlockToDisplay)}
                />
              )}
              {isCollectionBlockToDisplay && isQuickAccessBlockToDisplay && (
                <div
                  css={css`
                    background: ${colors.LIGHT};
                    width: 1px;
                    height: 115px;
                    display: none;
                    @media (min-width: ${breakpoints.L}px) {
                      display: block;
                    }
                  `}
                />
              )}
              {isQuickAccessBlockToDisplay && (
                <IconLinks
                  title={TITLE_QUICK_ACCESS}
                  links={quickAccessLinks}
                  isTitleCenter={Boolean(!isCollectionBlockToDisplay)}
                />
              )}
            </div>
            <Divider />
          </>
        )}
        <div
          css={css`
            display: grid;
            grid-auto-flow: row;
            grid-gap: none;
            justify-content: initial;
            align-items: flex-start;
            margin: 0;
            @media (min-width: ${breakpoints.L}px) {
              grid-auto-flow: column;
              grid-gap: 80px;
              justify-content: center;
              margin: ${!isCollectionBlockToDisplay && !isQuickAccessBlockToDisplay
                ? '48px 0'
                : '24px 0'};
            }
          `}
        >
          <Links links={mainLinks} />
          <div
            css={css`
              display: block;
              margin-bottom: 16px;
              @media (min-width: ${breakpoints.L}px) {
                display: none;
              }
            `}
          >
            <Divider />
          </div>
          <div
            css={css`
              display: grid;
              grid-gap: 16px;
              justify-content: initial;
              @media (min-width: ${breakpoints.L}px) {
                grid-gap: 24px;
                justify-content: center;
              }
            `}
          >
            <PaymentMethods images={paymentsMethodsImages} />
            <div
              css={css`
                display: block;
                @media (min-width: ${breakpoints.L}px) {
                  display: none;
                }
              `}
            >
              <Divider />
            </div>
            <SocialLinks links={socialLinks} />
          </div>
        </div>
        <Divider />
        <LegalLinks />
      </div>
    </footer>
  );
};

export { Footer };
