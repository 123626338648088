import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';
import { useSwipeable } from 'react-swipeable';

import { CmsFontFamily, CmsFullSlider, CmsHtmlTag } from '../../cms/types';
import {
  FULL_SLIDER_MEDIA_HEIGHT,
  FULL_SLIDER_MEDIA_WIDTH,
  getOurBrandBannerPosition,
} from '../utils';
import { Icon, breakpoints, colors, durations, opacities } from '../../../design-system';
import { hasValidCmsText } from '../../home/utils';
import { heights } from '../../common/constants';
import { FullSliderVideo } from './FullSliderVideo';

type Props = {
  cmsData: CmsFullSlider | null;
  index: number;
};

type StyledContainerProps = {
  full_slider_position_on_page: number;
  full_slider_margin_bottom: number;
  full_slider_margin_bottom_small_screen: number;
  full_slider_title_font_family: CmsFontFamily;
  full_slider_html_tag: CmsHtmlTag;
  full_slider_title_font_size: number;
  full_slider_title_font_size_small_screen: number;
  full_slider_title_font_weight: number;
  full_slider_title_and_text_color: string;
  full_slider_background_color: string;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  order: ${(props) => props.full_slider_position_on_page};
  margin-bottom: ${(props) => props.full_slider_margin_bottom_small_screen}px;
  margin-top: ${(props) =>
    props.full_slider_position_on_page === 1 ? `-${heights.HEADER_MOBILE}px` : 0};
  background-color: ${(props) => props.full_slider_background_color};
  width: 100%;
  padding-bottom: 60px;
  overflow: hidden;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.full_slider_margin_bottom}px;
    margin-top: ${(props) =>
      props.full_slider_position_on_page === 1 ? `-${heights.HEADER}px` : 0};
    padding-bottom: 80px;
  }

  .full-slider-title {
    width: 90%;
    margin: ${(props) =>
      props.full_slider_position_on_page === 1 ? '80px auto 40px auto' : '40px auto'};
    text-align: center;
    font-family: ${(props) => props.full_slider_title_font_family};
    font-size: ${(props) => props.full_slider_title_font_size_small_screen}px;
    font-weight: ${(props) => props.full_slider_title_font_weight};
    color: ${(props) => props.full_slider_title_and_text_color};

    @media (min-width: ${breakpoints.L}px) {
      width: 70%;
      font-size: ${(props) => props.full_slider_title_font_size}px;
    }
  }

  .media-wrapper {
    position: relative;
    margin: auto;
    width: 90vw;
    height: ${`calc(90vw * (${FULL_SLIDER_MEDIA_HEIGHT} / ${FULL_SLIDER_MEDIA_WIDTH}))`};

    @media (min-width: ${breakpoints.L}px) {
      width: ${FULL_SLIDER_MEDIA_WIDTH}px;
      height: ${FULL_SLIDER_MEDIA_HEIGHT}px;
    }
  }

  .media {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    scale: 1;
    opacity: 1;
    translate: 0 0;
    transition: all ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (min-width: ${breakpoints.L}px) {
      filter: brightness(1);
      pointer-events: initial;
    }
  }

  .media:not(.active) {
    @media (max-width: ${breakpoints.L - 1}px) {
      opacity: 0;
    }
  }

  .prev,
  .next {
    scale: 0.7;
    z-index: -1;
    pointer-events: none;

    @media (min-width: ${breakpoints.L}px) {
      z-index: 1;
      filter: brightness(0.65);
    }
  }

  .prev {
    translate: -100% 0;
  }

  .next {
    translate: 100% 0;
  }

  .disable {
    scale: 0.5;
    opacity: 0;
    pointer-events: none;

    @media (min-width: ${breakpoints.L}px) {
      z-index: 0;
    }
  }

  .prev-button {
    left: -16px;

    @media (min-width: ${breakpoints.L}px) {
      left: -15vw;
    }
  }

  .next-button {
    right: -16px;

    @media (min-width: ${breakpoints.L}px) {
      right: -15vw;
    }
  }

  .prev-button,
  .next-button {
    position: absolute;
    top: calc(50% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: #fffffccc;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 4;
    transform: translateZ(0);

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
      }
    }
  }

  .full-slider-text {
    position: absolute;
    width: 100%;
    padding: 0 8px;
    bottom: -54px;
    left: 0;
    text-align: center;
    color: ${colors.WHITE};
    font-size: 14px;
    line-height: 21px;

    @media (min-width: ${breakpoints.M}px) {
      bottom: -64px;
    }

    a {
      color: ${colors.WHITE};
      text-underline-position: under;
      @media (hover: hover) {
        &:hover {
          opacity: ${opacities.HOVERED};
          transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
        }
      }
    }
    strong {
      font-weight: 700;
    }
  }
`;

export const FullSlider = ({ cmsData, index }: Props) => {
  const {
    full_slider_position_on_page,
    full_slider_margin_bottom,
    full_slider_margin_bottom_small_screen,
    full_slider_title,
    full_slider_title_font_family,
    full_slider_html_tag,
    full_slider_title_font_size,
    full_slider_title_font_size_small_screen,
    full_slider_title_font_weight,
    full_slider_title_and_text_color,
    full_slider_background_color,
  } = cmsData?.primary ?? {};

  const { items } = cmsData ?? {};

  const [activeIndex, setActiveIndex] = useState(0);
  const [isClickable, setIsClickable] = useState(true);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const onPrevClick = () => {
    if (isClickable) {
      if (activeIndex === 0) {
        setActiveIndex((items?.length ?? 2) - 1);
      } else {
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  const onNextClick = () => {
    if (isClickable) {
      if (activeIndex === (items?.length ?? 2) - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => onNextClick(),
    onSwipedRight: () => onPrevClick(),
    swipeDuration: durations.FOCUS_DELAY,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    setIsClickable(false);
    const timer = setTimeout(() => setIsClickable(true), durations.FOCUS_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const HtmlTag = full_slider_html_tag ?? 'p';

  return (items?.length ?? 0) < 3 ? null : (
    <StyledContainer
      id={`full-slider-banner-block-${index}`}
      full_slider_position_on_page={getOurBrandBannerPosition(
        full_slider_position_on_page ?? 'Not activated'
      )}
      full_slider_margin_bottom={full_slider_margin_bottom ?? 0}
      full_slider_margin_bottom_small_screen={full_slider_margin_bottom_small_screen ?? 0}
      full_slider_title_font_family={full_slider_title_font_family ?? 'Ogg-text'}
      full_slider_title_font_size={full_slider_title_font_size ?? 40}
      full_slider_title_font_size_small_screen={full_slider_title_font_size_small_screen ?? 32}
      full_slider_title_font_weight={full_slider_title_font_weight ?? 300}
      full_slider_title_and_text_color={full_slider_title_and_text_color ?? colors.WHITE}
      full_slider_background_color={full_slider_background_color ?? colors.BLACK}
      full_slider_html_tag={full_slider_html_tag ?? 'p'}
    >
      {full_slider_title && <HtmlTag className="full-slider-title">{full_slider_title}</HtmlTag>}
      <div className="media-wrapper" {...handlers}>
        {items?.map((item, itemIndex) => {
          if (item.full_slider_image?.url) {
            return (
              <img
                key={`full-slider-img-${itemIndex}`}
                src={item.full_slider_image.url}
                alt={item.full_slider_image.alt ?? ''}
                className={`media ${
                  itemIndex === activeIndex
                    ? 'active'
                    : itemIndex === (activeIndex + 1) % items.length
                    ? 'next'
                    : itemIndex === (activeIndex + items.length - 1) % items.length
                    ? 'prev'
                    : 'disable'
                }`}
              />
            );
          }
          if (item.full_slider_video_link) {
            const url = `${item.full_slider_video_link.split('?')?.[0]}?muted=true`;
            return (
              <div
                key={`full-slider-video-${itemIndex}`}
                className={`media ${
                  itemIndex === activeIndex
                    ? 'active'
                    : itemIndex === (activeIndex + 1) % items.length
                    ? 'next'
                    : itemIndex === (activeIndex + items.length - 1) % items.length
                    ? 'prev'
                    : 'disable'
                }`}
              >
                <FullSliderVideo
                  url={url}
                  isActive={itemIndex === activeIndex}
                  isScriptLoaded={scriptLoaded}
                />
              </div>
            );
          }
          return null;
        })}
        <button className="prev-button" type="button" onClick={onPrevClick}>
          <Icon name="arrowLeft" size={16} />
        </button>
        <button className="next-button" type="button" onClick={onNextClick}>
          <Icon name="arrowRight" size={16} />
        </button>
        {hasValidCmsText(items?.[activeIndex]?.full_slider_text_under_media) && (
          <div className="full-slider-text">
            <RichText render={items?.[activeIndex].full_slider_text_under_media} />
          </div>
        )}
      </div>
    </StyledContainer>
  );
};
