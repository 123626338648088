import React, { useEffect, useMemo, useState, FC, ReactElement } from 'react';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { css } from '@emotion/core';

import { useStickySliderObserver } from '../../common/hooks/useStickySliderObserver';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Filters } from '../../filters/components/Filters';
import { ProductCard } from './ProductCard';
import { Refinements } from '../../filters/components/Refinements';
import { SortSelector } from '../../sorting/components/SortSelector';
import { Box, breakpoints, colors } from '../../../design-system';
import { TilesSwitcher } from './TilesSwitcher';
import { getIsClient, getPersistedData, persistData } from '../../common/utils';
import { CatalogProduct, Tiles } from '../types';
import { durations, heights, opacities, zIndex } from '../../common/constants';
import { ColorText } from '../../common/components/Custom';
import { MobileFilters } from '../../filters/components/MobileFilters';
import paths from '../../routing/paths';
import { Banner } from '../../banners/catalog/Banner';
import { ButtonLink } from '../../../design-system/button';
import { LBL_LOAD_MORE_PRODUCTS, LBL_LOAD_PREVIOUS_PRODUCTS, MSG_NO_RESULTS } from '../locale';
import { RootState } from '../../../store/rootReducer';
import { pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { ScrollTo } from './ScrollTo';
import { getGridColumn } from '../utils';
import { SeoDescription } from '../../common/components/SeoDescription';
import { emptyText } from '../../cms/state';
import { CmsSliderSticky } from './CmsSliderSticky';
import { CmsCatalogBanner } from '../../cms/types';
import { updateCatalog } from '../actions';
import { CmsSearchBlock } from '../../search/components/CmsSearchBlock';

export const Results: FC<{ searchInput?: ReactElement }> = ({ searchInput }) => {
  const dispatch = useDispatch();
  const { isMobile, isLargeDesktop } = useMediaQueries();
  const { status, results } = useInstantSearch();
  const { hits, isLastPage, showMore, isFirstPage, showPrevious } =
    useInfiniteHits<CatalogProduct>();
  const { pathname, search } = useLocation();

  const [currentTile, setCurrentTile] = useState<Tiles>(Tiles.SMALL);
  const [stickySliderIsActive, setStickySliderIsActive] = useState(false);

  const {
    background_color,
    text_color,
    promotion_banner_text,
    hide_filters,
    bottom_banners,
    slider_with_number_is_sticky_mobile,
    slider_with_number_is_active_mobile,
  } = useSelector((state: RootState) => state.cms.catalog) ?? {};
  const { isBannerOnScreen } = useSelector((state: RootState) => state.catalog);
  const { isLoading: cmsIsLoading, search: cmsSearch } = useSelector(
    (state: RootState) => state.cms
  );
  const cmsContent = useSelector((state: RootState) => state.cms.catalog);
  const stickySliderItems = useSelector(
    (state: RootState) =>
      state.cms.catalog?.body4?.find((el) => el.slice_type === 'slider_with_number')?.items
  );

  const sliderElement = getIsClient()
    ? document.getElementById('catalog-slider-with-number-container')
    : null;

  const banners = pathname.startsWith(paths.SEARCH_RESULTS)
    ? []
    : [
        ...(cmsContent?.banners ?? []).filter((banner) => banner.banner_type !== null),
        ...(cmsContent?.body3?.map((body) => {
          return {
            ...body,
            ...body.primary,
          };
        }) ?? []),
      ];
  const { text: seo_description = [...emptyText], alignment } = bottom_banners?.[0] ?? {};

  useEffect(() => {
    const storedTiles = getPersistedData('tiles');
    if (storedTiles !== null && storedTiles !== undefined) {
      setCurrentTile(storedTiles);
    }
  }, []);

  useEffect(() => {
    setStickySliderIsActive(
      Boolean(!slider_with_number_is_active_mobile && slider_with_number_is_sticky_mobile)
    );
  }, [slider_with_number_is_active_mobile, slider_with_number_is_sticky_mobile]);

  useStickySliderObserver({
    sliderElement,
    isDataLoaded: !cmsIsLoading,
    isMobile,
    slider_with_number_is_active_mobile,
    setStickySliderIsActive,
  });

  useEffect(() => {
    dispatch(updateCatalog({ hits: results.hits, indexName: results.index }));
  }, [JSON.stringify(results)]);

  const totalHits = pathname.startsWith(paths.SELECTION)
    ? Object.values(results._rawResults[0].facets?.['categories.lvl0'] ?? {}).reduce(
        (acc, curr) => acc + curr,
        0
      ) ?? 0
    : results.nbHits;

  const bannerOffsets = useMemo(
    () =>
      banners.reduce(
        (acc, curr, index) => {
          switch (curr.banner_type) {
            case 'one tile':
              return [...acc, acc[index] + 1];
            case 'two tiles':
              return [...acc, acc[index] + 2];
            case 'half column':
            case 'half with original ratio':
            case 'highlighted':
              return [
                ...acc,
                acc[index] + (isLargeDesktop ? (currentTile === Tiles.SMALL ? 2 : 1) : 1),
              ];
            case 'full column':
            case 'full with original ratio':
              return [
                ...acc,
                acc[index] + (isLargeDesktop ? (currentTile === Tiles.SMALL ? 4 : 3) : 2),
              ];
            default:
              return acc;
          }
        },
        [0]
      ),
    [isLargeDesktop, currentTile, banners]
  );

  // We remove current page from the URL and remove trailing ? or &
  const basePath = `${pathname}${search.replace(/page=\d+/, '')}`.replace(/[?|$]$/, '');
  const separator = basePath.includes('?') ? '&' : '?';

  const onLoadPreviousClick = () => {
    showPrevious();
    pushToGTM(Events.loadPrevious, {
      pageNumber: `${results.page}`,
    });
  };

  const onLoadMoreClick = () => {
    showMore();
    pushToGTM(Events.loadPrevious, {
      pageNumber: `${results.page + 2}`,
    });
  };

  const shouldDisplayStickySlider =
    !cmsIsLoading &&
    isMobile &&
    slider_with_number_is_sticky_mobile &&
    (stickySliderIsActive || !slider_with_number_is_active_mobile);

  const isSearchResultsPage = pathname.startsWith(paths.SEARCH_RESULTS);
  const hasResults = hits?.length > 0;

  return (
    <>
      <Helmet>
        {results.page !== 0 && (
          <link rel="prev" href={`${pathname}${results ? `?page=${results.page}` : ''}`} />
        )}
        {!isLastPage && <link rel="next" href={`${pathname}?page=${results.page + 2}`} />}
      </Helmet>
      {shouldDisplayStickySlider && <CmsSliderSticky items={stickySliderItems ?? []} />}
      <div
        css={css`
          position: sticky;
          top: ${stickySliderIsActive
            ? heights.HEADER_MOBILE + heights.STICKY_SLIDER_HEIGHT
            : heights.HEADER_MOBILE}px;
          background: ${colors.WHITE};
          width: 100%;
          z-index: ${zIndex.SIDEBAR - 1};
          transition: all ${durations.ENTER}ms ease-in-out;

          @media (min-width: ${breakpoints.M}px) {
            display: none;
          }
        `}
      >
        {promotion_banner_text && !isBannerOnScreen && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg={background_color || 'BACKGROUND'}
            width="100%"
            p="s"
          >
            <ColorText
              cmsColor={text_color}
              whiteSpace="nowrap"
              preset="caption"
              fontWeight="bold"
              id="catalog-promotion-banner"
            >
              {promotion_banner_text}
            </ColorText>
          </Box>
        )}
        {searchInput}
        {hide_filters || !hasResults || (isSearchResultsPage && !results.query) ? null : (
          <MobileFilters />
        )}
      </div>
      <div
        css={css`
          @media (max-width: ${breakpoints.M - 1}px) {
            display: none;
          }
        `}
      >
        {searchInput}
      </div>
      {isSearchResultsPage && (
        <div
          css={css`
            margin-top: 16px;
            @media (min-width: ${breakpoints.M}px) {
              display: none;
            }
          `}
        >
          <CmsSearchBlock cmsSearch={cmsSearch} isHidden={hasResults && Boolean(results.query)} />
        </div>
      )}
      <ScrollTo>
        {hide_filters ? null : (
          <div
            css={css`
              min-width: 314px;
              width: 314px;
              padding-right: 12px;
              display: none;
              @media (min-width: ${breakpoints.M}px) {
                display: ${!hasResults || (isSearchResultsPage && !results.query)
                  ? 'none'
                  : 'block'};
                position: sticky;
                margin-top: 58px;
                margin-right: 8px;
                top: ${heights.HEADER}px;
                height: calc(100vh - 104px);
                overflow: auto;
              }
            `}
          >
            <Filters />
          </div>
        )}
        <div
          css={css`
            width: 100%;
          `}
        >
          {!hasResults || (isSearchResultsPage && !results.query) ? null : (
            <div
              css={css`
                display: none;
                justify-content: space-between;

                @media (min-width: ${breakpoints.M}px) {
                  display: flex;
                }
              `}
            >
              <Refinements />
              <div
                css={css`
                  display: inline-flex;
                  flex-wrap: wrap;
                  gap: 8px;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                  max-width: 360px;
                `}
              >
                <div
                  css={css`
                    display: none;
                    @media (min-width: ${breakpoints.L}px) {
                      display: block;
                    }
                  `}
                >
                  <TilesSwitcher
                    currentTile={currentTile}
                    handleTileChange={(tile) => {
                      persistData('tiles', tile);
                      setCurrentTile(tile);
                    }}
                  />
                </div>
                <p
                  id="number-of-results"
                  css={css`
                    margin-block-start: 0;
                    margin-block-end: 0;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: ${colors.BLACK};
                  `}
                >{`${totalHits} ${totalHits === 1 ? 'Résultat' : 'Résultats'}`}</p>
                <Box height="xl" width="1px" bg="LIGHT" ml="s" />
                <SortSelector />
              </div>
            </div>
          )}
          <div
            css={css`
              position: relative;
            `}
          >
            {status === 'stalled' || status === 'loading' ? (
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 1;
                  opacity: ${opacities.LOADING};
                  background-color: ${colors.WHITE};
                `}
              />
            ) : null}
            {(hasResults && results.query && isSearchResultsPage) ||
            (hasResults && !isSearchResultsPage) ? (
              <>
                {!isFirstPage && (
                  <Box my="xl">
                    <Box width={['100%', '280px']} mx="auto" textAlign="center" px={['m', 'na']}>
                      <ButtonLink
                        to={`${basePath}${separator}page=${results.page}`}
                        data-testid="btn-catalog-load-previous"
                        onClick={onLoadPreviousClick}
                        id="btn-catalog-load-previous"
                        preset="secondary"
                      >
                        {LBL_LOAD_PREVIOUS_PRODUCTS}
                      </ButtonLink>
                    </Box>
                  </Box>
                )}
                <ul
                  id="product-grid"
                  css={css`
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    margin-top: 8px;
                    display: grid;
                    grid-gap: 16px;
                    grid-template-columns: repeat(2, 1fr);

                    @media (min-width: ${breakpoints.L}px) {
                      grid-template-columns: repeat(
                        ${currentTile === Tiles.SMALL ? '4' : '3'},
                        1fr
                      );
                    }
                  `}
                >
                  {banners
                    .filter((banner, index) => {
                      const bannerRow =
                        (isMobile
                          ? banner.banner_row_mobile ?? banner.banner_row
                          : banner.banner_row) ?? 0;

                      const maxRow =
                        (hits.length + bannerOffsets[index]) /
                        (isLargeDesktop ? (currentTile === Tiles.SMALL ? 4 : 3) : 2);

                      return bannerRow < maxRow;
                    })
                    .map((banner, index) => {
                      return (
                        <Banner
                          key={`banner_${index}`}
                          id={`banner-${banner.banner_type}-${index}`}
                          banner={banner as CmsCatalogBanner}
                          css={css`
                            grid-row: ${banner.banner_row_mobile ?? banner.banner_row} /
                              ${banner.banner_row_mobile ?? banner.banner_row ?? 0 + 1};
                            grid-column: ${getGridColumn({
                              type: banner.banner_type,
                              colIndex: banner.banner_column,
                              colPosition: banner.banner_column_position,
                              currentTile,
                              isLargeDesktop,
                            })};

                            @media (min-width: ${breakpoints.S}px) {
                              grid-row: ${banner.banner_row} / ${banner.banner_row ?? 0 + 1};
                            }
                          `}
                        />
                      );
                    })}
                  {hits.map((hit) => {
                    return (
                      <li key={hit.objectID}>
                        <ProductCard indexName={results.index} hit={hit} />
                      </li>
                    );
                  })}
                </ul>
                <Box my="xl">
                  <Box width={['100%', '280px']} mx="auto" textAlign="center" px={['m', 'na']}>
                    <p
                      id="number-of-loaded"
                      css={css`
                        margin-block-start: 0;
                        margin-block-end: 0;
                        margin-bottom: 16px;
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        color: ${colors.BLACK};
                      `}
                    >
                      {hasResults
                        ? `${hits.length} produit${hits.length === 1 ? '' : 's'} sur ${totalHits}`
                        : ''}
                    </p>
                    {!isLastPage && (
                      <ButtonLink
                        to={`${basePath}${separator}page=${results.page + 2}`}
                        data-testid="btn-catalog-load-next"
                        onClick={onLoadMoreClick}
                        id="btn-catalog-load-next"
                      >
                        {LBL_LOAD_MORE_PRODUCTS}
                      </ButtonLink>
                    )}
                  </Box>
                </Box>
                {isFirstPage && (
                  <SeoDescription
                    seo_description={seo_description}
                    alignment={alignment ?? 'left'}
                  />
                )}
              </>
            ) : null}
            {!hasResults && status === 'idle' && !isSearchResultsPage ? (
              <div
                id="msg-no-results"
                css={css`
                  width: 100%;
                  text-align: center;
                  padding: 24px 16px;
                `}
              >
                <p
                  css={css`
                    font-size: 2rem;
                    line-height: 2.4rem;
                    color: ${colors.BLACK};
                  `}
                >
                  {MSG_NO_RESULTS}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </ScrollTo>
    </>
  );
};
