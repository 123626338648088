import * as React from 'react';
import { useDispatch } from 'react-redux';
import { breakpoints, theme } from '../../../design-system/systemprovider/constants';

import { Box, Icon, Text, opacities } from '../../../design-system';
import { CmsUspContent } from '../../cms/types';
import { openModal } from '../../common/actions';
import UspModal from './UspModal';
import { useDevice } from '../../common/hooks/useDevice';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

type Props = {
  item: CmsUspContent;
};

const UspItem = ({ item }: Props) => {
  const handleClick = () => {
    dispatch(
      openModal({
        content: <UspModal item={item} />,
        title: ' ',
      })
    );
  };

  const dispatch = useDispatch();

  const { isDesktop, isTablet } = useDevice();

  const { icon, subtitle, title } = item ?? {};

  return isDesktop || isTablet ? (
    <UspButton onClick={handleClick}>
      {icon && (
        <div className="usp-item__icon-wrapper">
          <Icon name={icon} className="usp-item__icon" />
        </div>
      )}
      <h3 className="usp-item__title">{title}</h3>
      <p className="usp-item__subtitle">{subtitle}</p>
    </UspButton>
  ) : (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridAutoColumns="auto 1fr auto"
      gridGap="m"
      alignItems="center"
      justifyContent="center"
      onClick={handleClick}
      px="m"
      width="100%"
    >
      {icon && <Icon name={icon} size={34} />}
      <Box
        display="grid"
        gridGap="xs"
        alignItems="center"
        justifyContent="left"
        textAlign="left"
        my="s"
      >
        <Text preset="subheading" fontWeight="bold">
          {title}
        </Text>
        <Text preset="caption" color="GREY">
          {subtitle}
        </Text>
      </Box>
      <Icon name="plus" size={12} />
    </Box>
  );
};

const UspButton = styled('button')(
  css`
    flex: 1 0 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: ${theme.space.xs};
    background: unset;
    border: unset;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    @media (min-width: ${breakpoints.S}px) {
      flex: 0 0 180px;
    }
    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        .usp-item__icon {
          transform: scale(1.1);
        }
      }
    }
    .usp-item__icon-wrapper {
      margin: 0 auto;
      height: 56px;
    }
    .usp-item__icon {
      width: 40px;
      height: 40px;
      transition: all 0.1s ease-in-out;
      -webkit-backface-visibility: hidden;
    }
    .usp-item__title {
      margin: 0;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;
      color: #000;
    }
    .usp-item__subtitle {
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #5a5a5a;
    }
  `
);
export default UspItem;
