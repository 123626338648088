import * as React from 'react';
import ReactDOM from 'react-dom';
import pathOr from 'ramda/src/pathOr';

import SignInContainer from './SignIn';
import SignUpContainer from './SignUp';
import withAuth, { WithAuthProps } from '../hocs/withAuth';
import withSidebar, { WithSidebarActions } from '../../common/hocs/withSidebar';
import withCms, { WithCmsActions } from '../../cms/hocs/withCms';
import { CmsLogin, CmsCustomTypes } from '../../cms/types';
import { emptyText } from '../../cms/state';
import CheckEmailExist from './CheckEmailExist';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { ForgotPassword } from './ForgotPassword';
import { checkUserExist, setHasForgotPassword } from '../actions';
import { Snackbar } from '../../../../shared/design-system';
import { useDevice } from '../../common/hooks/useDevice';
import { useLocation } from 'react-router-dom';
import paths from '../../routing/paths';
import useQuery from '../../common/hooks/useQuery';

type Props = { defaultTabIndex?: number } & WithSidebarActions &
  WithAuthProps &
  WithCmsActions & {
    cmsContent: CmsLogin;
    onLogin?: () => void;
  };

export const AuthBar = ({
  cmsContent,
  loadCmsContent,
  closeSidebar,
  onLogin,
  checkEmail,
  login,
  signup,
  isLoggedIn,
  errLogin,
  errSignup,
  errSocial,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const query = useQuery();

  const { isMobile } = useDevice();
  const cmsText = pathOr([...emptyText], ['message'], cmsContent);
  const [email, setEmail] = React.useState('');
  const { userExist, hasForgotPassword } = useSelector((state: RootState) => state.auth);
  const [comeFromResetPassword, setComeFromResetPassword] = React.useState(false);

  React.useEffect(() => {
    if (!cmsText[0].text) {
      loadCmsContent(CmsCustomTypes.login);
    }
    dispatch(setHasForgotPassword(false));
    dispatch(checkUserExist(null));
  }, []);

  React.useEffect(() => {
    if (isLoggedIn) {
      closeSidebar();
      if (onLogin) {
        onLogin();
      }
      ReactDOM.render(
        <Snackbar placement="top" offset={isMobile ? 95 : 140}>
          Vous êtes bien connecté.e !
        </Snackbar>,
        document.getElementById('snackbars')
      );
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (pathname.startsWith(paths.RESET_PASSWORD)) {
      const queryEmail = query.get('email') || '';
      checkEmail(queryEmail);
      setEmail(queryEmail);
      setComeFromResetPassword(true);
      window.history.replaceState(null, document.title, paths.HOME);
    }
  }, [pathname]);

  if (hasForgotPassword) {
    return <ForgotPassword email={email} />;
  }
  if (userExist === false) {
    return (
      <SignUpContainer signup={signup} errSignup={errSignup} email={email} errSocial={errSocial} />
    );
  }
  if (userExist === true) {
    return (
      <SignInContainer
        login={login}
        errLogin={errLogin}
        email={email}
        comeFromResetPassword={comeFromResetPassword}
      />
    );
  }
  return (
    <CheckEmailExist
      checkEmail={checkEmail}
      setEmail={setEmail}
      errLogin={errLogin}
      errSocial={errSocial}
    />
  );
};

export default withSidebar(withAuth(withCms(AuthBar, CmsCustomTypes.login)));
