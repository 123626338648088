/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Store } from 'redux';
import { loadableReady } from '@loadable/component';
import { InstantSearchServerState } from 'react-instantsearch';

import configureStore from '../shared/store';
import AppContainer from '../shared/App';

const devMode = process.env.NODE_ENV === 'development';
declare global {
  interface Window {
    __INITIAL_SEARCH_STATE__: Partial<InstantSearchServerState>;
    __INITIAL_SEARCH_URL__: URL;
    store: Store;
    OptanonWrapper: () => void;
    Optanon?: {
      OnConsentChanged: (callback: () => void) => void;
    };
    OptanonActiveGroups?: string[];
  }
}

const store = window.store || configureStore();
const serverState = window.__INITIAL_SEARCH_STATE__;
const serverUrl = window.__INITIAL_SEARCH_URL__;

const Entry = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Provider store={store}>
          <AppContainer serverState={serverState} serverUrl={serverUrl} />
        </Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

loadableReady(() => {
  const root = document.getElementById('root');
  hydrate(<Entry />, root);
});

if (devMode && (module as any).hot) {
  (module as any).hot.accept();
}

if (!window.store) {
  window.store = store;
}
