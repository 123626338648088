import { Color, Size } from '../product/types';

export type State = {
  catalog: CatalogProduct[];
  indexName?: string;
  tiles: Tiles;
  lastPositionId: string;
  isBannerOnScreen: boolean;
  algoliaQuery?: AlgoliaQuery;
};

export type CatalogProduct = {
  objectID: string;
  __position: number;
  __queryID: string;
  rcFilter: string;
  productRef: string;
  colorRef: string;
  productName: string;
  colorLabel: string;
  detailedColorLabel: string;
  realColorRef: string;
  originalPrice: number;
  storePrice: number;
  colorVariants: Color[];
  colorName: string;
  collectionName: string;
  model?: ModelPDP[];
  categories: {
    lvl0: string;
    lvl1: string;
    lvl2: string;
    lvl3: string;
  };
  crossSellType?: string;
  images: number[];
  sizeVariants: Size[];
  promotionPercentage?: string;
  promotionLabel?: string;
  promoLongLabel?: string;
  promoBColor?: string;
  promoTColor?: string;
  typology?: string;
  isOutlet?: boolean;
  storesAvailable: string[];
  tax?: number;
  _highlightResult?: {
    productName: {
      value: string;
    };
  };
};

export type ModelPDP = {
  position?: number;
  name?: string;
  size?: string;
};

export type BreadcrumbsItems = {
  label: string;
  value: string;
  readonly?: boolean;
}[];

export enum Tiles {
  LARGE,
  SMALL,
}

export type StateResults = {
  nbHits?: number;
  hierarchicalFacets?: {
    name?: string;
    data?: {
      count?: number;
    }[];
  }[];
  hits?: {
    objectID: string;
    _rankingInfo: {
      filters: number;
    };
  }[];
  query?: string;
  _rawResults: {
    facets: {
      'categories.lvl0': {
        [key: string]: number;
      };
      'categories.lvl1': {
        [key: string]: number;
      };
    };
  }[];
};

export type AlgoliaQuery = {
  queryID: string;
  index: string;
};
