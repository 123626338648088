import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { RecommendationsT2S } from '../../recommendations/components/RecommendationsT2S';
import { RecommendationTypes } from '../../recommendations/types';
import { loadRecommendations } from '../../recommendations/actions';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { breakpoints } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

const StyledCartRecommandationsContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.L}px) {
    display: block;
  }
`;

export const CartRecommendations = () => {
  const dispatch = useDispatch();

  const { isDesktop } = useMediaQueries();

  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.cart
  );
  const { cart_recommendation_title: title } = useSelector((state: RootState) => state.cms.cart);
  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (isDesktop && (!recommendedProducts || !recommendedProducts.length)) {
      dispatch(loadRecommendations(RecommendationTypes.cart) as unknown as UnknownAction);
    }
  }, [isDesktop]);

  if (!isDesktop || !recommendedProducts.length) {
    return null;
  }

  return (
    <StyledCartRecommandationsContainer>
      <RecommendationsT2S
        type={RecommendationTypes.cart}
        tracking={tracking}
        hits={hits}
        title={title}
      />
    </StyledCartRecommandationsContainer>
  );
};
