import { css } from '@emotion/core';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { Box, breakpoints } from '../../../design-system';
import { CmsCatalogBanner, CmsCatalogHighlight } from '../../cms/types';
import { BannerVideo } from '../components/BannerVideo';
import { useMediaQuery } from '../../common/hooks/useMediaQuery';
import { ColorText, ImageWrapperLink } from '../../common/components/Custom';
import { Timer } from '../../cms';
import { theme } from '../../../design-system/systemprovider';
import { sortIndices } from '../../sorting/constants';
import { fetchProductForBannerFromBackend } from '../../api';
import { Product } from '../../product/types';
import { getProductLink, getProductPrice } from '../../product/utils';
import { HighlightedName } from '../../catalog/components/HighlightedName';
import { HighlightedBanner } from './HighlightedBanner/HighlightedBanner';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';

const getImageUrl = (banner: CmsCatalogBanner, isMobile: boolean) => {
  const images = isMobile ? banner.banner_image_mobile ?? banner.banner_image : banner.banner_image;
  switch (banner.banner_type) {
    case 'one tile':
      return images?.one_tile?.url ?? '';
    case 'two tiles':
      return images?.two_tiles?.url ?? '';
    case 'full column':
    case 'full with original ratio':
      return images?.url ?? '';
    case 'half column':
    case 'half with original ratio':
      return (
        (banner.banner_content_orientation === 'portrait'
          ? images?.half_column_portrait?.url
          : images?.half_column_landscape?.url) ?? ''
      );
    default:
      return '';
  }
};

const noBannerWhenFilter = [
  'full column',
  'full with original ratio',
  'half column',
  'half with original ratio',
];

type BannerProduct = Pick<
  Product,
  | 'productRef'
  | 'productName'
  | 'colorRef'
  | 'colorLabel'
  | 'storePrice'
  | 'originalPrice'
  | 'typology'
  | 'collectionName'
>;

const BannerProduct: React.FC<ComponentProps<'li'> & { banner: CmsCatalogBanner }> = ({
  banner,
  ...props
}) => {
  const [product, setProduct] = useState<BannerProduct | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.S - 1}px)`);

  useEffect(() => {
    (async () => {
      try {
        if (!banner.banner_product_ref || !banner.banner_color_ref) {
          return;
        }
        const res = await fetchProductForBannerFromBackend(
          banner.banner_product_ref,
          banner.banner_color_ref
        );
        if (res.ok === true && res.data) {
          setProduct(res.data);
        } else {
          setError(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(true);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (error) {
    return null;
  }

  if (loading) {
    return <li {...props}>Loading...</li>;
  }

  if (!product) {
    return null;
  }
  const {
    productRef,
    colorRef,
    colorLabel,
    productName,
    storePrice,
    originalPrice,
    collectionName,
    typology,
  } = product ?? {};

  const productLink = getProductLink({
    productRef,
    colorLabel,
    colorRef,
    productName,
  });

  const price = getProductPrice({
    storePrice,
    originalPrice,
  });

  const objectID = `${productRef}-${colorRef}`;

  return (
    <li {...props}>
      <ImageWrapperLink
        to={productLink}
        id={`product-banner-${objectID}-image-wrapper`}
        decoration="none"
      >
        <div
          css={css`
            height: calc(100% - 74px);
            overflow: hidden;
          `}
        >
          <img
            src={getImageUrl(banner, isMobile)}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: 0.3s ease-in-out;
            `}
            alt={`${productName} ${colorLabel}`}
          />
        </div>
        <Box display="flex" id={`product-banner-${objectID}-product-name`} mt="8px">
          <HighlightedName
            objectID={objectID}
            productName={productName}
            typology={typology}
            collectionName={collectionName}
          />
          <Box display="flex" ml="25px">
            <Box
              id={`product-banner-${objectID}-store-price`}
              display="flex"
              justifyContent="right"
              mb="xs"
              color={price.hasPromotion ? '#C21947' : '#000'}
            >
              {price.storePrice}
            </Box>
            {price.hasPromotion && (
              <Box
                id={`product-banner-${objectID}-original-price`}
                display="flex"
                justifyContent="right"
                fontSize="12px"
                textDecoration="line-through"
                opacity={0.4}
                ml="xs"
              >
                {price.originalPrice}
              </Box>
            )}
          </Box>
        </Box>
      </ImageWrapperLink>
    </li>
  );
};

export const Banner: React.FC<ComponentProps<'li'> & { banner: CmsCatalogBanner }> = ({
  banner,
  ...props
}) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.S - 1}px)`);
  const { indexUiState } = useInstantSearch();
  const [showQuickAddMobile, setShowQuickAddMobile] = useState(false);
  const [productRefWithColorSelected, setProductRefWithColorSelected] = useState('');
  const [originalColorRef, setOriginalColorRef] = useState('');

  useEffect(() => {
    if (productRefWithColorSelected) {
      setShowQuickAddMobile(true);
    }
  }, [productRefWithColorSelected]);

  const handleCloseQuickAdd = () => {
    setShowQuickAddMobile(false);
    setProductRefWithColorSelected('');
  };

  // We don't display half and full column tiles when some filters are applied
  if (
    (indexUiState.refinementList ||
      indexUiState.range ||
      (indexUiState.sortBy && indexUiState.sortBy !== sortIndices[0].value)) &&
    noBannerWhenFilter.includes(banner.banner_type ?? 'full column')
  ) {
    return null;
  }
  if (banner.is_banner_active === false) {
    return null;
  }
  if (isMobile && banner.is_banner_hidden_on_mobile) {
    return null;
  }

  if (banner.banner_video) {
    return (
      <li {...props}>
        <div
          css={css`
            width: 100%;
            position: relative;
            padding-top: ${banner.banner_type === 'one tile' ? '177.777%' : '56.25%'};
          `}
        >
          <BannerVideo video={banner.banner_video} banner_type={banner.banner_type} />
        </div>
      </li>
    );
  }

  if (banner.banner_product_ref && banner.banner_color_ref) {
    return <BannerProduct banner={banner} {...props} />;
  }
  if (banner.banner_type === 'highlighted' && banner.items?.length) {
    return (
      <li {...props}>
        <div
          css={css`
            width: 100%;
          `}
        >
          <HighlightedBanner
            banner={banner as unknown as CmsCatalogHighlight}
            setProductRefWithColorSelected={setProductRefWithColorSelected}
            setOriginalColorRef={setOriginalColorRef}
          />
        </div>
        {isMobile && (
          <QuickAddMobile
            productRef={productRefWithColorSelected.split('-')?.[0]}
            colorRef={productRefWithColorSelected.split('-')?.[1]}
            originalSelectedColor={originalColorRef}
            isActive={showQuickAddMobile}
            onClose={handleCloseQuickAdd}
          />
        )}
      </li>
    );
  }
  return (
    <li {...props}>
      <a
        href={banner.banner_link}
        css={css`
          height: 100%;
          width: 100%;
          cursor: ${banner.banner_link ? 'pointer' : 'initial'};
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <img
            src={getImageUrl(banner, isMobile)}
            css={css`
              width: 100%;
            `}
            alt="Image de la bannière"
          />
          {banner.banner_text || banner.banner_promotion_end_date_time ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                flex-direction: column;
                text-shadow: ${theme.shadows.onImage};
                gap: 16px;
              `}
            >
              {banner.banner_text && (
                <ColorText
                  preset={isMobile ? 'body' : 'subheading'}
                  cmsColor={banner.banner_text_color || 'WHITE'}
                  fontWeight="bold"
                >
                  {banner.banner_text}
                </ColorText>
              )}
              {banner.banner_promotion_end_date_time && (
                <Timer
                  textColor={banner.banner_text_color || 'WHITE'}
                  timestamp={banner.banner_promotion_end_date_time}
                  preset="heading"
                  isWrapped={isMobile}
                />
              )}
            </div>
          ) : null}
        </div>
      </a>
    </li>
  );
};
