import React from 'react';
import { useDevice } from '../../common/hooks/useDevice';
import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { Timer } from '../../cms';

export function PromoBanner() {
  const cmsContent = useSelector((state: RootState) => state.cms.catalog);
  const {
    title_promo_banner,
    subtitle_promo_banner,
    secondary_title_promo_banner,
    secondary_subtitle_promo_banner,
    promo_banner_image,
    promo_banner_image_mobile,
    promo_banner_type,
    background_color_promo_banner,
    text_color_promo_banner,
    promo_banner_is_active,
    terms_conditions_text,
    terms_and_conditions_color,
    title_font_size_desktop,
    title_font_size_mobile,
    title_font_family,
    subtitle_font_size_desktop,
    subtitle_font_size_mobile,
    subtitle_font_family,
    secondary_title_font_size_desktop,
    secondary_title_font_size_mobile,
    secondary_title_font_family,
    secondary_subtitle_font_size_desktop,
    secondary_subtitle_font_size_mobile,
    secondary_subtitle_font_family,
    terms_conditions_font_size_desktop,
    terms_conditions_font_size_mobile,
    terms_conditions_font_family,
    position_is_sticky_mobile,
    promotion_end_of_time,
  } = cmsContent ?? {};

  if (!promo_banner_is_active) {
    return null;
  }

  const { isMobile } = useDevice();
  const paddingPromoBanner = isMobile ? '3vw' : '18px';

  const fragments = {
    promoBannerSimpleText: (
      <>
        <Box
          width="100%"
          minHeight="65px"
          backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
          marginBottom="8px"
          padding={paddingPromoBanner}
          position={isMobile && position_is_sticky_mobile ? 'sticky' : 'static'}
          top={isMobile && position_is_sticky_mobile ? '52px' : 'na'}
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mr={!promotion_end_of_time ? 'na' : isMobile ? '8px' : '40px'}
          >
            <Box
              textAlign="center"
              fontSize={isMobile ? title_font_size_mobile : title_font_size_desktop}
              color={text_color_promo_banner}
              fontWeight="600"
              fontFamily={title_font_family}
            >
              {title_promo_banner?.[0]?.text}
            </Box>
            <Box
              textAlign="center"
              fontSize={isMobile ? subtitle_font_size_mobile : subtitle_font_size_desktop}
              color={text_color_promo_banner}
              marginTop={isMobile ? '2vw' : '0.5vw'}
              fontFamily={subtitle_font_family}
            >
              {subtitle_promo_banner?.[0]?.text}
            </Box>
          </Box>
          {promotion_end_of_time && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              ml={isMobile ? '8px' : '40px'}
            >
              <Timer
                timestamp={promotion_end_of_time}
                textColor={text_color_promo_banner}
                preset={isMobile ? 'subheadingPromoBanner' : 'promoBannerMobile'}
              />
            </Box>
          )}
        </Box>
        {terms_conditions_text && (
          <Box
            textAlign="center"
            width="90%"
            color={terms_and_conditions_color}
            marginBottom="s"
            fontSize={
              isMobile ? terms_conditions_font_size_mobile : terms_conditions_font_size_desktop
            }
            marginLeft="5%"
            fontFamily={terms_conditions_font_family}
          >
            {terms_conditions_text}
          </Box>
        )}
      </>
    ),
    promoBannerDoublePromo: (
      <>
        <Box
          width="100%"
          minHeight="65px"
          backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
          marginBottom="8px"
          display="flex"
          flexDirection="column"
          position={isMobile && position_is_sticky_mobile ? 'sticky' : 'na'}
          top={isMobile && position_is_sticky_mobile ? '52px' : 'na'}
          zIndex={isMobile && position_is_sticky_mobile ? 21 : 'na'}
        >
          <Box display="flex" justifyContent="center">
            <Box
              borderRight={`solid 0.5px ${text_color_promo_banner}`}
              marginTop="10px"
              marginBottom="10px"
              display="flex"
              justifyContent={isMobile ? 'space-evenly' : 'flex-end'}
            >
              <Box
                textAlign="center"
                marginRight={isMobile ? '8px' : '24px'}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  fontSize={isMobile ? title_font_size_mobile : title_font_size_desktop}
                  color={text_color_promo_banner}
                  fontWeight="600"
                  textAlign="center"
                  fontFamily={title_font_family}
                >
                  {title_promo_banner?.[0]?.text}
                </Box>
                <Box
                  fontSize={isMobile ? subtitle_font_size_mobile : subtitle_font_size_desktop}
                  color={text_color_promo_banner}
                  marginTop={isMobile ? '4px' : '8px'}
                  textAlign="center"
                  fontFamily={subtitle_font_family}
                >
                  {subtitle_promo_banner?.[0]?.text}
                </Box>
              </Box>
            </Box>
            <Box
              borderLeft={`solid 0.5px ${text_color_promo_banner}`}
              marginTop="10px"
              marginBottom="10px"
              display="flex"
              justifyContent={isMobile ? 'space-evenly' : 'flex-start'}
            >
              <Box
                textAlign="center"
                marginLeft={isMobile ? '8px' : '24px'}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  fontSize={
                    isMobile ? secondary_title_font_size_mobile : secondary_title_font_size_desktop
                  }
                  color={text_color_promo_banner}
                  fontWeight="600"
                  textAlign="center"
                  fontFamily={secondary_title_font_family}
                >
                  {secondary_title_promo_banner?.[0]?.text}
                </Box>
                <Box
                  fontSize={
                    isMobile
                      ? secondary_subtitle_font_size_mobile
                      : secondary_subtitle_font_size_desktop
                  }
                  color={text_color_promo_banner}
                  marginTop={isMobile ? '4px' : '8px'}
                  textAlign="center"
                  fontFamily={secondary_subtitle_font_family}
                >
                  {secondary_subtitle_promo_banner?.[0]?.text}
                </Box>
              </Box>
            </Box>
            {promotion_end_of_time && !isMobile && (
              <Box display="flex" flexDirection="column" justifyContent="center" ml="80px">
                <Timer
                  timestamp={promotion_end_of_time}
                  textColor={text_color_promo_banner}
                  preset={isMobile ? 'subheadingPromoBanner' : 'promoBannerMobile'}
                />
              </Box>
            )}
          </Box>
          {promotion_end_of_time && isMobile && (
            <Box width="60%" display="flex" flexDirection="column" alignSelf="center" pb="s">
              <Timer
                timestamp={promotion_end_of_time}
                textColor={text_color_promo_banner}
                preset={isMobile ? 'subheadingPromoBanner' : 'promoBanner'}
              />
            </Box>
          )}
        </Box>
        {terms_conditions_text && (
          <Box
            textAlign="center"
            width="90%"
            color={terms_and_conditions_color}
            marginBottom="s"
            fontSize={
              isMobile ? terms_conditions_font_size_mobile : terms_conditions_font_size_desktop
            }
            marginLeft="5%"
            fontFamily={terms_conditions_font_family}
          >
            {terms_conditions_text}
          </Box>
        )}
      </>
    ),
    promoBannerWithPictureMobile: (
      <>
        <Box
          width="100%"
          minHeight="65px"
          backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
          marginBottom="-1px"
          padding={paddingPromoBanner}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position={position_is_sticky_mobile ? 'sticky' : 'na'}
          top={position_is_sticky_mobile ? '52px' : 'na'}
          zIndex={position_is_sticky_mobile ? 21 : 'na'}
        >
          <Box
            fontSize={title_font_size_mobile}
            color={text_color_promo_banner}
            fontWeight="600"
            textAlign="center"
            fontFamily={title_font_family}
          >
            {title_promo_banner?.[0]?.text}
          </Box>
          <Box
            fontSize={subtitle_font_size_mobile}
            color={text_color_promo_banner}
            marginTop="8px"
            textAlign="center"
            fontFamily={subtitle_font_family}
          >
            {subtitle_promo_banner?.[0]?.text}
          </Box>
          {promotion_end_of_time && (
            <Box display="flex" justifyContent="center" mt="16px">
              <Timer
                timestamp={promotion_end_of_time}
                textColor={text_color_promo_banner}
                preset="promoBannerMobile"
              />
            </Box>
          )}
        </Box>
        {(promo_banner_image_mobile?.url || promo_banner_image?.url) && (
          <Box
            width="100%"
            backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
            padding={paddingPromoBanner}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              backgroundImage={`url(${
                promo_banner_image_mobile?.url
                  ? promo_banner_image_mobile?.url
                  : promo_banner_image?.url
              })`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              width="90%"
              height="207px"
            />
          </Box>
        )}
        {terms_conditions_text && (
          <Box
            textAlign="center"
            width="100%"
            color={terms_and_conditions_color}
            my="s"
            fontSize={terms_conditions_font_size_mobile}
            fontFamily={terms_conditions_font_family}
          >
            {terms_conditions_text}
          </Box>
        )}
      </>
    ),
    promoBannerWithPictureDesktop: (
      <Box
        width="100%"
        height="350px"
        backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
        marginBottom="s"
        display="flex"
      >
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
        >
          <Box
            width="100%"
            height="90%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              fontSize={title_font_size_desktop}
              color={text_color_promo_banner}
              fontWeight="600"
              textAlign="center"
              marginTop="30px"
              width="80%"
              fontFamily={title_font_family}
            >
              {title_promo_banner?.[0]?.text}
            </Box>
            <Box
              fontSize={subtitle_font_size_desktop}
              color={text_color_promo_banner}
              marginTop="s"
              textAlign="center"
              width="80%"
              fontFamily={subtitle_font_family}
            >
              {subtitle_promo_banner?.[0]?.text}
            </Box>
            {promotion_end_of_time && (
              <Box display="flex" justifyContent="center" mt="24px" mb="s">
                <Timer
                  timestamp={promotion_end_of_time}
                  textColor={text_color_promo_banner}
                  preset="promoBanner"
                />
              </Box>
            )}
          </Box>
          {terms_conditions_text && (
            <Box
              justifyContent="center"
              display="flex"
              width="80%"
              color={terms_and_conditions_color}
              fontSize={terms_conditions_font_size_desktop}
              fontFamily={terms_conditions_font_family}
              position="absolute"
              bottom="8px"
            >
              {terms_conditions_text}
            </Box>
          )}
        </Box>
        <Box
          backgroundImage={`url(${promo_banner_image?.url})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          maxWidth="50%"
          maxHeight="350px"
          width="50%"
          height="350px"
        />
      </Box>
    ),
    promoBannerWithPicureWithDoublePromoDesktop: (
      <Box
        width="100%"
        height="350px"
        backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
        marginBottom="s"
        display="flex"
        position="relative"
      >
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="50%"
                borderRight={`solid 0.5px ${text_color_promo_banner}`}
              >
                <Box
                  fontSize={title_font_size_desktop}
                  color={text_color_promo_banner}
                  fontWeight="600"
                  textAlign="center"
                  width="80%"
                  fontFamily={title_font_family}
                >
                  {title_promo_banner?.[0]?.text}
                </Box>
                <Box
                  fontSize={subtitle_font_size_desktop}
                  color={text_color_promo_banner}
                  marginTop="s"
                  textAlign="center"
                  width="80%"
                  fontFamily={subtitle_font_family}
                >
                  {subtitle_promo_banner?.[0]?.text}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="50%"
                borderLeft={`solid 0.5px ${text_color_promo_banner}`}
              >
                <Box
                  fontSize={secondary_title_font_size_desktop}
                  color={text_color_promo_banner}
                  fontWeight="600"
                  textAlign="center"
                  width="80%"
                  fontFamily={secondary_title_font_family}
                >
                  {secondary_title_promo_banner?.[0]?.text}
                </Box>
                <Box
                  fontSize={secondary_subtitle_font_size_desktop}
                  color={text_color_promo_banner}
                  marginTop="s"
                  textAlign="center"
                  width="80%"
                  fontFamily={secondary_subtitle_font_family}
                >
                  {secondary_subtitle_promo_banner?.[0]?.text}
                </Box>
              </Box>
            </Box>
            {promotion_end_of_time && (
              <Box display="flex" justifyContent="center" mt="24px" mb="s">
                <Timer
                  timestamp={promotion_end_of_time}
                  textColor={text_color_promo_banner}
                  preset="promoBanner"
                />
              </Box>
            )}
          </Box>
          {terms_conditions_text && (
            <Box
              textAlign="center"
              width="80%"
              color={terms_and_conditions_color}
              fontSize={terms_conditions_font_size_desktop}
              fontFamily={terms_conditions_font_family}
              position="absolute"
              bottom="8px"
            >
              {terms_conditions_text}
            </Box>
          )}
        </Box>
        <Box
          backgroundImage={`url(${promo_banner_image?.url})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          maxWidth="50%"
          maxHeight="350px"
          width="50%"
          height="350px"
        />
      </Box>
    ),
    promoBannerWithPictureWithDoublePromoMobile: (
      <>
        <Box
          width="100%"
          backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
          marginBottom="-1px"
          minHeight="65px"
          padding={paddingPromoBanner}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position={position_is_sticky_mobile ? 'sticky' : 'na'}
          top={position_is_sticky_mobile ? '52px' : 'na'}
          zIndex={position_is_sticky_mobile ? 21 : 'na'}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <Box
              borderRight={`solid 0.5px ${text_color_promo_banner}`}
              width="50%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                fontSize={title_font_size_mobile}
                color={text_color_promo_banner}
                fontWeight="600"
                textAlign="center"
                width="90%"
                fontFamily={title_font_family}
              >
                {title_promo_banner?.[0]?.text}
              </Box>
              <Box
                fontSize={subtitle_font_size_mobile}
                color={text_color_promo_banner}
                marginTop="s"
                textAlign="center"
                width="90%"
                fontFamily={subtitle_font_family}
              >
                {subtitle_promo_banner?.[0]?.text}
              </Box>
            </Box>
            <Box
              borderLeft={`solid 0.5px ${text_color_promo_banner}`}
              width="50%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                fontSize={secondary_title_font_size_mobile}
                color={text_color_promo_banner}
                fontWeight="600"
                textAlign="center"
                width="90%"
                marginLeft="10%"
                fontFamily={secondary_title_font_family}
              >
                {secondary_title_promo_banner?.[0]?.text}
              </Box>
              <Box
                fontSize={secondary_subtitle_font_size_mobile}
                color={text_color_promo_banner}
                marginTop="s"
                textAlign="center"
                width="90%"
                marginLeft="10%"
                fontFamily={secondary_subtitle_font_family}
              >
                {secondary_subtitle_promo_banner?.[0]?.text}
              </Box>
            </Box>
          </Box>
          {promotion_end_of_time && (
            <Box display="flex" justifyContent="center" mt="m">
              <Timer
                timestamp={promotion_end_of_time}
                textColor={text_color_promo_banner}
                preset="promoBannerMobile"
              />
            </Box>
          )}
        </Box>
        {(promo_banner_image_mobile?.url || promo_banner_image?.url) && (
          <Box
            width="100%"
            backgroundColor={background_color_promo_banner ? background_color_promo_banner : 'red'}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              backgroundImage={`url(${
                promo_banner_image_mobile?.url
                  ? promo_banner_image_mobile?.url
                  : promo_banner_image?.url
              })`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              width="90%"
              height="207px"
              marginTop="s"
              marginBottom="m"
            />
          </Box>
        )}
        {terms_conditions_text && (
          <Box
            textAlign="center"
            width="90%"
            color={terms_and_conditions_color}
            my="s"
            fontSize={terms_conditions_font_size_mobile}
            marginLeft="5%"
            fontFamily={terms_conditions_font_family}
          >
            {terms_conditions_text}
          </Box>
        )}
      </>
    ),
  };

  if (promo_banner_type === 'simple text') {
    return <>{fragments.promoBannerSimpleText}</>;
  }
  if (promo_banner_type === 'double promo') {
    return <>{fragments.promoBannerDoublePromo}</>;
  }
  if (promo_banner_type === 'image version simple text' && isMobile) {
    return <>{fragments.promoBannerWithPictureMobile}</>;
  }
  if (promo_banner_type === 'image version simple text' && !isMobile) {
    return <>{fragments.promoBannerWithPictureDesktop}</>;
  }
  if (promo_banner_type === 'image version double promo' && isMobile) {
    return <>{fragments.promoBannerWithPictureWithDoublePromoMobile}</>;
  }
  if (promo_banner_type === 'image version double promo' && !isMobile) {
    return <>{fragments.promoBannerWithPicureWithDoublePromoDesktop}</>;
  }
  return null;
}
