import React from 'react';
import { Icon, StyledLink, breakpoints } from '../../../design-system';
import paths from '../../routing/paths';
import styled from '@emotion/styled';

const StyledSizeGuideFooter = styled.div`
  padding: 16px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 10;
  margin-top: auto;
  box-shadow: 0px -8px 8px rgba(0, 0, 0, 0.1);
  @media (min-width: ${breakpoints.S - 1}px) {
    display: flex;
    justify-content: center;
    padding: 24px;
    gap: 8px;
  }
  .size-guide-footer__description {
    font-size: 12px;
    margin: 4px 0;
  }
  #link-size-guide-page {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
  }
`;
const SizeGuideFooter = () => {
  return (
    <>
      <StyledSizeGuideFooter>
        <p className="size-guide-footer__description">Vous ne savez pas comment vous mesurer ?</p>
        <StyledLink to={paths.SIZE_GUIDE} id={'link-size-guide-page'} preset="caption">
          <Icon name="ruler" size={16} />
          Consulter notre guide des tailles
        </StyledLink>
      </StyledSizeGuideFooter>
    </>
  );
};

export { SizeGuideFooter };
