import * as React from 'react';

import { Box, Field, Selector, DynamicButton, DynamicButtonStatus } from '../../../design-system';
import { Forms, FieldCallback, FieldType, FormAddress, FieldRefs } from '../../form/types';
import withForm, { WithFormActions } from '../../form/hocs/withForm';
import { Address as AddressType } from '../types';
import locale from '../locale';
import {
  validate,
  populateForm,
  format,
  isCtaActive,
  isPhoneOnChangeValid,
  formatedPhoneValue,
  validateName,
  addSpacesForPhoneNumber,
  removeSpaces,
} from '../../form/utils';
import { countryOptions } from '../utils';
import { CTA_CONTENT } from '../../form/locale';
import { useDispatch } from 'react-redux';

type Props = {
  onSave: (address: AddressType) => void;
  address: AddressType;
  form: FormAddress;
} & WithFormActions;

export const Address = ({
  setFormValidation,
  setFormValues,
  setFeedback,
  form,
  onSave,
  address,
}: Props) => {
  const dispatch = useDispatch();
  const refs: FieldRefs = {};
  Object.keys(form.values).forEach((key) => {
    refs[key] = React.useRef(null);
  });

  React.useEffect(() => {
    const keys = Object.keys(address);
    const values = populateForm(address, keys);
    const validations = populateForm({}, keys);
    setFormValues({ form: Forms.address, values });
    setFormValidation({ form: Forms.address, values: validations });
  }, [address]);

  const validateField = (key: string, value: FieldType): string =>
    validate({
      value: String(value),
      key,
      isRequired: !['id', 'name', 'company', 'idTva', 'siret', 'streetAdditional'].includes(key),
    });

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    let fieldToFocus = '';
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField(key, values[key]);
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.address, values: { [key]: errMsg } });
      if (errMsg && !fieldToFocus) {
        fieldToFocus = key;
      }
      return errMsg;
    });
    if (fieldToFocus) {
      const node = refs[fieldToFocus].current;
      if (node) {
        node.focus();
      }
    }
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleFieldChange = ({ key, value }: FieldCallback) => {
    let newValue = value;
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      typeof value === 'string' &&
      !isPhoneOnChangeValid({ value, dispatch, formType: Forms.address, form })
    ) {
      return null;
    }

    newValue = key === 'phone' && typeof value === 'string' ? formatedPhoneValue(value) : value;
    if (form.validation[key] && key !== 'phone') {
      setFormValidation({ form: Forms.address, values: { [key]: validateField(key, newValue) } });
    }

    setFormValues({
      form: Forms.address,
      values: {
        [key]: typeof newValue === 'boolean' ? newValue : format({ value: newValue, key }),
      },
    });

    if (!form.feedback.isDirty) {
      setFeedback({
        form: Forms.address,
        ok: false,
        message: '',
        isDirty: true,
      });
    }
    return null;
  };

  const handleFieldBlur = ({ key, value }: FieldCallback) => {
    const errMsg = validateField(key, value);
    setFormValidation({ form: Forms.address, values: { [key]: errMsg } });
  };

  const getField = ({
    key,
    label,
    autoComplete,
    type,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    type?: string;
  }) => (
    <Box gridArea={key}>
      <Field
        id={`${Forms.address}-${key}`}
        onChange={(value: string) =>
          handleFieldChange({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        onBlur={(value: string) =>
          handleFieldBlur({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        value={key === 'phone' ? addSpacesForPhoneNumber(form.values[key]) : form.values[key]}
        errMsg={form.validation[key]}
        ref={refs[key]}
        label={label}
        autoComplete={autoComplete}
        type={type}
      />
    </Box>
  );

  const handleSubmit = () => {
    if (validateForm()) {
      onSave(form.values);
    }
  };

  return (
    <Box
      display="grid"
      gridColumnGap="s"
      gridRowGap="l"
      gridTemplateAreas={[
        `'name'
        'company'
        ${form.values.company && "'idTva'"}
        ${form.values.company && "'siret'"}
        'firstName'
        'lastName'
        'street'
        'streetAdditional'
        'postal'
        'city'
        'country'
        'phone'
        'submitbutton'`,

        `'name name'
        'company company'
         ${form.values.company && "'idTva siret'"}
        'firstName lastName'
        'street street'
        'streetAdditional streetAdditional'
        'postal city'
        'country phone'
        'submitbutton .'`,
      ]}
      mb="xxl"
      maxWidth="mws"
    >
      {getField({ key: 'name', label: 'Nom de l’adresse', autoComplete: 'address-name' })}
      {getField({ key: 'company', label: 'Entreprise', autoComplete: 'organization' })}
      {form.values.company &&
        getField({ key: 'idTva', label: 'N° d’identification TVA', autoComplete: 'tva' })}
      {form.values.company && getField({ key: 'siret', label: 'SIRET', autoComplete: 'siret' })}
      {getField({ key: 'firstName', label: 'Prénom*', autoComplete: 'given-name' })}
      {getField({ key: 'lastName', label: 'Nom*', autoComplete: 'family-name' })}
      {getField({ key: 'street', label: 'Adresse*', autoComplete: 'address-line1' })}
      {getField({
        key: 'streetAdditional',
        label: "Complément d'adresse",
        autoComplete: 'address-line2',
      })}
      {getField({ key: 'postal', label: 'Code postal*', autoComplete: 'postal-code' })}
      {getField({ key: 'city', label: 'Ville*', autoComplete: 'address-level2' })}
      <Box gridArea="country">
        <Selector
          id={`${Forms.address}-country`}
          onChange={(value: string) => handleFieldChange({ key: 'country', value })}
          value={form.values.country}
          options={countryOptions}
          note={locale.NOTE_COUNTRY}
        />
      </Box>
      {getField({ key: 'phone', label: 'Téléphone*', autoComplete: 'tel', type: 'tel' })}
      <Box gridArea="submitbutton">
        <Box width={['100%', '256px']}>
          <DynamicButton
            id="btn-submit-address"
            onClick={handleSubmit}
            disabled={!form.feedback.isDirty}
            isActive={isCtaActive(form)}
            feedback={form.ctaState || DynamicButtonStatus.Default}
            data={CTA_CONTENT}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withForm(Address, Forms.address);
