import React from 'react';
import { Box, Text } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Title } from '../../common/components/Custom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { RootState } from '../../../store/rootReducer';
import { loadRecommendations } from '../../recommendations/actions';
import { Recommendation, RecommendationTypes } from '../../recommendations/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { RecommendationsTilesT2S } from '../../recommendations/components/RecommendationsTilesT2S';
import { Product } from '../../product/types';

interface Props {
  id: string;
  title: string;
  tabs?: { name?: string; t2s_id?: string }[];
}

export function RecommendationsTabsBanner({ id, title, tabs }: Props) {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQueries();
  const recommendationBlocks = useSelector((state: RootState) => state.recommendations.blocks);

  const [selectedFilter, setSelectedFilter] = React.useState(0);

  const filteredTabs = tabs?.filter((tab) => tab.t2s_id && tab.name) ?? [];
  const t2sIds = filteredTabs.map((tab) => tab.t2s_id);

  const isRecommendationsNotLoaded =
    t2sIds.map((t2sId) => recommendationBlocks[`homeTab${t2sId}`]).filter((el) => el !== undefined)
      .length !== t2sIds.length;

  React.useEffect(() => {
    if (isRecommendationsNotLoaded) {
      dispatch(loadRecommendations(RecommendationTypes.home, t2sIds) as unknown as UnknownAction);
    }
  }, []);

  const recommendations = t2sIds.map(
    (t2sId) => recommendationBlocks[`homeTab${t2sId}`]
  ) as Recommendation[];

  const allRecommendedProducts = recommendations
    .map((recommendation) => recommendation?.recommendedProducts)
    .reduce((acc, val) => acc.concat(val), []); // use to flatten the array}

  const { hits: unorderedHits } = useProductsInfos(allRecommendedProducts);

  const hits: Product[][] = [[], [], []];

  unorderedHits.forEach((hit) => {
    recommendations.forEach((recommendation, i) => {
      recommendation.recommendedProducts.forEach((product) => {
        if (`${hit.productRef}-${hit.colorRef}` === product) {
          hits[i].push(hit);
        }
      });
    });
  });

  if (!hits.length || isRecommendationsNotLoaded) {
    return null;
  }

  return (
    <Box id={'recommendations-tabs-' + id}>
      <Title>{title}</Title>
      <Box width={isMobile ? '100%' : 'fit-content'} marginX={'auto'} paddingX={isMobile ? 16 : 0}>
        <Box
          display={'grid'}
          gridTemplateColumns={'1fr '.repeat(t2sIds.length)}
          justifyContent={'center'}
          marginY={16}
        >
          {filteredTabs.map((tab, i) => (
            <Text
              key={tab.t2s_id}
              onClick={() => setSelectedFilter(i)}
              paddingX={isMobile ? 0 : 24}
              paddingY={12}
              fontSize={16}
              borderBottom={selectedFilter === i ? '1px solid #000' : '1px solid #E6E6E6'}
            >
              {tab.name}
            </Text>
          ))}
        </Box>
      </Box>

      <RecommendationsTilesT2S
        type={RecommendationTypes.home}
        hits={hits[selectedFilter]}
        tracking={recommendations[selectedFilter].tracking}
      />
    </Box>
  );
}
