import { formatDate } from '../../design-system';
import {
  ERR_INVALID_EMAIL,
  ERR_INVALID_PASSWORD,
  ERR_REQUIRED,
  ERR_TOO_LONG,
  ERR_INVALID_FORMAT,
  ERR_PICKUP_PHONE_NUMBER,
  ERR_INVALID_CHARACTERS_FIRST_LAST_NAME,
  ERR_INVALID_CHARACTERS,
  ERR_ONLY_NUMBER_ALLOWED,
  ERR_INVALID_FIRST_CHARACTER_FIRST_LAST_NAME,
} from './locale';
import {
  Forms,
  FormValues,
  CartForms,
  FormPersonal,
  FormAddress,
  FormAffiliate,
  FormSignUp,
  FormSignInEmail,
  FormSignInPassword,
  Callback,
  FormEReservation,
} from './types';
import { setFormValidation } from './actions';

function validatePassword(password: string): boolean {
  return password.length >= 8 && password.length <= 255 && !password.includes(' ');
}

function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateDateOfBirth(value: string): boolean {
  const re =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
  const isValidated = re.test(value);
  const year = Number(value.slice(-4));
  const isYearValid = year >= 1920 && year < Number(new Date().getFullYear());
  return isValidated && isYearValid;
}

function validateNumber(value: string): boolean {
  return /^\d+$/.test(value);
}

function validateIdTva(value: string): boolean {
  const firstTwo = value.slice(0, 2);
  const lastNine = value.slice(-9);
  return value.length === 13 && firstTwo === 'FR' && validateNumber(lastNine);
}

function validateSiret(value: string): boolean {
  return value.length === 14 && validateNumber(value);
}

function validatePostalCode(value: string): boolean {
  return value.length === 5 && validateNumber(value);
}

function validatePickupPhone(value: string): boolean {
  return /^0([6-7])\d{8}$/.test(value.replace(/\s/g, ''));
}

function validatePhone(value: string): boolean {
  return /^(\+33|0)([1-9])\d{8}$/.test(value.replace(/\s/g, ''));
}

type Validation = {
  key?: string;
  isRequired?: boolean;
  maxLength?: number;
  message?: string;
  validator?: (value: string) => boolean;
  formatter?: (value: string) => string;
};

type ParamsValidation = {
  value: string;
  key?: string;
  isRequired?: boolean;
};

const validations: Validation[] = [
  {
    key: 'email',
    validator: validateEmail,
    maxLength: 80,
    message: ERR_INVALID_EMAIL,
  },
  {
    key: 'password',
    validator: validatePassword,
    maxLength: 120,
    message: ERR_INVALID_PASSWORD,
  },
  {
    key: 'firstName',
    maxLength: 30,
  },
  {
    key: 'lastName',
    maxLength: 30,
  },
  {
    key: 'dob',
    validator: validateDateOfBirth,
    formatter: formatDate,
  },
  {
    key: 'postal',
    validator: validatePostalCode,
    formatter: formatCode,
  },
  {
    key: 'phone',
    validator: validatePhone,
  },
  {
    key: 'street',
    maxLength: 38,
  },
  {
    key: 'streetAdditional',
    maxLength: 38,
  },
  {
    key: 'city',
    maxLength: 50,
  },
  {
    key: 'company',
    maxLength: 30,
  },
  {
    key: 'name',
    maxLength: 120,
  },
  {
    key: 'orderNumber',
    maxLength: 120,
    validator: validateNumber,
  },
  {
    key: 'subject',
    maxLength: 120,
  },
  {
    key: 'address',
    maxLength: 120,
  },
  {
    key: 'idTva',
    validator: validateIdTva,
    formatter: formatCode,
  },
  {
    key: 'siret',
    validator: validateSiret,
    formatter: formatCode,
  },
  { key: 'message', maxLength: 2000 },
  { key: 'giftMessage', maxLength: 240 },
  {
    key: 'pickupPhone',
    validator: validatePickupPhone,
    formatter: formatCode,
    message: ERR_PICKUP_PHONE_NUMBER,
  },
];

export function validate({ value, key, isRequired = true }: ParamsValidation): string {
  if (!value && isRequired) {
    return ERR_REQUIRED;
  }

  if ((key === 'phone' || key === 'pickupPhone') && !value.match(/^[0-9 +]+$/) && value !== '') {
    return key === 'pickupPhone' ? ERR_PICKUP_PHONE_NUMBER : ERR_INVALID_FORMAT;
  }

  if (!value.match(/^[A-Za-zÀ-ÖØ-öø-ÿŸœŒ '’-]+$/) && (key === 'firstName' || key === 'lastName')) {
    return ERR_INVALID_CHARACTERS_FIRST_LAST_NAME;
  }

  if (
    value.length &&
    !value[0].match(/^[A-Za-zÀ-ÖØ-öø-ÿŸœŒ]+$/) &&
    (key === 'firstName' || key === 'lastName')
  ) {
    return ERR_INVALID_FIRST_CHARACTER_FIRST_LAST_NAME;
  }

  if (value.length && key === 'postal' && !value.match(/^\d+$/)) {
    return ERR_ONLY_NUMBER_ALLOWED;
  }

  if (
    !value.match(/^[A-Za-zÀ-ÖØ-öø-ÿŸ0-9. \n,.:;?!#<<>>/$%^_[\]*|`~\-{}«»()/&…’'"=+@œŒ]+$/) &&
    value !== '' &&
    key !== 'email' &&
    key !== 'password' &&
    key !== 'name' &&
    key !== 'giftMessage' &&
    key !== 'firstName' &&
    key !== 'lastName'
  ) {
    return ERR_INVALID_CHARACTERS;
  }

  const validation = validations.find((validation) => validation.key === key);
  const maxLength = validation && validation.maxLength ? validation.maxLength : 120;

  if (value.length > maxLength) {
    return ERR_TOO_LONG(maxLength);
  }

  if (value || isRequired) {
    const isValidFormat = validation?.validator ? validation.validator(value) : true;
    if (!isValidFormat) {
      return validation?.message ? validation.message : ERR_INVALID_FORMAT;
    }
  }
  return '';
}

type DispatchCallback = {
  type: 'FORM / SET FORM VALIDATION';
  payload: Callback;
};

type Dispatch = (action: DispatchCallback) => DispatchCallback;

type NewPhoneValidation = {
  value: string;
  dispatch: Dispatch;
  formType: Forms;
  form: FormAffiliate | FormAddress | FormPersonal | FormEReservation;
};

export const isPhoneOnChangeValid = ({
  value,
  dispatch,
  formType,
  form,
}: NewPhoneValidation): boolean => {
  if (form.values.phone.length > value.length) {
    return true;
  }
  if (
    (!value.includes('+') && value.replace(/\s/g, '').length > 10) ||
    (!value.includes('+') &&
      value.replace(/\s/g, '').length === 10 &&
      value[value.length - 1] === ' ')
  ) {
    return false;
  }
  if (
    (value.includes('+') && value.replace('+', '').replace(/\s/g, '').length > 12) ||
    (value.includes('+') &&
      value.replace('+', '').replace(/\s/g, '').length === 12 &&
      value[value.length - 1] === ' ')
  ) {
    return false;
  }
  const inputOk = Boolean(value.match(/^[0-9+ ]+$/) || !value.length);
  dispatch(
    setFormValidation({
      form: formType,
      values: { ['phone']: !inputOk ? ERR_ONLY_NUMBER_ALLOWED : '' },
    })
  );

  if (value.length > 1 && value[value.length - 1] === '+') {
    return false;
  }
  if (
    (value.length === 1 && value === ' ') ||
    (value[0] === '+' && value.length === 4 && value[value.length - 1] === ' ')
  ) {
    return false;
  }
  if (form.values.phone.length < value.length && value.match(/^[0-9+ ]+$/)) {
    if (countSpaces(value) > countSpaces(form.values.phone) && form.values.phone.length) {
      return false;
    }
    return true;
  }

  return inputOk;
};

const insertAtIndex = (originalString: string, character: string, index: number) => {
  if (index < 0 || index > originalString.length) {
    return originalString;
  }
  return originalString.slice(0, index) + character + originalString.slice(index);
};

export const formatedPhoneValue = (value: string) => {
  let string = value;
  if (value[0] === '+' && value.length === 4 && value[3] !== '0' && value[3] && value[3] !== ' ') {
    string = insertAtIndex(value, '0', 3);
  }
  if (value.length && value.match(/^[1-9 ]+$/)) {
    string = insertAtIndex(value, '0', 0);
  }
  return string;
};

const countSpaces = (value: string) => {
  let spaceCount = 0;
  for (let i = 0; i < value.length; i++) {
    if (value[i] === ' ') {
      spaceCount++;
    }
  }
  return spaceCount;
};

export function validateName(key: string, value: string | boolean): boolean {
  return (
    (key === 'firstName' || key === 'lastName') &&
    typeof value === 'string' &&
    (value.length > 30 || /\s\s|--|''|’’/.test(value))
  );
}

export function addSpacesForPhoneNumber(value: string): string {
  return value.replace(/(\d{2})(?=\d)/g, '$& ');
}

export function removeSpaces(value: string): string {
  return value.replace(/\s/g, '');
}

export function isCtaActive(
  forms:
    | CartForms
    | FormPersonal
    | FormAddress
    | FormAffiliate
    | FormSignUp
    | FormSignInEmail
    | FormSignInPassword
): boolean {
  if ('validation' in forms) {
    for (const property in forms.validation) {
      if (forms.validation[property] !== '' && property !== 'optInEmail') {
        return false;
      }
    }
  } else {
    for (const property in forms) {
      for (const item in forms[property].validation) {
        if (forms[property].validation[item] !== '') {
          return false;
        }
      }
    }
  }
  return true;
}

function formatUpperCase(value: string): string {
  return value.toUpperCase();
}

function formatRemoveSpaces(value: string): string {
  return value.replace(/\s/g, '');
}

function formatCode(value: string): string {
  return formatRemoveSpaces(formatUpperCase(value));
}

type ParamsFormat = {
  value: string;
  key: string;
};

export function format({ value, key }: ParamsFormat): string {
  const validation = validations.find((validation) => validation.key === key);
  return validation?.formatter ? validation.formatter(value) : value;
}

export function populateForm(source: object, keys: string[]): FormValues {
  return Object.assign({}, ...keys.map((key) => ({ [key]: source[key] || '' })));
}
