import * as React from 'react';
import styled from '@emotion/styled';

import { StyledLink, Icon, breakpoints } from '../../../design-system';
export const StyledLogoIcon = styled(Icon)`
  width: 137px;
  height: 18px;
  @media (min-width: ${breakpoints.S}px) {
    width: 128px;
    height: 17px;
  }
`;

export const Logo = () => (
  <StyledLink id="link-logo" to="/" decoration="none" className="header__logo-container">
    <StyledLogoIcon name="logo" className="header__logo" />
  </StyledLink>
);
