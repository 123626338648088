import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Icon, Text } from '../../../design-system';
import { boxShadows } from '../../common/constants';
import locale from '../locale';
import { addToWishlist, removeFromWishlist, resetErrorMessage } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { wishlistItemsSelector } from '../selectors';
import { useDevice } from '../../common/hooks/useDevice';
import { Product } from '../../product/types';
import { CatalogProduct } from '../../catalog/types';
import { isItGiftCard } from '../../product/utils';

type Props = {
  objectID: string;
  productRef: string;
  colorRef: string;
  product: Product | CatalogProduct;
  isQuickAddMobile?: boolean;
};

const RECENT_MS = 1000;

const WishlistCta = ({ objectID, productRef, colorRef, product, isQuickAddMobile }: Props) => {
  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const [isHovered, setIsHovered] = React.useState(false);
  const [isRecentlyAdded, setIsRecentlyAdded] = React.useState(false);
  const [isAdded, setIsAdded] = React.useState(false);

  const wishlistItems = useSelector((state: RootState) => wishlistItemsSelector(state));
  const isInWishlist = wishlistItems.some(
    (item) => item.productRef === productRef && item.colorRef === colorRef
  );
  const { errMsg, isFetching } = useSelector((state: RootState) => state.wishlist);

  const onClick = () => {
    if (isInWishlist && !isFetching) {
      setIsAdded(false);
      dispatch(removeFromWishlist({ productRef, colorRef }, product) as unknown as UnknownAction);
    }
    if (!isInWishlist && !isFetching) {
      setIsRecentlyAdded(true);
      setIsAdded(true);
      dispatch(addToWishlist({ productRef, colorRef }, product) as unknown as UnknownAction);
      setTimeout(() => {
        setIsRecentlyAdded(false);
      }, RECENT_MS);
    }
  };

  React.useEffect(() => {
    dispatch(resetErrorMessage());
    if (isInWishlist) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }
  }, [isInWishlist, errMsg]);

  const showLongVersion =
    (isHovered && (!isAdded || isRecentlyAdded)) || (isMobile && isAdded && isRecentlyAdded);

  const isGiftCard = isItGiftCard(productRef);

  if (isQuickAddMobile) {
    return (
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        justifyContent="left"
        alignItems="center"
      >
        <Box
          size="xxl"
          borderRadius="50%"
          bg="WHITE"
          alignItems="center"
          justifyContent="center"
          boxShadow={boxShadows.ON_TOP}
          id={`btn-wishlist-${objectID}`}
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          display="flex"
        >
          <Icon name={isAdded ? 'heartFull' : 'heart'} size={23} />
        </Box>
        <Text display={!isAdded || isRecentlyAdded ? 'block' : 'none'} preset="caption">
          {isAdded ? locale.ADDED_TO_WISHLIST : locale.ADD_TO_WISHLIST}
        </Text>
      </Box>
    );
  }

  if (isGiftCard) {
    return null;
  }

  return (
    <Box
      width={showLongVersion ? (isMobile ? '185px' : '168px') : 'xxl'}
      height="xxl"
      borderRadius={showLongVersion ? '23px' : '50%'}
      bg="WHITE"
      alignItems="center"
      justifyContent="center"
      boxShadow={boxShadows.ON_TOP}
      id={`btn-wishlist-${objectID}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      display="grid"
      gridAutoFlow="column"
      gridTemplateColumns={showLongVersion ? (isMobile ? '136px auto' : '120px auto') : 'auto'}
      gridGap="s"
    >
      <Text
        display={showLongVersion ? 'block' : 'none'}
        preset={isAdded && isRecentlyAdded ? 'body' : 'caption'}
        textAlign="center"
      >
        {isMobile
          ? locale.ADDED_TO_WISHLIST_MOBILE
          : isAdded && isRecentlyAdded
          ? locale.ADDED_TO_WISHLIST
          : locale.ADD_TO_WISHLIST}
      </Text>
      <Icon name={isAdded ? 'heartFull' : 'heart'} size={23} />
    </Box>
  );
};

export default WishlistCta;
