import React from 'react';
import { Anchor, Box, colors, Divider, Text } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';

export default function GiftCardMiniDescription() {
  return (
    <Box>
      <Text fontSize="s" color={colors.GREY}>
        Notre carte cadeau est une carte virtuelle qui peut être utilisée aussi bien{' '}
        <ToolTip display={'inline'}>
          <Anchor id={'en-ligne'} color={colors.GREY}>
            {' '}
            en ligne{' '}
          </Anchor>
          <Box
            width={324}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            p={'1.6rem !important'}
          >
            <Text fontWeight={'700'}>En ligne</Text>
            <Divider my={'16px'} color={colors.BLACK} />
            <Text>
              Lors de l’étape du paiement, saisissez les 19 numéros inscrits sur votre carte cadeau
              dans le champ correspondant ainsi que votre code PIN.
            </Text>
          </Box>
        </ToolTip>{' '}
        qu&apos;
        <ToolTip display={'inline'}>
          <Anchor id={'en-boutique'} color={colors.GREY}>
            en boutique
          </Anchor>
          <Box
            width={324}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            p={'1.6rem !important'}
          >
            <Text fontWeight={'700'}>En boutique</Text>
            <Divider my={'16px'} color={colors.BLACK} />
            <Text>Imprimez-la et réglez avec directement en caisse.</Text>
          </Box>
        </ToolTip>
        .
      </Text>
    </Box>
  );
}
