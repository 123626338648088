import * as React from 'react';

import { Box } from '../../../design-system';
import { CmsRow } from '../types';
import { useDevice } from '../../common/hooks/useDevice';
import BrandCta from './BrandCta';
import BrandText from './BrandText';
import BrandImage from './BrandImage';
import BrandVideo from './BrandVideo';

type Props = {
  item: CmsRow;
};

const BrandItem = ({ item }: Props) => {
  const { isDesktop } = useDevice();

  const {
    type,
    column,
    body,
    image,
    video,
    image_mobile: imageMobile,
    ctatext: ctaText,
    ctatype: ctaType,
    cta_type_mobile: ctaTypeMobile,
    ctalink: link,
    cta_position: ctaPosition,
    background_color: backgroundColor,
    is_video_wide_format: isVideoWideFormat,
  } = item;

  const gridColumn = !isDesktop
    ? column === 'quarter'
      ? 'span 6'
      : 'span 12'
    : column === 'quarter'
    ? 'span 3'
    : column === 'one-third'
    ? 'span 4'
    : column === 'half'
    ? 'span 6'
    : 'span 12';

  const alignContent = column === 'half' || column === 'full' ? 'center' : 'start';

  return (
    <Box
      {...{ gridColumn, backgroundColor, alignContent }}
      py="xxl"
      justifySelf="center"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      display="grid"
      position="relative"
      gridAutoRows="min-content"
    >
      {type === 'video' && video ? (
        <BrandVideo {...{ column, video, isVideoWideFormat }} />
      ) : type === 'text' && body?.[0]?.text && (column === 'half' || column === 'full') ? (
        <>
          <BrandText {...{ body, column }} />
          {ctaText && link && <BrandCta {...{ ctaText, ctaType, ctaTypeMobile, link, column }} />}
        </>
      ) : (
        type === 'image' &&
        image?.url && (
          <BrandImage
            {...{
              body,
              column,
              image,
              imageMobile,
              ctaText,
              ctaType,
              ctaTypeMobile,
              ctaPosition,
              link,
            }}
          />
        )
      )}
    </Box>
  );
};

export default BrandItem;
