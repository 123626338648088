import React, { useEffect } from 'react';
import { CmsCatalogHighlightItem } from '../../../cms/types';
import { useProductsInfos } from '../../../common/hooks/useProductsInfos';
import { ProductCard } from './ProductCard';

type Props = {
  item: CmsCatalogHighlightItem;
  setProductRefWithColorSelected: (value: string) => void;
  setOriginalColorRef: (value: string) => void;
};

export const BannerItem = ({
  item,
  setProductRefWithColorSelected,
  setOriginalColorRef,
}: Props) => {
  const { hits } = useProductsInfos([item.banner_product_ref ?? '']);

  useEffect(() => {
    if (hits.length) {
      setOriginalColorRef(hits[0]?.colorRef);
    }
  }, [hits]);

  return (
    <div>
      {item.banner_product_ref?.length ? (
        hits?.[0] && (
          <ProductCard
            hit={hits[0]}
            setProductRefWithColorSelected={setProductRefWithColorSelected}
          />
        )
      ) : (
        <a href={item.banner_link}>
          <img
            className="item-visual__image"
            src={item.banner_image?.url}
            alt={item.banner_image?.alt ?? ''}
          />
        </a>
      )}
    </div>
  );
};
