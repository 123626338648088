import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { Box, StyledLink } from '../../../design-system';

export const Links = () => {
  const items = useSelector((state: RootState) => state.cms.menu?.items) ?? [];
  const uniqueLinks = items.filter(
    (item, index, self) => self.findIndex((s) => s.link === item.link) === index
  );

  return (
    <Box display="none" maxWidth="mws" m="auto" p="l">
      {uniqueLinks.map((item, index) => (
        <StyledLink key={index} id={`links-${index}`} to={item.link ?? ''} decoration="inline">
          {item.name ?? ''}
        </StyledLink>
      ))}
    </Box>
  );
};

export default Links;
