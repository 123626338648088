import * as React from 'react';
import { useSortBy } from 'react-instantsearch';

import { Box, Anchor, Text, Divider } from '../../../design-system';

type Props = {
  items: {
    value: string;
    label: string;
  }[];
  onClose: () => void;
};

export const MobileSortBar: React.FC<Props> = ({ items, onClose }) => {
  const { refine, currentRefinement } = useSortBy({ items });
  return (
    <Box>
      {items.map((item, index) => (
        <React.Fragment key={item.value}>
          <Box p="m">
            <Anchor
              id={`sort-drawer-link-${index}`}
              decoration="none"
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
                onClose();
              }}
            >
              <Text
                id={`sort-drawer-text-${index}`}
                fontWeight={item.value === currentRefinement ? 'bold' : 'regular'}
                textTransform="uppercase"
              >
                {item.label}
              </Text>
            </Anchor>
          </Box>
          <Divider />
        </React.Fragment>
      ))}
    </Box>
  );
};
