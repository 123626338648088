import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import { Box, Icon, Text, Divider, StyledLink } from '../../../design-system';
import { CmsUspContent } from '../../cms/types';
import { CmsBox } from '../../common/components/Custom';
import { KNOW_MORE } from '../locale';

type Props = {
  item: CmsUspContent;
};

const UspModal = ({ item }: Props) => {
  const { icon, link, points, subtitle, title } = item ?? {};

  return (
    <Box>
      <Box
        display="grid"
        alignItems="center"
        justifyContent="center"
        gridGap="xs"
        textAlign="center"
      >
        {icon && (
          <Box mx="auto" mb="m">
            <Icon name={icon} size={40} />
          </Box>
        )}
        <Text preset="subheading" fontWeight="bold">
          {title}
        </Text>
        <Text color="GREY">{subtitle}</Text>
      </Box>
      <Divider color="BLACK" my="m" />
      <Box>
        {points?.[0]?.text && (
          <CmsBox mb="m" withPadding>
            <RichText render={points} />
          </CmsBox>
        )}
        {link && (
          <Box ml="l" mb="xl">
            <StyledLink id="usp-modal-link" to={link}>
              {KNOW_MORE}
            </StyledLink>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UspModal;
