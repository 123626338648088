import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Icon, Pagination, ProgressBar } from '../../../design-system';
import { Feedback, Reviews as ReviewsType } from '../types';
import Recommendations from './Recommendations';
import ReviewCard from './ReviewCard';
import { useDevice } from '../../common/hooks/useDevice';
import { getFeedBackRatingCount, customSubReviewsSort } from '../utils';
import GlobalRecommendation from './GlobalRecommendation';
import { loadReviews } from '../../product/actions';
import { LBL_PRODUCT_REVIEWS_TITLE } from '../../product/locale';

type Props = {
  reviews: ReviewsType;
  productCode: string;
  reviewsRef: React.RefObject<HTMLDivElement>;
};
const Reviews = ({ reviews, productCode, reviewsRef }: Props) => {
  const { isMobile, isTablet } = useDevice();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const { rating_count, rating_average, recommendation_percent, sub_questions_global, feedbacks } =
    reviews;
  const feedBackRatingCount = feedbacks ? getFeedBackRatingCount(feedbacks) : null;
  const [selectedSort, setSelectedSort] = React.useState<string>('Plus récentes');
  const [sortedFeedbacks, setSortedFeedbacks] = React.useState<Feedback[]>(feedbacks);

  const feedbacksList = (list: Feedback[]) =>
    list.map((feedback: Feedback, index: number) => (
      <Box key={`${feedback.feedback_id}-${index}`} height="80">
        <ReviewCard feedback={feedback} />
      </Box>
    ));

  React.useEffect(() => {
    if (hash === '#reviews') {
      reviewsRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [reviewsRef?.current, hash]);

  React.useEffect(() => {
    if (selectedSort === 'Moins récentes') {
      setSortedFeedbacks(
        feedbacks
          .slice()
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
      );
    }
    if (selectedSort === 'Plus récentes') {
      setSortedFeedbacks(
        feedbacks
          .slice()
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      );
    }
    if (selectedSort === 'Notes croissantes') {
      setSortedFeedbacks(feedbacks.slice().sort((a, b) => a.rating - b.rating));
    }
    if (selectedSort === 'Notes décroissantes') {
      setSortedFeedbacks(feedbacks.slice().sort((a, b) => b.rating - a.rating));
    }
  }, [selectedSort, feedbacks]);

  React.useEffect(() => {
    let needToReloadReviews = true;
    feedbacks?.forEach((item: Feedback) => {
      if (item.size) {
        needToReloadReviews = false;
      }
    });
    if (needToReloadReviews) {
      dispatch(loadReviews(productCode) as unknown as UnknownAction);
    }
  }, [feedbacks]);

  const orderedSubQuestions = sub_questions_global
    ? customSubReviewsSort(sub_questions_global)
    : null;

  return (
    <div>
      <h2
        style={{
          fontSize: '3.2rem',
          fontWeight: 500,
          lineHeight: '110%',
          fontFamily: 'Ogg-text',
          textAlign: 'center',
          paddingBottom: isMobile || isTablet ? 'na' : '24px',
        }}
      >
        {LBL_PRODUCT_REVIEWS_TITLE}
      </h2>
      <Box
        display="flex"
        flexDirection={isMobile || isTablet ? 'column' : 'row'}
        gridGap={isMobile || isTablet ? 'l' : 'xxl'}
      >
        <Box
          id="reviews-block-global-recommandation"
          flex={isMobile || isTablet ? '0 1 100%' : '0 1 271px'}
        >
          <Recommendations
            recommendationPercent={recommendation_percent}
            ratingAverage={rating_average}
            ratingCount={rating_count}
          />
          <Box mt={'l'}>
            {feedBackRatingCount &&
              feedBackRatingCount?.length > 0 &&
              feedBackRatingCount.map((item: number, index: number) => {
                const ratingPrecentage = (item / reviews.feedbacks.length) * 100;
                return (
                  <Box
                    key={`star-rating-count-ordered-${index}`}
                    width="100%"
                    display="flex"
                    pb={index + 1 < feedBackRatingCount.length ? 'm' : 'na'}
                  >
                    <Box display="flex" width={isMobile || isTablet ? '15%' : '37px'}>
                      <Box
                        fontSize="1.4rem"
                        fontWeight={400}
                        lineHeight="110%"
                        color="#5A5A5A"
                        minWidth="m"
                        textAlign="left"
                      >
                        {feedBackRatingCount.length - index}
                      </Box>
                      <Icon name="star" size={12} />
                    </Box>
                    <Box width={isMobile || isTablet ? '70%' : '184px'} alignSelf="center">
                      <ProgressBar progressionPercentage={ratingPrecentage} />
                    </Box>
                    <Box
                      fontSize="1.4rem"
                      fontWeight={400}
                      lineHeight="110%"
                      color="#5A5A5A"
                      width={isMobile || isTablet ? '15%' : '50px'}
                      textAlign="right"
                    >
                      {`(${item})`}
                    </Box>
                  </Box>
                );
              })}
            <Box
              display="flex"
              width={isMobile || isTablet ? '100%' : 'auto'}
              flexDirection="column"
              mt={isMobile || isTablet ? 'l' : '52px'}
            >
              <Box fontSize="1.4rem" fontWeight={700} lineHeight="110%">
                Détails produit
              </Box>
              <Box width={isMobile || isTablet ? '100%' : '271px'}>
                {orderedSubQuestions?.map((subQuestion, index) => (
                  <Box mt="25px" key={`reviews-block-global-${index}`}>
                    <GlobalRecommendation
                      subQuestionTitle={subQuestion.sub_question_title['fr']}
                      subQuestionRating={subQuestion.sub_question_rating}
                      identifier={`reviews-global-recommandations-${index}`}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box id="reviews-block-cards" flex={isMobile || isTablet ? '0 1 100%' : '1 1 66%'}>
          <Pagination
            items={feedbacksList(sortedFeedbacks)}
            pageNeighbours={1}
            pageLimit={3}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Reviews;
