import React from 'react';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useSortBy } from 'react-instantsearch';

import { Box, Text, Divider, Icon, Anchor } from '../../../design-system';
import { sortIndices } from '../constants';
import { MobileSortBar } from './MobileSortBar';

type Props = {
  onClose: () => void;
};

export const MobileSortSelector: React.FC<Props> = ({ onClose }) => {
  const { refine, currentRefinement } = useSortBy({ items: sortIndices });
  const rest = [sortIndices[1], sortIndices[2], sortIndices[3], sortIndices[4]];
  const { search } = useLocation();
  const { trierPar } = qs.parse(search.slice(1));

  React.useEffect(() => {
    onClose();
  }, [trierPar]);

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="90px auto 90px"
        alignItems="center"
        justifyContent="space-between"
        mb="m"
        pt="l"
      >
        <Icon id="sort-bar-go-back" name="chevronLeft" size={14} onClick={onClose} />
        <Text preset="subheading" textTransform="uppercase" justifySelf="center">
          Trier Par
        </Text>
        {currentRefinement !== sortIndices[0].value ? (
          <Box justifySelf="right">
            <Anchor
              id={`sort-drawer-link-reset`}
              onClick={(event) => {
                event.preventDefault();
                refine(sortIndices[0].value);
                onClose();
              }}
              preset="caption"
              decoration="none"
            >
              Réinitialiser
            </Anchor>
          </Box>
        ) : null}
      </Box>
      <Divider color="BLACK" />
      <MobileSortBar items={rest} onClose={onClose} />
    </>
  );
};
