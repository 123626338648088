import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { css } from '@emotion/core';
import { RichText } from 'prismic-reactjs';

import { RootState } from 'src/shared/store/rootReducer';
import { Icon, breakpoints, colors } from '../../../design-system';
import { CmsCatalogTestimonial, CmsCatalogTestimonialItem } from '../../cms/types';
import { emptyText } from '../../cms/state';

export const CatalogTestimonial = () => {
  const { catalog_testimonial_is_active_desktop, catalog_testimonial_is_active_mobile, body1 } =
    useSelector((state: RootState) => state.cms.catalog) ?? {};

  const { items, primary } =
    body1?.find((el: CmsCatalogTestimonial) => el.slice_type === 'catalog_testimonial') ?? {};

  const { catalog_testimonial_title, catalog_testimonial_description } = primary ?? {};

  const [activeIndex, setActiveIndex] = React.useState(0);

  const NUMBER_OF_ITEMS_SHOWN = 1;

  const onClickLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onClickRight = () => {
    if (activeIndex + NUMBER_OF_ITEMS_SHOWN < (items?.length ?? 0)) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: onClickRight,
    onSwipedRight: onClickLeft,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  if (
    (!catalog_testimonial_is_active_desktop && !catalog_testimonial_is_active_mobile) ||
    !items?.length
  ) {
    return null;
  }

  return (
    <div
      id="catalog-testimonial-block"
      css={css`
        display: ${catalog_testimonial_is_active_desktop ? 'flex' : 'none'};
        scroll-margin-top: 84px;
        width: 100%;
        @media (max-width: ${breakpoints.S - 1}px) {
          display: ${catalog_testimonial_is_active_mobile ? 'flex' : 'none'};
          scroll-margin-top: 68px;
        }
      `}
    >
      <div
        css={css`
          overflow: hidden;
          padding: 124px 0 0 0;
          width: 100%;
          @media (max-width: ${breakpoints.S - 1}px) {
            padding: 48px 0;
          }
          @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.L}px) {
            padding-left: 24px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-left: 64px;
            @media (max-width: ${breakpoints.S - 1}px) {
              flex-direction: column;
              width: calc(100vw - 32px);
              margin: 0 16px;
              align-items: center;
            }
            @media (max-width: ${breakpoints.L - 1}px) {
              padding-left: 0;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 35%;
              flex-direction: column;
              margin-right: 64px;
              @media (max-width: ${breakpoints.S - 1}px) {
                width: 100%;
                margin-right: 0;
              }
              @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.M}px) {
                margin-right: 32px;
              }
            `}
          >
            {catalog_testimonial_title ? (
              <div
                css={css`
                  font-size: 3rem;
                  font-family: 'Ogg-text';
                  font-weight: 700;
                  line-height: 110%;
                `}
              >
                {catalog_testimonial_title}
              </div>
            ) : null}
            <div
              css={css`
                width: 80px;
                border: solid 1px black;
                margin: 16px 0;
                align-self: initial;
                @media (max-width: ${breakpoints.S - 1}px) {
                  align-self: flex-start;
                }
              `}
            />
            <div
              css={css`
                font-size: 1.6rem;
                line-height: 110%;
                * {
                  margin-block-start: 0;
                  margin-inline-end: 0;
                }
              `}
            >
              <RichText render={catalog_testimonial_description ?? [...emptyText]} />
            </div>
            <div
              css={css`
                margin: 32px 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                @media (max-width: ${breakpoints.S - 1}px) {
                  justify-content: flex-start;
                }
                @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.L - 1}px) {
                  justify-content: flex-start;
                }
              `}
            >
              <button
                id="catalog-testimonial-arrow-left-button"
                data-cy="catalog-testimonial-arrow-left-button"
                onClick={onClickLeft}
                css={css`
                  padding: 8px;
                  margin: 0 32px;
                  border: solid 1px grey;
                  background: none;
                  border-radius: 50%;
                  height: 48px;
                  width: 48px;
                  display: ${NUMBER_OF_ITEMS_SHOWN >= items.length ? 'none' : 'flex'};
                  align-items: center;
                  justify-content: center;
                  opacity: ${activeIndex === 0 ? 0.3 : 1};
                  cursor: ${activeIndex === 0 ? 'initial' : 'pointer'};
                  @media (max-width: ${breakpoints.S - 1}px) {
                    margin: 0;
                  }
                `}
              >
                <Icon name="arrowLeft" size={16} />
              </button>
              <button
                onClick={onClickRight}
                id="catalog-testimonial-arrow-right-button"
                data-cy="catalog-testimonial-arrow-right-button"
                type="button"
                css={css`
                  padding: 8px;
                  margin: 0 32px;
                  background: none;
                  border: solid 1px grey;
                  border-radius: 50%;
                  height: 48px;
                  width: 48px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  display: ${NUMBER_OF_ITEMS_SHOWN >= items.length ? 'none' : 'flex'};
                  opacity: ${activeIndex + NUMBER_OF_ITEMS_SHOWN === items.length ? 0.3 : 1};
                  cursor: ${activeIndex + NUMBER_OF_ITEMS_SHOWN === items.length
                    ? 'initial'
                    : 'pointer'};
                  @media (max-width: ${breakpoints.S - 1}px) {
                    margin: 0 48px;
                  }
                `}
              >
                <Icon name="arrowRight" size={16} />
              </button>
            </div>
          </div>
          <div
            css={css`
              width: calc(65% - 64px);
              background-color: ${colors.BACKGROUND};
              padding-bottom: 96px;
              margin: -96px 0 0 96px;
              @media (max-width: ${breakpoints.S - 1}px) {
                width: 100%;
                background: none;
                height: auto;
                margin: 0;
                padding: 0;
              }
            `}
          >
            <div
              id="catalog-testimonial-items-block"
              css={css`
                overflow: hidden;
                margin: 96px 0 0 -96px;
                @media (max-width: ${breakpoints.S - 1}px) {
                  margin: 0;
                }
              `}
            >
              <div
                css={css`
                  display: grid;
                  grid-auto-flow: column;
                  grid-auto-columns: 453px;
                  overflow: hidden;
                  max-width: ${activeIndex === 0 ? 1425 : 1425 + activeIndex * (453 + 16)}px;
                  transform: ${`translate3d(calc(-1 * ${activeIndex} * (453px + 16px)), 0px, 0px)`};
                  width: ${`calc(${items.length ?? 0} * 453px + (16px * ${
                    activeIndex + 1
                  } + 16px))`};
                  grid-gap: 16px;
                  transition: transform 0.3s ease-in-out;
                  @media (max-width: ${breakpoints.S - 1}px) {
                    transform: ${`translate3d(calc(-1 * ${activeIndex} * (80vw + 16px)), 0px, 0px)`};
                    width: ${`calc(${items.length ?? 0} * 80vw + (16px * ${
                      activeIndex + 1
                    } + 16px))`};
                    grid-auto-columns: 80vw;
                  }
                `}
                {...handlers}
              >
                {items.map((item: CmsCatalogTestimonialItem, index: number) => {
                  if (item.video_testimonial) {
                    return (
                      <div
                        key={`testimonial-video-${index}`}
                        css={css`
                          height: 280px;
                          width: 100%;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          justify-content: center;
                          @media (max-width: ${breakpoints.S - 1}px) {
                            height: 272px;
                          }
                        `}
                      >
                        <iframe
                          src={item.video_testimonial}
                          height="100%"
                          width="100%"
                          frameBorder="0"
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                          object-fit="contain"
                        />
                        {item.author ? (
                          <div
                            css={css`
                              margin-top: 16px;
                              background-color: #f5e9cb;
                              border-radius: 30px;
                              font-size: 1.6rem;
                              font-weight: 700;
                              padding: 8px;
                              @media (max-width: ${breakpoints.S - 1}px) {
                                padding: 4px 8px;
                              }
                            `}
                          >
                            {item.author}
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                  if (item.image_testimonial?.url) {
                    return (
                      <div
                        key={`testimonial-image-${index}`}
                        css={css`
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          width: 453px;
                          @media (max-width: ${breakpoints.S - 1}px) {
                            width: 80vw;
                          }
                        `}
                      >
                        <img
                          src={item.image_testimonial.url}
                          css={css`
                            width: 453px;
                            min-height: 232px;
                            object-fit: cover;
                            @media (max-width: ${breakpoints.S - 1}px) {
                              width: 80vw;
                            }
                          `}
                          alt={`catalog-testimonial-image-${index}`}
                        />
                        {item.author ? (
                          <div
                            css={css`
                              margin-top: 16px;
                              background-color: #f5e9cb;
                              border-radius: 30px;
                              font-size: 1.6rem;
                              font-weight: 700;
                              padding: 8px;
                              @media (max-width: ${breakpoints.S - 1}px) {
                                padding: 4px 8px;
                              }
                            `}
                          >
                            {item.author}
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                  if (item.text_testimonial) {
                    return (
                      <div
                        key={`testimonial-text-${index}`}
                        css={css`
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        `}
                      >
                        <div
                          css={css`
                            background-color: #f5e9cb;
                            width: 453px;
                            min-height: 196px;
                            padding: 24px;
                            @media (max-width: ${breakpoints.S - 1}px) {
                              width: 80vw;
                            }
                          `}
                        >
                          <div
                            css={css`
                              margin-bottom: 16px;
                              filter: invert();
                            `}
                          >
                            <Icon name="quoteIconsAffiliate" size={30} />
                          </div>
                          <div
                            css={css`
                              font-size: 1.6rem;
                              line-height: 110%;
                            `}
                          >
                            {item.text_testimonial}
                          </div>
                          {item.author ? (
                            <div
                              css={css`
                                margin-top: 16px;
                                font-size: 1.6rem;
                                font-weight: 700;
                              `}
                            >
                              {item.author}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div
              css={css`
                margin-top: 24px;
                display: none;
                height: 8px;
                justify-content: center;
                @media (max-width: ${breakpoints.S - 1}px) {
                  display: flex;
                }
              `}
            >
              {items.map((_item: CmsCatalogTestimonialItem, index: number) => {
                return (
                  <div
                    key={`bullet-point-catalog-testimonial-${index}`}
                    css={css`
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      background-color: ${activeIndex === index ? colors.BLACK : '#ece5df'};
                      transition: all 0.3s ease-in-out;
                      margin: 0 4px 32px 4px;
                    `}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
