import React from 'react';
import { RichText } from 'prismic-reactjs';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { CmsHomepageCategoriesSquareOrInlineBannerSlice } from '../../cms/types';
import { CmsBox } from '../../common/components/Custom';
import { Box, colors } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  id: string;
  content: CmsHomepageCategoriesSquareOrInlineBannerSlice['primary'];
  categories: CmsHomepageCategoriesSquareOrInlineBannerSlice['items'];
};

const StyledAnchorButton = styled.a<{ textColor: string; fontSize?: string }>`
  text-decoration: none;
  font-size: ${(props) => props.fontSize ?? '1.6rem'};
  font-weight: 600;
  color: ${(props) => props.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export function CategoriesSquareOrInlineBanner({ id, content, categories }: Props) {
  const { isMobile, isDesktop, isTablet } = useMediaQueries();

  if (isMobile && content.mobile_type === 'column') {
    return (
      <Box id={id} display={'flex'} flexWrap={'wrap'} justifyContent="center" marginX={16}>
        <CmsBox textAlign="center" width="100%" marginBottom={['16px', '24px']}>
          <RichText render={content.title_mobile} />
        </CmsBox>
        <Box
          width={'100%'}
          display="grid"
          gridTemplateColumns={'1fr'}
          gridTemplateRows={'1fr 1fr 1fr 1fr'}
          gridGap={16}
        >
          {categories.map((category, index) => {
            return (
              <Box key={index} position={'relative'}>
                <a
                  href={category.link ?? ''}
                  data-testid={`hp-categories-square-or-inline-cta-link-mobile-column-${`${index}`}`}
                  css={css`
                    display: block;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <img
                    width="100%"
                    height="auto"
                    src={category.image?.['column-mobile']?.url ?? ''}
                  />
                </a>
                {category.name && (
                  <Box
                    position="absolute"
                    bottom="24px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box
                      width={'244px'}
                      maxWidth="calc(100% - 32px)"
                      height={'44px'}
                      backgroundColor={content.cta_background_color ?? colors.WHITE}
                    >
                      <StyledAnchorButton
                        id={`hp-categories-square-or-inline-cta-link-mobile-column-${`${index}`}`}
                        href={category.link ?? ''}
                        data-testid={`hp-categories-square-or-inline-cta-link-mobile-column-${`${index}`}`}
                        textColor={content.cta_font_color ?? colors.BLACK}
                      >
                        {category.name}
                      </StyledAnchorButton>
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  if (isMobile && content.mobile_type === 'inline') {
    return (
      <Box id={id} display={'flex'} flexWrap={'wrap'} justifyContent="center">
        {hasValidCmsText(content.title_mobile) && (
          <CmsBox textAlign="center" width="100%" marginBottom={['16px', '24px']}>
            <RichText render={content.title_mobile} />
          </CmsBox>
        )}
        <Box>
          <Box overflowX="scroll" overflowY="hidden" display="flex" gridGap={16} paddingX={16}>
            {categories.map((category, index) => {
              return (
                <Box key={index} position={'relative'} flex={'0 0 66vw'}>
                  <a
                    href={category.link ?? ''}
                    data-testid={`hp-categories-square-or-inline-cta-link-mobile-inline-${`${index}`}`}
                    css={css`
                      display: block;
                      width: 100%;
                      height: 100%;
                    `}
                  >
                    <img
                      width="100%"
                      height="auto"
                      src={category.image?.['inline-mobile']?.url ?? ''}
                    />
                  </a>
                  {category.name && (
                    <Box
                      position="absolute"
                      bottom="24px"
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <Box
                        width={'244px'}
                        maxWidth="calc(100% - 32px)"
                        height={'44px'}
                        backgroundColor={content.cta_background_color ?? colors.WHITE}
                      >
                        <StyledAnchorButton
                          id={`hp-categories-square-or-inline-cta-link-mobile-inline-${`${index}`}`}
                          href={category.link ?? ''}
                          data-testid={`hp-categories-square-or-inline-cta-link-mobile-inline-${`${index}`}`}
                          textColor={content.cta_font_color ?? colors.BLACK}
                        >
                          {category.name}
                        </StyledAnchorButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  }

  if (isMobile && content.mobile_type === 'square picto') {
    return (
      <Box id={id} display={'flex'} flexWrap={'wrap'} justifyContent="center" marginX={16}>
        {hasValidCmsText(content.title_mobile) && (
          <CmsBox textAlign="center" width="100%" marginBottom={['16px', '24px']}>
            <RichText render={content.title_mobile} />
          </CmsBox>
        )}
        <Box
          display="grid"
          gridTemplateColumns={'1fr 1fr'}
          gridTemplateRows={'1fr 1fr'}
          gridGap={8}
        >
          {categories.map((category, index) => {
            return (
              <Box
                key={index}
                position={'relative'}
                backgroundColor={colors.LIGHT2}
                width={'calc((100vw - 32px - 8px) / 2)'}
                height={'calc((100vw - 32px - 8px) / 2)'}
              >
                <a
                  href={category.link ?? ''}
                  data-testid={`hp-categories-square-or-inline-cta-link-mobile-square-picto-${`${index}`}`}
                  css={css`
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                  `}
                >
                  <img
                    src={category.picto?.url}
                    css={css`
                      position: absolute;
                      top: calc(50% - 8px);
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: calc(100% - 72px);
                    `}
                  />
                </a>
                {category.name && (
                  <Box
                    position="absolute"
                    bottom="16px"
                    width="calc(100% - 32px)"
                    margin="0 16px"
                    display="flex"
                    justifyContent="center"
                  >
                    <StyledAnchorButton
                      id={`hp-categories-square-or-inline-cta-link-mobile-square-picto-${`${index}`}`}
                      href={category.link ?? ''}
                      data-testid={`hp-categories-square-or-inline-cta-link-mobile-square-picto-${`${index}`}`}
                      textColor={content.cta_font_color ?? colors.BLACK}
                      fontSize="1.4rem"
                    >
                      {category.name}
                    </StyledAnchorButton>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  if ((isDesktop || isTablet) && content.desktop_type === 'square') {
    return (
      <Box
        id={id}
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent="center"
        marginX={isTablet ? 16 : 0}
      >
        {hasValidCmsText(content.title) && (
          <CmsBox textAlign="center" width="100%" marginBottom={['16px', '24px']}>
            <RichText render={content.title} />
          </CmsBox>
        )}
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns={'1fr 1fr'}
          gridTemplateRows={'1fr 1fr'}
          gridGap={16}
        >
          {categories.map((category, index) => {
            return (
              <div
                key={index}
                css={css`
                  position: relative;
                  &::after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                  }
                `}
              >
                <a
                  href={category.link ?? ''}
                  data-testid={`hp-categories-square-or-inline-cta-link-desktop-square-${`${index}`}`}
                  css={css`
                    display: block;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <img
                    width="100%"
                    style={{
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                    }}
                    src={category.image?.square?.url ?? ''}
                  />
                </a>
                {category.name && (
                  <Box
                    position="absolute"
                    bottom="24px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box
                      width={'244px'}
                      maxWidth="calc(100% - 32px)"
                      height={'44px'}
                      backgroundColor={content.cta_background_color ?? colors.WHITE}
                    >
                      <StyledAnchorButton
                        id={`hp-categories-square-or-inline-cta-link-desktop-square-${`${index}`}`}
                        href={category.link ?? ''}
                        data-testid={`hp-categories-square-or-inline-cta-link-desktop-square-${`${index}`}`}
                        textColor={content.cta_font_color ?? colors.BLACK}
                      >
                        {category.name}
                      </StyledAnchorButton>
                    </Box>
                  </Box>
                )}
              </div>
            );
          })}
        </Box>
      </Box>
    );
  }

  if ((isDesktop || isTablet) && content.desktop_type === 'inline') {
    return (
      <Box
        id={id}
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent="center"
        marginX={isTablet ? 16 : 0}
      >
        {hasValidCmsText(content.title) && (
          <CmsBox textAlign="left" width="100%" marginBottom={['16px', '24px']}>
            <RichText render={content.title} />
          </CmsBox>
        )}
        <Box
          display="grid"
          gridTemplateColumns={'1fr 1fr 1fr 1fr'}
          gridTemplateRows={'1fr'}
          gridGap={16}
        >
          {categories.map((category, index) => {
            return (
              <Box key={index} position={'relative'}>
                <a
                  href={category.link ?? ''}
                  data-testid={`hp-categories-square-or-inline-cta-link-desktop-inline-${`${index}`}`}
                  css={css`
                    display: block;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <img
                    width="100%"
                    height="auto"
                    src={category.image?.['inline-desktop']?.url ?? ''}
                  />
                </a>
                {category.name && (
                  <Box
                    position="absolute"
                    bottom="24px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box
                      width={'244px'}
                      maxWidth="calc(100% - 32px)"
                      height={'44px'}
                      backgroundColor={content.cta_background_color ?? colors.WHITE}
                    >
                      <StyledAnchorButton
                        id={`hp-categories-square-or-inline-cta-link-desktop-inline-${`${index}`}`}
                        href={category.link ?? ''}
                        data-testid={`hp-categories-square-or-inline-cta-link-desktop-inline-${`${index}`}`}
                        textColor={content.cta_font_color ?? colors.BLACK}
                      >
                        {category.name}
                      </StyledAnchorButton>
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  return null;
}
