import * as React from 'react';

import { Box, Direction } from '../../../design-system';
import { RecommendationsProductCardT2S } from './RecommendationsProductCardT2S';
import { HorizontalSlider } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { NumberOfItemsShown, RecommendationTypes, RecommendationTracking } from '../types';
import { Product } from '../../product/types';
import { RecommendationsArrow } from './RecommendationsArrow';
import { theme } from '../../../design-system/systemprovider/constants';
import { RIGHT_PANEL_WIDTH_PLUS_LEFT_PANEL_MARGIN } from '../../cart/components/Cart';

type Props = {
  hits: Product[];
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  tracking?: RecommendationTracking;
  type: RecommendationTypes;
};

export const RecommendationsTilesT2S = ({
  hits,
  isFeedback,
  isDesktopFeedback,
  tracking,
  type,
}: Props) => {
  const { isDesktop, isTablet } = useMediaQueries();
  const isHomepage = type === RecommendationTypes.home;
  const GRID_GAP = 16;
  const ARROW_WIDTH = 24;
  const WIDTH = isHomepage
    ? isTablet
      ? `calc((100vw - 32px - (${GRID_GAP}px * (${NumberOfItemsShown.home} - 1))) / ${NumberOfItemsShown.home})`
      : `calc((min(calc(100vw - (${GRID_GAP}px * (${NumberOfItemsShown.home} - 1))), calc(1920px - (${theme.space.l} * 2))) - (${GRID_GAP}px * (${NumberOfItemsShown.home} - 1))) / ${NumberOfItemsShown.home})`
    : type === RecommendationTypes.upSell
    ? isTablet
      ? `calc((100vw - (${ARROW_WIDTH}px * 2)  - (${GRID_GAP}px * (${NumberOfItemsShown.upSell} - 1 + 2))) / ${NumberOfItemsShown.upSell})`
      : `calc((min(calc(100vw - (${ARROW_WIDTH}px * 2) - (${GRID_GAP}px * (${NumberOfItemsShown.upSell} - 1 + 2))), calc(1920px - (${theme.space.l} * 2) - (${ARROW_WIDTH}px * 2) - (${GRID_GAP}px * 2))) - (${GRID_GAP}px * (${NumberOfItemsShown.upSell} - 1))) / ${NumberOfItemsShown.upSell})`
    : type === RecommendationTypes.crossSell
    ? isTablet
      ? `calc((50vw - (${GRID_GAP}px * 2) - (${ARROW_WIDTH}px * 2) - (${GRID_GAP}px * (${NumberOfItemsShown.crossSell} - 1 + 2))) / ${NumberOfItemsShown.crossSell})`
      : `calc((442px - 96px)/${NumberOfItemsShown.crossSell})`
    : type === RecommendationTypes.cart
    ? `calc(min(calc(100vw - ${RIGHT_PANEL_WIDTH_PLUS_LEFT_PANEL_MARGIN}px - (${ARROW_WIDTH}px * 2) - (${GRID_GAP}px * (${NumberOfItemsShown.cart} - 1 + 2))), calc(1920px - ${RIGHT_PANEL_WIDTH_PLUS_LEFT_PANEL_MARGIN}px - 112px)) / ${NumberOfItemsShown.cart})`
    : 'initial';

  const NUMBER_OF_ITEMS_SHOWN =
    type === RecommendationTypes.home
      ? NumberOfItemsShown.home
      : type === RecommendationTypes.upSell
      ? NumberOfItemsShown.upSell
      : type === RecommendationTypes.crossSell
      ? NumberOfItemsShown.crossSell
      : type === RecommendationTypes.cart
      ? NumberOfItemsShown.cart
      : NumberOfItemsShown.default;

  const [activeIndex, setActiveIndex] = React.useState(0);
  const onClickLeft = () => {
    setActiveIndex(activeIndex - 1);
  };

  const onClickRight = () => {
    setActiveIndex(activeIndex + 1);
  };

  const fragments = {
    items: hits.map((hit) => {
      const trackingId = (tracking ?? []).find(
        (item) => item.id === `${hit.productRef}-${hit.colorRef}`
      )?.trackingId;
      return isDesktopFeedback ? (
        <Box
          key={`${hit.productRef}-${hit.colorRef}`}
          mx="xs"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <RecommendationsProductCardT2S
            key={`${hit.productRef}-${hit.colorRef}`}
            hit={hit}
            isFeedback={isFeedback}
            trackingId={trackingId}
            isDesktopFeedback
          />
        </Box>
      ) : (
        <RecommendationsProductCardT2S
          key={`${hit.productRef}-${hit.colorRef}`}
          hit={hit}
          isFeedback={isFeedback}
          trackingId={trackingId}
          isRecommendation
        />
      );
    }),
  };

  // Classic recommendations
  if ((isDesktop || isTablet) && !isDesktopFeedback) {
    return (
      <Box
        display={isHomepage ? 'block' : 'grid'}
        gridGap={isHomepage ? 0 : 'm'}
        position={isHomepage ? 'relative' : 'initial'}
        gridTemplateColumns={
          isHomepage || hits.length <= NUMBER_OF_ITEMS_SHOWN || isFeedback
            ? 'unset'
            : `${ARROW_WIDTH}px 1fr ${ARROW_WIDTH}px`
        }
        mx={
          type === RecommendationTypes.crossSell
            ? ['initial', 'auto']
            : type === RecommendationTypes.home && isTablet
            ? GRID_GAP
            : 'initial'
        }
      >
        {!isFeedback && (
          <RecommendationsArrow
            direction={Direction.LEFT}
            onClick={onClickLeft}
            isHomepage={isHomepage}
            hits={hits}
            NUMBER_OF_ITEMS_SHOWN={NUMBER_OF_ITEMS_SHOWN}
            activeIndex={activeIndex}
          />
        )}
        <Box overflow="hidden">
          <HorizontalSlider
            display="grid"
            gridAutoFlow="column"
            gridAutoColumns={WIDTH}
            activeIndex={activeIndex}
            length={hits.length}
            width={WIDTH}
            gridGap={`${GRID_GAP}px`}
          >
            {fragments.items}
          </HorizontalSlider>
        </Box>
        {!isFeedback && (
          <RecommendationsArrow
            direction={Direction.RIGHT}
            onClick={onClickRight}
            isHomepage={isHomepage}
            hits={hits}
            NUMBER_OF_ITEMS_SHOWN={NUMBER_OF_ITEMS_SHOWN}
            activeIndex={activeIndex}
          />
        )}
      </Box>
    );
  }

  // Recommendations inside Add to Cart Feedback
  if ((isDesktop || isTablet) && isDesktopFeedback) {
    return (
      <Box display="flex" justifyContent="center">
        {fragments.items}
      </Box>
    );
  }

  // Mobile recommendations
  return (
    <Box
      display="grid"
      gridGap={isFeedback ? 's' : 'm'}
      gridAutoFlow="column"
      overflow="auto"
      gridAutoColumns={isFeedback ? '130px' : '70vw'}
      ml="m"
      mr={isFeedback ? 'm' : 'na'}
      mb={isFeedback ? 's' : 'na'}
    >
      {fragments.items}
    </Box>
  );
};
