import * as React from 'react';
import { UnknownAction } from 'redux';

import {
  DynamicButton,
  PasswordField,
  Text,
  Box,
  Anchor,
  DynamicButtonStatus,
} from '../../../design-system';
import {
  AUTH_BAR_FORGOT_PASSWORD_TITLE,
  AUTH_BAR_SIGNIN_TITLE,
  AUTH_BAR_TITLE,
  CTA_CONTENT_SIGNIN,
  FORGOT_EMAIL_TEXT,
} from '../locale';

import { Forms, FieldCallback, FormSignInPassword } from '../../form/types';
import withForm from '../../form';
import { WithFormActions } from '../../form/hocs/withForm';
import { ParamsLogin } from '../types';
import { validate, isCtaActive } from '../../form/utils';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { checkEmail, errorLogin, setHasForgotPassword } from '../actions';
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../common/actions';
import { Alert } from '../../../design-system/alert';

type Props = {
  login: (params: ParamsLogin) => void;
  email: string;
  form: FormSignInPassword;
  errLogin: string;
  comeFromResetPassword?: boolean;
} & WithFormActions;

export const SignIn = ({
  login,
  email,
  errLogin,
  comeFromResetPassword,
  form,
  setFormValues,
  setFormValidation,
}: Props) => {
  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);
  const dispatch = useDispatch();

  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({
        form: Forms.signInPassword,
        values: { [key]: validateField({ key, value }) },
      });
    }

    setFormValues({ form: Forms.signInPassword, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signInPassword, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = () => {
    const { password } = form.values;
    if (validateForm()) {
      login({ email, password });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  React.useEffect(() => {
    handleChange({ key: 'email', value: email });
  }, [email]);

  React.useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_SIGNIN_TITLE));
    return () => {
      handleChange({ key: 'password', value: '' });
      setFormValidation({ form: Forms.signInPassword, values: { password: '' } });
    };
  }, []);

  return (
    <Box display="grid" gridGap="l">
      {comeFromResetPassword && (
        <Alert type="success" title="Votre mot de passe est mis à jour">
          <Text id="success-password-changed" preset="caption" color={'#5A5A5A'}>
            Connectez-vous avec vos nouveaux identifiants.
          </Text>
        </Alert>
      )}
      {errLogin && (
        <Alert type="error" title="Erreur">
          <Text id="error-login" preset="caption" color={'#5A5A5A'}>
            {errLogin}
          </Text>
        </Alert>
      )}
      <Box display={'flex'}>
        <Text paddingRight={16} color={'#5A5A5A'}>
          {email}
        </Text>
        <Anchor
          id="modify-email-link"
          type="button"
          preset="body"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeTitle(AUTH_BAR_TITLE));
            dispatch(checkEmail(null) as unknown as UnknownAction);
            dispatch(errorLogin(''));
          }}
        >
          Modifier
        </Anchor>
      </Box>
      <PasswordField
        onChange={(value) => handleChange({ key: 'password', value })}
        onKeyPress={handleKeyPress}
        errMsg={form.validation.password}
        value={form.values.password}
        id="signin-password"
      />
      <Box display="flex" justifyContent="center">
        <DynamicButton
          id="btn-submit-signin"
          onClick={handleSubmit}
          data={CTA_CONTENT_SIGNIN}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </Box>
      <Box textAlign="center">
        <Anchor
          id="btn-forgot-password"
          type="button"
          onClick={() => {
            dispatch(changeTitle(AUTH_BAR_FORGOT_PASSWORD_TITLE));
            dispatch(setHasForgotPassword(true));
          }}
        >
          {FORGOT_EMAIL_TEXT}
        </Anchor>
      </Box>
    </Box>
  );
};

export default withForm(SignIn, Forms.signInPassword);
