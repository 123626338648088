import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import css from '@styled-system/css';
import styled from '@emotion/styled-base';

import { Anchor, Box, Icon, Text, Button, Spinner } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';
import { Store as StoreType, Position, StoreService, StoresListType } from '../types';
import OpeningHours from './OpeningHours';
import { formatPhone, getDistanceInKm } from '../utils';
import paths from '../../routing/paths';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { StoreLocatorService } from '../../cms/types';
import { SEE_ON_THE_MAP, SELECTED_STORE, SELECT_STORE, SELECT_STORE_ERESA_MODAL } from '../locale';
import { StoreAvailable } from '../../product/types';
import { useDevice } from '../../common/hooks/useDevice';
import Availability from '../../product/components/Availability';
import { KNOW_MORE } from '../../home/locale';

export type Props = {
  store: StoreType;
  isMarked: boolean;
  isSelected: boolean;
  index: number;
  currentPosition?: Position;
  storeslistType?: StoresListType;
  storeRefs: object;
  storeAvailable?: StoreAvailable;
  showStockAvailability?: boolean;
  onClick: (id: string) => void;
  onMapLinkClick: () => void;
  onSelect?: (id: string) => void;
  highlightMarker?: (id: string) => void;
  isEresaModal?: boolean;
  isLoading: boolean;
  isDelivery?: boolean;
};

const StoreInfo = ({
  store,
  index,
  currentPosition,
  isMarked,
  isSelected,
  storeRefs,
  storeslistType,
  storeAvailable,
  showStockAvailability,
  onClick,
  onMapLinkClick,
  onSelect,
  highlightMarker,
  isEresaModal,
  isLoading,
  isDelivery: isDeliveryOnCart,
}: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const { services: cmsServices } = useSelector((state: RootState) => state.cms.storelocator);

  const [isHovered, setIsHovered] = React.useState(false);
  const [isNoScroll, setIsNoScroll] = React.useState(false);
  const {
    id,
    name,
    hours,
    address,
    mainPhone,
    services: storeServices,
    displayCoordinate: { latitude, longitude },
  } = store ?? {};

  const { line1, line2, postalCode, city } = address ?? {};

  let timer: ReturnType<typeof setTimeout>;
  const TIMEOUT = 300;

  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  });

  React.useEffect(() => {
    if (typeof highlightMarker === 'function') {
      if (isHovered) {
        onClick('');
        highlightMarker(id);
      }
    }
  }, [isHovered]);

  React.useEffect(() => {
    if (isMarked && !isNoScroll) {
      const { current } = storeRefs[index];
      current.parentNode.scrollTop = current.offsetTop - current.parentNode.offsetTop;
    }
  }, [isMarked]);

  const handleClick = () => {
    onClick(id);
    setIsNoScroll(true);
    typeof highlightMarker === 'function' && highlightMarker('');
  };

  const handleSelect = () => {
    typeof onSelect === 'function' ? onSelect(id) : undefined;
  };

  const distance = getDistanceInKm({
    from: currentPosition,
    to: { lat: latitude, lng: longitude },
  });

  const openGoogleMaps = () => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`);
  };

  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setIsHovered(true);
    }, TIMEOUT);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsNoScroll(false);
    clearTimeout(timer);
  };

  const isDelivery = storeslistType === StoresListType.DELIVERY_STORE;
  const isEreservation = storeslistType === StoresListType.ERESERVATION_STORE;
  const isStoreLocator = storeslistType === StoresListType.STORE_LOCATOR;

  const filteredStoreServices = isDelivery
    ? storeServices?.filter((service) =>
        [StoreService.parking, StoreService.wheelchair].includes(service)
      ) ?? []
    : storeServices;
  const services: StoreLocatorService[] = filteredStoreServices
    ? cmsServices.filter((item) => filteredStoreServices.includes(StoreService[item.icon]))
    : [];

  return (
    <StyledBox
      display="grid"
      isDeliveryOnCart={isDeliveryOnCart}
      gridGap="s"
      gridTemplateColumns="4fr 1fr"
      gridTemplateAreas="'leftBlock rightBlock' 'cta cta'"
      gridAutoRows="min-content"
      px={isEresaModal || isDeliveryOnCart ? 'na' : 'm'}
      py="m"
      onClick={handleClick}
      id={`store-${index}-box`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        gridArea="leftBlock"
        display="grid"
        gridGap={['xxs', 'xxs', 'xs']}
        gridAutoRows="min-content"
      >
        <Box
          display="flex"
          alignItems="start"
          justifyContent="space-between"
          mb={isDeliveryOnCart ? 'xs' : 'na'}
        >
          <Text
            id={`store-${index}-name`}
            textTransform="uppercase"
            fontWeight={isEresaModal || isDeliveryOnCart ? 700 : 400}
          >
            {name ?? ''}
          </Text>
        </Box>
        {isEresaModal || isDeliveryOnCart ? (
          <Box
            id={`store-${index}-address`}
            py={['na', 'na', 's']}
            color="#545454"
            fontSize="1.4rem"
            lineHeight="110%"
          >
            {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
          </Box>
        ) : (
          <Text
            id={`store-${index}-address`}
            py={['na', 'na', 's']}
            preset={['caption', 'caption', 'body']}
          >
            {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
          </Text>
        )}
        <OpeningHours hours={hours ?? {}} isShortenedVersion />
        {isDeliveryOnCart && (
          <Box display="flex" flexDirection="row">
            {services.map(({ icon, service_title }, index) => {
              return (
                <ToolTip mx="xs" reverse key={index}>
                  <Icon name={icon} size={18} />
                  <Box whiteSpace="nowrap">{service_title}</Box>
                </ToolTip>
              );
            })}
          </Box>
        )}
        {isStoreLocator && (
          <Box display="flex" mt="s">
            <>
              <Box>
                <Text
                  color="GREY"
                  textDecoration="underline"
                  onClick={() => navigate(`${paths.STORE_LOCATOR}/${id}`)}
                >
                  {KNOW_MORE}
                </Text>
              </Box>
              <Box ml="m">
                <Text color="GREY" textDecoration="underline" onClick={openGoogleMaps}>
                  Itinéraire
                </Text>
              </Box>
            </>
          </Box>
        )}
      </Box>
      <Box
        gridArea="rightBlock"
        justifySelf="end"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems={isDeliveryOnCart ? 'end' : 'initial'}
      >
        {showStockAvailability && isEreservation ? (
          <>
            <Box mr={isEresaModal ? 's' : 'na'}>
              <Availability isInStock={Boolean(storeAvailable && storeAvailable.quantity)} />
            </Box>
            <Text
              textAlign={isEresaModal ? 'right' : 'left'}
              mr={isEresaModal ? 's' : 'na'}
              id={`store-${index}-distance`}
              preset="caption"
              color="GREY"
              whiteSpace="nowrap"
            >
              {distance}
            </Text>
          </>
        ) : (
          <>
            <Text id={`store-${index}-distance`} preset="caption" color="GREY" whiteSpace="nowrap">
              {distance}
            </Text>

            {!isDeliveryOnCart && (
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                {services.map(({ icon, service_title }, index) => {
                  return (
                    <ToolTip mx="xs" reverse key={index}>
                      <Icon name={icon} size={18} />
                      <Box whiteSpace="nowrap">{service_title}</Box>
                    </ToolTip>
                  );
                })}
              </Box>
            )}
          </>
        )}
      </Box>

      {!isStoreLocator && (isMarked || isEresaModal || isDeliveryOnCart) && (
        <Box gridArea="cta" display="grid" gridGap="s" width="100%">
          {!isEresaModal && (
            <Box display="flex" my="s">
              <Icon name="phone" />
              <Box px="s">
                <Anchor
                  id={`store-${index}-tel`}
                  href={`tel:${mainPhone ?? ''}`}
                  decoration="inline"
                >
                  {formatPhone(mainPhone ?? '')}
                </Anchor>
              </Box>
            </Box>
          )}
          {((isEresaModal && storeAvailable && storeAvailable.quantity) || !isEresaModal) && (
            <Box width={isEresaModal || isDeliveryOnCart ? '212px' : 'auto'}>
              {!isLoading && (
                <Button
                  id={`store-${index}-button`}
                  preset={
                    isEresaModal || isDeliveryOnCart
                      ? 'subtle'
                      : isSelected
                      ? 'secondary'
                      : 'subtle'
                  }
                  onClick={handleSelect}
                  fontWeight={isEresaModal || isDeliveryOnCart ? '700' : '400'}
                >
                  {isEresaModal || isDeliveryOnCart
                    ? SELECT_STORE_ERESA_MODAL
                    : isSelected
                    ? SELECTED_STORE
                    : SELECT_STORE}
                </Button>
              )}
              {isLoading && (
                <Box
                  bg="transparent"
                  border="1px solid BLACK"
                  display="flex"
                  width="100%"
                  height="44px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Box>
              )}
            </Box>
          )}
          {isMobile && !isEresaModal && !isDeliveryOnCart && (
            <Box textAlign="center" width="100%" mt="s">
              <Anchor
                id={`store-${index}-link`}
                type="button"
                onClick={onMapLinkClick}
                preset="body"
              >
                {SEE_ON_THE_MAP}
              </Anchor>
            </Box>
          )}
        </Box>
      )}
    </StyledBox>
  );
};

type StyledBoxProps = {
  isDeliveryOnCart?: boolean;
};

const StyledBox = styled(Box)<StyledBoxProps>(
  ({ isDeliveryOnCart }) =>
    !isDeliveryOnCart &&
    css({
      '@media(hover: hover)': {
        ':hover': {
          bg: 'BACKGROUND',
        },
      },
    })
);

export default StoreInfo;
