import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { InfoPanel } from '../../catalog/components/InfoPanel';
import { Images } from '../../catalog/components/Images';
import { Color } from '../../product/types';

import { closeModal, openModal } from '../../common/actions';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { pushToGTM, getTrackingProduct } from '../../tracking';
import { Events } from '../../tracking/types';
import { trackRecommendation } from '../../recommendations/utils';
import { CmsImage } from '../../cms/types';
import { Product } from '../../product/types';
import { getQuickAddModalPreset } from '../../quickadd/utils';

type Props = {
  hit?: Product;
  isRecommendation?: boolean;
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  trackingId?: string;
  width?: string;
  alternativeSource?: CmsImage;
};

const StyledProductCardContainer = styled.div<{ isRecommendation: boolean; width: string }>`
  grid-column: ${(props) => (props.isRecommendation ? 'initial' : 'span 2')};
  width: ${(props) => props.width};
`;

export const RecommendationsProductCardT2S = ({
  hit,
  isRecommendation = false,
  isFeedback,
  isDesktopFeedback,
  trackingId,
  width = 'initial',
  alternativeSource,
}: Props) => {
  if (!hit) {
    return null;
  }

  const {
    productRef,
    colorRef,
    productName,
    collectionName,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    colorVariants,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
    sizeVariants,
  } = hit;

  const objectID = `${hit.productRef}-${hit.colorRef}`;

  const [selectedColor, setSelectedColor] = React.useState<Color>({
    colorRef,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
  });

  const [firstColorRef] = React.useState(selectedColor.colorRef);
  const [firstSelectedColor] = React.useState({
    colorRef: firstColorRef,
    originalPrice,
    storePrice,
  });

  const [showMobileQuickAdd, setShowMobileQuickAdd] = React.useState(false);

  const handleColorClick = (color: Color) => {
    setSelectedColor(color);
  };

  const dispatch = useDispatch();

  const { isMobile, isTablet } = useMediaQueries();

  const elementID = `product-card-${objectID}`;

  const onLinkClick = () => {
    trackRecommendation(trackingId);
  };

  const onQuickAddClick = () => {
    if (isMobile) {
      setShowMobileQuickAdd(true);
    } else {
      dispatch(closeModal());
      dispatch(
        openModal({
          content: (
            <QuickAdd
              productRef={productRef}
              colorRef={selectedColor.colorRef}
              originalSelectedColor={colorRef}
            />
          ),
          preset: getQuickAddModalPreset({ sizeVariants: sizeVariants ?? [], isTablet }),
        })
      );
    }

    pushToGTM(Events.quickAddOpen, {
      product: getTrackingProduct({ product: hit }),
    });

    trackRecommendation(trackingId);
  };

  return (
    <>
      <StyledProductCardContainer id={elementID} isRecommendation={isRecommendation} width={width}>
        <Images
          productName={productName}
          productRef={productRef}
          objectID={objectID}
          selectedColor={selectedColor}
          onLinkClick={onLinkClick}
          onQuickAddClick={onQuickAddClick}
          isFeedback={isFeedback}
          isDesktopFeedback={isDesktopFeedback}
          alternativeSource={
            firstColorRef === selectedColor.colorRef ? alternativeSource : undefined
          }
        />
        <InfoPanel
          colorVariants={colorVariants}
          productRef={productRef}
          productName={productName}
          objectID={objectID}
          selectedColor={selectedColor}
          firstSelectedColor={firstSelectedColor}
          collectionName={collectionName}
          isFeedback={isFeedback}
          isDesktopFeedback={isDesktopFeedback}
          handleColorClick={handleColorClick}
          onLinkClick={onLinkClick}
          reverseToolTip
          leftAlignToolTip
        />
      </StyledProductCardContainer>
      {!isFeedback && (
        <QuickAddMobile
          productRef={productRef}
          colorRef={selectedColor.colorRef}
          originalSelectedColor={colorRef}
          isActive={showMobileQuickAdd}
          onClose={() => setShowMobileQuickAdd(false)}
        />
      )}
    </>
  );
};
