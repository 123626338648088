import * as React from 'react';

import SidebarContainer from '../components/Sidebar';
import ModalContainer from '../components/Modal';
import PopupContainer from '../components/Popup';
import Main from './Main';
import { Box } from '../../../design-system';

type Props = {
  children: React.ReactNode;
};

const UIComponent = ({ children }: Props) => (
  <>
    <Box id="snackbars" />
    <ModalContainer />
    <PopupContainer />
    <SidebarContainer />
    <Main>{children}</Main>
  </>
);

export default UIComponent;
