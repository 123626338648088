import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Link } from 'react-router-dom';

import {
  Box,
  Image,
  ImageContainer,
  Text,
  Anchor,
  Icon,
  opacities,
  Button,
  ProductName,
} from '../../../design-system';
import { WishlistItem as WishlistItemType } from '../types';
import { getProductImageLink, getProductLink, productImageWidths } from '../../product/utils';
import { formatPrice } from '../../common/utils';
import { ColorText, LineThroughText } from '../../common/components/Custom';
import { RootState } from '../../../store/rootReducer';
import { TXT_CART_ITEM_UNDO } from '../../cart/locale';
import { getPromotionColors } from '../../catalog/utils';
import { transitions, durations } from '../../common/constants';
import { AnimationContainer, CartItemContainer } from '../../cart/components/CartItem';
import { removeFromWishlist, addToWishlist } from '../actions';
import locale from '../locale';
import { moveToCartFromWishlist } from '../../cart/actions';
import { LBL_SIZE, LBL_COLOR, NONE_LEFT } from '../../product/locale';
import { openModal } from '../../common/actions';
import WishlistSizeModal from './WishlistSizeModal';

type Props = {
  id: string;
  item: WishlistItemType;
};

const WishlistItem = ({ id, item }: Props) => {
  const dispatch = useDispatch();

  const [showUndo, setShowUndo] = React.useState(false);
  const [hasWaitedForUndo, setHasWaitedForUndo] = React.useState(false);
  const [shouldDisappear, setShouldDisappear] = React.useState(false);

  const [showMove, setShowMove] = React.useState(false);

  const {
    productRef,
    colorRef,
    sku,
    productName,
    colorLabel,
    detailedColorLabel,
    size,
    bandSize,
    cupSize,
    promotionLabel,
    promotionPercentage,
    stockQuantity,
    originalPrice,
    storePrice,
    sizeVariants,
  } = item;

  const isAvailable = stockQuantity > 0;

  React.useEffect(() => {
    if (hasWaitedForUndo) {
      if (showUndo) {
        setShouldDisappear(true);
      }
      setHasWaitedForUndo(false);
    }
  }, [hasWaitedForUndo]);

  React.useEffect(() => {
    if (shouldDisappear) {
      dispatch(removeFromWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
    }
  }, [shouldDisappear]);

  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
  });

  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });

  const hasPromo = originalPrice !== storePrice;
  const rawPrice = formatPrice(originalPrice);
  const price = formatPrice(storePrice);

  const productLink = getProductLink({ productRef, colorLabel, colorRef, productName });

  const handleDeleteItem = () => {
    if (isAvailable) {
      setShowUndo(true);
      setTimeout(() => {
        setHasWaitedForUndo(true);
      }, durations.UNDO_DELAY);
    } else {
      setShouldDisappear(true);
    }
  };

  const handleUndo = () => {
    setShowUndo(false);
  };

  const handleOpenSizeModal = () => {
    dispatch(
      openModal({
        content: (
          <WishlistSizeModal
            {...{ id, sku, sizeVariants, handleSizeChange, productRef, colorRef }}
          />
        ),
        preset: (sizeVariants ?? []).length > 30 ? 'l' : 's',
      })
    );
  };

  const handleMoveToCart = (sku: string) => {
    setShowMove(true);
    dispatch(
      moveToCartFromWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction
    );
  };

  const handleMoveClick = () => {
    if (sku) {
      handleMoveToCart(sku);
    } else {
      handleOpenSizeModal();
    }
  };

  const handleSizeChange = (sku: string) => {
    dispatch(addToWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
  };

  return (
    <CartItemContainer {...{ showUndo, showMove }} id={`box-wishlist-item-${id}`}>
      <Box width="100%" position="relative">
        <Box
          display="grid"
          gridTemplateAreas="'image name price' 'image selectors selectors' 'image cta cta'"
          my="m"
          gridGap={['s', 'm']}
          opacity={showUndo || showMove ? 0 : 1}
          transition={transitions.GENERIC}
          mx={['m', 'l']}
          gridAutoColumns="auto 1fr 1fr"
        >
          <Box
            width={['80px', '128px']}
            gridArea="image"
            justifySelf="left"
            opacity={isAvailable ? 1 : opacities.DISABLED}
          >
            <Link id={`link-wishlist-item-image-${productRef}-${colorRef}`} to={productLink}>
              <ImageContainer>
                <Image isAbsolute src={thumbnail} alt="Product Image" />
              </ImageContainer>
            </Link>
          </Box>
          <Box
            gridArea="name"
            display="grid"
            gridGap="s"
            gridAutoFlow="column"
            alignItems="center"
            justifyContent="left"
            alignSelf="start"
            justifySelf="left"
          >
            <Text id={`link-wishlist-item-name-${productRef}-${colorRef}`} preset="caption">
              <ProductName productName={productName} />
            </Text>
            {(promotionPercentage || promotionLabel) && (
              <ColorText
                display="inline-flex"
                justifyContent="flex-end"
                alignItems="center"
                py="xxs"
                px="s"
                cmsColor={textColor}
                bg={backgroundColor}
                fontWeight="bold"
                preset="caption"
                alignSelf="start"
              >
                {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
              </ColorText>
            )}
          </Box>
          <Box
            gridArea="selectors"
            display="grid"
            gridTemplateAreas={["'color size' 'errMsg errMsg'", "'color' 'size' 'errMsg'"]}
            gridGap={['xs', 's']}
            alignSelf="center"
            justifySelf="left"
          >
            <Text
              gridArea="color"
              id={`text-wishlist-item-color-${id}`}
              preset="caption"
              whiteSpace="nowrap"
            >
              {LBL_COLOR} :{' '}
              <Text display="inline-block" fontWeight="bold" preset="caption">
                {detailedColorLabel}
              </Text>
              <Text display={['inline-block', 'none']} preset="caption" ml="xs">
                |
              </Text>
            </Text>
            <Text
              gridArea="size"
              id={`text-wishlist-item-size-${id}`}
              preset="caption"
              whiteSpace="nowrap"
            >
              {LBL_SIZE} :{' '}
              {sku ? (
                <>
                  <Text
                    display="inline-block"
                    fontWeight="bold"
                    preset="caption"
                    color={isAvailable ? 'BLACK' : 'ERROR'}
                  >
                    {size || `${bandSize} ${cupSize}`}
                  </Text>
                  <Box ml="s" display="inline-block">
                    <Anchor
                      type="button"
                      id={`btn-wishlist-item-change-size-${id}`}
                      preset="caption"
                      onClick={handleOpenSizeModal}
                    >
                      {locale.CHANGE_SIZE}
                    </Anchor>
                  </Box>
                </>
              ) : (
                <Anchor
                  type="button"
                  id={`btn-wishlist-item-select-size-${id}`}
                  preset="caption"
                  onClick={handleOpenSizeModal}
                >
                  {locale.SELECT_SIZE}
                </Anchor>
              )}
            </Text>
            {!isAvailable && (
              <Text
                gridArea="errMsg"
                display="inline-block"
                preset="caption"
                color="ERROR"
                id={`text-wishlist-item-size-error-message-${id}`}
              >
                {NONE_LEFT}
              </Text>
            )}
          </Box>
          <Box
            gridArea="price"
            alignSelf="start"
            justifySelf="right"
            display="grid"
            gridGap="xs"
            justifyContent="flex-end"
            alignItems="center"
            textAlign="right"
          >
            {hasPromo ? (
              <>
                <LineThroughText id={`text-wishlist-item-base-row-total-${id}`}>
                  {rawPrice}
                </LineThroughText>
                <Text id={`text-wishlist-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                  {price}
                </Text>
              </>
            ) : (
              <Text id={`text-wishlist-item-row-total-${id}`} fontWeight="bold">
                {price}
              </Text>
            )}
          </Box>
          <Box
            gridArea="cta"
            display="grid"
            gridAutoFlow="column"
            gridGap="m"
            justifyContent="space-between"
            alignItems="end"
          >
            <Box maxWidth="240px" opacity={isAvailable ? 1 : opacities.DISABLED}>
              <Button
                preset="subtle"
                id={`btn-wishlist-move-to-cart-${id}`}
                onClick={isAvailable ? handleMoveClick : undefined}
              >
                <Text fontWeight="bold" textTransform="uppercase">
                  {locale.MOVE_TO_CART}
                </Text>
              </Button>
            </Box>
            <Icon name="trash" id={`btn-wishlist-item-remove-${id}`} onClick={handleDeleteItem} />
          </Box>
        </Box>
        <AnimationContainer
          show={showUndo || showMove}
          shouldDisappear={shouldDisappear}
          id={`wishlist-item-animation-container-${id}`}
        >
          {showUndo ? (
            <>
              <Text id={`text-wishlist-removed-${id}`} mb="s">
                {locale.ITEM_REMOVED}
              </Text>
              <Anchor id={`btn-wishlist-item-undo-${id}`} type="button" onClick={handleUndo}>
                {TXT_CART_ITEM_UNDO}
              </Anchor>
            </>
          ) : showMove ? (
            <Text
              id={`text-wishlist-moved-${id}`}
              color="WHITE"
              fontWeight="bold"
              preset="subheading"
            >
              {locale.MOVED_TO_CART}
            </Text>
          ) : null}
        </AnimationContainer>
      </Box>
    </CartItemContainer>
  );
};

export default WishlistItem;
