import { useEffect } from 'react';

type Ref<T> = React.MutableRefObject<T>;

type Callback = () => void;

export const useOutsideClick = (
  ref: Ref<HTMLDivElement | HTMLUListElement | null>,
  onClickOutside: Callback
): void => {
  useEffect(() => {
    const handleClickOutside = (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    const handleEscapeKey = (event: Event): void => {
      if (event instanceof KeyboardEvent && event.key === 'Escape') {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [ref, onClickOutside]);
};
