import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useClearRefinements, usePagination, useSearchBox } from 'react-instantsearch';
import {
  AutocompleteState,
  BaseItem,
  GetSources,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

import { Icon } from '../../../design-system';
import paths from '../../routing/paths';
import { searchClient } from '../../api/search';
import { RootState } from '../../../../shared/store/rootReducer';
import { CustomHighlight, Item } from './CustomHighlight';
import { SearchAutocompleteWrapper } from './SearchAutocompleteWrapper';
import { debounced } from '../utils';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { indices } from '../constants';

export const SearchAutocomplete = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search).get('r') ?? '';
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<BaseItem> | null>(
    null
  );
  const placeholder = useSelector((state: RootState) => state.cms.search?.placeholder) ?? '';
  const { refine: setQuery } = useSearchBox();
  const { refine: setPage } = usePagination();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const { refine: clearRefine } = useClearRefinements();
  const [direction, setDirection] = useState<'down' | 'up'>('up');
  let oldScrollY = 0;
  const isSearchPage = pathname.startsWith(paths.SEARCH_RESULTS);
  const { isMobile } = useMediaQueries();

  const controlDirection = () => {
    if (window.scrollY > oldScrollY && window.scrollY > 150) {
      setDirection('down');
    } else {
      setDirection('up');
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (direction === 'down') {
      autocompleteState?.isOpen && autocomplete.setIsOpen(false);
      inputRef.current?.blur();
    }
  }, [direction, inputRef.current]);

  useEffect(() => {
    if (isSearchPage === false) {
      autocomplete.setQuery('');
      autocomplete.setIsOpen(false);
      inputRef.current?.blur();
    } else {
      setInputValue(query);
    }
  }, [pathname]);

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: isMobile ? 2 : 3,
    });

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: indices.QUERY_SUGGESTIONS,
      getSearchParams() {
        return {
          ...recentSearches.data?.getAlgoliaSearchParams({
            hitsPerPage: isMobile ? 5 : 6,
          }),
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestions',
        };
      },
    });

    return [recentSearches, querySuggestions];
  }, [isMobile]);

  const runSearch = useCallback((query: string) => {
    clearRefine();
    setQuery(query);
    setPage(0);
    window.scrollTo(0, 0);
  }, []);

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        initialState: {
          query,
        },
        onStateChange({ state, prevState }) {
          setInputValue(state.query);
          if (state.query !== prevState.query) {
            if (timeoutId.current) {
              clearTimeout(timeoutId.current);
            }

            timeoutId.current = setTimeout(() => {
              runSearch(state.query);
            }, 500);
          }

          setAutocompleteState(state);
        },
        debug: true,
        openOnFocus: true,
        autoFocus: false,
        insights: true,
        getSources: function ({ query }) {
          return debounced([
            {
              sourceId: 'querySuggestions',
              getItems() {
                return query;
              },
              onSelect(params) {
                autocomplete.setIsOpen(false);
                setInputValue(params.item.query);
                autocomplete.setQuery(params.item.query);
                runSearch(params.item.query);
                plugins[0].data?.addItem({ id: params.item.query, label: params.item.query });
                inputRef.current?.blur();
                autocompleteState?.isOpen && autocomplete.setIsOpen(false);
              },
            },
          ]);
        } as GetSources<BaseItem>,
        plugins,
      }),
    [isMobile]
  );

  return (
    <SearchAutocompleteWrapper
      isSuggestionsDisplayed={Boolean(
        autocompleteState?.isOpen && autocompleteState?.collections?.length
      )}
      {...autocomplete.getRootProps({})}
      onBlur={() => {
        autocomplete.setIsOpen(false);
        if (inputValue) {
          plugins[0].data?.addItem({ id: inputValue, label: inputValue });
        }
      }}
    >
      <div className="form__wrapper">
        <form
          {...(autocomplete.getFormProps({
            inputElement: inputRef.current,
          }) as unknown as React.HTMLProps<HTMLFormElement>)}
        >
          <div>
            <input
              ref={inputRef}
              {...(autocomplete.getInputProps({
                inputElement: null,
              }) as unknown as React.HTMLProps<HTMLInputElement>)}
              value={inputValue}
              placeholder={placeholder}
              onClick={() => {
                if (isSearchPage === false) {
                  navigate(paths.SEARCH_RESULTS);
                }
              }}
              data-testid="SearchAutocomplete_input"
            />
            {inputValue ? (
              <button
                className="clear-input-button"
                data-testid="SearchAutocomplete_clear-button"
                type="button"
                onClick={() => {
                  setInputValue('');
                  setQuery('');
                  autocomplete.setQuery('');
                }}
              >
                <Icon name="close16" size={16} className="clear-input__icon" />
              </button>
            ) : null}
          </div>
          <button
            className="input-search-button"
            data-testid="SearchAutocomplete_submit-button"
            type="submit"
          >
            <Icon name="search16" size={16} className="input-search__icon" />
          </button>
        </form>
      </div>
      <div
        className="autocomplete-panel"
        {...(autocomplete.getPanelProps({}) as unknown as React.HTMLProps<HTMLDivElement>)}
      >
        {autocompleteState?.isOpen &&
          autocompleteState?.collections.map((collection, index) => {
            const { source, items } = collection;

            return (
              <div key={`source-${index}`} className="autocomplete-suggestion-block">
                {items.length > 0 && (
                  <ul {...autocomplete.getListProps()}>
                    {items.map((item, itemIndex) => (
                      <li
                        key={(item.objectID ?? item.id) as string}
                        {...(autocomplete.getItemProps({
                          item,
                          source,
                        }) as unknown as React.HTMLProps<HTMLLIElement>)}
                        data-testid={`SearchAutocomplete_suggestion-item-${index}-${itemIndex}`}
                      >
                        <div>
                          <span className="icon-custom-highlight">
                            <Icon name={index === 0 ? 'clock16' : 'search16'} size={16} />
                            <CustomHighlight hit={item as Item} />
                          </span>
                          {item.label ? (
                            <button
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                plugins[0].data?.removeItem(item.id as string);
                                autocomplete.refresh();
                              }}
                              data-testid={`SearchAutocomplete_recent-search-delete-${index}-${itemIndex}`}
                            >
                              <Icon name="close16" size={16} />
                            </button>
                          ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </SearchAutocompleteWrapper>
  );
};
