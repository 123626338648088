import React, { useEffect, useMemo, useState } from 'react';
import { UnknownAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { Box, breakpoints, SpinnerAndIconLinear } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { cmsHomepageInit } from '../../cms/actions';
import { Categories } from './Categories';
import UspSection from './UspSection';
import Bubbles from './Bubbles';
import { useDevice } from '../../common/hooks/useDevice';
import { getHomeContent } from '../utils';
import { HomeRecommendations } from './HomeRecommendations';
import { Spacing } from './Spacing';
import { Title } from './Title';
import {
  CollectionBanner,
  HomeBanners,
  PromoCategoriesByButton,
  RecommendationsTabsBanner,
  ThinBanner,
  StudentOfferBanner,
  CategoriesSquareOrInlineBanner,
  CenteredPromoBoxBanner,
  CommitmentsBanner,
} from '../../banners';
import { heights } from '../../common/constants';
import { InstagramFeed } from './InstagramFeed';
import { BrandBanner } from '../../OurBrand/components/BrandBanner';
import { CmsBrandBanner } from '../../cms/types';

const StyledDiv = styled.div`
  text-align: center;
  margin-top: ${-heights.HEADER_MOBILE}px;
  @media (min-width: ${breakpoints.M}px) {
    margin-top: ${-heights.HEADER}px;
  }
  .home-banner-class {
    padding: 16px 0;
    position: relative;
    @media (min-width: ${breakpoints.S}px) {
      max-width: 1440px;
      margin: 0 auto;
      padding: 20px 24px;
    }
    &:first-of-type {
      padding: 0 0 16px 0;
      @media (min-width: ${breakpoints.S}px) {
        max-width: inherit;
        padding: 0 0 20px 0;
      }
    }
  }
`;

export const Home = () => {
  const dispatch = useDispatch();
  const cmsHome = useSelector((state: RootState) => state.cms.homepage);
  const cmsUsp = useSelector((state: RootState) => state.cms.usp);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!cmsHome?.seotitle) {
      setIsFetching(true);
      dispatch(cmsHomepageInit() as unknown as UnknownAction);
    }
  }, []);

  const { isDesktop } = useDevice();

  const { usp, usp_main_title } = cmsUsp ?? {};

  const { seotitle, seodescription, banners } = cmsHome ?? {};

  const metaImage = (banners ?? []).find((banner) => banner.image?.url)?.image?.url ?? '';

  const homeContent = useMemo(() => {
    return getHomeContent(cmsHome);
  }, [cmsHome]);

  useEffect(() => {
    if (cmsHome.seotitle) {
      setIsFetching(false);
    }
  }, [seotitle.length]);

  return isFetching ? (
    <Box
      height="calc(100vh - 206px)"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SpinnerAndIconLinear
        color="black"
        size={50}
        stroke={40}
        iconName="Ddarjeeling"
        id="home-spinner-linear"
      />
    </Box>
  ) : (
    <>
      <Helmet>
        {seotitle && <title>{seotitle}</title>}
        {seotitle && <meta property="og:title" content={seotitle} />}
        {seodescription && <meta name="description" content={seodescription} />}
        {seodescription && <meta property="og:description" content={seodescription} />}
        {metaImage && <meta property="og:image" content={metaImage} />}
      </Helmet>
      <StyledDiv>
        {!isDesktop && cmsHome?.bubbles?.length !== 0 && (
          <Bubbles content={cmsHome?.bubbles ?? []} />
        )}
        {homeContent?.map((item, index) => {
          if (Object.keys(item).length === 0) {
            return null;
          }

          return (
            <Box key={index} id={`banner-${index + 1}`} className="home-banner-class">
              <Title content={item.title} />
              <HomeBanners banner={item.banner} index={index} />
              {item.newBanner?.slice_type === 'thin_banner' && (
                <ThinBanner id={item.newBanner.id} content={item.newBanner.primary} />
              )}
              {item.newBanner?.slice_type === 'student_offer_banner' && (
                <StudentOfferBanner id={item.newBanner.id} content={item.newBanner.primary} />
              )}
              {item.newBanner?.slice_type === 'commitments_banner' && (
                <CommitmentsBanner
                  id={item.newBanner.id}
                  items={item.newBanner.items}
                  title={item.newBanner.primary.commitments_banner_title}
                  isHomepage={true}
                />
              )}
              {item.newBanner?.slice_type === 'promo_categories_by_button' && (
                <PromoCategoriesByButton
                  id={item.newBanner.id}
                  content={item.newBanner.primary}
                  ctaList={item.newBanner.items}
                />
              )}
              {item.newBanner?.slice_type === 'collection_banner' && (
                <CollectionBanner id={item.newBanner.id} content={item.newBanner.primary} />
              )}
              <Spacing content={item.spacing} />
              {item.categories && (
                <Categories
                  content={cmsHome?.categories ?? []}
                  backgroundColorMobile={cmsHome?.categories_background_color_mobile ?? ''}
                  titleDesktop={cmsHome?.categories_block_title_desktop}
                  titleMobile={cmsHome?.categories_block_title_mobile}
                  titleDesktopAlignment={cmsHome?.categories_title_alignment_desktop}
                  titleMobileAlignment={cmsHome?.categories_title_alignment_mobile}
                  isActiveDesktop={cmsHome.categories_block_is_active_desktop}
                  isActiveMobile={cmsHome.categories_block_is_active_mobile}
                />
              )}
              {item.recommendations && <HomeRecommendations />}
              {item.recommendationsTab && item.recommendationsTab.primary.is_active && (
                <RecommendationsTabsBanner
                  id={item.recommendationsTab.id}
                  title={item.recommendationsTab.primary.title}
                  tabs={item.recommendationsTab.items}
                />
              )}
              {item.centeredPromoBox && <CenteredPromoBoxBanner banners={item.centeredPromoBox} />}
              {item.newBanner?.slice_type === 'categories_square_or_inline' && (
                <CategoriesSquareOrInlineBanner
                  id={item.newBanner.id}
                  content={item.newBanner.primary}
                  categories={item.newBanner.items}
                />
              )}
              {(item.brandBanners?.length ?? 0) > 0 && (
                <BrandBanner cmsData={item.brandBanners?.[0] as CmsBrandBanner} index={index} />
              )}
            </Box>
          );
        })}
        <UspSection content={usp ?? []} title={usp_main_title ?? ''} />
        <InstagramFeed feedId={'0bd0720b-c0a6-43a9-8b87-56aee18828a8'} />
      </StyledDiv>
    </>
  );
};

export default Home;
