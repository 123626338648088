import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import { DynamicButton, EmailField, Text, Box } from '../../../design-system';
import {
  FORGOT_EMAIL_MESSAGE_1,
  FORGOT_EMAIL_MESSAGE_2,
  CTA_CONTENT_FORGOT_PASSWORD,
  ERR_EMAIL_NOT_SEND,
  ERR_EMAIL_NOT_FOUND,
  SUCCESS_EMAIL_SENT,
  ATTEMPT_LIMIT_EXCEEDED,
} from '../locale';
import { Forms, FormForgotPassword } from '../../form/types';
import { validate } from '../../form/utils';
import { requestPasswordReset } from '../actions';
import useForm from '../../form/hooks/useForm';
import { ErrorCodes } from '../types';
import { Alert } from '../../../design-system/alert';

type Props = {
  email: string;
};

export const ForgotPassword = ({ email }: Props) => {
  const dispatch = useDispatch();
  const [isError, setIsError] = React.useState<boolean | null>(null);
  const formState = useForm(Forms.forgotPassword);
  const { actions } = formState;
  const form = formState.form as FormForgotPassword;

  const clearMessage = () =>
    actions.setFeedback({ form: Forms.forgotPassword, ok: false, message: '' });

  React.useEffect(() => {
    return () => {
      clearMessage();
    };
  }, []);

  const validateEmail = (value: string): string => validate({ value, key: 'email' });

  const handleChange = (value: string) => {
    if (form.validation.email) {
      actions.setFormValidation({
        form: Forms.forgotPassword,
        values: { email: validateEmail(value) },
      });
    }

    actions.setFormValues({ form: Forms.forgotPassword, values: { email: value } });
  };

  const handleSubmit = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const errMsg = validateEmail(form.values.email);

    actions.setFormValidation({
      form: Forms.forgotPassword,
      values: { email: errMsg },
    });

    if (!errMsg) {
      clearMessage();
      dispatch(requestPasswordReset(form.values.email) as unknown as UnknownAction);
    }
  };

  React.useEffect(() => {
    if (form.ctaState === 2) {
      setIsError(true);
    }
    if (form.ctaState === 1) {
      setIsError(false);
    }
  }, [form]);

  React.useEffect(() => {
    handleChange(email);
  }, [email]);

  return (
    <form>
      {isError === true && (
        <Alert type="error" title="Erreur">
          <Text id="error-forgot-password" preset={'caption'} color={'#5A5A5A'}>
            {form.feedback.message === ErrorCodes.UserNotFoundException && ERR_EMAIL_NOT_FOUND}
            {form.feedback.message === ErrorCodes.LimitExceededException && ATTEMPT_LIMIT_EXCEEDED}
            {form.feedback.message !== ErrorCodes.UserNotFoundException &&
              form.feedback.message !== ErrorCodes.LimitExceededException &&
              ERR_EMAIL_NOT_SEND}
          </Text>
        </Alert>
      )}
      {isError === false && (
        <Alert type="success" title="E-mail envoyé">
          <Text id="success-forgot-password-send" preset={'caption'} color={'#5A5A5A'}>
            {SUCCESS_EMAIL_SENT}
          </Text>
        </Alert>
      )}
      <Box display="grid" gridGap="l">
        <EmailField
          onChange={(value) => handleChange(value)}
          errMsg={form.validation.email}
          value={form.values.email}
          id="reset-password-email"
          autoComplete="email"
        />
        <Box textAlign="left">
          <Text marginBottom={16}>{FORGOT_EMAIL_MESSAGE_1}</Text>
          <Text>{FORGOT_EMAIL_MESSAGE_2}</Text>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={16}>
          <DynamicButton
            id="btn-submit-reset-password-email"
            onClick={handleSubmit}
            data={CTA_CONTENT_FORGOT_PASSWORD}
            feedback={form.ctaState}
          />
        </Box>
      </Box>
    </form>
  );
};
