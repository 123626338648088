import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import { Box, Text } from '../../../design-system';
import { OrderDetail } from '../types';
import { formatPrice } from '../../common/utils';
import { getTax, getTaxFree } from '../../tracking/utils';
import { CmsInvoice } from '../../cms/types';
import { emptyText } from '../../cms/state';
import { CmsBox } from '../../common/components/Custom';
import { ShippingTypes } from '../../cart/types';
import { SHIPMENT_METHODS_TEXT } from '../../cart/locale';
import locale, { SHIPPING_TYPES } from '../locale';
import { getPaymentInfo } from '../utils';

type Props = {
  order: OrderDetail;
  cmsContent: CmsInvoice;
};

class ComponentToPrint extends React.Component<Props> {
  render() {
    const { order, cmsContent } = this.props;

    const paymentInfo = getPaymentInfo(order);

    return (
      <Box display="grid" m="l" gridGap="xl">
        <Box
          backgroundImage={`url(${cmsContent?.image?.url ?? ''})`}
          width="240px"
          height="60px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          alignSelf="center"
          justifySelf="center"
        />
        <Box
          display="grid"
          border="1px solid BLACK"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="xxl"
        >
          <Box display="flex" justifyContent="space-between" p="s">
            <Text>Numéro de commande : </Text>
            <Text>{order.orderNumber}</Text>
          </Box>
          <Box display="flex" justifyContent="space-between" p="s">
            <Text>Numéro de facture : </Text>
            <Text>{order.orderNumber}</Text>
          </Box>
          <Box display="flex" justifyContent="space-between" p="s">
            <Text>Date de commande : </Text>
            <Text>{new Date(order.createdAt).toLocaleDateString('fr-FR')}</Text>
          </Box>
          <Box display="flex" justifyContent="space-between" p="s">
            <Text>Date de facturation : </Text>
            <Text>{new Date(order.createdAt).toLocaleDateString('fr-FR')}</Text>
          </Box>
        </Box>
        <Box
          display="grid"
          border="1px solid BLACK"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="xxl"
        >
          <Text p="s" fontWeight="bold">
            Adresse de livraison
          </Text>
          <Text p="s" fontWeight="bold">
            Adresse de facturation
          </Text>
          <Text p="s">
            {order.isDigital
              ? locale.EMAIL_DELIVERY
              : order.shippingType === ShippingTypes.STORE
              ? SHIPPING_TYPES.STORE
              : order.shippingType === ShippingTypes.PICKUP
              ? SHIPPING_TYPES.PICKUP
              : order.shippingAddress.company}
          </Text>
          <Text p="s">{order.billingAddress.company}</Text>
          <Text p="s">
            {order.shippingAddress.lastName} {order.shippingAddress.firstName}
          </Text>
          <Text p="s">
            {order.billingAddress.lastName} {order.billingAddress.firstName}
          </Text>
          <Text p="s">
            {order.shippingAddress.street} {order.shippingAddress.streetAdditional}
          </Text>
          <Text p="s">
            {order.billingAddress.street} {order.billingAddress.streetAdditional}
          </Text>
          <Text p="s">
            {order.shippingAddress.postal} {order.shippingAddress.city}
          </Text>
          <Text p="s">
            {order.billingAddress.postal} {order.billingAddress.city}
          </Text>
          <Text p="s">{order.shippingAddress.phone}</Text>
          <Text p="s">{order.billingAddress.phone}</Text>
          <Text p="s" />
          <Text p="s">
            {order.billingAddress.idTva
              ? `N° d’identification TVA : ${order.billingAddress.idTva}`
              : ''}
          </Text>
          <Text p="s" />
          <Text p="s">
            {order.billingAddress.idTva
              ? `SIREN / SIRET : ${order.billingAddress.idTva.slice(-9)} / ${
                  order.billingAddress.siret ?? ''
                }`
              : ''}
          </Text>
        </Box>
        <Box
          display="grid"
          border="1px solid BLACK"
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="xxl"
        >
          <Text p="s" fontWeight="bold">
            Mode de paiement
          </Text>
          <Text p="s" fontWeight="bold">
            Mode de livraison
          </Text>
          <Box display="grid" gridGap="xs" p="s">
            {paymentInfo.map((payment, index) => (
              <Box key={index} display="flex" justifyContent="space-between">
                <Text>{`Paiement: ${payment.method}`}</Text>
                <Text>{payment.amount}</Text>
              </Box>
            ))}
          </Box>
          <Box display="grid" gridGap="xs" p="s">
            <Text>{order.shippingType && SHIPMENT_METHODS_TEXT[order.shippingType]}</Text>
            <Box display="flex" justifyContent="space-between">
              <Text>Montant total de la livraison</Text>
              <Text>{formatPrice(order.shippingAmount)}</Text>
            </Box>
          </Box>
        </Box>
        <Box display="grid" border="1px solid BLACK" gridTemplateColumns="3fr 1fr 1fr 1fr 1fr auto">
          <Text p="s" fontWeight="bold" textTransform="uppercase">
            Produits
          </Text>
          <Text p="s" fontWeight="bold" textTransform="uppercase">
            REF.
          </Text>
          <Text p="s" fontWeight="bold" textTransform="uppercase" textAlign="center">
            PRIX HT
          </Text>
          <Text p="s" fontWeight="bold" textTransform="uppercase" textAlign="center">
            QTE
          </Text>
          <Text p="s" fontWeight="bold" textTransform="uppercase" textAlign="center">
            TVA
          </Text>
          <Text p="s" fontWeight="bold" textTransform="uppercase" textAlign="center">
            SOUS-TOTAL
          </Text>
          {(order.items ?? []).map((item) => (
            <React.Fragment key={`${item.productRef}\\${item.sku}`}>
              <Text p="s" textTransform="uppercase">
                {item.productName}
              </Text>
              <Text p="s" textTransform="uppercase">
                {`${item.productRef}\\${item.sku}`}
              </Text>
              <Text p="s" textTransform="uppercase" textAlign="center">
                {formatPrice(
                  getTaxFree({
                    amount: item.rowTotal / (item.quantity ?? 1),
                    taxRate: item.tax,
                  })
                )}
              </Text>
              <Text p="s" textTransform="uppercase" textAlign="center">
                {item.quantity}
              </Text>
              <Text p="s" textTransform="uppercase" textAlign="center">
                {formatPrice(getTax({ amount: item.rowTotal, taxRate: item.tax }))}
              </Text>
              <Text p="s" textTransform="uppercase" textAlign="center">
                {formatPrice(item.rowTotal)}
              </Text>
            </React.Fragment>
          ))}
        </Box>
        <Box
          textAlign="right"
          display="grid"
          gridAutoFlow="row"
          gridTemplateColumns="auto auto"
          gridColumnGap="l"
          justifySelf="end"
        >
          <Text fontWeight="bold">SOUS-TOTAL (Hors taxe) :</Text>
          <Text>
            {formatPrice(
              (order.items ?? []).reduce(
                (total, item) =>
                  total +
                  getTaxFree({
                    amount: item.rowTotal,
                    taxRate: item.tax,
                  }),
                0
              )
            )}
          </Text>
          <Text fontWeight="bold">TVA :</Text>
          <Text>
            {' '}
            {formatPrice(
              (order.items ?? []).reduce(
                (total, item) =>
                  total +
                  getTax({
                    amount: item.rowTotal,
                    taxRate: item.tax,
                  }),
                0
              )
            )}
          </Text>
          <Text fontWeight="bold">LIVRAISON :</Text>
          <Text>{formatPrice(order.shippingAmount)}</Text>
          <Text fontWeight="bold">MONTANT GLOBAL :</Text>
          <Text>{formatPrice(order.total)}</Text>
        </Box>
        <CmsBox textAlign="center" mt="xl" withPadding>
          <RichText render={cmsContent?.body || [...emptyText]} />
        </CmsBox>
      </Box>
    );
  }
}
export default ComponentToPrint;
