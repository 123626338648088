import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { Store } from '../types';
import { getIsClient } from '../../common/utils';
import InfoWindow from '../components/InfoWindow';
import withSystemProvider from '../../common/hocs/withSystemProvider';
import { fetchIcon } from '../../../design-system';
import { CDN_S3_BUCKET } from '../../api/constants';

type Props = {
  map?: google.maps.Map;
  onClick: (id: string) => void;
  selectedMarker: string;
  highlightedMarker?: string;
  stores: Store[];
};

let markerClusterer: MarkerClusterer;

export default function useMarkers({
  map,
  stores,
  onClick,
  selectedMarker,
  highlightedMarker = '',
}: Props) {
  const [markers, setMarkers] = React.useState<{ id: string; mapMarker: google.maps.Marker }[]>([]);
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
  const [svg, setSvg] = React.useState({});

  const loadIcon = async () => {
    const iconsList = ['mapMarkerPink', 'mapMarkerFull'];
    const results = await Promise.all(iconsList.map((name) => fetchIcon({ name })));
    let svgs = {};
    results.map((response, index) => (svgs = { ...svgs, [iconsList[index]]: response }));
    setSvg(svgs);
  };

  React.useEffect(() => {
    loadIcon();
  }, []);

  const url = (name) => {
    return getIsClient()
      ? `data:image/svg+xml;charset=UTF-8;base64, ${window.btoa(svg[name])}`
      : '';
  };

  const icon = (isSelected: boolean, isHighlighted?: boolean) => ({
    url: url(isHighlighted ? 'mapMarkerPink' : 'mapMarkerFull'),
    scaledSize:
      isHighlighted || isSelected ? new google.maps.Size(44, 44) : new google.maps.Size(24, 24),
  });

  React.useEffect(() => {
    if (!map) {
      return () => {};
    }

    const mapMarkers = stores.map(({ id, displayCoordinate: { latitude, longitude } }) => {
      const mapMarker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        icon: icon(id === selectedMarker, id === highlightedMarker),
      });
      mapMarker.addListener('click', () => onClick(id));

      return { id, mapMarker };
    });

    setMarkers(mapMarkers);

    if (markerClusterer) {
      markerClusterer.clearMarkers();
    }

    markerClusterer = new MarkerClusterer(
      map,
      mapMarkers.map((item) => item.mapMarker),
      {
        maxZoom: 7,
        minimumClusterSize: 4,
        averageCenter: true,
        enableRetinaIcons: true,
        styles: Array.from(Array(3)).map((_item, index) => ({
          url: `https://${CDN_S3_BUCKET}/map/image${index + 1}.png`,
          width: 46,
          height: 46,
          anchorText: [17, 0],
          textColor: 'white',
          textSize: 12,
          fontWeight: 'bold',
        })),
      }
    );

    return () => {
      mapMarkers.forEach(({ mapMarker }) => {
        google.maps.event.clearListeners(mapMarker, 'click');
        mapMarker.setMap(null);
      });
    };
  }, [map, stores, JSON.stringify(svg)]);

  React.useEffect(() => {
    markers?.forEach((marker) =>
      marker.mapMarker.setIcon(icon(marker.id === selectedMarker, marker.id === highlightedMarker))
    );
    if (infoWindow) {
      infoWindow.close();
    }

    const selectedStore = stores.find((store) => store.id === selectedMarker);
    if (selectedStore && map) {
      const StyledInfoWindow = withSystemProvider(InfoWindow);
      const content = ReactDOMServer.renderToString(<StyledInfoWindow store={selectedStore} />);
      const mapInfoWindow = new google.maps.InfoWindow({
        content,
      });
      const selectedMapMarker: google.maps.Marker | undefined = (markers ?? []).find(
        (marker) => marker.id === selectedMarker
      )?.mapMarker;

      if (selectedMapMarker) {
        mapInfoWindow.open(map, selectedMapMarker);
        setInfoWindow(mapInfoWindow);
      }
    }
  }, [markers, selectedMarker, highlightedMarker]);
}
