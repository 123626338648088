import { Cart as CartDetail, CartStep } from '../cart/types';
import { User as UserDetail } from '../account/types';
import { User as UserInfo } from '../auth/types';
import { Product } from '../product/types';
import { CatalogProduct } from '../catalog/types';

export type State = {
  dataLayer: DataLayer;
};

export type DataLayer = {
  env_work?: string;
  env_device?: string;
  env_language?: string;
  env_country?: string;
  env_currency?: string;
  page_id?: string;
  page_name?: string;
  page_template?: string;
  page_error?: string;
  page_category1?: string;
  page_category2?: string;
  page_category3?: string;
  page_products?: TrackingProduct[];
  page_query?: string;
  page_sorting?: string;
  page_filters?: any;
  page_number?: string;
  page_size?: string;
  user?: User;
  cart?: Cart;
  order?: Order;
};

export type User = {
  id?: string;
  visitor_id?: string;
  uid_resolved_MI?: string;
  uid_unresolved_Individual?: string;
  email?: string;
  email_sh256?: string;
  email_md5?: string;
  email_optin?: string;
  email_optin_origin?: string;
  email_optin_type?: string;
  account_creation_type?: string;
  age?: number;
  birthdate?: string;
  postalcode?: string;
  city?: string;
  registration_date?: string;
  last_visit?: string;
  gender?: string;
  is_new_customer?: string;
  last_order_date?: string;
};

export type Cart = {
  id?: string;
  amount?: number;
  promo_code?: string | null;
  promo_code_discount?: number;
  products_count?: number;
  products?: TrackingProduct[];
};

export type Order = {
  id?: string;
  status?: string;
  products_count?: number;
  products?: TrackingProduct[];
  payment_method?: string;
  shipping_method?: string;
  amount_ati_without_sf?: number;
  amount_ati_with_sf?: number;
  discount_ati?: number;
  ship_ati?: number;
  amount_tf_without_sf?: number;
  amount_tf_with_sf?: number;
  amount_ati_without_sf_without_disc?: number;
  amount_ati_with_sf_without_disc?: number;
  tax?: number;
  promo_code?: string;
};

export type TrackingProduct = {
  simple_sku?: string;
  configurable_sku?: string;
  name?: string;
  line?: string;
  category1?: string;
  category2?: string;
  category3?: string;
  brand?: string;
  quantity?: number;
  add_to_cart_origin?: string;
  add_to_cart_page?: string;
  color?: string;
  size?: string;
  size_type?: string;
  currentprice_ati?: number;
  originalprice_ati?: number;
  discount_ati?: number;
  currentprice_tf?: number;
  discount_tf?: number;
  originalprice_tf?: number;
  promotions?: string;
  url_page?: string;
  url_picture?: string;
};

export type InitParams = {
  pathname: string;
  search: string;
  cartDetail: CartDetail & { orderNumber?: string };
  cartStep: CartStep;
  isLoggedIn: boolean;
  userInfo?: UserInfo;
  userDetail?: UserDetail;
  product: Product;
  catalog: CatalogProduct[];
};

export enum Events {
  updateDataLayer = 'data_layer_update',
  addToCart = 'add_cart_item',
  removeFromCart = 'remove_cart_item',
  loadMore = 'load_more',
  loadPrevious = 'load_previous',
  clickRecommended = 'products_recs_click',
  applyPromo = 'promo_code_apply',
  updateAuthStatus = 'update_status',
  applyFilter = 'filter_apply',
  cartOpen = 'cart_open',
  // cartOpen to be remove because its only use on miniCart component
  // and we not use it any more
  authBarOpen = 'account_open',
  quickAddOpen = 'quickshop_open',
  addToWishlist = 'add_wishlist_item',
  removeFromWishlist = 'remove_wishlist_item',
  eresaIntent = 'eresa_intent',
  completeReservation = 'complete_eresa',
}

export type TaxParams = { amount: number; taxRate?: number };

export enum CustomerStatus {
  NEW_CUSTOMER = '1',
  NOT_NEW_CUSTOMER = '0',
}
