import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { breakpoints } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { RecommendationsT2S } from '../../recommendations/components/RecommendationsT2S';
import { RecommendationTypes } from '../../recommendations/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { cmsProductInit } from '../../cms/actions';
import { setCrossSellLoading } from '../actions';

type Props = {
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
};

const StyledCrossSellContainer = styled.div<{ isFeedBack: boolean }>`
  margin: ${(props) => (props.isFeedBack ? 0 : '0 -16px')};
  overflow: hidden;

  @media (min-width: ${breakpoints.S}px) {
    margin: ${(props) => (props.isFeedBack ? '0 16px' : 0)};
  }
`;

export const CrossSell = ({ isFeedback = false, isDesktopFeedback = false }: Props) => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.crossSell
  );
  const { cross_sell_title: title } = useSelector((state: RootState) => state.cms.product);
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  useEffect(() => {
    if (!title) {
      dispatch(cmsProductInit() as unknown as UnknownAction);
    }
  }, []);

  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setCrossSellLoading(false));
    }
    if (!recommendedProducts.length) {
      if (typeof window !== 'undefined' && (isDesktopFeedback || isFeedback)) {
        const target = document.getElementById('modal-content');
        if (target) {
          target.style.height = '270px';
        }
      }
    }
  }, [recommendedProducts, hits, isFetching]);

  if (!recommendedProducts || isFetching) {
    return null;
  }

  return (
    <StyledCrossSellContainer isFeedBack={isFeedback}>
      <RecommendationsT2S
        hits={hits.slice(0, isDesktopFeedback ? 4 : 6)}
        type={RecommendationTypes.crossSell}
        tracking={tracking}
        isDesktopFeedback={isDesktopFeedback}
        isFeedback={isFeedback}
        title={title}
      />
    </StyledCrossSellContainer>
  );
};
