import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { useSwipeable } from 'react-swipeable';
import styled from '@emotion/styled';

import { RootState } from 'src/shared/store/rootReducer';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { CrossSellProductPageItem } from './CrossSellProductPageItem';
import { Icon, colors, durations, opacities } from '../../../design-system';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { cmsProductInit } from '../../cms/actions';
import { setCrossSellLoading } from '../actions';

type StyledCrossSellContainerProps = {
  activeIndex: number;
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
};

const StyledCrossSellContainer = styled.div<StyledCrossSellContainerProps>`
  overflow: hidden;
  width: 100%;

  .carousel-inner {
    display: flex;
    transform: ${(props) => `translate3d(-${props.activeIndex * 100}%, 0, 0)`};
    width: 100%;
    transition: transform ${durations.FOCUS_DELAY}ms ease-in-out;
  }

  .cross-sell-header-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .cross-sell-title {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0;
    font-weight: 700;
    font-family: 'ogg-text';
    flex-grow: 1;
    text-align: left;
  }

  .carousel-button {
    background-color: unset;
    border: none;
  }

  .prev-button {
    opacity: ${(props) => (props.isPrevButtonDisabled ? opacities.DISABLED : 1)};
    cursor: ${(props) => (props.isPrevButtonDisabled ? 'initial' : 'pointer')};
    pointer-events: ${(props) => (props.isPrevButtonDisabled ? 'none' : 'initial')};
  }

  .next-button {
    opacity: ${(props) => (props.isNextButtonDisabled ? opacities.DISABLED : 1)};
    cursor: ${(props) => (props.isNextButtonDisabled ? 'initial' : 'pointer')};
    pointer-events: ${(props) => (props.isNextButtonDisabled ? 'none' : 'initial')};
  }

  .prev-button,
  .next-button {
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .cross-sell-navigation-button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }
  .cross-sell-items-wrapper {
    flex: 0 0 100%;
    box-sizing: border-box;
    will-change: transform;
  }

  .cross-sell-navigation-dots-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 8px 0;
  }

  .navigation-dot {
    border: solid 0.5px ${colors.LIGHT};
    cursor: pointer;
    padding: 2px;
    background-color: ${colors.LIGHT};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    transition: background-color ${durations.FOCUS_DELAY}ms ease,
      border-color ${durations.FOCUS_DELAY}ms ease;
  }

  .dot-active {
    border: solid 0.5px ${colors.BLACK};
    background-color: ${colors.BLACK};
    cursor: initial;
  }
`;

export const CrossSellProductPage = () => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.crossSell
  );
  const { cross_sell_title } = useSelector((state: RootState) => state.cms.product);
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  const { hits } = useProductsInfos(recommendedProducts);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMobileQuickAdd, setShowMobileQuickAdd] = useState(false);
  const [clickedItem, setClickedItem] = useState('');
  const [productRef, colorRef] = clickedItem.split('-') ?? '';
  const isPrevButtonDisabled = activeIndex === 0;
  const isNextButtonDisabled = activeIndex + 1 === hits?.length;

  const handlePrev = () => {
    if (!isPrevButtonDisabled) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (!isNextButtonDisabled) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const onClose = () => {
    setClickedItem('');
    setShowMobileQuickAdd(false);
  };

  useEffect(() => {
    if (!cross_sell_title) {
      dispatch(cmsProductInit() as unknown as UnknownAction);
    }
  }, []);

  useEffect(() => {
    setActiveIndex(0);
  }, [recommendedProducts]);

  useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setCrossSellLoading(false));
    }
  }, [recommendedProducts, hits, isFetching]);

  return !hits.length ? null : (
    <>
      <StyledCrossSellContainer
        activeIndex={activeIndex}
        isPrevButtonDisabled={isPrevButtonDisabled}
        isNextButtonDisabled={isNextButtonDisabled}
        {...handlers}
      >
        <div className="cross-sell-header-block">
          {cross_sell_title && <h2 className="cross-sell-title">{cross_sell_title}</h2>}
          {hits.length > 1 && (
            <div className="cross-sell-navigation-button-wrapper">
              <button
                data-testid="cross-sell-navigation-to-prev-item"
                className="carousel-button prev-button"
                onClick={handlePrev}
                disabled={isPrevButtonDisabled}
              >
                <Icon name="arrowLeft" size={20} />
              </button>
              <button
                data-testid="cross-sell-navigation-to-next-item"
                className="carousel-button next-button"
                onClick={handleNext}
                disabled={isNextButtonDisabled}
              >
                <Icon name="arrowRight" size={20} />
              </button>
            </div>
          )}
        </div>
        <div className="carousel-inner">
          {hits.map((hit) => {
            const { productRef, colorRef } = hit;
            const trackingId = (tracking ?? []).find(
              (item) => item.id === `${hit.productRef}-${hit.colorRef}`
            )?.trackingId;
            return (
              <div className="cross-sell-items-wrapper" key={`${productRef}—${colorRef}`}>
                <CrossSellProductPageItem
                  hit={hit}
                  trackingId={trackingId}
                  setShowMobileQuickAdd={setShowMobileQuickAdd}
                  setClickedItem={setClickedItem}
                />
              </div>
            );
          })}
        </div>
        <div className="cross-sell-navigation-dots-wrapper">
          {hits.length > 1 &&
            hits.map((hit, index) => {
              const { productRef, colorRef } = hit;
              const isActive = activeIndex === index;
              return (
                <button
                  key={`navigation-dot-${productRef}-${colorRef}`}
                  className={`navigation-dot ${isActive ? 'dot-active' : ''}`}
                  type="button"
                  data-testid={`cross-sell-navigation-dot-product-${productRef}-${colorRef}-button`}
                  onClick={() => (isActive ? null : setActiveIndex(index))}
                />
              );
            })}
        </div>
      </StyledCrossSellContainer>
      <QuickAddMobile
        productRef={productRef}
        colorRef={colorRef}
        originalSelectedColor={colorRef}
        isActive={showMobileQuickAdd}
        onClose={onClose}
      />
    </>
  );
};
