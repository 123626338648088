import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { RootState } from 'src/shared/store/rootReducer';
import {
  Anchor,
  Box,
  Button,
  Icon,
  Spinner,
  breakpoints,
  colors,
  opacities,
} from '../../../design-system';
import { durations } from '../../common/constants';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { Product } from '../../product/types';
import { CmsDefaultCrossSell } from '../../cms/types';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import OpeningHours from '../../store-locator/components/OpeningHours';
import { formatPhone } from '../../store-locator/utils';
import {
  ALL_PRODUCTS_ADDED,
  ALL_PRODUCTS_ADDED_DESCRIPTION,
  COMPLETE_WITH,
  MODIFY_STORE,
  PRODUCT_ADDED,
  RESERVATION,
  STORE,
  VALIDATE_ERESERVATION,
} from '../locale';
import { EresaStep, checkECartValidity, crossSellProductIsToDisplay } from '../utils';
import { EresaForm } from './EresaForm';
import { EresaProductCard } from './EresaProductCard';

type Props = {
  step: number;
  setStep: (value: number) => void;
};

const transitionStylesContent = {
  entering: {
    opacity: 0.5,
    transition: `opacity ${durations.EXIT}ms ease-in-out`,
    transitionTimingFunction: 'ease',
  },
  entered: {
    opacity: 1,
    transition: `opacity ${durations.EXIT}ms ease-in-out`,
    transitionTimingFunction: 'ease',
  },
  exiting: {
    opacity: 0.5,
    transition: `opacity ${durations.EXIT}ms ease-in-out`,
    transitionTimingFunction: 'ease',
  },
  exited: {
    opacity: 0.5,
    transition: `opacity ${durations.EXIT}ms ease-in-out`,
    transitionTimingFunction: 'ease',
  },
};

export const ScrollbarWrapper = styled(Box)(
  css({
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: '#FFF',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E6E6E6',
      borderRadius: '5px',
    },
    '&::-moz-scrollbar': {
      width: '4px',
      backgroundColor: '#FFF',
    },
    '&::-moz-scrollbar-thumb': {
      backgroundColor: '#E6E6E6',
      borderRadius: '5px',
    },
    '&::-moz-scrollbar-track': {
      backgroundColor: '#E6E6E6',
    },
    scrollbarColor: '#E6E6E6 #FFF',
    scrollbarWidth: 'thin',
  })
);

const isAllCrossSellAdded = (
  additionnals_products: Product[],
  crossSellProducts: Product[],
  defaultCrossSellMerged: Product[]
) => {
  return (
    (additionnals_products.length === crossSellProducts.length && crossSellProducts.length > 0) ||
    (additionnals_products.length === defaultCrossSellMerged.length &&
      defaultCrossSellMerged.length > 0 &&
      crossSellProducts.length === 0)
  );
};

const isCrossSellLoading = (
  recommendedProducts: string[],
  crossSellProducts: Product[],
  defaultCrossSellMerged: Product[],
  default_cross_sell: CmsDefaultCrossSell[]
) => {
  return (
    (recommendedProducts?.length > 0 && crossSellProducts.length === 0) ||
    (defaultCrossSellMerged.length === 0 &&
      recommendedProducts.length === 0 &&
      default_cross_sell?.length > 0)
  );
};

export const EresaSummary = ({ step, setStep }: Props) => {
  const { isMobile } = useMediaQueries();
  const refMainList = useRef<HTMLDivElement>(null);
  const refAdditionnalList = useRef<HTMLDivElement>(null);
  const refErrorOnAdditionalProduct = useRef<HTMLDivElement>(null);
  const [isRecentlyAdded, setIsRecentlyAdded] = useState(false);
  const [storeLinkOpacity, setStoreLinkOpacity] = useState(1);
  const { storeInfo } = useSelector((state: RootState) => state.ecart.ecart);
  const { main_product, additionnals_products, isLoading } = useSelector(
    (state: RootState) => state.ecart
  );
  const { items } = useSelector((state: RootState) => state.ecart.ecart);
  const { line1, line2, postalCode, city } = storeInfo?.address ?? {};
  const { recommendedProducts } = useSelector(
    (state: RootState) => state.recommendations.blocks.crossSell
  );
  const { default_cross_sell } = useSelector((state: RootState) => state.cms.ecart);

  // we create an empty array for default products with default color
  // this array will be use in case of no cross sell is provided by T2S
  // in recommendedProducts
  const arrayOfDefaultProductsWithDefaultColor: string[] = [];
  // we create an empty array for default products with main_product color
  // this array will be use in case of no cross sell is provided by T2S
  // in recommendedProducts
  const arrayOfDefaultProductsWithMainProductColor: string[] = [];

  // if recommendedProducts not provided by T2S we push in
  // the arrayOfDefaultProductsWithDefaultColor all the productRef
  // and colorRef provided on the cms
  recommendedProducts.length === 0 &&
    default_cross_sell?.forEach((item) => {
      arrayOfDefaultProductsWithDefaultColor.push(
        `${item.productref_default_cs}-${item.colorref_default_cs}`
      );
    });
  // if recommendedProducts provided by T2S is empty we push in
  // the arrayOfDefaultProductsWithMainProductColor all the productRef
  // provided on the cms and main_product colorRef provided by user
  // on EresaStep.PRODUCT ( step 1 )
  recommendedProducts.length === 0 &&
    default_cross_sell?.forEach((item) => {
      arrayOfDefaultProductsWithMainProductColor.push(
        `${item.productref_default_cs}-${main_product.colorRef}`
      );
    });

  const { hits: crossSellProducts } = useProductsInfos(recommendedProducts);
  const { hits: defaultCrossSellWithMainProductColor } = useProductsInfos(
    arrayOfDefaultProductsWithMainProductColor
  );
  const { hits: defaultCrossSellWithDefaultColor } = useProductsInfos(
    arrayOfDefaultProductsWithDefaultColor
  );

  // we merge all defaults cross sell and filter with Main color preference
  // to not duplicate product and removing product from the cms if is the
  // same product than the main product of the ereservation
  const defaultCrossSellMerged = [
    ...defaultCrossSellWithMainProductColor,
    ...defaultCrossSellWithDefaultColor,
  ]
    .filter(
      (product: Product, index: number, array: Product[]) =>
        index === array.findIndex((item: Product) => item.productRef === product.productRef)
    )
    .filter((item: Product) => item.productRef !== main_product.productRef);

  const allCrossSellAdded = isAllCrossSellAdded(
    additionnals_products,
    crossSellProducts,
    defaultCrossSellMerged
  );

  const numberOfItemsToAdd = additionnals_products.length + 1;
  const crossSellProductIsLoading = isCrossSellLoading(
    recommendedProducts,
    crossSellProducts,
    defaultCrossSellMerged,
    default_cross_sell
  );

  useEffect(() => {
    let tiemoutId: NodeJS.Timeout;
    if (isRecentlyAdded) {
      tiemoutId = setTimeout(() => setIsRecentlyAdded(false), durations.UNDO_DELAY);
    }
    return () => {
      clearTimeout(tiemoutId);
    };
  }, [isRecentlyAdded]);

  useEffect(() => {
    if (refMainList?.current && refAdditionnalList?.current && !isMobile) {
      refMainList.current.scrollTo(0, refMainList.current.scrollHeight);
      refAdditionnalList.current.scrollTo(0, 0);
    }
  }, [additionnals_products.length]);

  return (
    <div
      id={`eresa-modal-summary-step-${step}`}
      css={css`
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 182px);
        display: flex;
        flex-direction: column;
        @media (min-width: ${breakpoints.S}px) {
          height: 466px;
          min-height: initial;
          flex-direction: row;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media (min-width: ${breakpoints.S}px) {
            width: calc(50% - 12px);
            margin-right: 12px;
          }
        `}
      >
        <div
          css={css`
            @media (min-width: ${breakpoints.S}px) {
              height: 272px;
            }
          `}
        >
          <Box
            width="100%"
            height="34px"
            display="flex"
            justifyContent="space-between"
            borderBottom="solid 1px black"
            py="s"
          >
            <Box fontSize="16px" fontWeight={700}>
              {RESERVATION}
            </Box>
            <Box fontSize="12px" color="#545454" display="flex" alignSelf="center">
              {`${numberOfItemsToAdd} produit${numberOfItemsToAdd > 1 ? 's' : ''}`}
            </Box>
          </Box>
          <ScrollbarWrapper
            css={css`
              height: 100%;
              overflow: auto;
              @media (min-width: ${breakpoints.S}px) {
                overflow: scroll;
                height: 80%;
              }
              @media (min-width: ${breakpoints.L}px) {
                overflow: auto;
              }
            `}
            ref={refMainList}
          >
            <EresaProductCard product={main_product} step={step} />
            {additionnals_products?.map((it: Product, index: number) => {
              return (
                <EresaProductCard
                  key={`eresa-modal-product-added-${index}`}
                  isAdditionalProduct={true}
                  step={step}
                  product={it}
                  refErrorOnAdditionalProduct={refErrorOnAdditionalProduct}
                />
              );
            })}
          </ScrollbarWrapper>
        </div>
        <Box display="flex" flexDirection="column" justifyContent="end">
          <Box
            width="100%"
            height="34px"
            display="flex"
            flexDirection="column"
            borderBottom="solid 1px black"
            py="s"
          >
            <Box fontSize="16px" fontWeight={700}>
              {STORE}
            </Box>
          </Box>
          <Box fontSize="14px" fontWeight={700} my="m">
            {storeInfo?.name.toUpperCase()}
          </Box>
          <Box
            width="85%"
            fontSize="14px"
            lineHeight="110%"
            color="#545454"
            id="eresa-modal-store-address"
          >
            {line2 && line2 + ', '}
            {line2 && <br />}
            {line1 && line1 + ', '}
            {postalCode && postalCode + ' '}
            {city && city}
          </Box>
          <Box my="s">
            <OpeningHours hours={storeInfo?.hours ?? {}} isShortenedVersion={true} />
          </Box>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin: 8px 0 16px 0;
              justify-content: space-between;
              @media (min-width: ${breakpoints.S}px) {
                margin: 8px 0 0 0;
              }
            `}
          >
            <Box display="flex">
              <Icon name="phone" />
              <Box px="s">
                <Anchor
                  id={`eresa-modal-store-tel`}
                  href={`tel:${storeInfo?.mainPhone}`}
                  decoration="inline"
                >
                  {formatPhone(storeInfo?.mainPhone)}
                </Anchor>
              </Box>
            </Box>
            <Box
              data-cy="ereservation-summary-stores-link"
              fontSize="12px"
              opacity={storeLinkOpacity}
              borderBottom="solid 1px black"
              alignSelf="end"
              pb="xxs"
              onClick={() => (!isLoading ? setStep(EresaStep.STORES) : null)}
              onMouseEnter={() => setStoreLinkOpacity(opacities.HOVERED)}
              onMouseLeave={() => setStoreLinkOpacity(1)}
            >
              {MODIFY_STORE}
            </Box>
          </div>
        </Box>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          @media (min-width: ${breakpoints.S}px) {
            width: calc(50% - 12px);
            height: 466px;
            margin-left: 12px;
          }
        `}
      >
        {step === EresaStep.SUMMARY && (
          <>
            <Box
              fontSize="16px"
              fontWeight={700}
              width="100%"
              minHeight="34px"
              height="34px"
              py="s"
              borderBottom="solid 1px black"
            >
              {COMPLETE_WITH}
            </Box>
            <ScrollbarWrapper
              css={css`
                overflow: auto;
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;
                @media (min-width: ${breakpoints.S}px) {
                  overflow: scroll;
                  height: 398px;
                }
                @media (min-width: ${breakpoints.L}px) {
                  overflow: auto;
                }
              `}
              ref={refAdditionnalList}
            >
              {crossSellProductIsLoading && !isMobile && <LoadingScreen />}
              <Transition
                in={isRecentlyAdded && !allCrossSellAdded}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 0, exit: allCrossSellAdded ? 0 : durations.EXIT }}
              >
                {(state) => (
                  <Box
                    id="eresa-modal-success-add-box"
                    display="flex"
                    alignItems="center"
                    mt="m"
                    width="100%"
                    borderRadius="2px"
                    border="1px solid #9B9B9B"
                    p="7px 9px"
                    style={{
                      ...transitionStylesContent[state],
                    }}
                  >
                    <Icon name="checkmark" size={10} color="#36C754" />{' '}
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#545454',
                        marginLeft: '8px',
                      }}
                    >
                      {PRODUCT_ADDED}
                    </span>
                  </Box>
                )}
              </Transition>
              {allCrossSellAdded && (
                <Box
                  display="flex"
                  flexDirection="column"
                  mt="m"
                  width="100%"
                  borderRadius="2px"
                  border="1px solid #9B9B9B"
                  p="7px 9px"
                >
                  <Box display="flex">
                    <Icon name="checkmark" size={10} color="#36C754" />{' '}
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#545454',
                        marginLeft: '8px',
                      }}
                    >
                      {ALL_PRODUCTS_ADDED}
                    </span>
                  </Box>
                  <span style={{ fontSize: '11px', color: '#5A5A5A', marginTop: '2px' }}>
                    {ALL_PRODUCTS_ADDED_DESCRIPTION}
                  </span>
                </Box>
              )}
              {crossSellProducts?.map((hit: Product, index: number) => {
                if (!crossSellProductIsToDisplay({ additionnals_products, hit, main_product })) {
                  return null;
                }
                return (
                  <EresaProductCard
                    key={`eresa-modal-additionnal-product-${index}`}
                    product={hit}
                    isAdditionalProduct={true}
                    step={step}
                    setIsRecentlyAdded={setIsRecentlyAdded}
                  />
                );
              })}
              {crossSellProducts.length === 0 &&
                defaultCrossSellMerged?.map((hit: Product, index: number) => {
                  if (!crossSellProductIsToDisplay({ additionnals_products, hit, main_product })) {
                    return null;
                  }
                  return (
                    <EresaProductCard
                      key={`eresa-modal-additionnal-product-default-${index}`}
                      product={hit}
                      isAdditionalProduct={true}
                      step={step}
                      setIsRecentlyAdded={setIsRecentlyAdded}
                    />
                  );
                })}
            </ScrollbarWrapper>
            <div
              css={css`
                margin: 16px 0;
                height: 44px;
                background: ${colors.BLACK};
                justify-content: center;
                align-items: center;
                display: flex;
                @media (min-width: ${breakpoints.S}px) {
                  margin: 24px 0 0 0;
                }
              `}
            >
              {!isLoading && (
                <Button
                  id="eresa-modal-step-3-button"
                  data-cy="ereservation-summary-button-next-step"
                  fontWeight="700"
                  onClick={() => setStep(EresaStep.FORM)}
                  disabled={!checkECartValidity(items)}
                >
                  {VALIDATE_ERESERVATION}
                </Button>
              )}
              {isLoading && <Spinner color={colors.WHITE} />}
            </div>
          </>
        )}
        {step === EresaStep.FORM && <EresaForm />}
      </div>
    </div>
  );
};
