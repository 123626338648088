import * as React from 'react';
import { colors } from '../systemprovider';
import { Dropdown } from '../../design-system';
import PaginationBox from './components/PaginationBox';
import { useDevice } from '../../modules/common/hooks/useDevice';
import Box from '../box';

type Props = {
  items: any[];
  pageLimit?: number;
  pageNeighbours?: number;
  selectedSort: string;
  setSelectedSort: (value: string) => void;
};

const Pagination = ({
  items = [],
  pageLimit = 4,
  pageNeighbours = 0,
  selectedSort,
  setSelectedSort,
}: Props) => {
  const { isMobile, isTablet } = useDevice();
  const [currentItems, setCurrentItems] = React.useState(items.slice(0, pageLimit));
  const [currentPage, setCurrentPage] = React.useState(1);

  const topPaginationRef = React.useRef<HTMLInputElement>(null);
  const navHeightMobile = 52;
  const navHeightTablet = 64;
  const ctaHeightMobile = 46;
  const dropdownOptions = [
    { value: 'Plus récentes', label: 'Plus récentes' },
    { value: 'Moins récentes', label: 'Moins récentes' },
    { value: 'Notes croissantes', label: 'Notes croissantes' },
    { value: 'Notes décroissantes', label: 'Notes décroissantes' },
  ];

  React.useEffect(() => {
    setCurrentItems(items.slice(0, pageLimit));
  }, [items, pageLimit]);

  React.useEffect(() => {
    const offset = (currentPage - 1) * pageLimit;
    setCurrentItems(items.slice(offset, offset + pageLimit));
  }, [currentPage, items]);

  const totalCount = items.length;
  const totalPages = Math.ceil(totalCount / pageLimit);

  const gotoPage = (page: number) => {
    if ((isMobile || isTablet) && topPaginationRef.current) {
      topPaginationRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
    const currentPage = Math.max(0, Math.min(page, totalPages));
    setCurrentPage(currentPage);
  };

  const range = (from: number, to: number, step = 1) => {
    let i: number = from;
    const range: number[] = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages: number[];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages];
      } else if (leftSpill && rightSpill) {
        pages = [...pages];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const customChange = (value: string) => {
    setSelectedSort(value);
    setCurrentPage(1);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        px={isMobile || isTablet ? 'na' : 'm'}
        borderTop={`1px solid ${colors.LIGHT}`}
        borderBottom={`1px solid ${colors.LIGHT}`}
        style={{
          scrollMarginTop: isMobile
            ? `${navHeightMobile + ctaHeightMobile}px`
            : isTablet
            ? `${navHeightTablet}px`
            : 'initial',
        }}
        ref={topPaginationRef}
      >
        <Box
          fontSize="1.2rem"
          fontWeight={400}
          color="#5A5A5A"
          alignSelf="center"
        >{`${totalCount} commentaire${totalCount > 1 ? 's' : ''}`}</Box>
        <Box display="grid" gridAutoFlow="column" gridGap="xs" alignItems="center" minWidth="185px">
          <Dropdown
            id="reviews-pdp-selector"
            data-cy="pdp-reviews-dropdown-sorting"
            options={dropdownOptions}
            selected={selectedSort}
            onChange={(value: string) => customChange(value)}
            background="WHITE"
            isReviewsBlock
          />
        </Box>
      </Box>
      <Box>
        {currentItems.map((item, index) => (
          <div key={`${item.key}-${index}`}>{item}</div>
        ))}
      </Box>

      <Box
        display="flex"
        width="100%"
        justifyContent={isMobile ? 'flex-start' : 'center'}
        pt={isMobile || isTablet ? 'm' : 'l'}
        pb={isMobile || isTablet ? 'm' : 'na'}
      >
        <PaginationBox
          pages={fetchPageNumbers()}
          goToPage={gotoPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Box>
    </>
  );
};

export default Pagination;
