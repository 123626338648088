import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import { Box, PasswordField, DynamicButton, Text, Anchor } from '../../../design-system';
import { Forms, FormForgotPassword } from '../../form/types';
import { validate } from '../../form/utils';
import useQuery from '../../common/hooks/useQuery';
import { submitPasswordReset, requestNewPasswordReset } from '../actions';
import useForm from '../../form/hooks/useForm';
import {
  CTA_CONTENT_SUBMIT_PASSWORD,
  LINK_NEW_EMAIL,
  ERR_CODE_EXPIRED,
  MESSAGE_EMAIL_SENT,
  NOTE_PASSWORD,
  AUTH_BAR_TITLE,
  SENDING_A_NEW_EMAIL,
  ATTEMPT_LIMIT_EXCEEDED,
  ERR_CODE_MISMATCH,
} from '../locale';
import { ErrorCodes } from '../types';
import { openSidebar } from '../../common/actions';
import AuthBarContainer from './AuthBar';
import { Alert } from '../../../design-system/alert';
import paths from '../../routing/paths';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState<boolean | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formState = useForm(Forms.forgotPassword);
  const { actions } = formState;
  const form = formState.form as FormForgotPassword;

  const isNewPasswordResetAction = form.feedback.ok || isLoading;

  const query = useQuery();
  const email = query.get('email') || '';
  const token = query.get('token') || '';

  const clearMessage = () =>
    actions.setFeedback({ form: Forms.forgotPassword, ok: false, message: '' });

  React.useEffect(() => {
    return () => {
      clearMessage();
      navigate(paths.HOME, { replace: true });
    };
  }, []);

  const validatePassword = (value: string): string => validate({ value, key: 'password' });

  const handleChange = (value: string) => {
    if (form.validation.password) {
      actions.setFormValidation({
        form: Forms.forgotPassword,
        values: {
          password: validatePassword(value),
        },
      });
    }

    actions.setFormValues({ form: Forms.forgotPassword, values: { password: value } });
  };

  const handleSubmit = () => {
    const errMsg = validatePassword(form.values.password);

    actions.setFormValidation({
      form: Forms.forgotPassword,
      values: { password: errMsg },
    });

    if (!errMsg) {
      clearMessage();
      dispatch(
        submitPasswordReset({
          password: form.values.password,
          email,
          token,
        }) as unknown as UnknownAction
      );
    }
  };

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSendNewEmail = async () => {
    setIsLoading(true);
    dispatch(requestNewPasswordReset(email) as unknown as UnknownAction);
  };

  React.useEffect(() => {
    if (form.feedback.ok) {
      setIsLoading(false);
    }
  }, [form.feedback.ok]);

  React.useEffect(() => {
    if (form.ctaState === 2) {
      setIsError(true);
    }
    if (form.ctaState === 1) {
      setIsError(false);
      dispatch(
        openSidebar({
          title: AUTH_BAR_TITLE,
          content: <AuthBarContainer />,
        })
      );
    }
  }, [form]);

  return (
    <form>
      <Box display="grid" gridGap="l">
        {isError === true && (
          <Alert
            type={isNewPasswordResetAction ? 'success' : 'error'}
            title={isNewPasswordResetAction ? SENDING_A_NEW_EMAIL : 'Erreur'}
          >
            {form.feedback.message === ErrorCodes.ExpiredCodeException &&
              !isNewPasswordResetAction && (
                <Text id="error-reset-password" preset={'caption'} color={'#5A5A5A'}>
                  {ERR_CODE_EXPIRED}
                  {'. '}
                  <Anchor
                    id="btn-submit-reset-password-new-email"
                    type="button"
                    onClick={handleSendNewEmail}
                    preset="caption"
                  >
                    {LINK_NEW_EMAIL}
                  </Anchor>
                </Text>
              )}
            {form.feedback.message === ErrorCodes.LimitExceededException && (
              <Text id="error-reset-password" preset={'caption'} color={'#5A5A5A'}>
                {ATTEMPT_LIMIT_EXCEEDED}
              </Text>
            )}
            {form.feedback.message === ErrorCodes.CodeMismatchException && (
              <Text id="error-reset-password" preset={'caption'} color={'#5A5A5A'}>
                {ERR_CODE_MISMATCH}
              </Text>
            )}
            {form.feedback.message === ErrorCodes.ExpiredCodeException &&
            isNewPasswordResetAction &&
            form.feedback.ok ? (
              <Text id="success-resend-password" preset="caption" color={'#5A5A5A'}>
                {MESSAGE_EMAIL_SENT}
              </Text>
            ) : isLoading ? (
              <Text id="success-resend-password" preset="caption" color={'#5A5A5A'}>
                Envoi...
              </Text>
            ) : null}
          </Alert>
        )}
        <Text paddingRight={16} color={'#5A5A5A'}>
          {email}
        </Text>
        <PasswordField
          onChange={(value) => handleChange(value)}
          onKeyPress={handleKeyPress}
          errMsg={form.validation.password}
          value={form.values.password}
          id="reset-password-password"
          autoComplete="new-password"
        />
        <Text
          preset="caption"
          opacity={form.validation.password.length === 0 ? 1 : 0}
          position="relative"
          top="-24px"
        >
          {NOTE_PASSWORD}
        </Text>
        <Box display="flex" justifyContent="center">
          <DynamicButton
            id="btn-submit-reset-password-password"
            onClick={handleClick}
            data={CTA_CONTENT_SUBMIT_PASSWORD}
            feedback={form.ctaState}
          />
        </Box>
      </Box>
    </form>
  );
};

export default ResetPassword;
