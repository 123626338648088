import * as React from 'react';
import { Box } from '../../../design-system';
import { ProductUsp as ProductUspType } from '../../cms/types';
import ProductUspItem from './ProductUspItem';

type Props = {
  content: ProductUspType[];
};
const ProductUsp = ({ content }: Props) => (
  <Box
    display="grid"
    justifyContent="center"
    gridAutoFlow={['row', 'row', 'column']}
    gridGap={['m', 'm', 'na']}
    my="s"
  >
    {content.map((item, index) => (
      <ProductUspItem key={index} item={item} />
    ))}
  </Box>
);

export default ProductUsp;
