import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { CmsHomepageStudentOfferBanner } from '../../cms/types';
import { breakpoints, colors } from '../../../design-system';
import { BannerCta } from '../components/BannerCta';
import { css } from '@emotion/core';
import { heights } from '../../common/constants';

type Props = {
  id: string;
  content: CmsHomepageStudentOfferBanner;
};

type StyledLinkWrapperProps = {
  backgroundColor: string;
  withImage: string | undefined;
};

export const StyledLinkWrapper = styled.a<StyledLinkWrapperProps>`
  scroll-margin-top: ${heights.HEADER_MOBILE}px;
  text-decoration: none;
  display: grid;
  background-color: ${(props) => props.backgroundColor};
  @media (min-width: ${breakpoints.S}px) {
    scroll-margin-top: ${heights.HEADER_TABLET}px;
    grid-template-columns: ${(props) => (props.withImage ? '1fr 1fr' : '1fr')};
  }
  @media (min-width: ${breakpoints.M}px) {
    scroll-margin-top: ${heights.HEADER}px;
  }
`;

export const StudentOfferBanner = ({ id, content }: Props) => {
  const {
    title,
    subtitle,
    promo_title,
    promo_description,
    cta_name,
    cta_link,
    background_color,
    font_color,
    image,
  } = content ?? {};
  const { hash } = useLocation();
  const withImage = image?.url;
  const studentBannerRef = useRef<HTMLAnchorElement | null>(null);

  if (hash === '#etudiante' && studentBannerRef?.current) {
    studentBannerRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <StyledLinkWrapper
      id={id}
      ref={studentBannerRef}
      backgroundColor={background_color ?? '#DD5440'}
      withImage={withImage}
      href={cta_link}
    >
      <div
        css={css`
          position: relative;
          display: ${withImage ? 'block' : 'none'};
          height: inherit;
        `}
      >
        <picture>
          <source media="(max-width: 768px)" srcSet={image?.mobile?.url ?? ''} />
          <img
            alt={image?.alt ?? ''}
            src={image?.desktop?.url ?? ''}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </picture>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: ${withImage ? 'column' : 'initial'};
          justify-content: ${withImage ? 'initial' : 'space-between'};
          align-items: center;
          gap: ${withImage ? '16px' : '36px'};
          max-width: 360px;
          color: ${font_color ?? colors.WHITE};
          padding: 16px;
          width: 100%;
          margin: auto;
          @media (min-width: ${breakpoints.S}px) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: ${withImage ? '32px' : '56px'};
            max-width: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: ${withImage ? 'initial' : 'column'};
            gap: ${withImage ? '16px' : '8px'};
            @media (min-width: ${breakpoints.S}px) {
              flex-direction: initial;
              justify-content: ${withImage ? 'initial' : 'center'};
              align-items: center;
              gap: ${withImage ? '32px' : '56px'};
            }
          `}
        >
          {(title || subtitle) && (
            <div
              css={css`
                text-align: left;
                @media (min-width: ${breakpoints.S}px) {
                  text-align: center;
                }
              `}
            >
              {title && (
                <p
                  css={css`
                    font-size: ${withImage ? '16px' : '14px'};
                    line-height: 110%;
                    font-weight: 500;
                    font-style: italic;
                    margin: 0;
                    @media (min-width: ${breakpoints.S}px) {
                      font-size: ${withImage ? '20px' : '24px'};
                    }
                  `}
                >
                  {title}
                </p>
              )}
              {subtitle && (
                <p
                  css={css`
                    font-size: ${withImage ? '26px' : '20px'};
                    line-height: 110%;
                    font-weight: 700;
                    margin: 0;
                    @media (min-width: ${breakpoints.S}px) {
                      font-size: ${withImage ? '28px' : '32px'};
                    }
                  `}
                >
                  {subtitle}
                </p>
              )}
            </div>
          )}

          {(promo_title || promo_description) && (
            <div
              css={css`
                text-align: left;
              `}
            >
              {promo_title && (
                <p
                  css={css`
                    font-size: ${withImage ? '28px' : '24px'};
                    line-height: 110%;
                    font-weight: 700;
                    margin: 0;
                    @media (min-width: ${breakpoints.S}px) {
                      font-size: 28px;
                    }
                  `}
                >
                  {promo_title}
                </p>
              )}
              {promo_description && (
                <p
                  css={css`
                    font-size: 12px;
                    line-height: 110%;
                    font-weight: 500;
                    margin: 0;
                    @media (min-width: ${breakpoints.S}px) {
                      font-size: ${withImage ? '12px' : '14px'};
                    }
                  `}
                >
                  {promo_description}
                </p>
              )}
            </div>
          )}
        </div>

        {cta_link && (
          <div
            css={css`
              width: ${withImage ? '100%' : 'unset'};
              max-width: ${withImage ? '152px' : 'unset'};
              height: 44px;
              padding: 0;
              border: 1px solid ${font_color};
              @media (min-width: ${breakpoints.S}px) {
                width: 100%;
                max-width: 152px;
              }
            `}
          >
            <BannerCta
              id={`student-offer-banner-${`${id}`}`}
              text={cta_name ?? 'Découvrir'}
              fontColor={font_color ?? colors.WHITE}
              color="transparent"
              type={'color button'}
            />
          </div>
        )}
      </div>
    </StyledLinkWrapper>
  );
};
