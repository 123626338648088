import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Helmet } from 'react-helmet-async';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { CmsCustomTypes } from '../types';
import { loadCmsContent } from '../actions';
import { offers as locale } from '../locale';
import OfferItem from './OfferItem';
import { Title } from '../../common/components/Custom';

const Offers = () => {
  const dispatch = useDispatch();

  const cmsContent = useSelector((state: RootState) => state.cms.offers) ?? {};
  const items = cmsContent?.items ?? [];

  React.useEffect(() => {
    if (items.length < 1) {
      dispatch(loadCmsContent(CmsCustomTypes.offers) as unknown as UnknownAction);
    }
  }, []);

  const metaTitle = `${locale.TITLE} | Darjeeling`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
      </Helmet>
      <Box
        display="grid"
        gridGap={['l', 'xl']}
        id="box-offers"
        justifyContent="center"
        maxWidth="mwm"
        my={['m', 'l']}
        mx="auto"
        px="l"
      >
        <Title id="title-offers" textAlign="center">
          {locale.TITLE}
        </Title>
        {items.map((item, index) => (
          <OfferItem key={index} {...{ index, item }} />
        ))}
      </Box>
    </>
  );
};

export default Offers;
