import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Box, Text, StyledLink, Image, ImageContainer } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { CmsCustomTypes } from '../../cms/types';
import { loadCmsContent } from '../../cms/actions';
import { emptyText } from '../../cms/state';
import { getCanonicalUrl } from '../../common/utils';
import paths from '../paths';

const NotFound = () => {
  const dispatch = useDispatch();

  const cmsContent = useSelector((state: RootState) => state.cms.notfound) ?? {};
  const { title, subtitle, body, items } = cmsContent;

  React.useEffect(() => {
    if (!title) {
      dispatch(loadCmsContent(CmsCustomTypes.notfound) as unknown as UnknownAction);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>404 Not Found | Darjeeling</title>
        <link rel="canonical" href={getCanonicalUrl(paths.NOT_FOUND)} />
      </Helmet>
      <Box textAlign="center" m={['m', 'l', 'xl']} display="grid" gridGap="l">
        <Text preset="heading">{title}</Text>
        <Text preset="subheading">
          <RichText render={body || [...emptyText]} />
        </Text>
        <Text preset="heading">{subtitle}</Text>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridGap="m"
          overflow="auto"
          gridAutoColumns={['70vw', '1fr']}
          mr={['-16px', 'na']}
        >
          <Box display={['none', 'none', 'block']}>&nbsp;</Box>
          {(items ?? []).map(({ text, link, image }, index) => (
            <Box key={index} display="grid" gridGap="m">
              <Link id={`link-404-recommended-cat-img-${index}`} to={link ?? ''}>
                <ImageContainer preset="portrait">
                  <Image src={image?.url ?? ''} isAbsolute alt="Recommended Category"></Image>
                </ImageContainer>
              </Link>
              <Box height="xl">
                <StyledLink id={`link-404-recommended-cat-${index}`} to={link ?? ''}>
                  {text ?? ''}
                </StyledLink>
              </Box>
            </Box>
          ))}
          <Box display={['none', 'none', 'block']}>&nbsp;</Box>
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
