import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import { Box, Text, Divider } from '../../../design-system';
import { ProductUsp as ProductUspType } from '../../cms/types';
import styled from '@emotion/styled';
import css from '@styled-system/css';

type Props = {
  item: ProductUspType;
};

const ProductUspModal = ({ item }: Props) => {
  const { usp_title, body } = item ?? {};

  return (
    <Box display="grid" gridGap="m" textAlign="center" my="m" mx={['na', 'xxxl', 'l']}>
      <Text fontWeight="bold">{usp_title}</Text>
      <Divider color="BLACK" />
      <Box>
        {body?.[0]?.text && (
          <StyledBox>
            <RichText render={body} />
          </StyledBox>
        )}
      </Box>
    </Box>
  );
};

export const StyledBox = styled(Box)(
  css({
    '*': {
      paddingBlockStart: '0.8rem',
      paddingBlockEnd: '0.8rem',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: 'xs',
      lineHeight: 'xs',
    },
  })
);

export default ProductUspModal;
