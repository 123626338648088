import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '../../../design-system';
import { closeSidebar } from '../../common/actions';
import { RootState } from '../../../store/rootReducer';

import { numberOfItemsSelector } from '../../cart/selectors';
import { numberOfWishlistItemsSelector } from '../../wishlist/selectors';
import locale from '../../wishlist/locale';

type Props = {
  title: string;
  icon: string;
};

const MultiBar = ({ title, icon, children }: PropsWithChildren<Props>) => {
  const numberOfCartItems = useSelector((state: RootState) => numberOfItemsSelector(state));
  const numberOfWishlistItems = useSelector((state: RootState) =>
    numberOfWishlistItemsSelector(state)
  );
  const dispatch = useDispatch();

  return (
    <Box display="grid" id="box-multibar">
      <Box
        display="grid"
        position="sticky"
        top={0}
        zIndex="absolute"
        id="box-multibar-title"
        bg="WHITE"
      >
        <Box
          display="grid"
          gridAutoFlow="column"
          gridGap="s"
          alignItems="center"
          justifyContent="right"
          my="m"
        >
          <Icon size={16} name={icon} />
          <Text>
            {title}
            {title === locale.WISHLIST ? ` (${numberOfWishlistItems})` : `(${numberOfCartItems})`}
          </Text>
          <Icon
            id="close-drawer"
            name="closeSmall"
            size={16}
            onClick={() => dispatch(closeSidebar())}
          />
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default MultiBar;
