import React from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { CmsFontFamily, CmsGalleryBanner } from '../../cms/types';
import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { getOurBrandBannerPosition } from '../utils';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  cmsData: CmsGalleryBanner | null;
  index: number;
};

type StyledContainerProps = {
  gallery_banner_position_on_page: number;
  gallery_banner_margin_bottom: number;
  gallery_banner_margin_bottom_small_screen: number;
  gallery_banner_title_color: string;
  gallery_banner_title_font_family: CmsFontFamily;
  gallery_banner_title_font_size: number;
  gallery_banner_title_font_size_small_screen: number;
  gallery_banner_title_font_weight: number;
  gallery_banner_subtitle_color: string;
  gallery_banner_subtitle_font_family: CmsFontFamily;
  gallery_banner_subtitle_font_size: number;
  gallery_banner_subtitle_font_size_small_screen: number;
  gallery_block_text_color: string;
  gallery_block_text_font_family: CmsFontFamily;
  gallery_block_text_font_size: number;
  gallery_block_text_font_size_small_screen: number;
  gallery_block_title_color: string;
  gallery_block_title_font_family: CmsFontFamily;
  gallery_block_title_font_size: number;
  gallery_block_title_font_size_small_screen: number;
  gallery_block_title_font_weight: number;
  gallery_block_with_text_background_color: string;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  order: ${(props) => props.gallery_banner_position_on_page};
  margin-bottom: ${(props) => props.gallery_banner_margin_bottom_small_screen}px;
  width: 100%;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.gallery_banner_margin_bottom}px;
  }

  .gallery-banner-title {
    width: 90%;
    color: ${(props) => props.gallery_banner_title_color};
    font-family: ${(props) => props.gallery_banner_title_font_family};
    font-weight: ${(props) => props.gallery_banner_title_font_weight};
    font-size: ${(props) => props.gallery_banner_title_font_size_small_screen}px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: auto;
    padding-bottom: 16px;
    text-align: center;

    @media (min-width: ${breakpoints.M}px) {
      width: 80%;
      font-size: ${(props) => props.gallery_banner_title_font_size}px;
    }
  }

  .gallery-banner-subtitle {
    width: 90%;
    line-height: 24px;
    color: ${(props) => props.gallery_banner_subtitle_color};
    font-family: ${(props) => props.gallery_banner_subtitle_font_family};
    font-size: ${(props) => props.gallery_banner_subtitle_font_size_small_screen}px;
    margin: auto;
    padding-bottom: 32px;
    text-align: center;

    @media (min-width: ${breakpoints.M}px) {
      width: 50%;
      font-size: ${(props) => props.gallery_banner_subtitle_font_size}px;
    }

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    a {
      color: ${(props) => props.gallery_banner_subtitle_color};
      text-underline-position: under;
      @media (hover: hover) {
        &:hover {
          opacity: ${opacities.HOVERED};
          transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
        }
      }
    }

    strong {
      font-weight: 700;
    }
  }

  .gallery-block-container {
    display: block;

    @media (min-width: ${breakpoints.S}px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
    }

    @media (min-width: ${breakpoints.L}px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .gallery-block-with-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.gallery_block_with_text_background_color};
    padding: 16px;

    @media (min-width: ${breakpoints.S}px) {
      padding: 24px;
    }

    @media (min-width: ${breakpoints.L}px) {
      padding: 32px;
    }
  }

  .gallery-block-with-text-title {
    color: ${(props) => props.gallery_block_title_color};
    font-family: ${(props) => props.gallery_block_title_font_family};
    font-weight: ${(props) => props.gallery_block_title_font_weight};
    font-size: ${(props) => props.gallery_block_title_font_size_small_screen}px;
    margin-block-start: 0;
    margin-block-end: 0;

    @media (min-width: ${breakpoints.M}px) {
      font-size: ${(props) => props.gallery_block_title_font_size}px;
    }
  }

  .gallery-block-with-text-content {
    color: ${(props) => props.gallery_block_text_color};
    font-family: ${(props) => props.gallery_block_text_font_family};
    font-size: ${(props) => props.gallery_block_text_font_size_small_screen}px;
    line-height: 21px;

    a {
      color: ${(props) => props.gallery_block_text_color};
      text-underline-position: under;
      @media (hover: hover) {
        &:hover {
          opacity: ${opacities.HOVERED};
          transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
        }
      }
    }
    strong {
      font-weight: 700;
    }

    @media (min-width: ${breakpoints.M}px) {
      font-size: ${(props) => props.gallery_block_text_font_size}px;
    }
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .gallery-tile-tablet {
    display: none;

    @media (min-width: ${breakpoints.S}px) {
      display: block;
    }
  }

  .gallery-tile-mobile {
    display: none;

    @media (min-width: ${breakpoints.L}px) {
      display: block;
    }
  }
`;

export const GalleryBanner = ({ cmsData, index }: Props) => {
  const {
    gallery_banner_position_on_page,
    gallery_banner_margin_bottom,
    gallery_banner_margin_bottom_small_screen,
    gallery_banner_title,
    gallery_banner_title_color,
    gallery_banner_title_font_family,
    gallery_banner_title_font_size,
    gallery_banner_title_font_size_small_screen,
    gallery_banner_title_font_weight,
    gallery_banner_subtitle,
    gallery_banner_subtitle_color,
    gallery_banner_subtitle_font_family,
    gallery_banner_subtitle_font_size,
    gallery_banner_subtitle_font_size_small_screen,
    gallery_block_text,
    gallery_block_text_color,
    gallery_block_text_font_family,
    gallery_block_text_font_size,
    gallery_block_text_font_size_small_screen,
    gallery_block_title,
    gallery_block_title_color,
    gallery_block_title_font_family,
    gallery_block_title_font_size,
    gallery_block_title_font_size_small_screen,
    gallery_block_title_font_weight,
    gallery_block_with_text_background_color,
    gallery_image_1,
    gallery_image_2,
    gallery_image_3,
    gallery_image_4,
    gallery_image_5,
  } = cmsData?.primary ?? {};

  const contentItems = [
    { type: 'image', url: gallery_image_1?.url ?? '', alt: gallery_image_1?.alt ?? '' },
    { type: 'text', title: gallery_block_title, text: gallery_block_text },
    {
      type: 'image',
      url: gallery_image_2?.url ?? '',
      alt: gallery_image_2?.alt ?? '',
      disable: '-mobile',
    },
    {
      type: 'image',
      url: gallery_image_3?.url ?? '',
      alt: gallery_image_3?.alt ?? '',
      disable: '-tablet',
    },
    {
      type: 'image',
      url: gallery_image_4?.url ?? '',
      alt: gallery_image_4?.alt ?? '',
      disable: '-tablet',
    },
    {
      type: 'image',
      url: gallery_image_5?.url ?? '',
      alt: gallery_image_5?.alt ?? '',
      disable: '-mobile',
    },
  ];

  return (
    <StyledContainer
      id={`gallery-banner-block-${index}`}
      gallery_banner_position_on_page={getOurBrandBannerPosition(
        gallery_banner_position_on_page ?? 'Not activated'
      )}
      gallery_banner_margin_bottom={gallery_banner_margin_bottom ?? 0}
      gallery_banner_margin_bottom_small_screen={gallery_banner_margin_bottom_small_screen ?? 0}
      gallery_banner_title_color={gallery_banner_title_color ?? colors.BLACK}
      gallery_banner_title_font_family={gallery_banner_title_font_family ?? 'Ogg-text'}
      gallery_banner_title_font_size={gallery_banner_title_font_size ?? 40}
      gallery_banner_title_font_size_small_screen={
        gallery_banner_title_font_size_small_screen ?? 30
      }
      gallery_banner_title_font_weight={gallery_banner_title_font_weight ?? 700}
      gallery_banner_subtitle_color={gallery_banner_subtitle_color ?? colors.BLACK}
      gallery_banner_subtitle_font_family={gallery_banner_subtitle_font_family ?? 'MaisonNeue'}
      gallery_banner_subtitle_font_size={gallery_banner_subtitle_font_size ?? 16}
      gallery_banner_subtitle_font_size_small_screen={
        gallery_banner_subtitle_font_size_small_screen ?? 16
      }
      gallery_block_text_color={gallery_block_text_color ?? colors.WHITE}
      gallery_block_text_font_family={gallery_block_text_font_family ?? 'MaisonNeue'}
      gallery_block_text_font_size={gallery_block_text_font_size ?? 14}
      gallery_block_text_font_size_small_screen={gallery_block_text_font_size_small_screen ?? 14}
      gallery_block_title_color={gallery_block_title_color ?? colors.WHITE}
      gallery_block_title_font_family={gallery_block_title_font_family ?? 'Ogg-text'}
      gallery_block_title_font_size={gallery_block_title_font_size ?? 64}
      gallery_block_title_font_size_small_screen={gallery_block_title_font_size_small_screen ?? 64}
      gallery_block_title_font_weight={gallery_block_title_font_weight ?? 300}
      gallery_block_with_text_background_color={
        gallery_block_with_text_background_color ?? '#4D4F49'
      }
    >
      {gallery_banner_title && <h2 className="gallery-banner-title">{gallery_banner_title}</h2>}
      {hasValidCmsText(gallery_banner_subtitle) && (
        <div className="gallery-banner-subtitle">
          <RichText render={gallery_banner_subtitle} />
        </div>
      )}
      <div id={`gallery-block-container-${index}`} className="gallery-block-container">
        {contentItems.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div key={`gallery-text-${index}`} className="gallery-block-with-text gallery-tile">
                {item.title && <h3 className="gallery-block-with-text-title">{item.title}</h3>}
                {hasValidCmsText(item.text) && (
                  <div className="gallery-block-with-text-content">
                    <RichText render={item.text} />
                  </div>
                )}
              </div>
            );
          }
          return (
            <img
              key={`gallery-image-${index}`}
              className={`gallery-image gallery-tile${item.disable}`}
              src={item?.url ?? ''}
              alt={item?.alt ?? ''}
            />
          );
        })}
      </div>
    </StyledContainer>
  );
};
