import * as React from 'react';
import { UnknownAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';

import { RecommendationsT2S } from '../../recommendations/components/RecommendationsT2S';
import { loadRecommendations } from '../../recommendations/actions';
import { RecommendationTypes } from '../../recommendations/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';

export const HomeRecommendations = () => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.home
  );
  const { recommendations_t2s_title: title } = useSelector(
    (state: RootState) => state.cms.homepage
  );
  const { hits } = useProductsInfos(recommendedProducts);

  React.useEffect(() => {
    if (!recommendedProducts || recommendedProducts.length === 0) {
      dispatch(loadRecommendations(RecommendationTypes.home) as unknown as UnknownAction);
    }
  }, []);

  if (recommendedProducts.length === 0) {
    return null;
  }

  return (
    <Box>
      <RecommendationsT2S
        type={RecommendationTypes.home}
        {...{ tracking }}
        hits={hits}
        title={title}
      />
    </Box>
  );
};

export default HomeRecommendations;
