import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import { BannerBox } from '../../common/components/Custom';
import { Box } from '../../../design-system';
import { CmsText } from '../../cms/types';

type Props = {
  isDesktop: boolean;
  textColor: string;
  text_up?: CmsText;
  text_right?: CmsText;
  text?: CmsText;
};

export const BannerText = ({ textColor, text_up, text_right, text, isDesktop }: Props) => (
  <BannerBox
    overflow="hidden"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    color={textColor}
    mx={isDesktop ? 'initial' : 'm'}
  >
    {text_up?.[0]?.text && (
      <Box mb={isDesktop ? 's' : 'na'}>
        <RichText render={text_up} />
      </Box>
    )}
    {text?.[0]?.text && text_right?.[0]?.text ? (
      <Box
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="1fr auto 1fr"
        alignItems="center"
        my="s"
      >
        <Box mr={isDesktop ? 'xxxl' : 'l'}>
          <RichText render={text} />
        </Box>
        <Box height={isDesktop ? '160px' : '88px'} width="1px" bg={textColor} />
        <Box ml={isDesktop ? 'xxxl' : 'l'}>
          <RichText render={text_right} />
        </Box>
      </Box>
    ) : (
      text?.[0]?.text && <RichText render={text} />
    )}
  </BannerBox>
);
