export type ModalPreset =
  | 's'
  | 'l'
  | 'small'
  | 'medium'
  | 'large'
  | 'feedback'
  | 'feedbackWithoutCrossSell'
  | 'eresaModal'
  | 'eresaModalResults'
  | 'paypalECS'
  | 'paymentError'
  | 'sizeGuide'
  | 'nameValidationWarning';

export enum Devices {
  mobile,
  tablet,
  desktop,
}

export enum WS_EVENTS {
  VERSION_UPDATE = 'VERSION_UPDATE',
  MAINTENANCE_SWITCH = 'MAINTENANCE_SWITCH',
}
