import styled from '@emotion/styled';
import { variant } from 'styled-system';
import css from '@styled-system/css';

import { opacities, colors } from '../systemprovider';

type Preset = 'body' | 'caption' | 'subheading';
type Color = 'BLACK' | 'WHITE' | 'ERROR';
type Decoration = 'custom' | 'inline' | 'none';

type Props = {
  id: string;
  disabled?: boolean;
  preset?: Preset | Preset[];
  color?: Color | string;
  decoration?: Decoration;
  itemProp?: string;
  shouldnothaveopacityhover?: string;
};

const Anchor = styled.a<Props>(
  css({
    '@media(hover: hover)': {
      ':hover': {
        cursor: 'pointer',
        opacity: opacities.HOVERED,
        transition: 'opacity 0.4s',
      },
    },
    fontSize: 's',
    lineHeight: 's',
    color: 'BLACK',
    paddingBottom: 'xs',
    textDecoration: 'none',
    textUnderlineOffset: '2px',
    borderBottom: `1px solid ${colors.BLACK}`,
    transition: 'opacity 0.4s',
  }),
  variant({
    scale: 'links',
    prop: 'preset',
    variants: {
      body: {
        fontSize: 's',
        lineHeight: 's',
      },
      subheading: {
        fontSize: 'm',
        lineHeight: 'm',
      },
      caption: {
        fontSize: 'xs',
        lineHeight: 'xs',
        paddingBottom: 'xxs',
      },
    },
  }),
  ({ color }) =>
    color && {
      color: colors[color] ? colors[color] : color,
      borderColor: colors[color] ? colors[color] : color,
    },
  ({ decoration = 'custom' }) => {
    switch (decoration) {
      case 'custom':
        return {};
      case 'none':
        return {
          borderBottom: 'none',
          paddingBottom: 0,
        };
      case 'inline':
        return {
          borderBottom: 'none',
          paddingBottom: 0,
          textDecoration: 'underline',
        };
      default:
        return {};
    }
  },
  ({ disabled }) =>
    disabled && {
      opacity: opacities.DISABLED,
      pointerEvents: 'none',
    },
  ({ shouldnothaveopacityhover }) =>
    shouldnothaveopacityhover && {
      '@media(hover: hover)': {
        ':hover': {
          opacity: 1,
        },
      },
    }
);

export default Anchor;
