import * as React from 'react';
import { breakpoints } from '../../../design-system';
import { colors, theme } from '../../../design-system/systemprovider/constants';
import { useLocation } from 'react-router-dom';
import paths from '../../routing/paths';
import { isItGiftCard, getProductIdsFromUrl } from '../../product/utils';
import { MSG_PHOTOS_RETOUCHED } from '../locale';
import { css } from '@emotion/core';

const LegalLinks = () => {
  const { pathname } = useLocation();
  const needAdditionalMarginForStickyCta =
    pathname.startsWith(paths.PRODUCT) && !isItGiftCard(getProductIdsFromUrl(pathname).productRef);
  const currentYear = new Date().getFullYear();
  return (
    <div
      css={css`
        padding-top: 16px;
        padding-bottom: 16px;
        @media (min-width: ${breakpoints.S - 1}px) {
          padding-top: 20px;
          padding-bottom: ${needAdditionalMarginForStickyCta ? '100px' : '20px'};
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: ${breakpoints.S - 1}px) {
            justify-content: space-between;
          }
        `}
      >
        <div
          css={css`
            display: none;
            @media (min-width: ${breakpoints.S - 1}px) {
              display: flex;
              gap: 40px;
            }
            > a {
              text-decoration: none;
              color: ${colors.MAIN_GREY};
              font-size: ${theme.fontSizes.xs};
              @media (hover: hover) {
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          `}
        >
          <a href="/cgv">CGV</a>
          <a href="/mentions-legales">Mentions légales</a>
          <a href="/politique-de-confidentialite">Politique de confidentialité</a>
          <a href="/politique-de-cookies">Politique de cookies</a>
        </div>
        <div
          css={css`
            text-align: center;
            @media (min-width: ${breakpoints.S - 1}px) {
              text-align: right;
            }
            > p {
              line-height: 150%;
              margin: 0;
            }
          `}
        >
          <p
            css={css`
              font-size: ${theme.fontSizes.xs};
              color: ${colors.MAIN_GREY};
            `}
          >
            DARJEELING © {currentYear}
          </p>
          <p
            css={css`
              font-size: 10px;
              color: ${colors.GREY2};
              font-style: italic;
            `}
          >
            {MSG_PHOTOS_RETOUCHED}
          </p>
        </div>
      </div>
    </div>
  );
};

export { LegalLinks };
