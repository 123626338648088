import React, { useEffect, useRef, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import { CmsText } from '../../cms/types';
import { colors, durations, opacities } from '../../../design-system';
import { KNOW_MORE, SEE_LESS } from '../../home/locale';
import { hasValidCmsText } from '../../home/utils';
import { isButtonToDisplay } from '../utils';
import { CmsBox } from './Custom';

type Alignment = 'left' | 'center' | 'right';

const StyledContainer = styled.div<{ alignment: Alignment; isTextExtended: boolean }>`
  width: 100%;
  margin: auto;
  max-width: 1120px;
  padding: 0 16px;
  margin-bottom: 16px;

  .seo-description-content {
    text-align: ${(props) => props.alignment};
    ${(props) =>
      props.isTextExtended
        ? ''
        : `
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    `}
  }

  .seo-description-button {
    display: flex;
    margin-left: auto;
    margin-top: 8px;
    border: none;
    background: unset;
    padding: 0;
    font-size: 1.4rem;
    color: ${colors.BLACK};
    text-decoration: underline;
    text-underline-position: under;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        cursor: pointer;
      }
    }
  }
`;

type Props = {
  seo_description: CmsText;
  alignment: Alignment;
};

export const SeoDescription = ({ seo_description, alignment }: Props) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTextExtended, setIsTextExtended] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);

  useEffect(() => {
    const checkButtonDisplay = () => {
      setDisplayButton(isButtonToDisplay(textRef));
    };

    checkButtonDisplay();
  }, []);

  useEffect(() => {
    setDisplayButton(isTextExtended || isButtonToDisplay(textRef));
  }, [isTextExtended]);

  if (!hasValidCmsText(seo_description)) {
    return null;
  }

  return (
    <StyledContainer
      id="seo-description-block"
      alignment={alignment}
      isTextExtended={isTextExtended}
    >
      <div
        className={`seo-description-content`}
        ref={textRef}
        aria-hidden={displayButton && !isTextExtended}
      >
        <CmsBox withMargin withCustomHTag>
          <RichText render={seo_description} />
        </CmsBox>
      </div>
      {displayButton && (
        <button
          className="seo-description-button"
          aria-controls="seo-description-content"
          aria-expanded={isTextExtended}
          onClick={() => setIsTextExtended(!isTextExtended)}
          data-testid={`seo-description-show-${isTextExtended ? 'less' : 'more'}-button`}
        >
          {isTextExtended ? SEE_LESS : KNOW_MORE}
        </button>
      )}
    </StyledContainer>
  );
};
