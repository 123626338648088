export const MSG_EMPTY_ECART = "Vous n'avez aucune réservation";
export const ERESA_DELETE_TEXT = `Supprimer le produit`;
export const ERESA_DELETE_TEXT_MOBILE = `Supprimer`;
export const ERESA_MODAL_TITLE = `Réserver un produit gratuitement sans obligation d'achat`;
export const RESERVATION = ` Votre réservation`;
export const STORE = `Votre boutique`;
export const COMPLETE_WITH = `Complétez avec`;
export const MODIFY_STORE = `Modifier la boutique`;
export const PRODUCT_ADDED = `Le produit a été ajouté.`;
export const ALL_PRODUCTS_ADDED = ` Tous les produits ont été ajoutés.`;
export const ALL_PRODUCTS_ADDED_DESCRIPTION = `Vous avez ajouté tous les ensembles assortis disponibles.`;
export const VALIDATE_ERESERVATION = ` Valider ma réservation`;
export const FINALIZE_ERESERVATION = `Finaliser ma réservation`;
export const ERESA_ADD_DETAILS = `Veuillez ajouter vos coordonnées`;
export const ERESA_ALLOW_OPTIN = `J'autorise Darjeeling à m'informer des nouveautés`;
export const MANDATORY_FIELDS = `*Champs obligatoires`;
export const ERESA_TOTAL = `Total`;
export const PAY_IN_STORE = ` Paiement en boutique`;
export const ERESA_CONFIRMATION = (reservationId: string) => {
  return `Votre réservation n° ${reservationId} est confirmée !`;
};
export const ERESA_CONFIRMATION_DESCRIPTION = `Vous allez recevoir un e-mail récapitulatif à présenter en magasin pour récupérer votre réservation.`;
export const ERESA_CODE_PROMO_INFOS = `Vous avez un code promo ? Pas d'inquiétude, vous pourrez l'utiliser lors de votre
passage en caisse (si l'offre est toujours en cours).`;
export const ERESA_CONTINUE_TO_BUY = `Continuer mes achats`;
export const ERESA_COLOR = `Couleur :`;
export const ERESA_SIZE = `Taille :`;
export const ADD_TO_ERESA = `Ajouter à la réservation`;
export const ERESA_SIZE_SELECTION = `Choisissez votre taille`;
export const ERESA_OUT_OF_STOCK = `Cet article, n'est hélas plus disponible, cliquez-ici pour le supprimer.`;
export const ERESA_ERROR_TEXT = `Il semble que cet article soit maintenant en rupture de stock dans cette boutique`;
export const ERESA_ERROR_SOLUTION_ONE = `Effectuez une autre e-reservation en modifiant la couleur, taille ou boutique`;
export const ERESA_ERROR_SOLUTION_TWO_1 = `Ajoutez cet article au panier et optez pour`;
export const ERESA_ERROR_SOLUTION_TWO_2 = `avant d'effectuer le paiment`;
export const ERESA_ERROR_FREE_DELIVERY_STORE = `la livraison gratuite en boutique`;
export const ERESA_NEXT = `Suivant`;
