import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { CmsAnimatedPictures, CmsFontFamily } from '../../cms/types';
import {
  ANIMATED_BANNER_IMAGE_HEIGHT,
  ANIMATED_BANNER_MAX_WIDTH,
  getOurBrandBannerPosition,
} from '../utils';
import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { hasValidCmsText } from '../../home/utils';
import { heights } from '../../common/constants';

type Props = {
  cmsData: CmsAnimatedPictures | null;
  index: number;
};

type StyledContainerProps = {
  animated_pictures_position_on_page: number;
  animated_pictures_margin_bottom: number;
  animated_pictures_margin_bottom_small_screen: number;
  animated_pictures_text_font_family: CmsFontFamily;
  animated_pictures_text_font_size: number;
  animated_pictures_text_font_size_small_screen: number;
  animated_pictures_title_font_family: CmsFontFamily;
  animated_pictures_title_font_size: number;
  animated_pictures_title_font_size_small_screen: number;
  animated_pictures_title_font_weight: number;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  order: ${(props) => props.animated_pictures_position_on_page};
  margin-bottom: ${(props) => props.animated_pictures_margin_bottom_small_screen}px;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.animated_pictures_margin_bottom}px;
  }

  .animated-banner-large-screen {
    display: none;

    @media (min-width: ${breakpoints.M}px) {
      display: flex;
      max-width: ${ANIMATED_BANNER_MAX_WIDTH}px;
      margin: auto;
    }
  }

  .animated-banner-small-screen {
    display: grid;
    gap: 40px;

    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }

  .animated-banner-text-block {
    display: grid;
    gap: 40px;
    width: 40%;
  }

  .animated-banner-item-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${ANIMATED_BANNER_IMAGE_HEIGHT}px;
  }

  .animated-banner-image-block {
    display: grid;
    gap: 40px;
    width: 60%;
  }

  .animated-banner-item-image {
    width: 100%;
    height: ${ANIMATED_BANNER_IMAGE_HEIGHT}px;
    display: block;
    object-fit: cover;
    position: sticky;
    top: ${heights.HEADER}px;

    @media (min-width: ${breakpoints.M}px) {
      border-radius: 8px;
      width: calc(100% - 16px);
      margin: 0 8px;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: calc(100% - 32px);
      margin: 0 16px;
    }
  }

  .animated-banner-title {
    width: 100%;
    padding: 0 16px;
    color: ${colors.BLACK};
    font-family: ${(props) => props.animated_pictures_title_font_family};
    font-size: ${(props) => props.animated_pictures_title_font_size_small_screen}px;
    font-weight: ${(props) => props.animated_pictures_title_font_weight};
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 110%;

    @media (min-width: ${breakpoints.M}px) {
      padding: 0;
      font-size: ${(props) => props.animated_pictures_title_font_size}px;
      width: 80%;
    }
  }

  .animated-banner-text {
    width: 100%;
    padding: 0 16px;
    font-family: ${(props) => props.animated_pictures_text_font_family};
    font-size: ${(props) => props.animated_pictures_text_font_size_small_screen}px;
    color: ${colors.MAIN_GREY};
    line-height: 24px;

    @media (min-width: ${breakpoints.M}px) {
      padding: 0;
      font-size: ${(props) => props.animated_pictures_text_font_size}px;
      width: 80%;
    }

    a {
      color: ${colors.MAIN_GREY};
      text-underline-position: under;
      @media (hover: hover) {
        &:hover {
          opacity: ${opacities.HOVERED};
          transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
        }
      }
    }
    strong {
      font-weight: 700;
    }
  }
`;

export const AnimatedPictures = ({ cmsData, index }: Props) => {
  const {
    animated_pictures_position_on_page,
    animated_pictures_margin_bottom,
    animated_pictures_margin_bottom_small_screen,
    animated_pictures_text_font_family,
    animated_pictures_text_font_size,
    animated_pictures_text_font_size_small_screen,
    animated_pictures_title_font_family,
    animated_pictures_title_font_size,
    animated_pictures_title_font_size_small_screen,
    animated_pictures_title_font_weight,
  } = cmsData?.primary ?? {};

  const { items } = cmsData ?? {};

  const [imagePositions, setImagePositions] = useState<DOMRect[]>([]);

  useEffect(() => {
    const updateImagePositions = () => {
      const positions = items?.map((_item, index) => {
        const elem = document.getElementById(`image-animated-${index}`) as HTMLElement;
        return elem.getBoundingClientRect();
      });
      setImagePositions(positions ?? []);
    };

    window.addEventListener('scroll', updateImagePositions);

    return () => {
      window.removeEventListener('scroll', updateImagePositions);
    };
  }, []);

  return !items?.length ? null : (
    <StyledContainer
      id={`animated-picture-banner-block-${index}`}
      animated_pictures_position_on_page={getOurBrandBannerPosition(
        animated_pictures_position_on_page ?? 'Not activated'
      )}
      animated_pictures_margin_bottom={animated_pictures_margin_bottom ?? 0}
      animated_pictures_margin_bottom_small_screen={
        animated_pictures_margin_bottom_small_screen ?? 0
      }
      animated_pictures_text_font_family={animated_pictures_text_font_family ?? 'MaisonNeue'}
      animated_pictures_text_font_size={animated_pictures_text_font_size ?? 16}
      animated_pictures_text_font_size_small_screen={
        animated_pictures_text_font_size_small_screen ?? 16
      }
      animated_pictures_title_font_family={animated_pictures_title_font_family ?? 'Ogg-text'}
      animated_pictures_title_font_size={animated_pictures_title_font_size ?? 32}
      animated_pictures_title_font_size_small_screen={
        animated_pictures_title_font_size_small_screen ?? 32
      }
      animated_pictures_title_font_weight={animated_pictures_title_font_weight ?? 700}
    >
      <div className="animated-banner-large-screen">
        <div className="animated-banner-text-block">
          {items.map((item, index) => {
            return (
              <div key={`animated-banner-item-text-${index}`} className="animated-banner-item-text">
                {item.animated_pictures_title && (
                  <h2 className="animated-banner-title">{item.animated_pictures_title}</h2>
                )}
                {hasValidCmsText(item.animated_pictures_text) && (
                  <div className="animated-banner-text">
                    <RichText render={item.animated_pictures_text} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="animated-banner-image-block">
          {items.map((item, index) => {
            let opacity = 1;
            const height = ANIMATED_BANNER_IMAGE_HEIGHT;
            const offset = 80;

            if (index < imagePositions.length - 1) {
              const nextImageTop = imagePositions[index + 1]?.top ?? Number.MAX_VALUE;
              opacity = (nextImageTop - offset) / height;
            }
            return (
              <img
                key={`animated-banner-item-image-${index}`}
                id={`image-animated-${index}`}
                className="animated-banner-item-image"
                src={item.animated_image?.url ?? ''}
                alt={item.animated_image?.alt ?? ''}
                style={{ opacity }}
              />
            );
          })}
        </div>
      </div>
      <div className="animated-banner-small-screen">
        {items.map((item, index) => {
          return (
            <div
              key={`animated-banner-item-small-screen-block-${index}`}
              className="animated-banner-item-small-screen-block"
            >
              {item.animated_pictures_title && (
                <h2 className="animated-banner-title">{item.animated_pictures_title}</h2>
              )}
              {hasValidCmsText(item.animated_pictures_text) && (
                <div className="animated-banner-text">
                  <RichText render={item.animated_pictures_text} />
                </div>
              )}
              <img
                className="animated-banner-item-image"
                src={item.animated_image?.url ?? ''}
                alt={item.animated_image?.alt ?? ''}
              />
            </div>
          );
        })}
      </div>
    </StyledContainer>
  );
};
