import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { InfoPanel } from '../../../catalog/components/InfoPanel';
import { Images } from '../../../catalog/components/Images';
import { Color } from '../../../product/types';
import { closeModal, openModal } from '../../../common/actions';
import { QuickAdd } from '../../../quickadd/components/QuickAdd';
import { CmsImage } from '../../../cms/types';
import { Product } from '../../../product/types';
import { useMediaQueries } from '../../../common/hooks/useMediaQuery';
import { getQuickAddModalPreset } from '../../../quickadd/utils';

type Props = {
  hit: Product;
  alternativeSource?: CmsImage;
  setProductRefWithColorSelected: (value: string) => void;
};

const StyledContainer = styled.div`
  grid-column: 'span 2';
`;

export const ProductCard = ({ hit, alternativeSource, setProductRefWithColorSelected }: Props) => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useMediaQueries();

  const {
    productRef,
    colorRef,
    productName,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    colorVariants,
    collectionName,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
    sizeVariants,
  } = hit;

  const [selectedColor, setSelectedColor] = useState<Color>({
    colorRef,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
  });

  const [firstColorRef] = useState(selectedColor.colorRef);
  const [firstSelectedColor] = useState({
    colorRef: firstColorRef,
    originalPrice,
    storePrice,
  });

  const handleColorClick = (color: Color) => {
    setSelectedColor(color);
  };

  const onQuickAddClick = () => {
    if (isMobile) {
      setProductRefWithColorSelected(productRef + '-' + selectedColor.colorRef);
    } else {
      dispatch(closeModal());
      dispatch(
        openModal({
          content: (
            <QuickAdd
              productRef={productRef}
              colorRef={selectedColor.colorRef}
              originalSelectedColor={colorRef}
            />
          ),
          preset: getQuickAddModalPreset({ sizeVariants: sizeVariants ?? [], isTablet }),
        })
      );
    }
  };

  const objectID = `${hit.productRef}-${hit.colorRef}`;

  return (
    <StyledContainer id={`highlighted-banner-product-card-${objectID}`}>
      <Images
        productName={productName}
        productRef={productRef}
        objectID={objectID}
        selectedColor={selectedColor}
        onLinkClick={() => null}
        onQuickAddClick={onQuickAddClick}
        alternativeSource={firstColorRef === selectedColor.colorRef ? alternativeSource : undefined}
      />
      <InfoPanel
        colorVariants={colorVariants}
        productRef={productRef}
        productName={productName}
        objectID={objectID}
        selectedColor={selectedColor}
        firstSelectedColor={firstSelectedColor}
        handleColorClick={handleColorClick}
        collectionName={collectionName}
        onLinkClick={() => null}
      />
    </StyledContainer>
  );
};
