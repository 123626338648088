import React from 'react';

import { Box, Button, Icon } from '../../../design-system';
import { scrollToFormAffiliate } from '../utils';
import { getIconUrl } from '../../../design-system/icon/utils';
import { H1Affiliate } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  image: {
    url: string;
  };
  video: string;
};

const DescriptionBlock = ({ image, video }: Props) => {
  const { isMobile, isTablet } = useMediaQueries();

  return (
    <>
      <Box
        height={['369px', '543px', '544px']}
        backgroundColor="rgba(218, 176, 158, 0.7)"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <H1Affiliate
          pt={['64px', '64px', '64px']}
          textAlign="center"
          width={['343px', '706px', '922px']}
          fontWeight="700"
        >
          Choisissez la vie qui vous va,{!isMobile && <br />} devenez affilié Darjeeling
        </H1Affiliate>
        <Box
          width={['343px', '173px', '188px']}
          mt={['16px', '32px', '48px']}
          fontWeight="700"
          fontSize={['16px', 'na', 'na']}
          onClick={() => scrollToFormAffiliate({ isMobile, isTablet })}
        >
          <Button id="affiliate-description-button-0" fontWeight="700">
            Devenir affilié
          </Button>
        </Box>
      </Box>
      {image && (
        <Box
          position="absolute"
          top={['360px', '385px', '470px']}
          width={['343px', '706px', '922px']}
          height={['192px', '340px', '480px']}
          backgroundImage={`url(${image.url})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        />
      )}
      <Box
        mt={['140px', '130px', '300px']}
        display="flex"
        flexDirection="column"
        alignItems={['flex-start', 'center', 'center']}
        width={['343px', '706px', '922px']}
        px={['na', '16px', '16px']}
        textAlign={['left', 'center', 'center']}
      >
        <Box
          fontSize={['20px', '30px', '30px']}
          fontWeight="700"
          fontFamily="Ogg-text"
          lineHeight="110%"
        >
          La lingerie, un marché porteur
        </Box>
        <Box
          width="80px"
          border="solid 1px black"
          mt="16px"
          mb={['16px', '24px', '24px']}
          alignSelf={['flex-start', 'inherit', 'inherit']}
        />
        <Box fontSize={['14px', '20px', '20px']} fontWeight="400" lineHeight="130%">
          Depuis sa création en 1995 en France, Darjeeling n’a cessé d’inventer et de réinventer la
          lingerie en s’adaptant à chaque féminité. Grâce à l’expertise du Groupe Chantelle,
          Darjeeling a su imposer son style dans le paysage de la lingerie française.
        </Box>
      </Box>
      <Box
        mt={['na', 'na', '64px']}
        display="flex"
        justifyContent={['na', 'na', 'space-between']}
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        alignItems="center"
        width={['100%', '100%', '80%']}
      >
        <Box
          display="flex"
          justifyContent={['na', 'na', 'center']}
          flexDirection="column"
          width={['90%', '85%', '45%']}
          height={['inherit', 'inherit', '450px']}
        >
          <Box
            fontSize={['20px', '30px', '30px']}
            fontWeight="700"
            fontFamily="Ogg-text"
            lineHeight={['110%', '130%', '130%']}
          >
            Un nom qui parle à toutes et à tous.
          </Box>
          <Box
            width="80px"
            border="solid 1px black"
            mt={['16px', '16px', '16px']}
            mb={['16px', '24px', '24px']}
            alignSelf="flex-start"
          />
          <Box fontSize={['14px', '20px', '20px']} lineHeight="130%">
            Aujourd’hui, nous sommes fiers de la notoriété que nous avons acquise au fil des années.
          </Box>
          <Box
            mt="16px"
            id="container"
            display="flex"
            flexDirection={['column', 'row', 'row']}
            justifyContent={['center', 'flex-start', 'space-between']}
          >
            <Box mt="16px" width={['100%', '40%', '50%']} mr={['na', '32px', '16px']}>
              <Box display="flex" mb={['16px', '24px', '32px']}>
                <Icon name="sewingMachineAffiliate" size={16} />
                <Box
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Ogg-text"
                  ml="16px"
                  alignSelf="center"
                  lineHeight="110%"
                >
                  Un savoir faire
                </Box>
              </Box>
              <Box display="flex" mb={['16px', '24px', '32px']}>
                <Icon name="emptyHeartAffiliate" size={16} />
                <Box
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Ogg-text"
                  ml="16px"
                  alignSelf="center"
                  lineHeight="110%"
                >
                  Des gammes généreuses
                </Box>
              </Box>
              <Box display="flex">
                <Icon name="emptyStarAffiliate" size={16} />
                <Box
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Ogg-text"
                  ml="16px"
                  alignSelf="center"
                  lineHeight="110%"
                >
                  De la qualité
                </Box>
              </Box>
            </Box>
            <Box mt="16px" width={['100%', '40%', '50%']}>
              <Box display="flex" mb={['16px', '24px', '32px']}>
                <Icon name="cashAffiliate2" size={16} />
                <Box
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Ogg-text"
                  ml="16px"
                  alignSelf="center"
                  lineHeight="110%"
                >
                  Des prix pour toutes
                </Box>
              </Box>
              <Box display="flex">
                <Icon name="recycleAffiliate" size={16} />
                <Box
                  fontSize="16px"
                  fontWeight="700"
                  fontFamily="Ogg-text"
                  ml="16px"
                  alignSelf="center"
                  lineHeight="110%"
                >
                  Des collections engagnées : 70% de nos collections seront écoconçues en 2025
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignSelf="flex-end"
          alignItems="center"
          height={['195px', '395px', '350px']}
          width={['100%', '100%', '45%']}
          mb={['24px', '32px', 'na']}
          mt={['48px', '32px', 'na']}
        >
          {video && (
            <iframe
              src={video}
              height="100%"
              width="100%"
              frameBorder="0"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              object-fit="contain"
              allowFullScreen
            />
          )}
        </Box>
      </Box>
      <Box
        width={['100%', '90%', '80%']}
        display="flex"
        justifyContent={['center', 'center', 'left']}
      >
        <Box
          width={['343px', '173px', '188px']}
          mt={['48px', '36px', '24px']}
          onClick={() => scrollToFormAffiliate({ isMobile, isTablet })}
        >
          <Button id="affiliate-description-button-1" fontWeight="700">
            Devenir affilié
          </Button>
        </Box>
      </Box>
      <Box
        mt={['48px', '64px', '88px']}
        display="flex"
        width={['343px', '90%', '80%']}
        flexDirection={['column', 'row', 'row']}
        alignItems={['center', 'na', 'na']}
        justifyContent={['na', 'space-between', 'space-between']}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={['100%', '49%', '48%']}
          minHeight={['na', '360px', '360px']}
          backgroundColor="rgba(241, 237, 230, 0.8)"
          p={['24px', '24px', '40px']}
          mb={['16px', 'na', 'na']}
        >
          <Box
            backgroundImage={`url(${getIconUrl('chantelleLogoAffiliate')})`}
            width={['180px', '180px', '180px']}
            height={['30px', '50px', '50px']}
            backgroundSize="contain"
            backgroundPosition={['center', 'top', 'top']}
            backgroundRepeat="no-repeat"
            mb={['24px', 'na', 'na']}
          />

          <Box
            fontFamily="ogg-text"
            fontWeight="700"
            fontSize={['20px', '20px', '30px']}
            lineHeight="130%"
          >
            Le groupe Chantelle
          </Box>
          <Box
            width="80px"
            border="solid 1px black"
            my={['24px', '24px', '24px']}
            alignSelf={['flex-start', 'inherit', 'inherit']}
          />
          <Box fontSize="16px" fontWeight="700" lineHeight="110%">
            Un groupe français et familial qui conçoit de la lingerie depuis 1876
          </Box>
          <Box mt={['24px', '24px', '24px']} display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-2px', '-2px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              140 ans de créativité &#38; d’innovation
            </Box>
          </Box>
          <Box mt={['8px', '8px', '8px']} display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-24px', '-24px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              301 millions d’€ de chiffre d’affaires en 2021
            </Box>
          </Box>
          <Box mt={['8px', '8px', '8px']} display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-24px', '-24px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              3 expertises : wholesale, retail &#38;{isMobile && <br />} e-commerce
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={['100%', '49%', '48%']}
          minHeight={['na', '360px', '360px']}
          backgroundColor="rgba(237, 227, 218, 0.8)"
          p={['24px', '24px', '40px']}
        >
          <Box
            backgroundImage={`url(${getIconUrl('darjeelingLogoAffiliate')})`}
            width={['180px', '180px', '180px']}
            height={['30px', '50px', '50px']}
            backgroundSize="contain"
            backgroundPosition={['center', 'top', 'top']}
            backgroundRepeat="no-repeat"
            mb={['24px', '16px', 'na']}
          />

          <Box
            fontFamily="ogg-text"
            fontWeight="700"
            fontSize={['20px', '20px', '30px']}
            lineHeight={['110%', '130%', '130%']}
          >
            La marque Darjeeling
          </Box>
          <Box
            width="80px"
            height="1px"
            border="solid 1px black"
            my="16px"
            alignSelf={['flex-start', 'inherit', 'inherit']}
          />
          <Box fontSize="16px" fontWeight="700" mb={['na', 'na', 'na']} lineHeight="130%">
            + de 25 ans d’existence
          </Box>
          <Box mt="16px" display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-2px', '-2px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              + 161 boutiques dont 34 affiliées
            </Box>
          </Box>
          <Box mt={['8px', '8px', '8px']} display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-22px', '-24px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              70% de l’offre renouvelée chaque saison
            </Box>
          </Box>
          <Box mt={['8px', '8px', '8px']} display="flex">
            <Box width="10px" alignSelf="center" mr="8px" mt={['-24px', '-24px', '-4px']}>
              <Box
                width="6px"
                height="6px"
                borderRadius="50%"
                backgroundColor="rgba(0, 0, 0, 0.2)"
              />
            </Box>
            <Box fontSize="16px" fontWeight="400" lineHeight="130%">
              8 tailles de bonnet proposées : du{isMobile && <br />} A à H
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DescriptionBlock;
