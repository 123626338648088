import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import { Box, Text, Anchor, Button } from '../../../design-system';
import { Address as AddressType } from '../types';
import { RootState } from '../../../store/rootReducer';
import AddressContainer from './Address';
import { loadUserDetails, updateAddresses } from '../actions';
import { FormAddress } from '../../form/types';
import { scrollToTop } from '../../common/utils';

type Props = {
  addresses: AddressType[];
  form: FormAddress;
  updateAddresses: (addresses: AddressType[]) => void;
};

export const Addresses = ({ addresses, form, updateAddresses }: Props) => {
  const dispatch = useDispatch();
  const emptyAddress: AddressType = {
    id: '',
    name: '',
    company: '',
    siret: '',
    idTva: '',
    firstName: '',
    lastName: '',
    street: '',
    streetAdditional: '',
    postal: '',
    city: '',
    phone: '',
    country: 'FR',
  };
  const [address, setAddress] = React.useState(emptyAddress);
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  const formRef = React.useRef<HTMLDivElement>(null);

  const handleClickDelete = (id: string) => {
    updateAddresses(addresses.filter((address) => address.id !== id));
  };

  const handleClickEdit = (id: string) => {
    setIsFormOpen(true);
    const address = addresses.find((address) => address.id === id);
    if (address) {
      setAddress(address);
    }
    const node = formRef.current;
    if (node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickNew = () => {
    setIsFormOpen(true);
  };

  const handleClickSave = (newAddress: AddressType) => {
    if (newAddress.id) {
      updateAddresses(
        addresses.map((address) => (address.id === newAddress.id ? newAddress : address))
      );
    } else {
      updateAddresses([...addresses, newAddress]);
    }

    scrollToTop();
  };

  React.useEffect(() => {
    if (form.feedback.ok) {
      setAddress(emptyAddress);
      setIsFormOpen(false);
    }
  }, [form.feedback.ok]);

  React.useEffect(() => {
    dispatch(loadUserDetails() as unknown as UnknownAction);
  }, []);

  return (
    <Box display="grid" gridTemplateAreas="'message' 'title' 'addresses' 'form'" gridGap="xl">
      <Box gridArea="title">
        <Text preset="heading">Vos adresses</Text>
      </Box>
      <Box gridArea="message" textAlign="center">
        {form.feedback.message && (
          <Text
            color={form.feedback.ok ? 'SUCCESS' : 'ERROR'}
            id={`account-address-form-feedback-${form.feedback.ok ? 'success' : 'error'}`}
          >
            {form.feedback.message}
          </Text>
        )}
      </Box>
      <Box
        gridArea="addresses"
        display="grid"
        gridGap="xxl"
        gridTemplateColumns={['auto', 'repeat(auto-fill, minmax(176px, 1fr))']}
      >
        {addresses.length > 0 ? (
          addresses.map((address) => (
            <div key={address.id}>
              <Box mb="s">
                <Text preset="subheading" wordBreak="break-word">
                  {address.name}
                </Text>
              </Box>
              <Text wordBreak="break-word">{address.company}</Text>
              <Text wordBreak="break-word">
                {address.lastName} {address.firstName}
              </Text>
              <Text wordBreak="break-word">{address.street}</Text>
              <Text wordBreak="break-word">{address.streetAdditional}</Text>
              <Text wordBreak="break-word">
                {address.postal} {address.city}
              </Text>
              <Text wordBreak="break-word">{address.country}</Text>
              <Box
                display="grid"
                gridAutoFlow="column"
                gridGap="l"
                gridAutoColumns="max-content"
                my="m"
              >
                <Anchor
                  id="link-delete-address"
                  type="button"
                  onClick={() => handleClickDelete(address.id)}
                >
                  Supprimer
                </Anchor>
                <Anchor
                  id="link-edit-address"
                  type="button"
                  onClick={() => handleClickEdit(address.id)}
                >
                  Editer
                </Anchor>
              </Box>
            </div>
          ))
        ) : isFormOpen ? null : (
          <Text whiteSpace="nowrap">Vous n’avez pas enregistré d’adresse.</Text>
        )}
      </Box>
      <Box gridArea="form" ref={formRef}>
        {isFormOpen ? (
          <AddressContainer onSave={handleClickSave} address={address} />
        ) : (
          <Box width={['100%', '256px']}>
            <Button id="link-new-address" onClick={handleClickNew}>
              Ajouter une nouvelle adresse
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    form: state.form.address,
    addresses: state.account.user?.addresses ?? [],
  }),
  { updateAddresses }
)(Addresses);
