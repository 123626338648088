import * as React from 'react';

import withAuth from '../hocs/withAuth';
import { getIsClient } from '../../common/utils';

type Props = {
  logout: () => void;
};

export const Logout = ({ logout }: Props) => {
  React.useEffect(() => {
    logout();
    if (getIsClient()) {
      window.location.href = '/';
    }
  }, []);
  return null;
};

export default withAuth(Logout);
