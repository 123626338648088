import * as React from 'react';

import { Box, Icon, colors, Text } from '../../../design-system';
import { Tiles } from '../types';
import { ToolTip } from '../../common/components/Custom';
import { TOOLTIP_TILE } from '../locale';

export const TilesSwitcher: React.FC<{
  currentTile: Tiles;
  handleTileChange: (tile: Tiles) => void;
}> = ({ currentTile, handleTileChange }) => {
  const handleLargeTileClick = () => handleTileChange(Tiles.LARGE);
  const handleSmallTileClick = () => handleTileChange(Tiles.SMALL);

  return (
    <Box display="grid" gridGap="s" gridAutoFlow="column" alignItems="center" mx="s">
      <ToolTip reverse>
        <Icon
          name="tileLess"
          onClick={handleLargeTileClick}
          color={currentTile === Tiles.LARGE ? colors.BLACK : colors.GREY2}
          size={20}
        />
        <Box>
          <Text preset="caption" color="GREY" withEllipsis>
            {TOOLTIP_TILE(3)}
          </Text>
        </Box>
      </ToolTip>
      <ToolTip reverse>
        <Icon
          name="tileMore"
          onClick={handleSmallTileClick}
          color={currentTile === Tiles.SMALL ? colors.BLACK : colors.GREY2}
          size={20}
        />
        <Box>
          <Text preset="caption" color="GREY" withEllipsis>
            {TOOLTIP_TILE(4)}
          </Text>
        </Box>
      </ToolTip>
    </Box>
  );
};
