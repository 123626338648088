import * as React from 'react';

import { Button, Box, StyledLink } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';

type Props = {
  link: string;
  column: string;
  ctaText: string;
  ctaType?: string;
  ctaTypeMobile?: string;
};

const BrandCta = ({ ctaText, ctaType, ctaTypeMobile, link, column }: Props) => {
  const { isDesktop } = useDevice();

  const mt = column === 'full' || column === 'half' ? 'xxl' : isDesktop ? 'xxl' : 'm';
  const px = isDesktop || column !== 'quarter' ? 'na' : 's';
  const width = isDesktop || column !== 'quarter' ? '240px' : 'auto';
  const maxWidth = isDesktop || column !== 'quarter' ? '240px' : '160px';

  const type = !isDesktop && ctaTypeMobile ? ctaTypeMobile : ctaType;

  return (
    <Box {...{ mt, px, width, maxWidth }} mx="auto" textAlign="center">
      <StyledLink id="link-cms-brand" to={link}>
        {type !== 'link' ? (
          <Button
            id="btn-cms-brand"
            preset={type === 'light' ? 'primaryLight' : type === 'subtle' ? 'subtle' : 'primary'}
          >
            {ctaText}
          </Button>
        ) : (
          ctaText
        )}
      </StyledLink>
    </Box>
  );
};

export default BrandCta;
