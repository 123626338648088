import { getIsClient } from '../common/utils';
import { TARGET2SELL_URL } from '../api/constants';

declare global {
  interface Window {
    T2S?: {
      click?: ({ trackingId }: { trackingId: string }) => void;
    };
  }
}

export function getCookie(name: string): string | undefined {
  if (!getIsClient()) {
    return undefined;
  }
  const value = `; ${document.cookie}`;
  const parts = value?.split(`; ${name}=`);
  return parts?.length === 2 ? parts?.pop()?.split(';')?.shift() ?? undefined : undefined;
}

export function trackRecommendation(trackingId: string | undefined) {
  if (!trackingId || typeof window.T2S?.click !== 'function') {
    return;
  }

  window.T2S.click({ trackingId });
}

export async function postToT2S(data = {}) {
  const response = await fetch(TARGET2SELL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const json = await response.json();
  return json;
}

export function getFilter(items) {
  return {
    filter:
      items
        .map((item) => item.externalID.replace('/', '-'))
        .reduce((accumulator, item) => `${accumulator} OR rcFilter:"${item}"`, '')
        ?.substring(4) ?? '',
    tracking: items.map((item) => ({
      id: item.externalID.replace('/', '-'),
      trackingId: item.trackingId,
    })),
    recommendedProducts: items.map((item) => item.externalID.replace('/', '-')),
  };
}
