import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateDevice } from '../actions';
import { getIsClient } from '../utils';
import { RootState } from '../../../store/rootReducer';
import { Devices } from '../types';

export function useDevice(): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } {
  const dispatch = useDispatch();

  const { device } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    const isClient = getIsClient();
    if (isClient && !device) {
      dispatch(updateDevice(navigator.userAgent));
    }
  }, []);

  return {
    isMobile: device === Devices.mobile,
    isTablet: device === Devices.tablet,
    isDesktop: device === Devices.desktop,
  };
}
