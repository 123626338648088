import { Auth } from '@aws-amplify/auth';
import { SocialProviders } from './types';
import { AWS_IDENTITY_POOL_ID } from '../api/constants';
import { getBFFData, Queries } from '../api';

async function getSession() {
  return Auth.currentSession();
}

export async function getJWT() {
  try {
    return (await getSession()).getAccessToken().getJwtToken();
  } catch (error) {
    return null;
  }
}

export async function getIdPayload() {
  try {
    // @ts-ignore
    return (await getSession()).idToken.payload;
  } catch (error) {
    return null;
  }
}

export async function getIdentityId() {
  try {
    return localStorage.getItem(`CognitoIdentityId-${AWS_IDENTITY_POOL_ID}`);
  } catch {
    return null;
  }
}

export async function getSocialUserName({
  provider,
  credential,
}: {
  provider: SocialProviders;
  credential?: string;
}) {
  if (provider === SocialProviders.facebook) {
    if (!window.FB) {
      throw 'FB SDK not found';
    }

    const accessToken = await getFBToken();
    if (!accessToken) {
      throw 'Failed to get the token';
    }

    const response = await getBFFData(Queries.getFbUsername, {
      payload: { accessToken },
    });
    if (!response.ok) {
      throw 'Bad BFF response';
    }

    const { username } = response.data.socialFb;
    if (!username) {
      throw 'Failed to get the username';
    }

    return { username, accessToken };
  } else if (provider === SocialProviders.google) {
    if (!credential) {
      throw 'Failed to get the token';
    }

    const response = await getBFFData(Queries.getGoogleUsername, {
      payload: { credential },
    });
    if (!response.ok) {
      throw 'Bad BFF response';
    }

    const { username } = response.data.socialGoogle;
    if (!username) {
      throw 'Failed to get the username';
    }

    return { username, accessToken: credential };
  } else if (provider === SocialProviders.apple) {
    if (!window.AppleID) {
      throw 'Apple SDK not found';
    }

    const { idToken, email, firstName, lastName } = await getAppleToken();

    if (!idToken) {
      throw 'Failed to get the token';
    }

    const response = await getBFFData(Queries.getAppleUsername, {
      payload: {
        idToken,
        userEmail: email,
        userFirstName: firstName,
        userLastName: lastName,
      },
    });

    if (!response.ok) {
      throw 'Bad BFF response';
    }

    const { username } = response.data.socialApple;
    if (!username) {
      throw 'Failed to get the username';
    }

    return { username, accessToken: idToken };
  }
  throw 'Bad provider';
}

async function getFBToken(): Promise<string> {
  function getLoginStatus(): Promise<{ status: string; authResponse: { accessToken: string } }> {
    return new Promise((resolve) => {
      window.FB?.getLoginStatus((response) => {
        resolve(response);
      });
    });
  }

  function login(): Promise<{ status: string; authResponse: { accessToken: string } }> {
    return new Promise((resolve) => {
      window.FB?.login(
        (response) => {
          resolve(response);
        },
        { scope: 'public_profile,email' }
      );
    });
  }

  const existingLogin = await getLoginStatus();
  if (existingLogin.status === 'connected') {
    return existingLogin.authResponse.accessToken;
  }

  const response = await login();
  if (response.status === 'connected') {
    return response.authResponse.accessToken;
  }
  throw 'FB Authentication failed';
}

async function getAppleToken(): Promise<{
  email?: string;
  firstName?: string;
  lastName?: string;
  idToken: string;
}> {
  try {
    const response = await window.AppleID.auth.signIn();
    return {
      idToken: response.authorization.id_token,
      email: response.user?.email,
      firstName: response.user?.name.firstName,
      lastName: response.user?.name.lastName,
    };
  } catch (error) {
    throw 'Apple Authentication failed';
  }
}
