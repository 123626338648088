import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { useNavigate } from 'react-router-dom';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { ColorText } from './Custom';
import { Timer } from '../../cms';
import { heights } from '../constants';
import { useDevice } from '../hooks/useDevice';

const bannerAnimation = () => keyframes`
  0% {
    left: 0;
  }
  100% {
    left: -1000px;
  }
`;

const LoopingBox = styled.div`
  position: relative;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ${bannerAnimation()};
`;

export const Banner = () => {
  const { isDesktop } = useDevice();

  const cmsContent = useSelector((state: RootState) => state.cms.banner);
  const {
    body,
    textcolor,
    backgroundcolor: backgroundColor,
    link,
    promotion_end_date_time: timestamp,
  } = cmsContent ?? {};
  const navigate = useNavigate();

  const onBannerClick = () => {
    navigate(link);
  };

  const textColor = textcolor || 'BLACK';

  const fragments = {
    text: (
      <ColorText
        cmsColor={textColor}
        whiteSpace="nowrap"
        fontWeight={timestamp ? 'bold' : 'initial'}
        preset={'caption'}
      >
        {body ?? ''}
      </ColorText>
    ),
  };

  if (!body) {
    return null;
  }

  return (
    <Box
      id="top-promotion-banner"
      onClick={link ? onBannerClick : undefined}
      height={timestamp ? `${heights.BANNER_WITH_TIMESTAMP}px` : `${heights.BANNER}px`}
      textAlign="center"
      p={timestamp ? 'na' : '11px'}
      bg={backgroundColor ?? 'BACKGROUND'}
      display="flex"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      gridGap="48px"
    >
      {timestamp ? (
        <Box
          display="grid"
          alignItems="center"
          justifyContent="center"
          gridAutoFlow="column"
          gridGap={isDesktop ? 'xxxl' : 'xl'}
          mx="l"
        >
          {fragments.text}
          <Timer {...{ timestamp, textColor }} preset="subheading" />
          {isDesktop && fragments.text}
        </Box>
      ) : (
        [...Array(50).keys()].map((i) => <LoopingBox key={i}>{fragments.text}</LoopingBox>)
      )}
    </Box>
  );
};
