import * as React from 'react';
import { connect } from 'react-redux';

import { openModal, closeModal, OpenModalParams } from '../actions';
import { RootState } from '../../../store/rootReducer';

export type WithModalActions = {
  closeModal?: () => void;
  openModal: (options: OpenModalParams) => void;
};

function withModal(WrappedComponent) {
  const Modal = (props) => <WrappedComponent {...props} />;

  Modal.displayName = `withModal(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ ...state.modal }), { openModal, closeModal })(Modal);
}

export default withModal;
