import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import { Box, Text, Anchor, Divider, Icon, Button } from '../../../design-system';
import { emptyText } from '../state';
import { CmsBox, ColorText, ToolTip } from '../../common/components/Custom';
import { useDevice } from '../../common/hooks/useDevice';
import { CmsOfferItem } from '../types';
import { offers as locale } from '../locale';
import { copyToClipboard, formatDate, getDiffHour } from '../utils';

type Props = {
  index: number;
  item: CmsOfferItem;
};

const COLOR_EXPIRING = '#FFA500';
const COLOR_EXPIRED = '#FF0000';

const OfferItem = ({ item, index }: Props) => {
  const { isMobile } = useDevice();

  const [hasCopied, setHasCopied] = React.useState(false);

  const {
    background_color,
    conditions,
    coupon_code,
    cta_link,
    exclusions,
    image,
    name,
    text_color,
    tooltip_message,
    tooltip_message_expired,
    valid_from,
    valid_to,
  } = item ?? {};

  const handleCopy = async () => {
    if (coupon_code) {
      const response = await copyToClipboard(coupon_code);
      if (response) {
        setHasCopied(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setHasCopied(false);
      }
    }
  };

  const diffHour = valid_to ? getDiffHour(valid_to) : 0;

  const isExpiring = diffHour >= 0 && diffHour <= 48;
  const isExpired = diffHour < 0;
  const showClock = isExpiring || isExpired;
  const iconColor = isExpiring ? COLOR_EXPIRING : COLOR_EXPIRED;
  const tooltipMessage = isExpiring
    ? tooltip_message || locale.EXPIRING_PROMOTION
    : tooltip_message_expired || locale.EXPIRED_PROMOTION;

  return isMobile ? (
    <Box display="grid" gridGap="m" id={`box-offer-item-${index}`}>
      {index !== 0 && <Divider color="BLACK" />}
      <Box
        display="grid"
        gridGap="m"
        gridAutoFlow="column"
        justifyContent={['left', 'left', 'center']}
      >
        <Box
          background={`url(${image?.mobile?.url ?? ''}) no-repeat center / contain`}
          borderRadius="50%"
          size="h"
        />
        <Box
          display="grid"
          gridRowGap="s"
          gridColumnGap="m"
          alignItems="center"
          justifyContent="center"
          gridTemplateColumns="40px 1fr"
        >
          <Text color="GREY" preset="caption">
            {locale.LBL_NAME}
          </Text>
          <ColorText
            preset="caption"
            fontWeight="bold"
            bg={background_color}
            cmsColor={text_color}
            py="xs"
            px="s"
            justifySelf="left"
          >
            {name}
          </ColorText>
          <Text color="GREY" preset="caption">
            {locale.LBL_VALIDITY}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="xs"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="caption" fontWeight="bold">
              {`${valid_from ? formatDate(valid_from) : ''} - ${
                valid_to ? formatDate(valid_to) : ''
              }`}
            </Text>
            {showClock && (
              <Box mb="xs">
                <Icon name="clock" color={iconColor} />
              </Box>
            )}
          </Box>
          <Text color="GREY" preset="caption">
            {locale.LBL_COUPON_CODE}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="m"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="caption" fontWeight="bold">
              {coupon_code ?? locale.TXT_NO_COUPON_CODE}
            </Text>
            {coupon_code && (
              <Anchor
                id={`cta-copy-offer-item-${index}`}
                type="button"
                preset="caption"
                color="BLACK"
                onClick={handleCopy}
              >
                {hasCopied ? locale.CTA_COPIED : locale.CTA_COPY}
              </Anchor>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridGap="s">
        <Text color="GREY" preset="caption">
          {locale.LBL_CONDITIONS}
        </Text>
        <CmsBox isDescription>
          <RichText render={conditions || [...emptyText]} />
        </CmsBox>
      </Box>
      <Box display="grid" gridGap="s">
        <Text color="GREY" preset="caption">
          {locale.LBL_EXCLUSIONS}
        </Text>
        <CmsBox isDescription>
          <RichText render={exclusions || [...emptyText]} />
        </CmsBox>
      </Box>
      <Box mx="auto" my="s">
        <Anchor
          id={`link-offer-item-${index}`}
          href={cta_link?.url ?? ''}
          target={cta_link?.target ?? '_self'}
          preset="subheading"
        >
          {locale.CTA_LINK}
        </Anchor>
      </Box>
    </Box>
  ) : (
    <Box display="grid" gridGap="xxl" id={`box-offer-item-${index}`}>
      {index !== 0 && <Divider />}
      <Box display="grid" gridAutoFlow="column" gridGap="xxl">
        <Box
          backgroundImage={[
            `url(${image?.mobile?.url ?? ''})`,
            `url(${image?.mobile?.url ?? ''})`,
            `url(${image?.desktop?.url ?? ''})`,
          ]}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          width={['100px', '100px', '400px']}
          height={['100px', '100px', '360px']}
          borderRadius={['50%', '50%', 'initial']}
        />
        <Box
          display="grid"
          gridRowGap="m"
          gridColumnGap="l"
          alignItems="center"
          justifyContent="center"
          gridTemplateColumns="80px 1fr"
        >
          <Text color="GREY" preset="subheading" py="xs">
            {locale.LBL_NAME}
          </Text>
          <ColorText
            preset="heading"
            fontWeight="bold"
            bg={background_color}
            cmsColor={text_color}
            py="xs"
            px="s"
            justifySelf="left"
          >
            {name}
          </ColorText>
          <Text color="GREY" preset="subheading">
            {locale.LBL_VALIDITY}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="xs"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="subheading" fontWeight="bold">
              {`${valid_from ? formatDate(valid_from) : ''} - ${
                valid_to ? formatDate(valid_to) : ''
              }`}
            </Text>
            {showClock && (
              <Box mb="s">
                <ToolTip>
                  <Icon name="clock" color={iconColor} />
                  <Box>
                    <Text preset="caption" withEllipsis>
                      {tooltipMessage}
                    </Text>
                  </Box>
                </ToolTip>
              </Box>
            )}
          </Box>
          <Text color="GREY" preset="subheading">
            {locale.LBL_COUPON_CODE}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="m"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="subheading" fontWeight="bold">
              {coupon_code ?? locale.TXT_NO_COUPON_CODE}
            </Text>
            {coupon_code && (
              <ToolTip>
                <Anchor
                  id={`cta-copy-offer-item-${index}`}
                  type="button"
                  preset="caption"
                  color="BLACK"
                  onClick={handleCopy}
                >
                  {hasCopied ? locale.CTA_COPIED : locale.CTA_COPY}
                </Anchor>
                <Box>
                  <Text preset="caption" withEllipsis>
                    {locale.TOOLTIP_COPY}
                  </Text>
                </Box>
              </ToolTip>
            )}
          </Box>
          <Text color="GREY" preset="subheading" alignSelf="start">
            {locale.LBL_CONDITIONS}
          </Text>
          <CmsBox isDescription>
            <RichText render={conditions || [...emptyText]} />
          </CmsBox>
          <Text color="GREY" preset="subheading" alignSelf="start">
            {locale.LBL_EXCLUSIONS}
          </Text>
          <CmsBox isDescription>
            <RichText render={exclusions || [...emptyText]} />
          </CmsBox>
          <Box />
          <Box mx="auto" mt="m" width="180px">
            <Anchor
              id={`link-offer-item-${index}`}
              href={cta_link?.url ?? ''}
              target={cta_link?.target ?? '_self'}
              preset="subheading"
            >
              <Button id={`btn-offer-item-${index}`} preset="subtle">
                {locale.CTA_LINK}
              </Button>
            </Anchor>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OfferItem;
