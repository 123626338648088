import * as React from 'react';

import { Box } from '../../../design-system';
import RatingScale from './RatingScale';
import { useDevice } from '../../common/hooks/useDevice';
import HorizontalScale from './HorizontalScale';

type Props = {
  subQuestionTitle: string;
  subQuestionRating: number | string;
  identifier: string;
};
const GlobalRecommendation = ({ subQuestionTitle, subQuestionRating, identifier }: Props) => {
  const { isMobile, isTablet } = useDevice();
  const ratingAverage =
    typeof subQuestionRating === 'number'
      ? subQuestionRating
      : Number.parseFloat(subQuestionRating);

  return (
    <Box
      display="flex"
      alignItems="end"
      justifyContent={isMobile || isTablet ? 'space-between' : 'initial'}
    >
      <Box minWidth="130px" fontSize="1.2rem" fontWeight={400} lineHeight="110%" color="5A5A5A">
        {subQuestionTitle}
      </Box>
      {subQuestionTitle === 'Taille juste' ? (
        <Box mb="s">
          <HorizontalScale
            ratingAverage={ratingAverage}
            maximumValue={5}
            width={140}
            labelLeft="Petit"
            labelRight="Grand"
          />
        </Box>
      ) : (
        <RatingScale ratingAverage={ratingAverage} identifier={identifier} />
      )}
    </Box>
  );
};
export default GlobalRecommendation;
