import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import {
  Alert,
  Box,
  DynamicButton,
  DynamicButtonStatus,
  EmailField,
  Text,
} from '../../../design-system';
import SocialLogin from './SocialLogin';
import { FieldCallback, Forms, FormSignInEmail } from '../../form/types';
import { validate, isCtaActive } from '../../form/utils';
import withForm from '../../form';
import { AUTH_BAR_TITLE, CTA_CONTENT_SIGNIN_EMAIL } from '../locale';
import { WithFormActions } from '../../form/hocs/withForm';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { changeTitle } from '../../common/actions';

type Props = {
  checkEmail: (email: string) => void;
  setEmail: (email: string) => void;
  form: FormSignInEmail;
  errLogin: string;
  errSocial: string;
} & WithFormActions;

function CheckEmailExist({
  form,
  errLogin,
  errSocial,
  setFormValues,
  setFormValidation,
  checkEmail,
  setEmail,
}: Props) {
  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);

  const dispatch = useDispatch();

  const { ok, message } = useSelector((state: RootState) => state.form.socialLogin.feedback);

  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({
        form: Forms.signInEmail,
        values: { [key]: validateField({ key, value }) },
      });
    }

    setFormValues({ form: Forms.signInEmail, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signInEmail, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = () => {
    const { email } = form.values;
    if (validateForm()) {
      checkEmail(email);
      setEmail(email);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_TITLE));
    return () => {
      handleChange({ key: 'email', value: '' });
      setFormValidation({ form: Forms.signInEmail, values: { email: '' } });
    };
  }, []);

  return (
    <Box display="grid" gridGap="l">
      {errLogin && (
        <Alert type="error" title="Erreur">
          <Text id="error-login" preset="caption" color={'#5A5A5A'}>
            {errLogin}
          </Text>
        </Alert>
      )}
      {message && (
        <Alert type={ok ? 'success' : 'error'} title={ok ? 'Connexion' : 'Erreur'}>
          <Text id="social-login-alert" preset="caption" color={'#5A5A5A'}>
            {message}
          </Text>
          {errSocial && (
            <Text id="error-social-login" preset="caption" color={'#5A5A5A'}>
              {errSocial}
            </Text>
          )}
        </Alert>
      )}
      <EmailField
        onChange={(value) => handleChange({ key: 'email', value })}
        onKeyPress={handleKeyPress}
        errMsg={form.validation.email}
        value={form.values.email}
        id="signin-email"
        autoComplete="email"
      />

      <Box display="flex" justifyContent="center">
        <DynamicButton
          id="btn-submit-signin"
          onClick={handleSubmit}
          data={CTA_CONTENT_SIGNIN_EMAIL}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </Box>
      <SocialLogin title="ou connectez-vous avec" />
    </Box>
  );
}

export default withForm(CheckEmailExist, Forms.signInEmail);
