export default {
  NOTE_COUNTRY: 'Livraison en France uniquement.',
  ERR_EMAIL_EXISTS: 'Un compte client existe déjà avec cet email.',
  MSG_THIRD_PARTY_NOTICE:
    'Les informations nominatives recueillies au moment de votre inscription sont confidentielles et ne seront ni cédées ni louées ni vendues à des tiers.',
  VIEW_MORE: 'Détails',
  EMAIL_DELIVERY: 'Livraison par e-mail',
} as const;

export const SHIPPING_TYPES = {
  HOME: 'A domicile',
  STORE: 'En magasin',
  PICKUP: 'En point relais',
};

export const ORDER_STATUS = {
  CANCELED: 'Annulée',
  PROCESSING: 'En préparation',
  PENDING: 'En attente',
  PENDING_PAYMENT: 'En attente de paiement',
  PAID: 'Payée',
  PARTIAL_REFUND: 'Remboursement partiel',
  REFUNDED: 'Remboursée',
  SHIPPED: 'Expédiée',
  REMOVED: 'Supprimée',
  NEW: 'Nouvelle',
  PACKED: 'En préparation',
  BAGGED: 'En préparation',
  DISPATCHED: 'Expédiée',
  RECEIVED: 'Reçue',
  COLLECTED: 'Collectée',
  'IN-TRANSIT': 'En transit',
};
