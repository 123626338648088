import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { Box } from '../../../design-system';
import { CmsText } from '../types';
import { CmsBox } from '../../common/components/Custom';
import { useDevice } from '../../common/hooks/useDevice';

type Props = {
  body: CmsText;
  column: string;
  withImage?: boolean;
};

const BrandText = ({ body, column, withImage }: Props) => {
  const { isDesktop, isTablet, isMobile } = useDevice();

  const px = withImage
    ? 'l'
    : !isDesktop
    ? 'm'
    : column === 'full'
    ? 'h'
    : column === 'half'
    ? 'xxxl'
    : 'l';
  const pt = withImage ? 'm' : 'na';

  return (
    <CmsBox {...{ px, pt }} textAlign="center">
      {isDesktop && !withImage && (
        <Box width="800px">
          <RichText render={body} />
        </Box>
      )}
      {isTablet && !withImage && (
        <Box width="600px">
          <RichText render={body} />
        </Box>
      )}
      {(withImage || (isMobile && !withImage)) && <RichText render={body} />}
    </CmsBox>
  );
};

export default BrandText;
