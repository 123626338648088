import { ModalPreset } from '../common/types';
import { Size } from '../product/types';

export const getQuickAddModalPreset = ({
  sizeVariants,
  isTablet,
}: {
  sizeVariants: Size[] | [];
  isTablet: boolean;
}): ModalPreset => {
  if (isTablet) {
    return 's';
  }
  if (sizeVariants.length > 24 && sizeVariants.length <= 30) {
    return 'medium';
  }
  if (sizeVariants.length > 30) {
    return 'large';
  }
  return 'small';
};
