import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Field, Icon, Button, Spinner, MultilineField } from '../../../design-system';
import { Forms, FieldCallback, FieldType, FieldRefs } from '../../form/types';
import { RootState } from '../../../store/rootReducer';
import { setFormValues, setFormValidation, setFeedback } from '../../form/actions';
import {
  validate,
  isCtaActive,
  populateForm,
  isPhoneOnChangeValid,
  formatedPhoneValue,
  validateName,
  removeSpaces,
  addSpacesForPhoneNumber,
} from '../../form/utils';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { subscribeNewsletter } from '../../footer/utils';
import { getBFFData, Mutations } from '../../api';
import { scrollToFormAffiliate } from '../utils';

type Props = {
  pdf_link: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

const AffiliateForm = ({ pdf_link, email, firstName, lastName }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitOk, setIsSubmitOk] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorOnInput, setShowErrorOnInput] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile, isTablet } = useMediaQueries();
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form.affiliate);

  const refs: FieldRefs = {
    firstName: useRef<HTMLInputElement | null>(null),
    lastName: useRef<HTMLInputElement | null>(null),
    email: useRef<HTMLInputElement | null>(null),
    phone: useRef<HTMLInputElement | null>(null),
    message: useRef<HTMLInputElement | null>(null),
  };

  useEffect(() => {
    if (email && firstName && lastName) {
      const userinfos = {
        email: email,
        firstName: firstName,
        lastName: lastName,
      };
      const keys = Object.keys(userinfos);
      dispatch(
        setFormValues({
          form: Forms.affiliate,
          values: populateForm(userinfos, keys),
        })
      );
    }
  }, [email, firstName, lastName]);

  useEffect(() => {
    if (
      form.validation.email === '' &&
      form.validation.firstName === '' &&
      form.validation.lastName === '' &&
      showErrorOnInput
    ) {
      setShowErrorOnInput(false);
    }
    if (
      (form.validation.email !== '' ||
        form.validation.firstName !== '' ||
        form.validation.lastName !== '') &&
      !showErrorOnInput
    ) {
      setShowErrorOnInput(true);
    }
  }, [form.validation]);

  const validateField = (key: string, value: FieldType): string => {
    return validate({ value: String(value), key, isRequired: !['phone', 'message'].includes(key) });
  };

  const handleFieldChange = ({ key, value }) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      !isPhoneOnChangeValid({ value, dispatch, formType: Forms.affiliate, form })
    ) {
      return null;
    }

    const newValue = key === 'phone' ? formatedPhoneValue(value) : value;

    if (form.validation[key] && key !== 'phone') {
      dispatch(
        setFormValidation({
          form: Forms.affiliate,
          values: { [key]: validateField(key, newValue) },
        })
      );
    }

    dispatch(setFormValues({ form: Forms.affiliate, values: { [key]: newValue } }));
    if (!form.feedback.isDirty) {
      dispatch(
        setFeedback({
          form: Forms.affiliate,
          ok: false,
          message: '',
          isDirty: true,
        })
      );
    }
    return null;
  };

  const handleFieldBlur = ({ key, value }: FieldCallback) => {
    const errMsg = validateField(key, value);
    dispatch(setFormValidation({ form: Forms.affiliate, values: { [key]: errMsg } }));
  };

  const getField = ({
    key,
    label,
    autoComplete,
    type,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    type?: string;
  }) => (
    <Box width={['343px', '336px', '345px']} minHeight="50px" mx="8px" my="8px">
      <Field
        id={`${Forms.affiliate}-${key}`}
        onChange={(value: string) =>
          handleFieldChange({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        onBlur={(value: string) =>
          handleFieldBlur({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        value={key === 'phone' ? addSpacesForPhoneNumber(form.values[key]) : form.values[key]}
        errMsg={form.validation[key]}
        label={label}
        ref={refs[key]}
        autoComplete={autoComplete}
        type={type}
        color="#9B9B9B"
        height="50px"
      />
    </Box>
  );

  const validateEmail = (value: string): string =>
    validate({
      value: String(value),
      key: 'email',
      isRequired: true,
    });

  const validateFirstName = (value: string): string =>
    validate({
      value: String(value),
      key: 'firstName',
      isRequired: true,
    });

  const validateLastName = (value: string): string =>
    validate({
      value: String(value),
      key: 'lastName',
      isRequired: true,
    });

  const onSubmitForm = async () => {
    setIsLoading(true);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
    const errMsgEmail = validateEmail(form.values.email);
    dispatch(
      setFormValidation({
        form: Forms.affiliate,
        values: { email: errMsgEmail },
      })
    );
    const errMsgFirstName = validateFirstName(form.values.firstName);
    dispatch(
      setFormValidation({
        form: Forms.affiliate,
        values: { firstName: errMsgFirstName },
      })
    );
    const errMsgLastName = validateLastName(form.values.lastName);
    dispatch(
      setFormValidation({
        form: Forms.affiliate,
        values: { lastName: errMsgLastName },
      })
    );
    if (!errMsgEmail && !errMsgFirstName && !errMsgLastName) {
      if (isChecked) {
        const isOk = await subscribeNewsletter({
          mail: form.values.email,
          origin: 'affiliate-page',
        });
        const response = await getBFFData(Mutations.sendAffiliationForm, {
          form: {
            ...form.values,
          },
        });
        if (isOk && response.data.affiliation.success) {
          setIsSubmitOk(true);
        } else {
          setShowErrorMessage(true);
        }
      } else {
        const response = await getBFFData(Mutations.sendAffiliationForm, {
          form: {
            ...form.values,
          },
        });
        if (response.data.affiliation.success) {
          setIsSubmitOk(true);
        } else {
          setShowErrorMessage(true);
        }
      }
    } else {
      setShowErrorOnInput(true);
    }
    scrollToFormAffiliate({ isMobile, isTablet });
    setIsLoading(false);
  };

  return (
    <Box
      id="form-block-affiliate"
      backgroundColor="#F2F2F2"
      width="100%"
      display="flex"
      flexDirection="column"
      alignSelf="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        width={['343px', '80%', '80%']}
        py={['48px', '64px', '88px']}
      >
        <Box
          fontSize={['20px', '30px', '30px']}
          fontWeight="700"
          fontFamily="Ogg-text"
          lineHeight={['110%', '130%', '130%']}
          textAlign="center"
          alignSelf="center"
          width={['343px', '90%', '50%']}
        >
          Vous souhaitez en savoir plus ?
        </Box>
        <Box
          width="80px"
          height="1px"
          border="solid 1px black"
          mt="16px"
          mb={['16px', '24px', '24px']}
          alignSelf="center"
        />
        <Box
          fontSize={['14px', '20px', '20px']}
          lineHeight="130%"
          textAlign="center"
          mb={['48px', '40px', '48px']}
          width={['100%', '100%', '40%']}
          alignSelf="center"
        >
          Remplissez le formulaire pour être recontacté.é par une de nos responsables affiliation
        </Box>
        {isSubmitOk && (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="center"
            backgroundColor="white"
            p="8px"
            width={['auto', 'auto', '688px']}
            height={['auto', 'auto', '52px']}
            border="1px solid #9B9B9B"
            borderRadius="2px"
          >
            <Box display="flex" flexDirection="row">
              <Icon name="checkmarkCircle" size={12} />
              <Box color="#545454" fontSize="14px" lineHeight="110%" fontWeight="700" ml="4px">
                Demande envoyée
              </Box>
            </Box>
            <Box color="#545454" fontSize="11px" lineHeight="13px" mt="8px">
              Votre demande va être prise en charge par notre équipe, vous allez être recontacté
              prochainement.
            </Box>
          </Box>
        )}
        {(showErrorMessage || showErrorOnInput) && (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="center"
            backgroundColor="white"
            p="8px"
            mb="16px"
            border="1px solid #9B9B9B"
            borderRadius="2px"
          >
            <Box color="red" fontSize="11px" lineHeight="13px" textAlign="center">
              {showErrorMessage
                ? 'Une erreur est survenue, vérifiez votre email ou réessayez ultérieurement'
                : 'Veuillez saisir le formulaire'}
            </Box>
          </Box>
        )}
        {!isSubmitOk && (
          <Box display="flex" flexDirection="column" alignSelf="center">
            <Box display="flex" flexDirection={['column', 'row', 'row']} alignSelf="center">
              {getField({ key: 'firstName', label: 'Prénom*', autoComplete: 'given-name' })}
              {getField({ key: 'lastName', label: 'Nom*', autoComplete: 'family-name' })}
            </Box>
            <Box display="flex" flexDirection={['column', 'row', 'row']} alignSelf="center">
              {getField({ key: 'email', label: 'Email*', autoComplete: 'email' })}
              {getField({ key: 'phone', label: 'Téléphone', autoComplete: 'tel', type: 'tel' })}
            </Box>
            <Box display="flex" alignSelf="center" width={['343px', '688px', '704px']}>
              <MultilineField
                id={`${Forms.affiliate}-message`}
                onChange={(value: string) => handleFieldChange({ key: 'message', value })}
                onBlur={(value: string) => handleFieldBlur({ key: 'message', value })}
                value={form.values.message}
                isAffiliatePage={true}
                label="Champs de texte"
                errMsg={form.validation.message}
                maxLength={2000}
                note={`${form.values.message.length}/2000 caractères maximum`}
                ref={refs.message}
              />
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection="column" alignSelf="center" mt="64px">
          {!isSubmitOk && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignSelf="center"
                width={['343px', '350px', '350px']}
                opacity={!isCtaActive(form) || showErrorMessage ? 0.3 : 1}
                pointerEvents={!isCtaActive(form) || showErrorMessage ? 'none' : 'initial'}
                onClick={() => setIsChecked(!isChecked)}
              >
                <Icon name={isChecked ? 'checkboxOn' : 'checkboxOff'} size={16} />
                <Box ml="8px" alignSelf="center" fontSize="12px">
                  J&apos;autorise Darjeeling à m’informer des nouveautés
                </Box>
              </Box>
              <Box
                fontSize="12px"
                alignSelf="center"
                color="#5A5A5A"
                mt="16px"
                mb={['32px', '32px', '32px']}
              >
                *Champs obligatoires
              </Box>
              <Box width={['343px', '255px', '255px']} alignSelf="center">
                <Button
                  id="button-submit-form-affiliate"
                  disabled={!isCtaActive(form) || showErrorMessage}
                  onClick={onSubmitForm}
                >
                  {!isLoading ? (
                    <Box fontWeight="700" fontSize="16px">
                      Envoyez votre demande
                    </Box>
                  ) : (
                    <Spinner color="white" />
                  )}
                </Button>
              </Box>
              <Box my={['24px', '32px', '32px']} alignSelf="center" fontSize="12px" color="#5A5A5A">
                OU
              </Box>
            </>
          )}
          <a
            href={pdf_link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', width: '300px', alignSelf: 'center' }}
          >
            <Box id="pdf-block-affiliate" display="flex" flexDirection="row" alignSelf="center">
              <Icon name="downloadAffiliate" size={16} />
              <Box
                fontSize="14px"
                lineHeight="110%"
                ml="4px"
                borderBottom="solid 1px black"
                pb="4px"
              >
                Télécharger le formulaire de candidature
              </Box>
            </Box>
          </a>
          <Box
            mt={['16px', '32px', '32px']}
            display="flex"
            flexDirection="column"
            width={['343px', '90%', '100%']}
            alignSelf="center"
          >
            <Box fontSize="12px" lineHeight="130%" textAlign="center" mb="32px" color="#5A5A5A">
              Une fois le formulaire rempli, transférez-le par email à l&apos;adresse
              affiliationdarjeeling@chantelle.fr, ou envoyez-la par courrier{!isMobile && <br />}{' '}
              postal à l&apos;adresse :
              <br />
              <br />
              DELTA Lingerie SA
              <br />
              Direction de l&apos;affiliation
              <br />
              Agnès CORNIER LE PAPE
              <br />
              16, rue de Provigny
              <br />
              BP 134
              <br />
              94 234 CACHAN cedex
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="row" mb="16px" onClick={() => setIsOpen(!isOpen)}>
                <Box fontSize="14px" lineHeight="110%" alignSelf="center" mr="8px">
                  Mentions légales
                </Box>
                <Box
                  transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  transition="all .25s ease-in-out"
                >
                  <Icon name="chevronDown" />
                </Box>
              </Box>

              {isOpen && (
                <Box
                  fontSize="12px"
                  lineHeight="130%"
                  textAlign="center"
                  width={['343px', '90%', '50%']}
                  color="#5A5A5A"
                >
                  Les informations recueillies font l’objet d’un traitement informatique destiné à
                  la base de données relative aux candidats à l’affiliation. Le destinataire des
                  données est le Service Affiliation de la société DELTA LINGERIE. Elles ne sont pas
                  destinées à être transmises à d’autres personnes morales ou physiques.
                  <br />
                  Conformément à la loi “informatique et libertés” du 6 janvier 1978 modifiée en
                  2004, vous bénéficiez d’un droit d’accès et de rectification aux informations vous
                  concernant, que vous pouvez exercer en vous adressant au Service Affiliation de la
                  société DELTA LINGERIE, 16 rue de Provigny, Cachan (94230).
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AffiliateForm;
