import React from 'react';
import { heights, zIndex } from '../../common/constants';
import { useDevice } from '../../common/hooks/useDevice';
import { Box, Text } from '../../../design-system';
import Rating from './Rating';
import { H1 } from '../../common/components/Custom';

type Props = {
  ratingAverage: string;
  ratingCount: string;
  showReviews: boolean;
  reviewsRef: React.RefObject<HTMLDivElement>;
  amount: string;
};

export default function GiftCardInfoPanel({
  showReviews,
  ratingAverage,
  ratingCount,
  reviewsRef,
  amount,
}: Props) {
  const { isMobile } = useDevice();
  return (
    <>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="start"
        justifyContent="space-between"
        bg="WHITE"
        zIndex={isMobile ? zIndex.STICKY : 'initial'}
        mb={isMobile ? 'na' : '-8px'}
        position={isMobile ? 'sticky' : 'initial'}
        bottom={isMobile ? `${heights.MOBILE_SELECTOR}px` : 'initial'}
        mx={isMobile ? '-16px' : 'na'}
        p={isMobile ? 'm' : 'na'}
      >
        <H1 id="pdp-product-name" preset={['body', 'subheading']} fontWeight={['regular', 'bold']}>
          E-Carte Cadeau Darjeeling
        </H1>
        <Text id="pdp-price" preset={['body', 'subheading']} color={'BLACK'} fontWeight="bold">
          {amount && amount !== '0' && amount + '€'}
        </Text>
      </Box>
      <Box display="grid" gridAutoFlow="column" justifyContent="space-between">
        <Box>{showReviews && <Rating {...{ ratingAverage, ratingCount, reviewsRef }} />}</Box>
      </Box>
    </>
  );
}
