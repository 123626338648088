import React, { useEffect, useState } from 'react';
import { Box, Text, ButtonSize, Field, colors, Icon } from '../../../design-system';
import { LBL_AMOUNT } from '../locale';
import { useDevice } from '../../common/hooks/useDevice';
import { TGiftCardErrors } from '../utils';
import { TGiftCardInfos } from './Product';

type Props = {
  objectID: string;
  isWishlist?: boolean;
  isQuickAdd?: boolean;
  onAmountChange: (amount: string) => void;
  noAmountSelected: boolean;
  giftCardAmount: string;
  giftCardErrMsg: TGiftCardErrors;
  giftCardInfos: TGiftCardInfos;
  onGiftCardInfo: (giftCardInfos: TGiftCardInfos) => void;
  onIconClick?: (value: boolean) => void;
};

export default function AmountSelector({
  isWishlist,
  objectID,
  isQuickAdd,
  onAmountChange,
  noAmountSelected,
  giftCardAmount,
  giftCardErrMsg,
  giftCardInfos,
  onGiftCardInfo,
  onIconClick = () => null,
}: Props) {
  const { isMobile } = useDevice();
  const amountList = [30, 50, 80, 100, 150, 200];
  const [selectedAmount, setSelectedAmount] = useState(
    giftCardAmount !== '' ? parseInt(giftCardAmount, 10) : 0
  );

  const [giftCardCustomAmount, setGiftCardCustomAmount] = useState(
    giftCardInfos.isAnotherAmount ? giftCardInfos.giftCardAmount : ''
  );
  const [isErrorCustomAmount, setIsErrorCustomAmount] = useState(false);
  const isAnotherAmount = giftCardCustomAmount !== '';
  const reggexForCustomAmount = /^[0-9]+([.,][0-9]{1,2})?$/;
  const handleAmountClick = (clickedAmount: number) => {
    setIsErrorCustomAmount(false);
    setGiftCardCustomAmount('');
    setSelectedAmount(clickedAmount);
    onGiftCardInfo({
      ...giftCardInfos,
      isAnotherAmount: false,
      giftCardAmount: clickedAmount.toString(10),
    });
    onAmountChange(clickedAmount.toString());
  };

  const handleCustomAmount = (customAmount: string) => {
    if (!reggexForCustomAmount.test(customAmount) && customAmount.length !== 0) {
      return;
    }
    if (giftCardCustomAmount === '' && customAmount === '0') {
      return;
    }
    if (customAmount.length > 6) {
      return;
    }
    setGiftCardCustomAmount(customAmount);
    onGiftCardInfo({
      ...giftCardInfos,
      isAnotherAmount: true,
      giftCardAmount: customAmount,
    });

    if (parseFloat(customAmount) < 1 || parseFloat(customAmount) > 300) {
      setIsErrorCustomAmount(true);
    } else {
      setIsErrorCustomAmount(false);
    }
  };

  useEffect(() => {
    if (
      parseFloat(giftCardInfos.giftCardAmount) < 1 ||
      parseFloat(giftCardInfos.giftCardAmount) > 300
    ) {
      setIsErrorCustomAmount(true);
    } else {
      setIsErrorCustomAmount(false);
    }
  }, []);

  useEffect(() => {
    if (noAmountSelected || isAnotherAmount) {
      setSelectedAmount(0);
    }
  }, [noAmountSelected, isAnotherAmount]);

  return (
    <>
      {isMobile ? (
        <Box
          display="grid"
          alignItems="center"
          justifyContent="left"
          gridAutoColumns="1fr"
          mb={isWishlist ? 'm' : 'na'}
        >
          <Box
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            gridGap="xs"
            justifyContent="space-between"
          >
            <Box
              display="grid"
              gridAutoFlow="column"
              justifyContent="left"
              alignItems="center"
              gridGap="xs"
            >
              {isWishlist ? (
                <Text whiteSpace="pre-wrap" preset="subheading" fontWeight="bold" mr="s">
                  {LBL_AMOUNT}
                </Text>
              ) : (
                <>
                  <Text whiteSpace="pre-wrap">{`${LBL_AMOUNT} : `}</Text>
                  {giftCardErrMsg?.amount && !selectedAmount && !isAnotherAmount && (
                    <Text color={colors.ERROR}>{giftCardErrMsg.amount}</Text>
                  )}
                </>
              )}

              {!isWishlist && (Boolean(selectedAmount) || isAnotherAmount) && (
                <Text fontWeight={700} id={`product-modal-${objectID}-size-label`}>
                  {isAnotherAmount ? giftCardCustomAmount + '€' : selectedAmount + '€'}
                </Text>
              )}
            </Box>
            <Box onClick={() => onIconClick(false)}>
              <Icon name="close" size={12} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="grid"
          alignItems="center"
          justifyContent="left"
          gridAutoFlow="column"
          gridTemplateColumns="auto 1fr auto"
          mb={isWishlist ? 'm' : 'na'}
        >
          {isWishlist ? (
            <Text whiteSpace="pre-wrap" preset="subheading" fontWeight="bold" mr="s">
              {LBL_AMOUNT}
            </Text>
          ) : (
            <>
              <Text whiteSpace="pre-wrap">{`${LBL_AMOUNT} : `}</Text>
              {giftCardErrMsg?.amount && <Text color={colors.ERROR}>{giftCardErrMsg.amount}</Text>}
            </>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="start"
        overflowY={isMobile ? 'auto' : 'initial'}
        maxHeight={isMobile ? (isQuickAdd ? '168px' : '240px') : 'initial'}
        gridGap="7px"
      >
        {amountList.map((amount, index) => {
          const isSelected = amount === selectedAmount;
          return (
            <div key={index} id={`product-modal-${objectID}-size-${index}`}>
              <ButtonSize
                id={`product-modal-${objectID}-size-${index}-button`}
                isSelected={isSelected}
                onClick={() => handleAmountClick(amount)}
                data-testid={`select-amount-button-giftcard-${index}`}
              >
                {amount + '€'}
              </ButtonSize>
            </div>
          );
        })}
      </Box>
      {isMobile && (
        <Box display="flex" alignItems={'center'} mb={'m'}>
          <Box mr={'1rem'}>
            <Text>Je veux un montant différent</Text>
            <Text color={colors.GREY}>Entre 1€ et 300€</Text>
          </Box>
          <Box flex={1}>
            <Field
              id="quantity"
              type="number"
              min="1"
              max="300"
              step="0.01"
              label="ex. 52,50€"
              isAPrice
              value={
                giftCardErrMsg.customAmount && !selectedAmount
                  ? giftCardInfos.giftCardAmount
                  : giftCardCustomAmount
              }
              onChange={(e) => handleCustomAmount(e)}
              errMsg={isErrorCustomAmount ? 'Vous devez choisir un montant entre 1€ et 300€.' : ''}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
