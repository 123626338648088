import * as React from 'react';

import { Box, Anchor, Text, Button } from '../../../design-system';
import paths from '../../routing/paths';
import { Store as StoreType } from '../types';
import OpeningHours from './OpeningHours';
import { KNOW_MORE } from '../../home/locale';

export type Props = {
  store: StoreType;
  push(path: string): void;
};

const InfoWindow = ({ store }: Props) => {
  const { id, name, routableCoordinate, displayCoordinate, hours, address } = store ?? {};
  const { line1, line2, postalCode, city } = address ?? {};
  let isDelivery = false;

  if (window) {
    isDelivery = window.location.pathname !== paths.STORE_LOCATOR;
  }

  return (
    <Box display="grid" gridGap="s" gridAutoRows="min-content" maxWidth="300px">
      <Text id={`store-info-window-${id}-name`} preset="subheading" textTransform="uppercase">
        {name ?? ''}
      </Text>
      <Text id={`store-info-window-${id}-address`} preset="caption" color="GREY">
        {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
      </Text>
      <OpeningHours hours={hours ?? {}} isShortenedVersion isBigSize />
      <Box display="grid" gridAutoColumns="1fr 1fr" gridAutoFlow="column" gridGap="s">
        <Anchor
          id="info-window-go-to-store-page-anchor"
          decoration="none"
          href={`${paths.STORE_LOCATOR}/${id}`}
          target={isDelivery ? '_blank' : '_self'}
        >
          <Button id="info-window-go-to-store-page-btn">
            <Text color="WHITE" mx="-8px">
              {KNOW_MORE}
            </Text>
          </Button>
        </Anchor>

        <Anchor
          id="info-window-get-directions-anchor"
          target="_blank"
          decoration="none"
          href={`https://www.google.com/maps/dir/?api=1&destination=${
            routableCoordinate?.latitude || displayCoordinate?.latitude
          },${routableCoordinate?.longitude || displayCoordinate?.longitude}`}
        >
          <Button id="info-window-get-directions-btn" preset="subtle">
            <Text color="GREY">Itinéraire</Text>
          </Button>
        </Anchor>
      </Box>
    </Box>
  );
};

export default InfoWindow;
