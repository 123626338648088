import styled from '@emotion/styled';
import css from '@styled-system/css';

import {
  Box,
  Button,
  Text,
  StyledLink,
  Picture,
  opacities,
  colors,
  Anchor,
} from '../../../design-system';
import { durations, transitions, zIndex } from '../constants';

type TransitionProps = {
  isOpen: boolean;
};

export const OpacityBox = styled(Box)<TransitionProps>(
  {
    opacity: 0,
    transition: transitions.GENERIC,
  },
  ({ isOpen }) =>
    isOpen && {
      opacity: 1,
    }
);

export const SecondDrawer = styled(Box)<TransitionProps>(
  css({
    px: ['m', 'l'],
    bg: 'WHITE',
  }),
  {
    height: '100%',
    width: '100%',
    top: 0,
    position: 'fixed',
    overflow: 'auto',
    right: '-100vw',
    transition: transitions.GENERIC,
  },
  ({ isOpen }) =>
    isOpen && {
      right: 0,
    }
);

export const LineThroughText = styled(Text)({
  textDecoration: 'line-through',
  opacity: opacities.DISABLED,
});

// @ts-ignore
export const ClampText = styled(Text)({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
});

type HorizontalSliderProps = {
  activeIndex: number;
  length: number;
  width: string;
  gridGap: string;
};

export const HorizontalSlider = styled(Box)<HorizontalSliderProps>(
  ({ activeIndex, length, width, gridGap }) => ({
    transform: `translate3d(calc(-1 * ${activeIndex} * (${width} + ${gridGap})), 0px, 0px)`,
    width: `calc(${length} * ${width})`,
    transition: 'transform 0.3s ease-in-out',
  })
);

type CmsBoxProps = {
  withPadding?: boolean;
  isDescription?: boolean;
  withMargin?: boolean;
  withCustomHTag?: boolean;
};

export const CmsBox = styled(Box)<CmsBoxProps>(
  ({ withPadding }) =>
    withPadding
      ? {
          '*': {
            paddingBlockStart: '1.4rem',
            paddingBlockEnd: '1.4rem',
          },
        }
      : {
          '*': {
            paddingBlockStart: 0,
            paddingBlockEnd: 0,
          },
        },
  ({ isDescription }) =>
    isDescription
      ? css({
          p: {
            fontSize: ['xs', 'm'],
            lineHeight: ['xs', 'm'],
          },
        })
      : css({
          p: {
            fontSize: ['s', 's'],
            lineHeight: ['s', 's'],
          },
        }),
  ({ withMargin }) =>
    withMargin
      ? css({
          '*': {
            marginBlockStart: '1em',
            margingBlockEnd: '1em',
          },
          p: {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        })
      : css({
          '*': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        }),
  ({ withCustomHTag }) =>
    withCustomHTag
      ? css({
          h1: {
            fontSize: ['2.6rem', '2.8rem'],
            lineHeight: ['3.0rem', '3.2rem'],
          },
          h2: {
            fontSize: ['2.4rem', '2.6rem'],
            lineHeight: ['2.8rem', '3.0rem'],
          },
          h3: {
            fontSize: ['2.2rem', '2.4rem'],
            lineHeight: ['2.6rem', '2.8rem'],
          },
          h4: {
            fontSize: ['2.0rem', '2.2rem'],
            lineHeight: ['2.4rem', '2.6rem'],
          },
          h5: {
            fontSize: ['1.8rem', '2.0rem'],
            lineHeight: ['2.2rem', '2.4rem'],
          },
          h6: {
            fontSize: ['1.6rem', '1.8rem'],
            lineHeight: ['2.0rem', '2.2rem'],
          },
        })
      : css({
          h1: {
            fontSize: ['xxxl', 'h'],
            lineHeight: ['xxxl', 'h'],
          },
          h2: {
            fontSize: ['xxl', 'xxxl'],
            lineHeight: ['xxl', 'xxxl'],
          },
          h3: {
            fontSize: ['xl', 'xxl'],
            lineHeight: ['xl', 'xxl'],
          },
          h4: {
            fontSize: ['l', 'xl'],
            lineHeight: ['l', 'xl'],
          },
          h5: {
            fontSize: ['m', 'l'],
            lineHeight: ['m', 'l'],
          },
          h6: {
            fontSize: ['s', 'm'],
            lineHeight: ['s', 'm'],
          },
        }),
  css({
    p: {
      fontSize: ['xs', 's'],
      lineHeight: ['xs', 's'],
      paddingBlockStart: 0,
      paddingBlockEnd: 0,
    },
    a: {
      textDecoration: 'underline',
      color: 'inherit',
      transition: `opacity ${durations.ENTER}ms ease-in-out`,
      '@media(hover: hover)': {
        ':hover': {
          opacity: opacities.HOVERED,
        },
      },
    },
    'strong, strong > *': {
      fontWeight: 700,
      fontFamily: 'inherit',
    },
    em: {
      fontWeight: 'inherit',
    },
    '.alt-font, .alt-font > *': {
      fontFamily: '"Ogg-text", serif',
      fontWeight: '700',
    },
    '.line-break': {
      paddingBlockStart: '0.7rem',
      paddingBlockEnd: '0.7rem',
    },
    '.caption': {
      fontSize: 'xs',
      lineHeight: 'xs',
    },
    '.MaisonNeue-12pt': {
      fontSize: 'xs',
      lineHeight: 'xs',
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-14pt': {
      fontSize: 's',
      lineHeight: 's',
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-16pt': {
      fontSize: ['s', 'm'],
      lineHeight: ['s', 'm'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-20pt': {
      fontSize: ['m', 'l'],
      lineHeight: ['m', 'l'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-30pt': {
      fontSize: ['l', 'xl'],
      lineHeight: ['l', 'xl'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-40pt': {
      fontSize: ['xl', 'xxl'],
      lineHeight: ['xl', 'xxl'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-50pt': {
      fontSize: ['xxl', 'xxxl'],
      lineHeight: ['xxl', 'xxxl'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.MaisonNeue-80pt': {
      fontSize: ['xxxl', 'h'],
      lineHeight: ['xxxl', 'h'],
      fontWeight: 'inherit',
      fontFamily: 'MaisonNeue',
    },
    '.Ogg-text-12pt': {
      fontSize: 'xs',
      lineHeight: 'xs',
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-14pt': {
      fontSize: 's',
      lineHeight: 's',
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-16pt': {
      fontSize: ['s', 'm'],
      lineHeight: ['s', 'm'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-20pt': {
      fontSize: ['m', 'l'],
      lineHeight: ['m', 'l'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-30pt': {
      fontSize: ['l', 'xl'],
      lineHeight: ['l', 'xl'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-40pt': {
      fontSize: ['xl', 'xxl'],
      lineHeight: ['xl', 'xxl'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-50pt': {
      fontSize: ['xxl', 'xxxl'],
      lineHeight: ['xxl', 'xxxl'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    '.Ogg-text-80pt': {
      fontSize: ['xxxxxl', 'h'],
      lineHeight: ['xxxxxl', 'h'],
      fontWeight: 'inherit',
      fontFamily: 'Ogg-text',
    },
    ul: {
      paddingInlineStart: '16px',
      mr: 's',
    },
    ol: {
      listStyle: 'disc',
      marginLeft: '-16px',
      paddingBlockStart: 0,
      paddingBlockEnd: 0,
    },
    li: {
      fontSize: 's',
      lineHeight: 's',
      paddingBlockStart: '0.8rem',
      paddingBlockEnd: '0.8rem',
    },
  })
);

export const BannerBox = styled(CmsBox)(
  css({
    '*': {
      paddingBlockStart: '0.4rem',
      paddingBlockEnd: '0.4rem',
    },
  })
);

type ColorTextProps = {
  cmsColor?: string;
};

export const ColorText = styled(Text)<ColorTextProps>(
  ({ cmsColor }) =>
    cmsColor && {
      color: cmsColor,
    }
);

type ColorButtonProps = {
  cmsColor?: string;
  fontColor?: string;
};

export const ColorButton = styled(Button)<ColorButtonProps>(
  ({ cmsColor }) =>
    cmsColor && {
      background: cmsColor,
    },
  ({ fontColor }) =>
    fontColor && {
      color: fontColor,
    }
);

export const LinkText = styled(Text)<ColorTextProps>(
  css({
    borderBottom: '1px solid BLACK',
    paddingBottom: 'xs',
  }),
  ({ cmsColor }) =>
    cmsColor && {
      color: cmsColor,
      borderBottom: `1px solid ${cmsColor}`,
    }
);

export const HomeLink = Anchor.withComponent(Text);

export const ImageWrapperLink = styled(StyledLink)({
  ':hover': {
    opacity: 1,
  },
});

const fragmentCMSMargin = {
  margin: 0,
  '*': { margin: 0 },
  p: {
    marginBottom: 'm',
  },
  a: {
    ':hover': {
      opacity: 0.7,
    },
  },
};

export const H1 = styled(Text.withComponent('h1'))(css(fragmentCMSMargin));
export const H2 = styled(Text.withComponent('h2'))(css(fragmentCMSMargin));
export const Div = styled(Text.withComponent('div'))(css(fragmentCMSMargin));

export const CmsDiv = styled(Div)(
  css({
    p: {
      marginBottom: 's',
    },
  })
);

export const H1Affiliate = styled(H1)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['30px', '40px', '50px'],
    lineHeight: ['130%', '110%', '110%'],
  })
);

export const H1Catalog = styled(H1)(
  css({
    fontSize: ['20px', '30px'],
    fontFamily: '"Ogg-text", serif',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '1.1',
    textAlign: 'center',
  })
);

export const Title = styled(H2)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['l', 'l', 'xl'],
    lineHeight: ['l', 'l', 'xl'],
  })
);

export const SubTitle = styled(Text)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['m', 'm', 'l'],
    lineHeight: ['m', 'm', 'l'],
  })
);

type ToolTipProps = {
  reverse?: boolean;
  isLeftAligned?: boolean;
};

export const ToolTip = styled(Box)<ToolTipProps>(
  {
    position: 'relative',
    '> div': {
      display: 'none',
      position: 'absolute',
      top: '28px',
      left: '50%',
      background: colors.BACKGROUND,
      border: `1px solid ${colors.LIGHT}`,
      borderRadius: '3px',
      padding: '4px 8px',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      zIndex: zIndex.TOOLTIP,
    },
    '@media (hover: hover)': {
      ':hover': {
        '> div': {
          display: 'block',
          ':before': {
            zIndex: zIndex.TOOLTIP,
            top: '-11px',
            content: '""',
            position: 'absolute',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${colors.LIGHT}`,
          },
          ':after': {
            zIndex: zIndex.TOOLTIP + 1,
            content: '""',
            position: 'absolute',
            top: '-10px',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${colors.BACKGROUND}`,
          },
        },
      },
    },
  },
  ({ reverse }) =>
    reverse && {
      '> div': {
        top: '-36px',
      },
      '@media (hover: hover)': {
        ':hover': {
          '> div': {
            ':before': {
              transform: 'rotateX(180deg)',
              top: '24px',
            },
            ':after': {
              transform: 'rotateX(180deg)',
              top: '23px',
            },
          },
        },
      },
    },
  ({ isLeftAligned }) =>
    isLeftAligned && {
      '>div': {
        left: '0',
        transform: 'translateX(0%)',
      },
      '@media (hover: hover)': {
        ':hover': {
          '> div': {
            ':before': {
              left: '0',
              marginLeft: '4px',
            },
            ':after': {
              left: '0',
              marginLeft: '4px',
            },
          },
        },
      },
    }
);

type HoverPictureProps = {
  isHovered: boolean;
};

export const HoverPicture = styled(Picture)<HoverPictureProps>(
  css({
    display: ['none', 'block'],
    'source, img': {
      opacity: 0,
      zIndex: zIndex.PRODUCT_ALT_IMAGE,
      transition: transitions.PRODUCT_ALT_IMAGE,
    },
  }),
  ({ isHovered }) =>
    isHovered && {
      'source, img': {
        opacity: 1,
      },
    }
);

export const SearchField = styled(Box)(
  css({
    ':hover': {
      bg: 'LIGHT2',
    },
    ' > input': {
      ':focus': {
        outline: 'none',
      },
      border: 'none',
      height: '100%',
      width: '100%',
      bg: 'inherit',
      paddingLeft: ['na', 's'],
      color: 'BLACK',
      fontSize: ['m', 'm', 's'],
      '::placeholder': {
        fontSize: ['m', 'm', 's'],
      },
    },
    display: 'flex',
    alignItems: 'center',
    height: 'xxl',
    bg: 'BACKGROUND',
    pl: 'm',
    py: 's',
    color: 'GREY2',
  })
);

export const SearchButton = styled(Button)(
  css({
    height: ['xxl', 'xxl'],
    width: ['xxl', '120px'],
  })
);
