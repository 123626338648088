import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

import { Box, Icon } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';
import { StorePhotoGallery as StorePhotoGalleryType } from '../types';

type Props = {
  photoGallery: StorePhotoGalleryType[];
};
const StorePhotoGallery = ({ photoGallery = [] }: Props) => {
  const { isMobile } = useDevice();
  const { store_image } = useSelector((state: RootState) => state.cms.storelocator);

  const gallery: StorePhotoGalleryType[] = photoGallery.length
    ? photoGallery
    : Array.from(Array(1));

  const [activeIndex, setActiveIndex] = React.useState(0);
  const onClickLeft = () => {
    setActiveIndex(activeIndex - 1);
  };

  const onClickRight = () => {
    setActiveIndex(activeIndex + 1);
  };

  return (
    <>
      {gallery.map((image, index) => {
        const url = image?.url || store_image.url || '';
        const width = Number(image?.width) || store_image.dimensions?.width || 1;
        const height = Number(image?.height) || store_image.dimensions?.height || 0;
        return (
          <Box
            position="relative"
            key={index}
            backgroundImage={`url(${url})`}
            backgroundRepeat="no-repeat"
            backgroundPosition="initial"
            backgroundSize="cover"
            width="100%"
            height={isMobile ? `calc(100vw * (${height / width}))` : 'calc(100% - 50px)'}
            maxWidth={width}
            display={activeIndex === index ? 'block' : 'none'}
          >
            {gallery.length > 1 && (
              <Box
                id="store-photo-gallery-chevron-left"
                position="absolute"
                display="flex"
                top="0"
                bottom="0"
                left="0"
                width="40px"
                onClick={onClickLeft}
                alignItems="center"
                justifyContent="center"
                background="linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 200%)"
                pointerEvents={activeIndex === 0 ? 'none' : 'auto'}
                opacity={activeIndex === 0 ? 0.4 : 1}
              >
                <Icon name="chevronLeft" color="WHITE" size={24} />
              </Box>
            )}

            {gallery.length > 1 && (
              <Box
                id="store-photo-gallery-chevron-right"
                position="absolute"
                display="flex"
                top="0"
                bottom="0"
                right="0"
                width="40px"
                onClick={onClickRight}
                alignItems="center"
                justifyContent="center"
                background="linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 200%)"
                pointerEvents={activeIndex === gallery.length - 1 ? 'none' : 'auto'}
                opacity={activeIndex === gallery.length - 1 ? 0.4 : 1}
              >
                <Icon name="chevronRight" color="WHITE" size={24} />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default StorePhotoGallery;
