import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { DynamicButtonStatus } from '../../design-system';
import types from './actionTypes';
import { Callback, FocusCallback, FeedbackCallback, CtaStateCallback, Forms } from './types';
import { durations } from '../common/constants';

export const setFormValues = (payload: Callback) => action(types.SET_FORM_VALUES, payload);
export const setFormValidation = (payload: Callback) => action(types.SET_FORM_VALIDATION, payload);
export const setFocus = (payload: FocusCallback) => action(types.SET_FOCUS, payload);
export const setFeedback = (payload: FeedbackCallback) => action(types.SET_FEEDBACK, payload);
export const setCtaState = (payload: CtaStateCallback) => action(types.SET_CTA_STATE, payload);

export type FormActions = ActionType<
  | typeof setFormValues
  | typeof setFormValidation
  | typeof setFocus
  | typeof setFeedback
  | typeof setCtaState
>;

export function handleDynamicButton(form: Forms, f: Function, args: any[]) {
  return async (dispatch: Dispatch<ActionType<typeof setCtaState | typeof setFeedback>>) => {
    dispatch(setCtaState({ form, ctaState: DynamicButtonStatus.Loading }));
    try {
      await f(...args);
      dispatch(setCtaState({ form, ctaState: DynamicButtonStatus.Success }));
    } catch (error) {
      dispatch(setCtaState({ form, ctaState: DynamicButtonStatus.Error }));
      dispatch(setFeedback({ form, ok: false, message: error.code }));
      const err = new Error();
      err.name = 'Forgot/Reset Password Error';
      err.message = error.code;
      datadogLogs.logger.error(err.name, err);
    }
    setTimeout(
      () => dispatch(setCtaState({ form, ctaState: DynamicButtonStatus.Default })),
      durations.BUTTON_RESET_DELAY
    );
  };
}
