import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { InfoPanel } from './InfoPanel';
import { Images } from './Images';
import { CatalogProduct } from '../types';
import { Color } from '../../product/types';
import { getIndexName, analyticsEvent } from '../../search/utils';
import { AnalyticsEventTypes, AnalyticsEvents } from '../../search/types';
import { setLastPositionId, setAlgoliaQuery } from '../actions';
import { closeModal, openModal } from '../../common/actions';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { pushToGTM, getTrackingProduct } from '../../tracking';
import { Events } from '../../tracking/types';
import { trackRecommendation } from '../../recommendations/utils';
import { CmsImage } from '../../cms/types';
import { getQuickAddModalPreset } from '../../quickadd/utils';

type Props = {
  hit: CatalogProduct;
  indexName?: string;
  isRecommendation?: boolean;
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  trackingId?: string;
  width?: string;
  alternativeSource?: CmsImage;
  reverseToolTip?: boolean;
  leftAlignToolTip?: boolean;
};

const StyledProductCard = styled.div<{ isRecommendation: boolean; width: string }>`
  grid-column: ${(props) => (props.isRecommendation ? 'initial' : 'span 2')};
  width: ${(props) => props.width};
  scroll-margin: 150px;
`;

export const ProductCard = ({
  hit,
  indexName,
  isRecommendation = false,
  isFeedback,
  isDesktopFeedback,
  trackingId,
  width = 'initial',
  alternativeSource,
  reverseToolTip = false,
  leftAlignToolTip = false,
}: Props) => {
  const {
    productRef,
    colorRef,
    productName,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    colorVariants,
    promotionPercentage,
    promotionLabel,
    promoBColor,
    promoTColor,
    isOutlet,
    objectID,
    typology,
    collectionName,
    sizeVariants,
    __position: position,
    __queryID: queryID,
  } = hit ?? {};

  const [selectedColor, setSelectedColor] = React.useState<Color>({
    colorRef,
    colorLabel,
    detailedColorLabel,
    realColorRef,
    originalPrice,
    storePrice,
    promotionPercentage,
    promotionLabel,
    promoBColor,
    promoTColor,
    isOutlet,
  });

  const [firstColorRef] = React.useState(selectedColor.colorRef);
  const [firstSelectedColor] = React.useState({
    colorRef: firstColorRef,
    originalPrice,
    storePrice,
  });

  const [showMobileQuickAdd, setShowMobileQuickAdd] = React.useState(false);

  const handleColorClick = (color: Color) => {
    setSelectedColor(color);
  };

  const dispatch = useDispatch();

  const { search, pathname } = useLocation();

  const { isMobile, isTablet } = useMediaQueries();

  const elementID = `product-card-${objectID}`;

  const index = indexName || getIndexName(pathname, search);

  const onLinkClick = () => {
    analyticsEvent({
      queryID,
      objectID,
      position,
      index,
      type: AnalyticsEventTypes.click,
      eventName: AnalyticsEvents.clickItem,
    });
    dispatch(setLastPositionId(elementID));
    dispatch(setAlgoliaQuery({ queryID, index }));
    trackRecommendation(trackingId);
  };

  const onQuickAddClick = () => {
    if (isMobile) {
      setShowMobileQuickAdd(true);
    } else {
      dispatch(closeModal());
      dispatch(
        openModal({
          content: (
            <QuickAdd
              productRef={productRef}
              colorRef={selectedColor.colorRef}
              originalSelectedColor={colorRef}
            />
          ),
          preset: getQuickAddModalPreset({ sizeVariants: sizeVariants ?? [], isTablet }),
        })
      );
    }

    analyticsEvent({
      index,
      queryID,
      objectID,
      position,
      type: AnalyticsEventTypes.click,
      eventName: AnalyticsEvents.clickQuickAdd,
    });

    dispatch(setAlgoliaQuery({ queryID, index }));

    pushToGTM(Events.quickAddOpen, {
      product: getTrackingProduct({ product: hit }),
    });

    trackRecommendation(trackingId);
  };

  return !hit ? null : (
    <>
      <StyledProductCard
        id={`catalog-${elementID}`}
        isRecommendation={isRecommendation}
        width={width}
      >
        <Images
          productName={productName}
          productRef={productRef}
          objectID={objectID}
          selectedColor={selectedColor}
          onLinkClick={onLinkClick}
          onQuickAddClick={onQuickAddClick}
          isFeedback={isFeedback}
          isDesktopFeedback={isDesktopFeedback}
          alternativeSource={
            firstColorRef === selectedColor.colorRef ? alternativeSource : undefined
          }
        />
        <InfoPanel
          colorVariants={colorVariants}
          productRef={productRef}
          productName={productName}
          objectID={objectID}
          selectedColor={selectedColor}
          firstSelectedColor={firstSelectedColor}
          handleColorClick={handleColorClick}
          typology={typology}
          collectionName={collectionName}
          isFeedback={isFeedback}
          onLinkClick={onLinkClick}
          isDesktopFeedback={isDesktopFeedback}
          reverseToolTip={reverseToolTip}
          leftAlignToolTip={leftAlignToolTip}
        />
      </StyledProductCard>
      {!isFeedback && (
        <QuickAddMobile
          productRef={productRef}
          originalSelectedColor={colorRef}
          colorRef={selectedColor.colorRef}
          isActive={showMobileQuickAdd}
          onClose={() => setShowMobileQuickAdd(false)}
        />
      )}
    </>
  );
};
