export const TOP_TEN_PRODUCT_CARD_MIN_WIDTH = 268;
export const TOP_TEN_PAGE_MAX_WIDTH = 1440;
export const TOP_TEN_DOT_WIDTH = 16;
export const TOP_TEN_DOT_HEIGHT = 16;
export const TOP_TEN_DOT_TRANSPARENT_GAP = 8;
export const TOP_TEN_SUGGESTIONS_CTA_TABLET_WIDTH = 186;
export const TOP_TEN_CTA_BLOCK_TEXT = 'VOIR LA COLLECTION';

export const getParagraphNumberText = (index: number): string => {
  return `\u2116${index + 1}`;
};

export const checkIfSliderNeedToBeObserved = ({
  isDataLoaded,
  isMobile,
  slider_with_number_is_active_mobile,
}: {
  isDataLoaded: boolean;
  isMobile: boolean;
  slider_with_number_is_active_mobile: boolean | undefined;
}): boolean => {
  return Boolean(isDataLoaded && isMobile && slider_with_number_is_active_mobile);
};
