import * as React from 'react';
import { UnknownAction } from 'redux';

import {
  DynamicButton,
  Field,
  PasswordField,
  Text,
  Box,
  Checkbox,
  DynamicButtonStatus,
  Anchor,
  Alert,
  StyledLink,
  breakpoints,
} from '../../../design-system';
import {
  CTA_CONTENT_SIGNUP,
  NOTE_PASSWORD,
  MSG_NEWSLETTER_CHECKBOX,
  PREFILL_HASH,
  AUTH_BAR_SIGNUP_TITLE,
  AUTH_BAR_TITLE,
  ERR_AUTH,
  ERR_ACCOUNT_CREATION,
} from '../locale';
import withForm from '../../form';
import { Forms, FormSignUp, FieldCallback } from '../../form/types';
import { WithFormActions } from '../../form/hocs/withForm';
import { ParamsSignUp } from '../types';
import { isCtaActive, validate } from '../../form/utils';
import SocialLogin from './SocialLogin';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { useLocation } from 'react-router-dom';
import { checkEmail, errorSignup } from '../actions';
import { changeTitle } from '../../common/actions';
import styled from '@emotion/styled';
import { theme } from '../../../design-system/systemprovider/constants';

type Props = {
  signup: (params: ParamsSignUp) => void;
  email: string;
  errSignup: string;
  errSocial: string;
  form: FormSignUp;
} & WithFormActions;

const StyledDiv = styled.div`
  display: grid;
  gap: ${theme.space.l};
  .checkbox-content__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-block-start: 0;
    margin-block-end: 0;
    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`;

export const SignUp = ({
  signup,
  errSignup,
  errSocial,
  form,
  setFormValues,
  setFormValidation,
  email,
}: Props) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();

  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);
  const { eReservation } = useSelector((state: RootState) => state.form);
  const { ok, message } = useSelector((state: RootState) => state.form.socialLogin.feedback);

  React.useEffect(() => {
    if (hash === PREFILL_HASH) {
      const userContact = {
        firstName: eReservation?.values?.firstName || '',
        lastName: eReservation?.values?.lastName || '',
        email: eReservation?.values?.email || '',
      };
      setFormValidation({
        form: Forms.signUp,
        values: Object.keys(userContact).reduce((acc, cVal) => {
          return { ...acc, [cVal]: validateField({ key: cVal, value: userContact[cVal] }) };
        }, {}),
      });

      setFormValues({
        form: Forms.signUp,
        values: Object.keys(userContact).reduce((acc, cVal) => {
          return { ...acc, [cVal]: userContact[cVal] };
        }, {}),
      });
    }
  }, [hash]);

  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({ form: Forms.signUp, values: { [key]: validateField({ key, value }) } });
    }

    setFormValues({ form: Forms.signUp, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signUp, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = () => {
    const { password, firstName, lastName, optInEmail } = form.values;
    if (validateForm()) {
      signup({
        email,
        password,
        firstName,
        lastName,
        optInEmail,
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const onOptInClick = () => {
    setFormValues({
      form: Forms.signUp,
      values: {
        optInEmail: !form.values.optInEmail,
      },
    });
  };

  React.useEffect(() => {
    handleChange({ key: 'email', value: email });
  }, [email]);

  React.useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_SIGNUP_TITLE));
    return () => {
      handleChange({ key: 'firstName', value: '' });
      handleChange({ key: 'lastName', value: '' });
      handleChange({ key: 'password', value: '' });
      setFormValidation({
        form: Forms.signUp,
        values: { firstName: '', lastName: '', password: '' },
      });
    };
  }, []);

  return (
    <StyledDiv>
      {errSignup && (
        <Alert type="error" title="Erreur">
          <Text id="signup-error" preset="caption" color={'#5A5A5A'}>
            {errSignup === ERR_AUTH ? (
              <>
                {ERR_ACCOUNT_CREATION}
                <StyledLink id={'customer-service-error-link'} to={'/contact'} preset="caption">
                  ici.
                </StyledLink>
              </>
            ) : (
              errSignup
            )}
          </Text>
        </Alert>
      )}
      {message && (
        <Alert type={ok ? 'success' : 'error'} title={ok ? 'Connexion' : 'Erreur'}>
          <Text id="social-login-alert" preset="caption" color={'#5A5A5A'}>
            {message}
          </Text>
          {errSocial && (
            <Text id="error-social-login" preset="caption" color={'#5A5A5A'}>
              {errSocial}
            </Text>
          )}
        </Alert>
      )}
      <Box display={'flex'}>
        <Text paddingRight={16} color={'#5A5A5A'}>
          {email}
        </Text>
        <Anchor
          id="modify-email-link"
          type="button"
          preset="body"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeTitle(AUTH_BAR_TITLE));
            dispatch(checkEmail(null) as unknown as UnknownAction);
            dispatch(errorSignup(''));
          }}
        >
          Modifier
        </Anchor>
      </Box>
      <Field
        onChange={(value) => handleChange({ key: 'firstName', value })}
        errMsg={form.validation.firstName}
        value={form.values.firstName}
        label="Prénom*"
        id="signup-firstName"
        autoComplete="given-name"
      />
      <Field
        onChange={(value) => handleChange({ key: 'lastName', value })}
        errMsg={form.validation.lastName}
        value={form.values.lastName}
        label="Nom*"
        id="signup-lastName"
        autoComplete="family-name"
      />
      <PasswordField
        onChange={(value) => handleChange({ key: 'password', value })}
        onKeyPress={handleKeyPress}
        errMsg={form.validation.password}
        value={form.values.password}
        note={NOTE_PASSWORD}
        id="signup-password"
        autoComplete="new-password"
      />
      <Checkbox id="signup-optin-email" checked={form.values.optInEmail} onChange={onOptInClick}>
        <div className="checkbox-content__container">
          <p className="checkbox-content__description">{MSG_NEWSLETTER_CHECKBOX}</p>
        </div>
      </Checkbox>
      <Box display="flex" justifyContent="center">
        <DynamicButton
          id="btn-submit-signup"
          onClick={handleSubmit}
          data={CTA_CONTENT_SIGNUP}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </Box>
      <SocialLogin title="ou inscrivez-vous avec" />
    </StyledDiv>
  );
};

export default withForm(SignUp, Forms.signUp);
