import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Loadable from '@loadable/component';
import { css } from '@emotion/core';

import { breakpoints } from '../../../design-system';
import { LABEL_PAYMENT_TYPE } from '../locale';
import PaymentError from './PaymentError';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import { RootState } from 'src/shared/store/rootReducer';
import usePrevious from '../../common/hooks/usePrevious';

const DropIn = Loadable(() => import('./DropIn'));

export const Payment = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.cms);

  const prevIsLoaded = usePrevious(isLoading);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    dispatch(
      loadCmsContent(CmsCustomTypes.payment, { isControlled: true }) as unknown as UnknownAction
    );
  }, []);

  React.useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(!isLoading && prevIsLoaded);
    }
  }, [isLoading]);

  return !isLoaded ? null : (
    <>
      <PaymentError />
      <div
        css={css`
          display: grid;
          grid-gap: 16px;
          margin: 32px 0;
          @media (min-width: ${breakpoints.S}px) {
            margin: 16px 0;
          }
          @media (min-width: ${breakpoints.L}px) {
            max-width: 720px;
          }
        `}
      >
        <div
          css={css`
            text-align: center;
            @media (min-width: ${breakpoints.S - 26}px) {
              // to match with ipad mini width
              text-align: left;
            }
          `}
        >
          <p
            css={css`
              font-size: 1.6rem;
              line-height: 2rem;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {LABEL_PAYMENT_TYPE}
          </p>
        </div>
        <DropIn />
      </div>
    </>
  );
};
