import * as React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Link } from 'react-router-dom';

import {
  Box,
  Selector,
  Image,
  ImageContainer,
  Text,
  Anchor,
  colors,
  Icon,
  opacities,
  Divider,
  ProductName,
} from '../../../design-system';
import { CartItem as CartItemType } from '../types';
import {
  getProductImageLink,
  getProductLink,
  productImageWidths,
  getIsMaskB2B,
  isItGiftCard,
} from '../../product/utils';
import { formatPrice } from '../../common/utils';
import { ColorText, LineThroughText } from '../../common/components/Custom';
import { updateQuantity, removeItem, moveToWishlistFromCart, removeGiftCard } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { checkCartItemValidity, isDateCorrect, checkMessageSpaces } from '../utils';
import {
  TXT_CART_ITEM_UNDO,
  MSG_CART_ITEM_REMOVED,
  MSG_CART_ITEM_INVALID,
  LBL_GIFT_ITEM,
  TXT_MOVE_TO_WISHLIST,
  MSG_MOVED_TO_WISHLIST,
} from '../locale';
import { getPromotionColors } from '../../catalog/utils';
import { transitions, durations } from '../../common/constants';
import { LBL_COLOR, LBL_SIZE } from '../../product/locale';
import { useDevice } from '../../common/hooks/useDevice';
import CartGiftCardEdit from './CartGiftCardEdit';
import { SEE_LESS, SEE_MORE } from '../../home/locale';

type Props = {
  id: string;
  item: CartItemType;
  giftItemTextColor: string;
  isAvailable?: boolean;
  isStepListingMobile?: boolean;
};

const CartItem = ({ id, item, giftItemTextColor, isAvailable, isStepListingMobile }: Props) => {
  const dispatch = useDispatch();

  const [showUndo, setShowUndo] = React.useState(false);
  const [hasWaitedForUndo, setHasWaitedForUndo] = React.useState(false);
  const [shouldDisappear, setShouldDisappear] = React.useState(false);

  const [showMove, setShowMove] = React.useState(false);
  const [editGiftCard, setEditGiftCard] = React.useState(false);

  const {
    productRef,
    colorRef,
    sku,
    productName,
    colorLabel,
    detailedColorLabel,
    rowBaseTotal,
    rowTotal,
    quantity,
    size,
    bandSize,
    cupSize,
    promotionLabel,
    promotionPercentage,
    stockQuantity: { stockQuantity },
    freeProduct,
    promoMessages,
    itemCouponAmount,
    promoPrice,
  } = item;

  React.useEffect(() => {
    if (hasWaitedForUndo) {
      if (showUndo) {
        setShouldDisappear(true);
      }
      setHasWaitedForUndo(false);
    }
  }, [hasWaitedForUndo]);

  React.useEffect(() => {
    if (shouldDisappear) {
      if (isGiftCard && item.gcFields) {
        dispatch(removeGiftCard(item.gcFields.itemId, item) as unknown as UnknownAction);
      } else {
        dispatch(removeItem({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
      }
    }
  }, [shouldDisappear]);

  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
  });

  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });

  const isQuantityAvailable = checkCartItemValidity(item);

  const hasPromo = promoPrice !== rowBaseTotal;
  const total = formatPrice(rowTotal);
  const baseTotal = formatPrice(rowBaseTotal);
  const promoAmount = formatPrice(promoPrice ?? 0);
  const couponAmount = `-${formatPrice(itemCouponAmount ?? 0)}`;

  const isMaskB2B = getIsMaskB2B(productRef);
  const optionCount = isMaskB2B
    ? stockQuantity > 75
      ? 75
      : stockQuantity
    : isQuantityAvailable
    ? stockQuantity > 10
      ? quantity > 10
        ? quantity
        : 10
      : stockQuantity
    : quantity;
  const dropdownOptions = [...Array(optionCount).keys()].map((index) => {
    const qty = index + 1;
    return {
      value: String(qty),
      label: String(qty),
      disabled: qty > stockQuantity,
    };
  });

  const productLink = getProductLink({ productRef, colorLabel, colorRef, productName });
  const { isMobile } = useDevice();
  const promoMessageList = (promoMessages ?? []).filter((message) => {
    const messageWithoutSpaces = message.replace(/\s/g, '');
    return Boolean(messageWithoutSpaces);
  });

  const handleDeleteItem = () => {
    if (isAvailable) {
      setShowUndo(true);
      setTimeout(() => {
        setHasWaitedForUndo(true);
      }, durations.UNDO_DELAY);
    } else {
      setShouldDisappear(true);
    }
  };

  const handleUndo = () => {
    setShowUndo(false);
  };

  const handleItemQuantityChange = (newQuantity) => {
    dispatch(
      updateQuantity(
        {
          productRef,
          colorRef,
          sku,
          quantity: newQuantity,
        },
        item
      )
    );
  };

  const handleMoveToWishlist = () => {
    setShowMove(true);
    dispatch(
      moveToWishlistFromCart({ productRef, colorRef, sku }, item) as unknown as UnknownAction
    );
  };

  const showActions = !freeProduct || (freeProduct && !(isQuantityAvailable && isAvailable));
  const isGiftCard = isItGiftCard(productRef);
  const emailGiftCard = item?.gcFields?.email ?? '';
  const dateGiftCard = item?.gcFields?.date ?? '';
  const message = item?.gcFields?.message ?? '';
  const isMessageTooLong = message.length > 60;
  const [showMoreMessage, setShowMoreMessage] = React.useState(false);

  const fragment = {
    giftCard: (
      <>
        <Box
          display="grid"
          gridTemplateAreas="'image info'"
          my="m"
          transition={transitions.GENERIC}
          opacity={showUndo || showMove ? 0 : 1}
          gridGap={['s', 'm']}
          mx={['m', 'na']}
          gridAutoColumns="auto 1fr"
        >
          <Box
            opacity={isAvailable ? 1 : opacities.DISABLED}
            width={['80px', '128px']}
            gridArea="image"
            justifySelf="left"
          >
            <Link id={`link-cart-item-image-${id}-${item.gcFields?.itemId}`} to={productLink}>
              <ImageContainer>
                <Image isAbsolute src={thumbnail} alt="Product Image" />
              </ImageContainer>
            </Link>
          </Box>
          <Box
            gridArea="info"
            display="flex"
            flexDirection="column"
            width="100%"
            pb={window.location.pathname !== '/cart' && isMobile ? 'm' : 'na'}
          >
            <Box display="flex" mt="m" mb="s" justifyContent="space-between">
              <Box display="grid" gridGap="s" gridAutoFlow="column" alignItems="center">
                <Text id={`link-cart-item-name-${id}-${item.gcFields?.itemId}`} preset="caption">
                  <ProductName productName={productName} />
                </Text>
                {(promotionPercentage || promotionLabel) && !isStepListingMobile && (
                  <ColorText
                    display="inline-flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    py="xxs"
                    px="s"
                    cmsColor={textColor}
                    bg={backgroundColor}
                    fontWeight="bold"
                    preset="caption"
                    alignSelf="start"
                  >
                    {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
                  </ColorText>
                )}
              </Box>

              <Box display="flex">
                {(itemCouponAmount ?? 0) > 0 && hasPromo ? (
                  <>
                    <LineThroughText
                      id={`text-cart-item-base-row-total-${id}-${item.gcFields?.itemId}`}
                    >
                      {baseTotal}
                    </LineThroughText>
                    <Text
                      id={`text-cart-item-promo-amount-${id}-${item.gcFields?.itemId}`}
                      preset="caption"
                      color="PROMO"
                    >
                      {promoAmount}
                    </Text>
                    <Text
                      id={`text-cart-item-coupon-amount-${id}-${item.gcFields?.itemId}`}
                      preset="caption"
                      color="PROMO"
                    >
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text
                      id={`text-cart-item-row-total-${id}-${item.gcFields?.itemId}`}
                      fontWeight="bold"
                      color="PROMO"
                    >
                      {total}
                    </Text>
                  </>
                ) : (itemCouponAmount ?? 0) > 0 ? (
                  <>
                    <Text
                      id={`text-cart-item-without-coupon-row-total-${id}-${item.gcFields?.itemId}`}
                    >
                      {baseTotal}
                    </Text>
                    <Text
                      id={`text-cart-item-coupon-amount-${id}-${item.gcFields?.itemId}`}
                      preset="caption"
                      color="PROMO"
                    >
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text
                      id={`text-cart-item-row-total-${id}-${item.gcFields?.itemId}`}
                      fontWeight="bold"
                      color="PROMO"
                    >
                      {total}
                    </Text>
                  </>
                ) : hasPromo ? (
                  <>
                    <LineThroughText
                      id={`text-cart-item-base-row-total-${id}-${item.gcFields?.itemId}`}
                    >
                      {baseTotal}
                    </LineThroughText>
                    <Text
                      id={`text-cart-item-row-total-${id}-${item.gcFields?.itemId}`}
                      fontWeight="bold"
                      color="PROMO"
                    >
                      {total}
                    </Text>
                  </>
                ) : (
                  <Text
                    id={`text-cart-item-row-total-${id}-${item.gcFields?.itemId}`}
                    fontWeight="bold"
                    lineHeight="1.54rem !important"
                  >
                    {total}
                  </Text>
                )}
              </Box>
            </Box>
            {(promotionPercentage || promotionLabel) && isStepListingMobile && (
              <ColorText
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                py="xxs"
                px="s"
                cmsColor={textColor}
                bg={backgroundColor}
                fontWeight="bold"
                preset="caption"
                alignSelf="start"
                mb="m"
              >
                {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
              </ColorText>
            )}
            {!editGiftCard && !isMobile && window.location.pathname === '/cart' && (
              <Box display="flex" justifyContent="space-between">
                <Box
                  border="solid 0.5px black"
                  padding="8px 8px"
                  mb={['s', 'm']}
                  width={['100%', '70%', '50%']}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                      Email du destinataire :
                    </Text>
                    {window.location.pathname === '/cart' && (
                      <Icon name="edit" size={15.4} onClick={() => setEditGiftCard(true)} />
                    )}
                  </Box>
                  <Text
                    fontSize="1.4rem !important"
                    fontWeight="700 !important"
                    lineHeight="1.54rem !important"
                    mb="10px"
                    overflowWrap="break-word"
                  >
                    {emailGiftCard}
                  </Text>
                  <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                    Date d&apos;envoi :
                  </Text>
                  <Text
                    fontSize="1.4rem !important"
                    fontWeight="700 !important"
                    lineHeight="1.54rem !important"
                    mb="10px"
                    color={isDateCorrect(new Date(dateGiftCard)) ? 'BLACK' : 'ERROR'}
                  >
                    {new Date(dateGiftCard).toLocaleDateString('fr-FR')}
                  </Text>
                  {!isDateCorrect(new Date(dateGiftCard)) && (
                    <Text
                      mb="10px"
                      color={'ERROR'}
                      fontSize="1.4rem !important"
                      lineHeight="1.54rem !important"
                    >
                      La date doit être supérieure ou égale à aujourd&apos;hui.
                    </Text>
                  )}
                  <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                    Message :
                  </Text>
                  <Box display="flex" overflow="hidden">
                    <Text
                      fontSize="1.4rem !important"
                      fontWeight="700 !important"
                      lineHeight="1.54rem !important"
                    >
                      {isMessageTooLong && !showMoreMessage
                        ? checkMessageSpaces(message, 45).slice(0, 59) + '...'
                        : checkMessageSpaces(message, 45)}
                      <br />
                      {isMessageTooLong && (
                        <Text
                          display="inline"
                          onClick={() => {
                            setShowMoreMessage(!showMoreMessage);
                          }}
                          color="grey"
                          fontSize="1.4rem !important"
                          lineHeight="1.54rem !important"
                        >
                          {showMoreMessage ? SEE_LESS : SEE_MORE}
                        </Text>
                      )}
                    </Text>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="flex-end"
                  mt={window.location.pathname !== '/cart' ? '20px' : 'na'}
                  mb={window.location.pathname !== '/cart' ? 'na' : 'm'}
                  ml={'s'}
                >
                  <Icon
                    name="trash"
                    id={`btn-cart-item-remove-${id}-${item.gcFields?.itemId}`}
                    onClick={handleDeleteItem}
                  />
                </Box>
              </Box>
            )}
            {!editGiftCard && (isMobile || (!isMobile && window.location.pathname !== '/cart')) && (
              <>
                <Box
                  border="solid 0.5px black"
                  padding="8px 8px"
                  mb={['s', 'm']}
                  mt={window.location.pathname !== '/cart' ? ['s', 'm'] : 'na'}
                  width={window.location.pathname === '/cart' ? ['100%', '423px'] : '100%'}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                      Email du destinataire :
                    </Text>
                    {window.location.pathname === '/cart' && (
                      <Icon name="edit" size={15.4} onClick={() => setEditGiftCard(true)} />
                    )}
                  </Box>
                  <Text
                    fontSize="1.4rem !important"
                    fontWeight="700 !important"
                    lineHeight="1.54rem !important"
                    mb="10px"
                  >
                    {emailGiftCard}
                  </Text>
                  <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                    Date d&apos;envoi :
                  </Text>
                  <Text
                    fontSize="1.4rem !important"
                    fontWeight="700 !important"
                    lineHeight="1.54rem !important"
                    mb="10px"
                    color={isDateCorrect(new Date(dateGiftCard)) ? 'BLACK' : 'ERROR'}
                  >
                    {new Date(dateGiftCard).toLocaleDateString('fr-FR')}
                  </Text>
                  {!isDateCorrect(new Date(dateGiftCard)) && (
                    <Text
                      mb="10px"
                      color={'ERROR'}
                      fontSize="1.4rem !important"
                      lineHeight="1.54rem !important"
                    >
                      La date doit être supérieure ou égale à aujourd&apos;hui.
                    </Text>
                  )}
                  {window.location.pathname === '/cart' && (
                    <>
                      <Text fontSize="1.4rem !important" lineHeight="1.54rem !important" mb="10px">
                        Message :
                      </Text>
                      <Box display="flex" overflow="hidden">
                        <Text
                          fontSize="1.4rem !important"
                          fontWeight="700 !important"
                          lineHeight="1.54rem !important"
                        >
                          {isMessageTooLong && !showMoreMessage
                            ? checkMessageSpaces(message, 30).slice(0, 59) + '...'
                            : checkMessageSpaces(message, 30)}
                          <br />
                          {isMessageTooLong && (
                            <Text
                              display="inline"
                              onClick={() => {
                                setShowMoreMessage(!showMoreMessage);
                              }}
                              color="grey"
                              fontSize="1.4rem !important"
                              lineHeight="1.54rem !important"
                            >
                              {showMoreMessage ? SEE_LESS : SEE_MORE}
                            </Text>
                          )}
                        </Text>
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="flex-end"
                  mt={['s', 'na']}
                  mb={isMobile && window.location.pathname !== '/cart' ? 'na' : 'm'}
                >
                  <Icon
                    name="trash"
                    id={`btn-cart-item-remove-${id}-${item.gcFields?.itemId}`}
                    onClick={handleDeleteItem}
                  />
                </Box>
              </>
            )}
            {!isMobile && editGiftCard && item.gcFields && (
              <CartGiftCardEdit
                item={item}
                gcFields={item.gcFields}
                onCloseEdit={() => setEditGiftCard(false)}
              />
            )}
          </Box>

          {isMobile && <Box display="flex" width="16px"></Box>}
        </Box>
        {isMobile && editGiftCard && item.gcFields && (
          <CartGiftCardEdit
            item={item}
            gcFields={item.gcFields}
            onCloseEdit={() => setEditGiftCard(false)}
          />
        )}
      </>
    ),
    itemCardStepListingMobile: (
      <Box
        display="flex"
        flexDirection="row"
        transition={transitions.GENERIC}
        opacity={showUndo || showMove ? 0 : 1}
        p="m"
      >
        <Box
          opacity={isAvailable ? 1 : opacities.DISABLED}
          width="80px"
          height="106px"
          justifySelf="left"
          mr="s"
        >
          <Link id={`link-cart-item-image-${productRef}-${colorRef}`} to={productLink}>
            <Box
              height="106px"
              width="80px"
              backgroundImage={`url(${thumbnail})`}
              backgroundSize="cover"
            ></Box>
          </Link>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
            <Box pr="s" display="flex" flexDirection="column" width="75%">
              <Text
                id={`link-cart-item-name-${productRef}-${colorRef}`}
                preset="caption"
                minHeight="32px"
              >
                <ProductName productName={productName} />
              </Text>
              {(promotionPercentage || promotionLabel || promoMessageList[0]) && (
                <Box display="flex" flexDirection="row" mb="xs">
                  {(promotionPercentage || promotionLabel) && (
                    <ColorText
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      py="xxs"
                      px="s"
                      mr="xs"
                      cmsColor={textColor}
                      bg={backgroundColor}
                      fontWeight="bold"
                      preset="caption"
                    >
                      {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
                    </ColorText>
                  )}
                  {promoMessageList[0] && (
                    <Text
                      preset="caption"
                      color="PROMO"
                      fontWeight="bold"
                      lineHeight="20px !important"
                    >
                      {promoMessageList[0]}
                    </Text>
                  )}
                </Box>
              )}
              {!promotionPercentage && !promotionLabel && !promoMessageList[0] && (
                <Box width="100%" height="8px"></Box>
              )}
              <Box alignSelf="start" justifySelf="left" display="flex" flexWrap="wrap">
                <Text id={`text-cart-item-color-${id}`} preset="caption" whiteSpace="nowrap">
                  {LBL_COLOR} :{' '}
                  <Text display="inline-block" fontWeight="700" preset="caption">
                    {detailedColorLabel}
                  </Text>
                </Text>
                <Text display={['block', 'none']} preset="caption" mx="xs">
                  |
                </Text>
                <Text id={`text-cart-item-size-${id}`} preset="caption" whiteSpace="nowrap">
                  {LBL_SIZE} :{' '}
                  <Text display="inline-block" fontWeight="700" preset="caption">
                    {size || `${bandSize} ${cupSize}`}
                  </Text>
                </Text>
              </Box>
              {!promotionPercentage && !promotionLabel && !promoMessageList[0] && (
                <Box width="100%" height="16px"></Box>
              )}
            </Box>

            {freeProduct ? (
              <Box
                gridArea="price"
                alignSelf="start"
                justifySelf="right"
                id={`box-gift-item-msg-${productRef}-${colorRef}`}
                display="grid"
                gridGap="s"
                gridAutoFlow="column"
                alignItems="flex-start"
              >
                <Icon name="gift" color={giftItemTextColor} />
                <ColorText cmsColor={giftItemTextColor} fontWeight="bold">
                  {LBL_GIFT_ITEM}
                </ColorText>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="flex-end" width="25%">
                {(itemCouponAmount ?? 0) > 0 && hasPromo ? (
                  <>
                    <LineThroughText id={`text-cart-item-base-row-total-${id}`}>
                      {baseTotal}
                    </LineThroughText>
                    <Text id={`text-cart-item-promo-amount-${id}`} preset="caption" color="PROMO">
                      {promoAmount}
                    </Text>
                    <Text id={`text-cart-item-coupon-amount-${id}`} preset="caption" color="PROMO">
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : (itemCouponAmount ?? 0) > 0 ? (
                  <>
                    <Text id={`text-cart-item-without-coupon-row-total-${id}`}>{baseTotal}</Text>
                    <Text id={`text-cart-item-coupon-amount-${id}`} preset="caption" color="PROMO">
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : hasPromo ? (
                  <>
                    <LineThroughText id={`text-cart-item-base-row-total-${id}`}>
                      {baseTotal}
                    </LineThroughText>
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : (
                  <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold">
                    {total}
                  </Text>
                )}
              </Box>
            )}
          </Box>
          <Box display="flex" width="100%" justifyContent="space-between" mt="xxs">
            {!freeProduct ? (
              <Box
                pointerEvents={isAvailable ? 'auto' : 'none'}
                opacity={isAvailable ? 1 : opacities.DISABLED}
              >
                <Selector
                  id={`select-quantity-product-${id}`}
                  options={dropdownOptions}
                  value={String(quantity)}
                  onChange={handleItemQuantityChange}
                  hasError={!isQuantityAvailable && isAvailable}
                  mini
                />
              </Box>
            ) : quantity > 1 ? (
              <Text>{quantity}</Text>
            ) : (
              <Text />
            )}
            {showActions && (
              <Box display="flex" alignItems="flex-end">
                <Icon name="trash" id={`btn-cart-item-remove-${id}`} onClick={handleDeleteItem} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    ),
  };

  return (
    <CartItemContainer
      {...{ showUndo, showMove }}
      id={`box-cart-item-${id}${isGiftCard ? '-' + item.gcFields?.itemId : ''}`}
    >
      <Box width="100%" position="relative">
        {isGiftCard && fragment.giftCard}
        {!isGiftCard && isStepListingMobile && fragment.itemCardStepListingMobile}
        {!isGiftCard && !isStepListingMobile && (
          <Box
            display="grid"
            gridTemplateAreas="'image name price' 'image selectors selectors' 'image cta cta'"
            my="m"
            transition={transitions.GENERIC}
            opacity={showUndo || showMove ? 0 : 1}
            gridGap={['s', 'm']}
            mx={['m', 'na']}
            gridAutoColumns="auto 1fr 1fr"
          >
            <Box
              opacity={isAvailable ? 1 : opacities.DISABLED}
              width={['80px', '128px']}
              gridArea="image"
              justifySelf="left"
            >
              <Link id={`link-cart-item-image-${productRef}-${colorRef}`} to={productLink}>
                <ImageContainer>
                  <Image isAbsolute src={thumbnail} alt="Product Image" />
                </ImageContainer>
              </Link>
            </Box>
            <Box
              gridArea="name"
              alignSelf="start"
              justifySelf="left"
              display="grid"
              gridGap="s"
              alignItems="center"
              justifyContent="left"
            >
              <Box display="grid" gridGap="s" gridAutoFlow="column" alignItems="center">
                <Text id={`link-cart-item-name-${productRef}-${colorRef}`} preset="caption">
                  <ProductName productName={productName} />
                </Text>
                {(promotionPercentage || promotionLabel) && (
                  <ColorText
                    display="inline-flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    py="xxs"
                    px="s"
                    cmsColor={textColor}
                    bg={backgroundColor}
                    fontWeight="bold"
                    preset="caption"
                    alignSelf="start"
                  >
                    {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
                  </ColorText>
                )}
              </Box>
              {promoMessageList.length > 0 && (
                <Box display="grid" gridGap="xs" mb="m">
                  {promoMessageList.map((message, index) => (
                    <Text key={index} preset="caption" color="PROMO" fontWeight="bold">
                      {message}
                    </Text>
                  ))}
                </Box>
              )}
            </Box>
            <Box
              gridArea="selectors"
              alignSelf="start"
              justifySelf="left"
              display="grid"
              gridAutoFlow={['column', 'row']}
              gridAutoColumns="min-content"
              gridGap={['xs', 's']}
            >
              <Text id={`text-cart-item-color-${id}`} preset="caption" whiteSpace="nowrap">
                {LBL_COLOR} :{' '}
                <Text display="inline-block" fontWeight="bold" preset="caption">
                  {detailedColorLabel}
                </Text>
              </Text>
              <Text display={['block', 'none']} preset="caption">
                {' '}
                |{' '}
              </Text>
              <Text id={`text-cart-item-size-${id}`} preset="caption" whiteSpace="nowrap">
                {LBL_SIZE} :{' '}
                <Text display="inline-block" fontWeight="bold" preset="caption">
                  {size || `${bandSize} ${cupSize}`}
                </Text>
              </Text>
            </Box>

            {freeProduct ? (
              <Box
                gridArea="price"
                alignSelf="start"
                justifySelf="right"
                id={`box-gift-item-msg-${productRef}-${colorRef}`}
                display="grid"
                gridGap="s"
                gridAutoFlow="column"
                alignItems="flex-start"
              >
                <Icon name="gift" color={giftItemTextColor} />
                <ColorText cmsColor={giftItemTextColor} fontWeight="bold">
                  {LBL_GIFT_ITEM}
                </ColorText>
              </Box>
            ) : (
              <Box
                gridArea="price"
                alignSelf="start"
                justifySelf="right"
                display="grid"
                gridGap="xs"
                justifyContent="flex-end"
                alignItems="center"
                textAlign="right"
              >
                {(itemCouponAmount ?? 0) > 0 && hasPromo ? (
                  <>
                    <LineThroughText id={`text-cart-item-base-row-total-${id}`}>
                      {baseTotal}
                    </LineThroughText>
                    <Text id={`text-cart-item-promo-amount-${id}`} preset="caption" color="PROMO">
                      {promoAmount}
                    </Text>
                    <Text id={`text-cart-item-coupon-amount-${id}`} preset="caption" color="PROMO">
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : (itemCouponAmount ?? 0) > 0 ? (
                  <>
                    <Text id={`text-cart-item-without-coupon-row-total-${id}`}>{baseTotal}</Text>
                    <Text id={`text-cart-item-coupon-amount-${id}`} preset="caption" color="PROMO">
                      {couponAmount}
                    </Text>
                    <Divider />
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : hasPromo ? (
                  <>
                    <LineThroughText id={`text-cart-item-base-row-total-${id}`}>
                      {baseTotal}
                    </LineThroughText>
                    <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold" color="PROMO">
                      {total}
                    </Text>
                  </>
                ) : (
                  <Text id={`text-cart-item-row-total-${id}`} fontWeight="bold">
                    {total}
                  </Text>
                )}
              </Box>
            )}

            <Box
              gridArea="cta"
              display="grid"
              gridAutoFlow="column"
              gridGap="m"
              justifyContent="space-between"
              alignItems="end"
            >
              {!freeProduct ? (
                <Box
                  pointerEvents={isAvailable ? 'auto' : 'none'}
                  opacity={isAvailable ? 1 : opacities.DISABLED}
                >
                  <Selector
                    id={`select-quantity-product-${id}`}
                    options={dropdownOptions}
                    value={String(quantity)}
                    onChange={handleItemQuantityChange}
                    hasError={!isQuantityAvailable && isAvailable}
                    mini
                  />
                </Box>
              ) : quantity > 1 ? (
                <Text>{quantity}</Text>
              ) : (
                <Text />
              )}

              {showActions && (
                <Box display="grid" gridAutoFlow="column" gridGap="m">
                  <Anchor
                    id={`btn-cart-move-to-wishlist-${id}`}
                    type="button"
                    preset="caption"
                    onClick={handleMoveToWishlist}
                  >
                    {TXT_MOVE_TO_WISHLIST}
                  </Anchor>
                  <Icon name="trash" id={`btn-cart-item-remove-${id}`} onClick={handleDeleteItem} />
                </Box>
              )}
            </Box>
          </Box>
        )}

        {isAvailable && !isQuantityAvailable && (
          <Text
            preset="caption"
            color="ERROR"
            id={`text-cart-item-qty-not-available-${id}`}
            mb="s"
            mx={['m', 'na']}
          >
            {MSG_CART_ITEM_INVALID}
          </Text>
        )}
        <AnimationContainer
          show={showUndo || showMove}
          shouldDisappear={shouldDisappear}
          id={`cart-item-animation-container-${id}`}
        >
          {showUndo ? (
            <>
              <Text id={`text-cart-removed-${id}`} mb="s">
                {MSG_CART_ITEM_REMOVED}
              </Text>
              <Anchor id={`btn-cart-item-undo-${id}`} type="button" onClick={handleUndo}>
                {TXT_CART_ITEM_UNDO}
              </Anchor>
            </>
          ) : showMove ? (
            <Box display="grid" gridAutoFlow="column" gridGap="m">
              <Text
                id={`text-cart-moved-${id}`}
                color="WHITE"
                fontWeight="bold"
                preset="subheading"
              >
                {MSG_MOVED_TO_WISHLIST}
              </Text>
              <Icon name="checkmark" />
            </Box>
          ) : null}
        </AnimationContainer>
      </Box>
    </CartItemContainer>
  );
};

type CartItemContainerProps = {
  showUndo: boolean;
  showMove: boolean;
};

export const CartItemContainer = styled.div<CartItemContainerProps>(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: colors.BACKGROUND,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'scaleX(1)',
      transformOrigin: '0 50%',
      transition: transitions.GENERIC,
      background: colors.WHITE,
    },
  },
  ({ showUndo }) =>
    showUndo && {
      '&:before': {
        transform: 'scaleX(0)',
      },
    },
  ({ showMove }) =>
    showMove && {
      '&:before': {
        transform: 'scaleX(0)',
      },
      background: 'linear-gradient(90deg, #83FFD1 0%, #41DDA3 100%)',
    }
);

type AnimationContainerProps = {
  show: boolean;
  shouldDisappear: boolean;
};

export const AnimationContainer = styled.div<AnimationContainerProps>(
  {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: transitions.GENERIC,
  },
  ({ show }) =>
    show && {
      display: 'flex',
      opacity: 1,
    },
  ({ shouldDisappear }) =>
    shouldDisappear && {
      opacity: 0,
    }
);

export default CartItem;
