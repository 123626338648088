import * as React from 'react';
import { UnknownAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/core';

import { Text, Checkbox, breakpoints, colors } from '../../../design-system';
import { ShippingTypes } from '../../cart/types';
import { emptyAddress, deliveryMethodsDesktopWidth } from '../utils';
import Address from './Address';
import { Forms, FormFieldCallback } from '../../form/types';
import { LABEL_SHIPPING, LBL_ERROR_FORM_SHIPPING } from '../../cart/locale';
import { RootState } from '../../../store/rootReducer';
import { ADD_NEW_ADDRESS, LBL_IS_DIFFERENT_ADDRESS, TITLE_BILLING_ADDRESS } from '../locale';
import GiftMessage from './GiftMessage';
import { opacities } from '../../../design-system';
import { getAddressOptions } from '../../cart/utils';
import { Address as AddressType } from '../../account/types';
import { populateForm } from '../../form/utils';
import { setFormValues, setFormValidation } from '../../form/actions';
import { AddressCard } from './AddressCard';
import { updateAddresses } from '../../account/actions';
import usePrevious from '../../common/hooks/usePrevious';
import { DeliveryMethodItems, StyledButton } from './DeliveryMethodItems';
import styled from '@emotion/styled';

type Props = {
  onShowBillingClick: () => void;
  onShowGiftMessageClick: () => void;
  onShippingMethodClick: (method: ShippingTypes) => void;
  onFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  onFieldChange: ({ form, key, value }: FormFieldCallback) => void;
  formErrorType: Forms | null;
  setFormErrorType: (value: Forms | null) => void;
  setOpenedFormType: (value: Forms | null) => void;
};

const StyledDiv = styled.div`
  padding: 0 16px 0 16px;
  @media (min-width: ${breakpoints.S}px) {
    padding: 0 0;
  }
  @media (min-width: ${breakpoints.L}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .checkbox-content__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-block-start: 0;
    margin-block-end: 0;
    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`;

export const Shipping = ({
  onShowBillingClick,
  onShowGiftMessageClick,
  onShippingMethodClick,
  onFieldBlur,
  onFieldChange,
  formErrorType,
  setFormErrorType,
  setOpenedFormType,
}: Props) => {
  const dispatch = useDispatch();
  const addresses = useSelector((state: RootState) => state.account.user?.addresses) ?? [];
  const shippingStateId = useSelector((state: RootState) => state.account.user?.shippingAddressId);
  const billingStateId = useSelector((state: RootState) => state.account.user?.billingAddressId);
  const { cart, shippingTypes } = useSelector((state: RootState) => state.cart);
  const { shipping, billing, delivery } = useSelector((state: RootState) => state.form);

  const { hometext, storetext, pickuptext } =
    useSelector((state: RootState) => state.cms.delivery) ?? {};
  const divRef = React.useRef<HTMLDivElement>(null);
  const deliveryBlockRefs = React.useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const [indexToScroll, setIndexToScroll] = React.useState<number>(0);
  const previousIndexToScroll = usePrevious(indexToScroll);
  const [loadingState, setLoadingState] = React.useState<Array<boolean>>([]);
  const [isErrorOnShowBillingClick, setIsErrorOnShowBillingClick] = React.useState(false);
  const [addingAddressType, setAddingAddressType] = React.useState<Forms | null>(null);
  const [editAddressType, setEditAddressType] = React.useState<Forms | null>(null);
  const billingBlockRef = React.useRef<HTMLDivElement>(null);
  const billingSelectorRef = React.useRef<HTMLDivElement>(null);
  const billingAddress = addresses.filter((addresse) => addresse.id !== shipping.values.id);
  const shippingAddress = addresses.filter((address) => address.country === 'FR');
  const prevNbOfShippingAddresses = usePrevious(shippingAddress?.length);
  const prevNbOfAddresses = usePrevious(addresses?.length);
  const shippingAddressId =
    shippingAddress.find((address) => address.id === shippingStateId)?.id ??
    shippingAddress?.[0]?.id ??
    '0';
  const billingAddressId =
    billingAddress.find((address) => address.id === billingStateId)?.id ??
    billingAddress?.[0]?.id ??
    '0';
  const addressOptionsForShipping = getAddressOptions(shippingAddress, shippingAddressId, false);
  const addressOptionsForBilling = getAddressOptions(billingAddress, shippingAddressId, false);
  const isShippingTypeLoading = loadingState.find((item) => item);
  const addressOptions = getAddressOptions(addresses, shippingAddressId, false);
  const shippingSelectorRef = deliveryBlockRefs.current[indexToScroll];
  const { showBilling } = delivery.values;
  const { shippingType, isGiftEnabled, isDigital } = cart ?? {};
  const labels = [
    {
      id: ShippingTypes.HOME,
      value: hometext,
    },
    {
      id: ShippingTypes.STORE,
      value: storetext,
    },
    {
      id: ShippingTypes.PICKUP,
      value: pickuptext,
    },
  ];
  const cmsShippingTypes = shippingTypes.map(({ label, amount }) => ({
    id: label,
    value: labels.find(({ id }) => id === label)?.value,
    amount,
  }));

  const handleClickSave = (newAddress: AddressType) => {
    if (newAddress.id) {
      dispatch(
        updateAddresses(
          addresses.map((address) => (address.id === newAddress.id ? newAddress : address))
        ) as unknown as UnknownAction
      );
    } else {
      dispatch(updateAddresses([...addresses, newAddress]) as unknown as UnknownAction);
    }
  };

  React.useEffect(() => {
    if (addresses.length) {
      if (!isDigital && shippingType === ShippingTypes.HOME && shippingAddress.length) {
        onAddressSelectShipping(shippingAddressId);
      } else if (
        (isDigital ||
          shippingType === ShippingTypes.PICKUP ||
          shippingType === ShippingTypes.STORE) &&
        billingAddress.length
      ) {
        onAddressSelectBilling(billingAddressId);
      }
    }
  }, []);

  React.useEffect(() => {
    if (delivery.feedback.message) {
      const node = divRef.current;
      if (node) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [delivery.feedback.message]);

  React.useEffect(() => {
    if (formErrorType) {
      if (isDigital && !addresses.length) {
        billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      if (isDigital && addresses.length > 0 && !editAddressType) {
        setEditAddressType(Forms.billing);
      }
      if (!isDigital) {
        if (shippingType === ShippingTypes.HOME) {
          if (
            formErrorType === Forms.shipping &&
            shippingAddress.length > 0 &&
            editAddressType !== Forms.shipping
          ) {
            setEditAddressType(Forms.shipping);
          }
          if (
            formErrorType === Forms.shipping &&
            !shippingAddress.length &&
            addingAddressType !== Forms.shipping
          ) {
            setAddingAddressType(Forms.shipping);
          }
          if (
            formErrorType === Forms.billing &&
            billingAddress.length > 0 &&
            editAddressType !== Forms.billing
          ) {
            setEditAddressType(Forms.billing);
          }
          if (formErrorType === Forms.billing && !billingAddress.length) {
            billingSelectorRef.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
          if (
            formErrorType === Forms.billing &&
            billingAddress.length > 0 &&
            editAddressType !== Forms.billing
          ) {
            setEditAddressType(Forms.billing);
          }
        }
        if (shippingType !== ShippingTypes.HOME && formErrorType === Forms.billing) {
          if (addresses.length > 0 && editAddressType !== Forms.billing) {
            setEditAddressType(Forms.billing);
          }
          if (!addresses.length) {
            billingSelectorRef.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }
    }
  }, [formErrorType]);

  React.useEffect(() => {
    let isShippingFormError = false;
    let isBillingFormError = false;
    if ('validation' in shipping) {
      for (const value in shipping.validation) {
        if (shipping.validation[value] !== '') {
          isShippingFormError = true;
        }
      }
    }
    if ('validation' in billing) {
      for (const value in billing.validation) {
        if (billing.validation[value] !== '') {
          isBillingFormError = true;
        }
      }
    }
    if (!isShippingFormError && !isBillingFormError && formErrorType) {
      setFormErrorType(null);
    }
  }, [shipping.validation, billing.validation]);

  React.useEffect(() => {
    if (isErrorOnShowBillingClick && addingAddressType === Forms.shipping) {
      setIsErrorOnShowBillingClick(false);
    }
    setOpenedFormType(addingAddressType);
  }, [addingAddressType]);

  React.useEffect(() => {
    setOpenedFormType(editAddressType);
  }, [editAddressType]);

  React.useEffect(() => {
    if (formErrorType) {
      setFormErrorType(null);
    }
    if (isErrorOnShowBillingClick) {
      setIsErrorOnShowBillingClick(false);
    }
    if (shippingType) {
      setLoadingState([]);
    }
    if (shippingType && (previousIndexToScroll || previousIndexToScroll === 0)) {
      const ref = deliveryBlockRefs.current[indexToScroll];
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (
      (!shippingAddress.length && shippingType === ShippingTypes.HOME) ||
      shippingType === ShippingTypes.PICKUP ||
      shippingType === ShippingTypes.STORE
    ) {
      Object.keys(emptyAddress).forEach((key) => {
        dispatch(
          setFormValues({
            form: Forms.shipping,
            values: {
              [key]: '',
            },
          })
        );
      });
    }
    if (shippingType === ShippingTypes.HOME && !isDigital) {
      Object.keys(emptyAddress).forEach((key) => {
        dispatch(
          setFormValues({
            form: Forms.billing,
            values: {
              [key]: '',
            },
          })
        );
      });
    }
    if (
      (shippingType === ShippingTypes.PICKUP ||
        shippingType === ShippingTypes.STORE ||
        isDigital) &&
      addresses.length > 0
    ) {
      onAddressSelectBilling(billingAddressId);
    }
    if (shippingType === ShippingTypes.HOME && shippingAddress.length > 0) {
      onAddressSelectShipping(shippingAddressId);
    }
  }, [shippingType]);

  React.useEffect(() => {
    if (!showBilling && shippingType === ShippingTypes.HOME && !isDigital) {
      Object.keys(emptyAddress).forEach((key) => {
        dispatch(
          setFormValues({
            form: Forms.billing,
            values: {
              [key]: '',
            },
          })
        );
      });
    }
    if (showBilling && billingAddress?.length > 0) {
      onAddressSelectBilling(billingAddressId);
    }
    if (!showBilling && formErrorType === Forms.billing) {
      setFormErrorType(null);
    }
    if (showBilling) {
      billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showBilling]);

  React.useEffect(() => {
    if (isErrorOnShowBillingClick) {
      shippingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isErrorOnShowBillingClick]);

  React.useEffect(() => {
    if (isErrorOnShowBillingClick) {
      setIsErrorOnShowBillingClick(false);
    }
    if (isDigital && prevNbOfAddresses + 1 === addresses.length) {
      onAddressSelectBilling(billingAddress[billingAddress.length - 1].id);
      billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (
      prevNbOfShippingAddresses + 1 === shippingAddress.length &&
      addingAddressType === Forms.shipping
    ) {
      formErrorType && setFormErrorType(null);
      onAddressSelectShipping(shippingAddress[shippingAddress.length - 1].id);
      deliveryBlockRefs.current[indexToScroll]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
    if (
      prevNbOfAddresses + 1 === addresses.length &&
      (addingAddressType === Forms.billing ||
        (addresses.length === 2 && showBilling) ||
        ((shippingType === ShippingTypes.STORE || shippingType === ShippingTypes.PICKUP) &&
          addresses.length === 1))
    ) {
      formErrorType && setFormErrorType(null);
      onAddressSelectBilling(billingAddress[billingAddress.length - 1].id);
      billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (addingAddressType) {
      setAddingAddressType(null);
    }
    if (editAddressType) {
      if (editAddressType === Forms.billing) {
        billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        shippingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      setEditAddressType(null);
    }
  }, [JSON.stringify(addresses)]);

  const onRadioItemClick = (item: ShippingTypes, index: number) => {
    const updatedState = [...loadingState];
    updatedState[index] = !updatedState[index];
    setLoadingState(updatedState);
    onShippingMethodClick(ShippingTypes[item]);
    setIndexToScroll(index);
  };

  const onAddressSelectShipping = (value: string) => {
    if (value === billing.values.id && showBilling) {
      onShowBillingClick();
    }
    const address = addresses.find((address) => address.id === value) || emptyAddress;
    const keys = Object.keys(address);

    dispatch(
      setFormValues({
        form: Forms.shipping,
        values: populateForm(address, keys),
      })
    );

    dispatch(
      setFormValidation({
        form: Forms.shipping,
        values: populateForm({}, keys),
      })
    );
  };

  const onAddressSelectBilling = (value: string) => {
    const address = addresses.find((address) => address.id === value) || emptyAddress;
    const keys = Object.keys(address);

    dispatch(
      setFormValues({
        form: Forms.billing,
        values: populateForm(address, keys),
      })
    );

    dispatch(
      setFormValidation({
        form: Forms.billing,
        values: populateForm({}, keys),
      })
    );
  };

  const fragments = {
    billing: (
      <div
        ref={billingBlockRef}
        css={css`
          scroll-margin-top: 16px;
        `}
      >
        <Address
          type={Forms.billing}
          onFieldBlur={onFieldBlur}
          onFieldChange={onFieldChange}
          form={billing}
          onSave={handleClickSave}
          setAddingAddressType={setAddingAddressType}
          editAddressType={editAddressType}
          setEditAddressType={setEditAddressType}
          shippingType={shippingType}
          shippingSelectorRef={shippingSelectorRef}
          billingSelectorRef={billingSelectorRef}
          shippingAddressId={shippingAddressId}
          billingAddressId={billingAddressId}
          formErrorType={formErrorType}
        />
      </div>
    ),
  };

  return (
    <StyledDiv id="cart-delivery-method-container" ref={divRef}>
      {delivery.feedback.message && (
        <Text id="msg-form-delivery" color={delivery.feedback.ok ? 'SUCCESS' : 'ERROR'}>
          {delivery.feedback.message}
        </Text>
      )}
      {isDigital ? (
        <div
          css={css`
            margin-bottom: 24px;
            scroll-margin-top: 16px;
            @media (min-width: ${breakpoints.L}px) {
              width: ${deliveryMethodsDesktopWidth}px;
            }
          `}
          ref={billingSelectorRef}
        >
          <div
            css={css`
              font-size: 1.6rem;
              font-weight: 700;
              margin-bottom: 16px;
              @media (min-width: ${breakpoints.S - 1}px) {
                margin-bottom: 24px;
              }
            `}
          >
            {TITLE_BILLING_ADDRESS}
          </div>
          {addresses.length > 0 && !editAddressType && !addingAddressType && (
            <>
              <AddressCard
                addressOptions={addressOptions}
                form={billing}
                type={Forms.billing}
                onChange={onAddressSelectBilling}
                setEditAddressType={setEditAddressType}
                showBilling={showBilling}
                onShowBillingClick={onShowBillingClick}
              />
              <StyledButton type="button" onClick={() => setAddingAddressType(Forms.billing)}>
                {ADD_NEW_ADDRESS}
              </StyledButton>
            </>
          )}
          {(editAddressType || addingAddressType || !addresses.length) && fragments.billing}
        </div>
      ) : (
        <>
          <div
            css={css`
              text-align: left;
              font-size: 1.6rem;
              font-weight: 700;
              margin-bottom: 16px;
              @media (min-width: ${breakpoints.L}px) {
                margin-bottom: 32px;
              }
              @media (min-width: ${breakpoints.L}px) {
                width: ${deliveryMethodsDesktopWidth}px;
              }
            `}
          >
            {LABEL_SHIPPING}
          </div>
          <div
            css={css`
              @media (max-width: ${breakpoints.S - 1}px) {
                background-color: ${colors.BACKGROUND};
                margin: 0 -16px;
                padding: 16px 0 16px 16px;
              }
            `}
          >
            {cmsShippingTypes?.length > 1 ? (
              cmsShippingTypes.map(
                (
                  item: { id: ShippingTypes; value: string | undefined; amount: number },
                  index: number
                ) => {
                  const isLoading = loadingState[index];
                  const isError = formErrorType === Forms.shipping || isErrorOnShowBillingClick;

                  return (
                    <DeliveryMethodItems
                      key={`cart-delivery-method-items-${index}`}
                      addressOptionsForShipping={addressOptionsForShipping}
                      formErrorType={formErrorType}
                      onAddressSelectShipping={onAddressSelectShipping}
                      onFieldBlur={onFieldBlur}
                      onFieldChange={onFieldChange}
                      onRadioItemClick={onRadioItemClick}
                      onShowBillingClick={onShowBillingClick}
                      addingAddressType={addingAddressType}
                      setAddingAddressType={setAddingAddressType}
                      editAddressType={editAddressType}
                      setEditAddressType={setEditAddressType}
                      handleClickSave={handleClickSave}
                      shippingSelectorRef={shippingSelectorRef}
                      billingSelectorRef={billingSelectorRef}
                      billingAddressId={billingAddressId}
                      shippingAddressId={shippingAddressId}
                      item={item}
                      index={index}
                      isError={isError}
                      isLoading={isLoading}
                      isShippingTypeLoading={isShippingTypeLoading}
                      deliveryBlockRefs={deliveryBlockRefs}
                    />
                  );
                }
              )
            ) : (
              <Text>{labels.find((label) => label.id === shippingType)?.value}</Text>
            )}
          </div>
          <div
            css={css`
              margin-bottom: 8px;
              @media (max-width: ${breakpoints.S - 1}px) {
                width: calc(100vw - 32px);
              }
              @media (min-width: ${breakpoints.L}px) {
                width: ${deliveryMethodsDesktopWidth}px;
              }
            `}
          >
            {shippingType === ShippingTypes.HOME ? (
              <>
                <div
                  css={css`
                    scroll-margin-top: 16px;
                    font-size: 1.6rem;
                    font-weight: 700;
                    margin-top: 16px;
                    margin-bottom: 8px;
                    @media (min-width: ${breakpoints.S}px) {
                      margin-top: 32px;
                    }
                  `}
                  ref={billingSelectorRef}
                >
                  {TITLE_BILLING_ADDRESS}
                </div>
                <div
                  css={css`
                    margin-top: 16px;
                    margin-bottom: 16px;
                    display: flex;
                    opacity: ${isErrorOnShowBillingClick ? opacities.DISABLED : 1};
                  `}
                >
                  <Checkbox
                    id="checkbox-checkout-show-billing"
                    checked={showBilling}
                    onChange={() =>
                      !shippingAddress.length
                        ? setIsErrorOnShowBillingClick(true)
                        : onShowBillingClick()
                    }
                  >
                    <div className="checkbox-content__container">
                      <p className="checkbox-content__description">{LBL_IS_DIFFERENT_ADDRESS}</p>
                    </div>
                  </Checkbox>
                </div>
                {isErrorOnShowBillingClick && (
                  <div
                    css={css`
                      font-size: 1.2rem;
                      color: ${colors.MAIN_ERROR};
                    `}
                  >
                    {LBL_ERROR_FORM_SHIPPING}
                  </div>
                )}
                {showBilling &&
                  addingAddressType !== Forms.billing &&
                  editAddressType !== Forms.billing &&
                  addressOptionsForBilling?.length > 0 && (
                    <div
                      css={css`
                        margin-bottom: 8px;
                      `}
                    >
                      {billingAddress.length > 0 && (
                        <AddressCard
                          addressOptions={addressOptionsForBilling}
                          form={billing}
                          type={Forms.billing}
                          onChange={onAddressSelectBilling}
                          setEditAddressType={setEditAddressType}
                          showBilling={showBilling}
                          onShowBillingClick={onShowBillingClick}
                        />
                      )}
                      <StyledButton
                        type="button"
                        onClick={() => setAddingAddressType(Forms.billing)}
                      >
                        {ADD_NEW_ADDRESS}
                      </StyledButton>
                    </div>
                  )}
                {(addingAddressType === Forms.billing ||
                  editAddressType === Forms.billing ||
                  addresses.length === 1) &&
                  showBilling && (
                    <div
                      ref={billingBlockRef}
                      css={css`
                        scroll-margin-top: 16px;
                      `}
                    >
                      {fragments.billing}
                    </div>
                  )}
              </>
            ) : (
              <>
                <div
                  css={css`
                    font-size: 1.6rem;
                    font-weight: 700;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    scroll-margin-top: 16px;
                  `}
                  ref={billingSelectorRef}
                >
                  {TITLE_BILLING_ADDRESS}
                </div>
                {addresses.length > 0 &&
                addingAddressType !== Forms.billing &&
                editAddressType !== Forms.billing ? (
                  <>
                    <AddressCard
                      addressOptions={addressOptions}
                      form={billing}
                      type={Forms.billing}
                      onChange={onAddressSelectBilling}
                      setEditAddressType={setEditAddressType}
                      showBilling={showBilling}
                      onShowBillingClick={onShowBillingClick}
                    />
                    <StyledButton type="button" onClick={() => setAddingAddressType(Forms.billing)}>
                      {ADD_NEW_ADDRESS}
                    </StyledButton>
                  </>
                ) : (
                  fragments.billing
                )}
              </>
            )}
            {isGiftEnabled && (
              <GiftMessage
                form={delivery}
                onFieldChange={onFieldChange}
                onFieldBlur={onFieldBlur}
                onShowGiftMessageClick={onShowGiftMessageClick}
              />
            )}
          </div>
        </>
      )}
    </StyledDiv>
  );
};
