import * as React from 'react';

import { RecommendationsTitleT2S } from './RecommendationsTitleT2S';
import { RecommendationsTilesT2S } from './RecommendationsTilesT2S';

import { Box, Divider } from '../../../design-system';
import { RecommendationTypes, RecommendationTracking } from '../types';
import { Product } from '../../product/types';

type Props = {
  hits: Product[];
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  tracking?: RecommendationTracking;
  type: RecommendationTypes;
  title: string;
};

export const RecommendationsT2S = ({
  hits,
  tracking,
  isFeedback,
  isDesktopFeedback,
  type,
  title,
}: Props) => {
  return (
    <>
      {hits.length > 0 && type === RecommendationTypes.crossSell && (
        <Box
          mx={[isFeedback ? 'm' : '-16px', 'na']}
          mt={isDesktopFeedback ? 'm' : 'na'}
          width={isDesktopFeedback ? '602px' : 'initial'}
        >
          <Divider />
        </Box>
      )}
      {hits.length > 0 && (
        <>
          <RecommendationsTitleT2S
            isFeedback={isFeedback}
            isDesktopFeedback={isDesktopFeedback}
            title={title}
            type={type}
          />
          <RecommendationsTilesT2S
            hits={hits}
            isFeedback={isFeedback}
            isDesktopFeedback={isDesktopFeedback}
            tracking={tracking}
            type={type}
          />
        </>
      )}
    </>
  );
};
