import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { css } from '@emotion/core';
import { breakpoints } from '../../../design-system';

import { Box, Icon, Field, Text, DynamicButton } from '../../../design-system';
import { Forms, FieldType } from '../../form/types';
import { RootState } from '../../../store/rootReducer';
import { validate } from '../../form/utils';
import { setFormValues, setFormValidation, setFeedback } from '../../form/actions';
import { partialUserUpdate } from '../actions';

import { useNavigate } from 'react-router-dom';
import { noop } from '../../common/utils';

export const ValidationWarning = ({ close }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector((state: RootState) => state.form.personal);
  const user = useSelector((state: RootState) => state.account.user);

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const hasValidationErrors =
    form.validation.firstName.length > 0 || form.validation.lastName.length > 0;
  const hasSaveErrors = !form.feedback.ok && Boolean(form.feedback.message);

  const validateField = (key: string, value: FieldType) => {
    const errMsg = validate({ value: String(value), key, isRequired: true });
    dispatch(setFormValidation({ form: Forms.personal, values: { [key]: errMsg } }));
  };

  const goToAcount = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    // revalidate saved fields before redirect
    validateField('firstName', user?.firstName || '');
    validateField('lastName', user?.lastName || '');

    navigate('/compte/informations');
  };

  const handleFieldChange = (key: string, value: FieldType) => {
    validateField(key, value);
    dispatch(setFormValues({ form: Forms.personal, values: { [key]: value } }));
  };

  // prevalidate name when the popup loaded to highlight the user the fields required corrections
  React.useEffect(() => {
    validateField('firstName', form.values.firstName);
  }, [form.values.firstName]);

  React.useEffect(() => {
    validateField('lastName', form.values.lastName);
  }, [form.values.lastName]);

  React.useEffect(() => {
    dispatch(
      setFeedback({
        ...form.feedback,
        form: Forms.personal,
        message: '',
      })
    );
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      mt="xxl"
      mb="xxl"
      flexDirection="column"
      id="validation-warning"
    >
      {hasSaveErrors && (
        <div
          css={css`
            position: absolute;
            top: 14px;
            right: 16px;
          `}
        >
          <Icon name="closeSmall" size={20} onClick={close} />
        </div>
      )}
      <div
        css={css`
          text-align: center;
          width: 80%;
          font-size: 14px;
          line-height: 18px;
          @media (min-width: ${breakpoints.S}px) {
            font-size: 16px;
            line-height: 18px;
          }
        `}
      >
        <Box mb="m">
          Les caractères spéciaux ne sont pas autorisés, mettez à jour vos nom et/ou prénom pour le
          bon traitement de votre commande. Une fois vos détails mis à jour vous pourrez terminer
          votre commande.&nbsp;
          <a href="/compte/informations" onClick={goToAcount}>
            Modifiez vos données
          </a>
        </Box>
        <Box textAlign="center" mb="m">
          {hasSaveErrors && <Text color="ERROR">{form.feedback.message}</Text>}
        </Box>
        <Box mb="m">
          <Field
            id={`${Forms.personal}-firstName`}
            onChange={(value: string) => handleFieldChange('firstName', value)}
            onBlur={(value: string) => validateField('firstName', value)}
            value={form.values.firstName}
            errMsg={form.validation.firstName}
            label="Prénom*"
            ref={firstNameRef}
            autoComplete="given-name"
          />
        </Box>
        <Box mb="m">
          <Field
            id={`${Forms.personal}-lastName`}
            onChange={(value: string) => handleFieldChange('lastName', value)}
            onBlur={(value: string) => validateField('lastName', value)}
            value={form.values.lastName}
            errMsg={form.validation.lastName}
            label="Nom*"
            ref={lastNameRef}
            autoComplete="family-name"
          />
        </Box>
        <DynamicButton
          id="btn-submit-personal-details"
          onClick={() =>
            dispatch(
              partialUserUpdate(
                { ...form.values },
                hasSaveErrors ? noop : close
              ) as unknown as UnknownAction
            )
          }
          disabled={hasValidationErrors}
          data={['Valider vos modifications', '', '']}
          feedback={form.ctaState}
        />
      </div>
    </Box>
  );
};
