import React from 'react';
import styled from '@emotion/styled';

import { Icon, colors, durations, opacities } from '../../../design-system';
import { Product } from '../types';
import { useDispatch } from 'react-redux';
import { trackRecommendation } from '../../recommendations/utils';
import { openModal } from '../../common/actions';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { getProductImage } from '../utils';
import { HighlightedName } from '../../catalog/components/HighlightedName';
import { Price } from '../../catalog/components/Price';
import { PromotionLabel } from '../../catalog/components/PromotionLabel';
import { getQuickAddModalPreset } from '../../quickadd/utils';

type Props = {
  hit: Product;
  trackingId?: string;
  setShowMobileQuickAdd: (value: boolean) => void;
  setClickedItem: (value: string) => void;
};

const CROSS_SELL_PRODUCT_IMAGE_WIDTH = 104;

const StyledProductButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  background-color: ${colors.BACKGROUND};
  height: 172px;
  width: 100%;
  margin: 8px 0;
  padding: 16px;
  cursor: pointer;
  transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      opacity: ${opacities.HOVERED};
    }
  }

  .cross-sell-product-image {
    height: 100%;
    width: ${CROSS_SELL_PRODUCT_IMAGE_WIDTH}px;
    margin-right: 16px;
    border-radius: 4px;
  }

  .cross-sell-product-info-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .cross-sell-fake-button-add-to-cart {
    position: absolute;
    border: solid 1px ${colors.LIGHT};
    bottom: 16px;
    right: 16px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.WHITE};
  }
`;

export const CrossSellProductPageItem = ({
  hit,
  trackingId,
  setShowMobileQuickAdd,
  setClickedItem,
}: Props) => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useMediaQueries();

  const {
    productRef,
    colorRef,
    productName,
    collectionName,
    colorLabel,
    originalPrice,
    storePrice,
    sizeVariants,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
  } = hit;

  const objectID = `${hit.productRef}-${hit.colorRef}`;

  const onItemClick = () => {
    trackRecommendation(trackingId);
    if (isMobile) {
      setClickedItem(`${productRef}-${colorRef}`);
      setShowMobileQuickAdd(true);
    } else {
      dispatch(
        openModal({
          content: <QuickAdd productRef={productRef} colorRef={colorRef} />,
          preset: getQuickAddModalPreset({ sizeVariants: sizeVariants ?? [], isTablet }),
        })
      );
    }
  };

  return (
    <StyledProductButton
      type="button"
      data-testid={`cross-sell-product-card-${objectID}-pdp-button`}
      id={`cross-sell-product-card-${objectID}-pdp-button`}
      onClick={onItemClick}
    >
      <img
        className="cross-sell-product-image"
        src={
          getProductImage({
            productRef,
            productName,
            colorRef,
            colorLabel,
            position: 1,
            width: CROSS_SELL_PRODUCT_IMAGE_WIDTH,
          })?.jpg
        }
        alt={`image du produit ${productName}`}
      />
      <div className="cross-sell-product-info-block">
        <HighlightedName
          objectID={objectID}
          productName={productName}
          collectionName={collectionName}
        />
        <Price originalPrice={originalPrice} storePrice={storePrice} />
        {(promoLongLabel || promotionLabel || promotionPercentage) && (
          <PromotionLabel
            promotionPercentage={promotionPercentage}
            promotionLabel={promoLongLabel ?? promotionLabel}
            promotionBackgroundColor={promoBColor}
            promotionTextColor={promoTColor}
            objectID={objectID}
            isOutlet={isOutlet}
          />
        )}
      </div>
      <div className="cross-sell-fake-button-add-to-cart">
        <Icon name="eyeOn" size={20} />
      </div>
    </StyledProductButton>
  );
};
