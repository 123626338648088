import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { RichText } from 'prismic-reactjs';
import { Text, Box, Accordion } from '../../../design-system';
import { CmsText } from '../../cms/types';
import { TITLE_LEGAL } from '../locale';
type Props = {
  links: {
    primary: {
      title: CmsText;
    };
    items: {
      color: string;
      text: CmsText;
    }[];
  }[];
};
type LinkTextProps = {
  cmsColor?: string;
};
const LinkText = styled(Text)<LinkTextProps>(
  css({
    p: {
      my: ['m', 's'],
    },
    'p > a': {
      fontSize: 's',
      color: 'MAIN_GREY',
      textDecoration: 'none',
      '@media(hover: hover)': {
        ':hover': {
          opacity: 0.8,
        },
      },
    },
  }),
  ({ cmsColor }) =>
    cmsColor && {
      'p > a': {
        color: cmsColor,
      },
    }
);
const Links = ({ links }: Props) => {
  return (
    <>
      <Box
        display={['none', 'none', 'grid']}
        justifyContent="center"
        gridGap="xxxl"
        gridAutoFlow="column"
      >
        {links.map((item, index) => (
          <div key={index}>
            <Text mb="s" fontWeight="bold" textTransform="uppercase">
              {item.primary?.title?.[0]?.text ?? ''}
            </Text>
            {(item.items ?? []).map((field, index) => (
              <LinkText key={index} cmsColor={field.color}>
                {RichText.render(field.text ?? '')}
              </LinkText>
            ))}
          </div>
        ))}
      </Box>
      <Box display={['block', 'block', 'none']}>
        <Accordion
          id="mobile-footer-links"
          focus={-1}
          items={[
            ...links.map((item, index) => {
              const title = item.primary?.title?.[0]?.text ?? '';
              if (!title) {
                return { title: '', content: '', index: 0 };
              }
              return {
                title: (
                  <Text fontWeight="bold" textTransform="uppercase">
                    {title}
                  </Text>
                ),
                content: (item.items ?? []).map((field, index) => (
                  <Box key={index} mx="s">
                    <LinkText cmsColor={field.color}>{RichText.render(field.text ?? '')}</LinkText>
                  </Box>
                )),
                index,
              };
            }),
            {
              title: (
                <Text fontWeight="bold" textTransform="uppercase">
                  {TITLE_LEGAL}
                </Text>
              ),
              content: (
                <>
                  <Box mx="s">
                    <LinkText>
                      <p>
                        <a href="/cgv">CGV</a>
                      </p>
                    </LinkText>
                  </Box>
                  <Box mx="s">
                    <LinkText>
                      <p>
                        <a href="/mentions-legales">Mentions légales</a>
                      </p>
                    </LinkText>
                  </Box>
                  <Box mx="s">
                    <LinkText>
                      <p>
                        <a href="/politique-de-confidentialite">Politique de confidentialité</a>
                      </p>
                    </LinkText>
                  </Box>
                  <Box mx="s">
                    <LinkText>
                      <p>
                        <a href="/politique-de-cookies">Politique de cookies</a>
                      </p>
                    </LinkText>
                  </Box>
                </>
              ),
              index: !links?.length ? 0 : links.length + 1,
            },
          ]}
        />
      </Box>
    </>
  );
};
export { Links };
