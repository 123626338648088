import * as React from 'react';

import { Box, Button, fetchIcon } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';
import { getIsClient } from '../../common/utils';
import { ITINERARY } from '../locale';
import { StoreCoordinate, ZoomLevels } from '../types';
import { createMap } from '../utils';
import Map from './Map';

type Props = {
  openGoogleMaps: () => void;
} & StoreCoordinate;

const StoreMap = ({ latitude, longitude, openGoogleMaps }: Props) => {
  const { isMobile } = useDevice();
  const mapRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [svg, setSvg] = React.useState('');
  const loadIcon = async () => {
    const response = await fetchIcon({ name: 'mapMarkerFull' });
    if (response) {
      setSvg(response);
    }
  };

  React.useEffect(() => {
    loadIcon();
  }, []);

  const url = getIsClient() ? `data:image/svg+xml;charset=UTF-8;base64, ${window.btoa(svg)}` : '';

  const icon = () => ({
    url,
    scaledSize: new google.maps.Size(44, 44),
  });

  React.useEffect(() => {
    const initMap = async (element: HTMLDivElement) => {
      const { map } = await createMap({
        center: { lat: latitude, lng: longitude },
        element,
        zoom: ZoomLevels.AREA,
      });
      setMap(map);
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
  }, [mapRef]);

  React.useEffect(() => {
    if (!map) {
      return () => {};
    }

    const mapMarker = new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      icon: icon(),
    });

    return () => {
      mapMarker.setMap(null);
    };
  }, [map, svg]);

  return (
    <Box width="100%" height="100%" position="absolute" top="0" bottom="0" left="0" right="0">
      <Map mapRef={mapRef} />
      {!isMobile && (
        <Box position="absolute" left="0" right="0" bottom="20px" width="200px" m="auto">
          <Button id="store-map-directions-btn" onClick={openGoogleMaps}>
            {ITINERARY}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StoreMap;
