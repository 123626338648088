import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Text, Icon, Anchor } from '../../../design-system';
import { useDevice } from '../../common/hooks/useDevice';
import locale from '../locale';
import { openModal } from '../../common/actions';
import RecycleModal from './RecycleModal';
import RecycleToolTip from './RecycleToolTip';

export const RECYCLE_BG_COLOR = '#B5C6A7';
export const RECYCLE_TEXT_COLOR = '#96A688';

const RecycleLabel = () => {
  const dispatch = useDispatch();

  const { isMobile, isTablet } = useDevice();

  const [isHovered, setIsHovered] = React.useState(false);

  const handleOpenRecycleModal = () => {
    dispatch(openModal({ content: <RecycleModal /> }));
  };

  if (isMobile || isTablet) {
    return (
      <Box
        bg={RECYCLE_BG_COLOR}
        px="s"
        py="xs"
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="center"
        justifyContent="space-between"
        onClick={isTablet ? handleOpenRecycleModal : undefined}
      >
        <Box display="grid" gridAutoFlow="column" gridGap="s" alignItems="center">
          <Icon name="change" color="WHITE" size={20} />
          <Text preset="caption" color="WHITE" fontWeight="bold">
            {locale.LBL_RECYCLE}
          </Text>
        </Box>
        {isMobile && (
          <Anchor
            type="button"
            id="link-open-recycle-modal"
            onClick={handleOpenRecycleModal}
            color="WHITE"
            decoration="inline"
            preset="caption"
          >
            {locale.TXT_MORE_INFO}
          </Anchor>
        )}
      </Box>
    );
  }
  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        bg={RECYCLE_BG_COLOR}
        px="s"
        py="xs"
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="center"
      >
        <Icon name="change" color="WHITE" size={20} />
        <Text preset="caption" color="WHITE" fontWeight="bold">
          {locale.LBL_RECYCLE}
        </Text>
      </Box>
      {isHovered && <RecycleToolTip />}
    </Box>
  );
};

export default RecycleLabel;
