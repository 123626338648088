import * as React from 'react';
import { UnknownAction } from 'redux';
import { Icon, Tabs, breakpoints, colors } from '../../../design-system';

import UspSection from '../../home/components/UspSection';
import { cmsUspInit } from '../../cms/actions';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { Helmet } from 'react-helmet-async';

import {
  sizingGuideBraData,
  sizingGuidePantyData,
  sizingGuidePyjamaData,
  sizingGuideBabyDollData,
  sizingGuideSwimwearTopData,
  sizingGuideSwimwearBottomData,
  sizingGuideOnePieceSwimwearData,
  sizingGuideSoftstretchData,
} from '../sizing.data';
import { sizingGuideBraVisual, sizingGuidePantyVisual } from '../visual.data';

import { sizingGuideFittingAdvice } from '../fittingAdviceStepList.data';

import { SizeGuideSection } from './SizeGuideSection';
import styled from '@emotion/styled';
import { SizeGuideFittingAdvice } from './SizeGuideFittingAdvice';
import { SIZE_GUIDE_TITLE_LP, SIZE_GUIDE_TITLE_POPUP } from '../locale';
import { closeModal } from '../../common/actions';
import { SizeGuideFooter } from './SizeGuideFooter';
import { useLocation } from 'react-router';

const StyledSizeGuide = styled.div<{ isPopup?: boolean }>`
  height: inherit;
  display: flex;
  flex-direction: column;
  .size-guide-tables__wrapper {
    @media (min-width: ${breakpoints.S}px) {
      overflow-y: ${(props) => (!props.isPopup ? 'unset' : 'auto')};
      height: ${(props) => (!props.isPopup ? 'unset' : '418px')};
      background: ${(props) => (!props.isPopup ? 'none' : colors.BACKGROUND)};
    }
  }
  .titles__sub-wrapper {
    max-width: 600px;
    margin: auto;
    text-align: center;
    padding: 16px;
    ${(props) =>
      props.isPopup &&
      `
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: ${breakpoints.S}px) {
          display: block;
        }
    `};
    @media (min-width: ${breakpoints.S}px) {
      padding: ${(props) => (!props.isPopup ? '24px' : '64px 24px 24px')};
    }
  }
  .titles-wrapper__main-title {
    font-family: 'Ogg-text', serif;
    font-size: 24px;
    line-height: 110%;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: ${breakpoints.S - 1}px) {
      font-size: 32px;
    }
  }
  .titles-wrapper__description {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 150%;
    b {
      font-weight: bold;
    }
  }
  .close-modal__wrapper {
    display: flex;
    align-items: center;
    top: 16px;
    right: 16px;
    @media (min-width: ${breakpoints.S}px) {
      position: absolute;
    }
  }
  .tabs__wrapper {
    min-width: auto;
    margin: auto;
    z-index: 20;
    background: #fff;
    padding-top: 8px;
    padding-bottom: 16px;
    overflow: auto;
    justify-content: flex-start;
    position: sticky;
    top: ${(props) => (!props.isPopup ? '55px' : '0px')};
    @media (min-width: ${breakpoints.S - 1}px) {
      position: initial;
      padding-top: 16px;
      padding-bottom: ${(props) => (!props.isPopup ? '24px' : '0px')};
      max-width: ${(props) => (!props.isPopup ? '980px' : '80%')};
    }
  }
`;

const SizeGuide = ({ isPopup }: { isPopup?: boolean }) => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const [tabIndexToFocus, setTabIndexToFocus] = React.useState(0);

  React.useEffect(() => {
    dispatch(cmsUspInit() as unknown as UnknownAction);
  }, []);

  React.useEffect(() => {
    if (hash && parseInt(hash.replace('#', ''), 10) < sizeGuideTabList.length) {
      setTabIndexToFocus(parseInt(hash.replace('#', ''), 10));
    }
  }, [hash]);

  const cmsUsp = useSelector((state: RootState) => state.cms.usp);
  const { usp, usp_main_title } = cmsUsp ?? '';

  const sizeGuideTabList = [
    {
      title: 'Soutiens-gorge',
      icon: 'productBra',
      content: (
        <div className="size-guide-tables__wrapper">
          <SizeGuideSection
            sizeGuideData={sizingGuideBraData}
            id="Soutiens-gorge"
            arrayOfVisual={sizingGuideBraVisual}
            isPopup={isPopup}
          />
        </div>
      ),
    },
    {
      title: 'Culottes',
      icon: 'productPanty',
      content: (
        <div className="size-guide-tables__wrapper">
          <SizeGuideSection
            sizeGuideData={sizingGuidePantyData}
            id="Culotte"
            arrayOfVisual={sizingGuidePantyVisual}
            isPopup={isPopup}
          />
        </div>
      ),
    },
    {
      title: 'Pyjamas',
      icon: 'productNightwear',
      content: (
        <div className="size-guide-tables__wrapper">
          <SizeGuideSection
            sizeGuideData={sizingGuidePyjamaData}
            id="Bas de pyjamas"
            isPopup={isPopup}
          />
          <SizeGuideSection
            sizeGuideData={sizingGuideBabyDollData}
            id="Hauts de pyjamas & nuisettes"
            isPopup={isPopup}
          />
        </div>
      ),
    },
    {
      title: 'Maillots de bain',
      icon: 'productBody',
      content: (
        <div className="size-guide-tables__wrapper">
          <SizeGuideSection
            sizeGuideData={sizingGuideSwimwearTopData}
            id="Haut maillots de bain"
            isPopup={isPopup}
          />
          <SizeGuideSection
            sizeGuideData={sizingGuideSwimwearBottomData}
            id="Bas maillots de bain"
            isPopup={isPopup}
          />
          <SizeGuideSection
            sizeGuideData={sizingGuideOnePieceSwimwearData}
            id="Maillots de bain une pièce"
            isPopup={isPopup}
          />
        </div>
      ),
    },
    {
      title: 'Softstretch',
      icon: 'productSet',
      content: (
        <div className="size-guide-tables__wrapper">
          <SizeGuideSection
            sizeGuideData={sizingGuideSoftstretchData}
            id="Softstretch"
            isPopup={isPopup}
          />
        </div>
      ),
    },
  ];
  if (!isPopup) {
    sizeGuideTabList.push({
      title: "Guide d'essayage",
      icon: 'productConfortBra',
      content: (
        <SizeGuideFittingAdvice id="Guide d'essayage" arrayOfVisual={sizingGuideFittingAdvice} />
      ),
    });
  }

  return (
    <StyledSizeGuide isPopup={isPopup}>
      <Helmet>
        <title>Guide des tailles</title>
      </Helmet>
      <div className="titles__wrapper">
        <div className="titles__sub-wrapper">
          {!isPopup ? (
            <h1 className="titles-wrapper__main-title">{SIZE_GUIDE_TITLE_LP}</h1>
          ) : (
            <h2 className="titles-wrapper__main-title">{SIZE_GUIDE_TITLE_POPUP}</h2>
          )}
          {isPopup && (
            <div className="close-modal__wrapper">
              <Icon
                data-cy="ereservation-icon-close-mobile"
                name="closeSmall"
                size={16}
                onClick={() => dispatch(closeModal())}
              />
            </div>
          )}
          {!isPopup && (
            <p className="titles-wrapper__description">
              Choisir sa taille de lingerie n&apos;est pas toujours une évidence. On n&apos;a pas de
              formule magique car
              <b> votre ressenti et vos préférences sont tout à fait uniques. </b>
              Certaines d&apos;entre vous préfèrent être maintenues très fort, d&apos;autres non...
              On a quand même des astuces à vous donner pour que vous vous sentiez au mieux !
            </p>
          )}
        </div>
      </div>
      <Tabs
        id="size-guide-tabs"
        items={sizeGuideTabList}
        defaultTabIndex={tabIndexToFocus}
        isSizeGuide
      />

      {isPopup && <SizeGuideFooter />}
      {!isPopup && Boolean(usp?.length) && (
        <UspSection content={usp} title={usp_main_title ?? ''} />
      )}
    </StyledSizeGuide>
  );
};
export { SizeGuide };
