import React from 'react';
import Slider from 'react-slick';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { CmsCenteredPromoBoxBanner } from '../../cms/types';
import { Direction, Icon, colors } from '../../../design-system';
import { CmsBox } from '../../common/components/Custom';
import { breakpoints } from '../../../design-system/systemprovider/constants';
import { zIndex } from '../../common/constants';
import { emptyText } from '../../cms/state';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  banners: CmsCenteredPromoBoxBanner[] | undefined | null;
};

export const CenteredPromoBoxBanner = ({ banners }: Props) => {
  const { isMobile } = useMediaQueries();
  if (!banners?.length) {
    return null;
  }

  const bannerContent = banners.map((banner, index) => {
    const imgDesktop = banner.background_image?.desktop;
    const imgLaptopBig = banner.background_image?.['laptop-big'];
    const imgLaptop = banner.background_image?.laptop;
    const imgMobile = banner.background_image_mobile;
    const shouldDisplayDoubleBox =
      (isMobile &&
        hasValidCmsText(banner.promo_text_left_mobile) &&
        hasValidCmsText(banner.promo_text_right_mobile)) ||
      (!isMobile &&
        hasValidCmsText(banner.promo_text_left) &&
        hasValidCmsText(banner.promo_text_right));

    return (
      <div
        id={`centered-promo-box-banner-${index}`}
        key={`centered-promo-box-banner-${index}`}
        css={css`
          position: relative;
        `}
      >
        <picture>
          <source media={`(max-width: 500px)`} srcSet={`${imgMobile?.url}`} />
          <source
            media={`(max-width: ${imgLaptop?.dimensions?.width}px)`}
            srcSet={`${imgLaptop?.url}`}
          />
          <source
            media={`(max-width: ${imgLaptopBig?.dimensions?.width}px)`}
            srcSet={`${imgLaptopBig?.url}`}
          />
          <img
            src={imgDesktop?.url ?? ''}
            alt={banner.background_image?.alt ?? ''}
            object-fit="contain"
            width="100%"
          />
        </picture>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 24px;
            left: 50%;
            transform: translate(-50%, 0%);
            max-width: min(calc(100vw - 32px), 390px);
            width: 100%;
          `}
        >
          <CmsBox width="100%">
            {hasValidCmsText(banner.promo_text_outside) && (
              <div
                css={css`
                  display: none;
                  @media (min-width: ${breakpoints.S}px) {
                    color: ${banner.promo_text_outside_color ?? colors.BLACK};
                    margin-bottom: 16px;
                    display: block;
                  }
                `}
              >
                <RichText render={banner.promo_text_outside} />
              </div>
            )}
            {hasValidCmsText(banner.promo_text_outside_mobile) && (
              <div
                css={css`
                  color: ${banner.promo_text_outside_mobile_color ?? colors.BLACK};
                  margin-bottom: 16px;
                  display: block;
                  @media (min-width: ${breakpoints.S}px) {
                    display: none;
                  }
                `}
              >
                <RichText render={banner.promo_text_outside_mobile} />
              </div>
            )}
            <div
              css={css`
                background: ${banner.box_background_color ?? colors.WHITE};
                color: ${banner.font_color ?? colors.BLACK};
                padding: 16px;
              `}
            >
              <RichText
                render={
                  (isMobile ? banner.promo_text_main_mobile : banner.promo_text_main) ?? [
                    ...emptyText,
                  ]
                }
              />
              {shouldDisplayDoubleBox && (
                <div
                  id={`centered-promo-box-double-block-${index}`}
                  css={css`
                    display: flex;
                    align-items: stretch;
                    margin-top: 16px;
                  `}
                >
                  <div
                    id={`centered-promo-box-left-${index}`}
                    css={css`
                      flex: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-right: solid ${colors.BLACK} 0.5px;
                      box-sizing: border-box;
                    `}
                  >
                    <RichText
                      render={
                        (isMobile ? banner.promo_text_left_mobile : banner.promo_text_left) ?? [
                          ...emptyText,
                        ]
                      }
                    />
                  </div>
                  <div
                    id={`centered-promo-box-right-${index}`}
                    css={css`
                      flex: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-left: solid ${colors.BLACK} 0.5px;
                      box-sizing: border-box;
                    `}
                  >
                    <RichText
                      render={
                        (isMobile ? banner.promo_text_right_mobile : banner.promo_text_right) ?? [
                          ...emptyText,
                        ]
                      }
                    />
                  </div>
                </div>
              )}

              {banner.link && hasValidCmsText(banner.promo_link_text) && (
                <a
                  id={`centered-promo-box-banner-link-${index}`}
                  href={banner.link}
                  css={css`
                    text-underline-position: under;
                  `}
                >
                  <div
                    css={css`
                      color: ${banner.font_color ?? colors.BLACK};
                      margin-top: 16px;
                    `}
                  >
                    <RichText render={banner.promo_link_text} />
                  </div>
                </a>
              )}
            </div>
          </CmsBox>
        </div>
      </div>
    );
  });

  return bannerContent.length === 1 ? (
    bannerContent[0]
  ) : (
    <Slider
      infinite={true}
      slidesToShow={1}
      adaptiveHeight={true}
      prevArrow={
        <Arrow
          id="centered-promo-banner-arrow-button-left"
          data-cy="centered-promo-banner-arrow-button-left"
          iconName="chevronLeft"
          direction={Direction.LEFT}
        />
      }
      nextArrow={
        <Arrow
          id="centered-promo-banner-arrow-button-right"
          data-cy="centered-promo-banner-arrow-button-right"
          iconName="chevronRight"
          direction={Direction.RIGHT}
        />
      }
    >
      {bannerContent}
    </Slider>
  );
};

export function Arrow({
  id,
  iconName,
  direction,
  onClick,
}: {
  id: string;
  iconName: string;
  direction: Direction;
  onClick?: () => void;
}) {
  return onClick ? (
    <StyledArrowButton id={id} onClick={onClick} direction={direction}>
      <span>
        <Icon name={iconName} />
      </span>
    </StyledArrowButton>
  ) : null;
}

type StyledArrowButtonProps = {
  direction: Direction;
};

const StyledArrowButton = styled.button<StyledArrowButtonProps>`
  all: unset; // reset button styles
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zIndex.ARROW_SLIDER};
  width: 54px;
  height: 54px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  left: ${(props) => (props.direction === Direction.LEFT ? '16px' : 'unset')};
  right: ${(props) => (props.direction === Direction.RIGHT ? '16px' : 'unset')};
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :focus {
    background-color: rgba(255, 255, 255, 1);
  }
  @media (hover: hover) {
    :hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;
