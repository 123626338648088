import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { RecommendationsT2S } from '../../recommendations/components/RecommendationsT2S';
import { RecommendationTypes } from '../../recommendations/types';
import { setUpSellLoading } from '../actions';

const UpSell = () => {
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.upSell
  );
  const { up_sell_title: title } = useSelector((state: RootState) => state.cms.product);
  const { hits } = useProductsInfos(recommendedProducts);

  React.useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setUpSellLoading(false));
    }
  }, [recommendedProducts, hits, isFetching]);

  if (!recommendedProducts || isFetching) {
    return null;
  }

  return (
    <Box mx={['-16px', 'na']} overflow="hidden">
      <RecommendationsT2S
        hits={hits}
        type={RecommendationTypes.upSell}
        tracking={tracking}
        title={title}
      />
    </Box>
  );
};

export default UpSell;
