import * as React from 'react';
import { css } from '@emotion/core';

import { CmsHomepageBanner } from '../../../cms/types';
import { Box, breakpoints, Text } from '../../../../design-system';
import { ImageWrapperLink } from '../../../common/components/Custom';
import { useDevice } from '../../../common/hooks/useDevice';
import { BannerVideo } from '../../components/BannerVideo';
import { BannerText } from '../../components/BannerText';
import { Timer } from '../../../cms';
import { getImageHeight, getTextCentered } from '../../utils';
import { BannerCta } from '../../components/BannerCta';
import { useMediaQuery } from '../../../common/hooks/useMediaQuery';

type Props = {
  banner: CmsHomepageBanner;
  index: string;
  isHalf?: boolean;
  ctaLink?: string;
};

export const HomeBanner = ({ banner, index, isHalf, ctaLink }: Props) => {
  const { isDesktop } = useDevice();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.S - 1}px)`);

  const {
    alignment,
    background_color_mobile,
    background_color,
    conditions_text_color,
    conditions,
    cta_color_mobile,
    cta_color,
    cta_font_color_mobile,
    cta_font_color,
    cta_link,
    cta_text,
    cta_type_mobile,
    cta_type,
    font_family_subtitle,
    font_family_title,
    image_mobile,
    image_position,
    image,
    justification,
    promotion_end_date_time: timestamp,
    subtitle_size_desktop,
    subtitle_size_mobile,
    subtitle,
    text_position_for_double_image_in_type_full,
    text_color_mobile,
    text_color,
    text_right,
    text_up,
    text,
    title_alignment,
    title_size_desktop,
    title_size_mobile,
    title,
    video,
    video_mobile,
  } = banner ?? {};

  const isFullWidth = image_position === 'full';

  const getGridTemplateAreas = () => {
    if (isHalf) {
      return "'content'";
    } else if (
      image_position === 'left' ||
      (isFullWidth &&
        text_position_for_double_image_in_type_full === 'text centered on right image')
    ) {
      return "'space1 content'";
    } else if (
      image_position === 'right' ||
      (isFullWidth && text_position_for_double_image_in_type_full === 'text centered on left image')
    ) {
      return "'content space1'";
    } else if (justification === 'right') {
      return "'space1 space2 content'";
    } else if (justification === 'left') {
      return "'content space1 space2'";
    }
    return "'space1 content space2'";
  };

  const gridTemplateColumns = isHalf
    ? '1fr'
    : isFullWidth && !text_position_for_double_image_in_type_full
    ? '1fr 1fr 1fr'
    : '1fr 1fr';

  const { full, half } = image ?? {};

  const url = isDesktop
    ? isFullWidth && !isHalf
      ? full?.url ?? ''
      : half?.url ?? ''
    : image_mobile?.url ?? '';

  const ctaType = isDesktop ? cta_type : cta_type_mobile;
  const ctaColor = isDesktop ? cta_color : cta_color_mobile;
  const ctaFontColor = isDesktop ? cta_font_color : cta_font_color_mobile;
  const ctaId = `hp-banner-cta-${index}`;
  const link = ctaLink ?? cta_link ?? '';

  const conditionsColor = conditions_text_color ?? 'WHITE';
  const backgroundColor = isDesktop
    ? background_color || 'BACKGROUND'
    : background_color_mobile || background_color || 'BACKGROUND';
  const textColor = isDesktop ? text_color || 'WHITE' : text_color_mobile || text_color || 'WHITE';

  const hasText = text?.[0]?.text || text_right?.[0]?.text || text_up?.[0]?.text;

  const isMobileCtaOutside = ctaType ? ctaType.includes('link') : false;
  const isDesktopCtaWithText = !isHalf;

  const width = isDesktop ? (isFullWidth || isHalf ? '100%' : '50%') : '100vw';
  const height = video
    ? 'auto'
    : getImageHeight({ image: isDesktop ? image : image_mobile, width });

  const fragments = {
    conditions: conditions ? (
      <Text color={conditionsColor} preset="caption" textAlign="left" mx={['m', 'na']}>
        {conditions}
      </Text>
    ) : null,
    cta: cta_text ? (
      <Box
        width={isDesktop ? '240px' : '207px'}
        maxHeight={!isDesktop ? '32px' : '44px'}
        overflow="hidden"
        my={[isMobileCtaOutside ? 's' : 'm', 'na']}
        mx="auto"
        display="flex"
        alignItems="center"
        justifyContent={
          isDesktop
            ? alignment === 'right'
              ? 'flex-end'
              : alignment === 'left'
              ? 'flex-start'
              : 'center'
            : 'center'
        }
      >
        <BannerCta
          id={ctaId}
          type={ctaType}
          text={cta_text ?? ''}
          color={ctaColor}
          fontColor={ctaFontColor}
        />
      </Box>
    ) : null,
    text: hasText ? (
      <BannerText
        textColor={textColor}
        text_up={text_up}
        text_right={text_right}
        text={text}
        isDesktop={isDesktop}
      />
    ) : null,
    video:
      video && video_mobile ? (
        <div
          css={css`
            position: relative;
            padding-top: 125%;
            @media (min-width: ${breakpoints.S}px) {
              padding-top: 42.8125%;
            }
          `}
        >
          <BannerVideo video={isMobile ? video_mobile : video} banner_type={banner.type} />
        </div>
      ) : null,
    timer: timestamp ? (
      <Box mt="m" mb="xxl">
        <Timer timestamp={timestamp} textColor={textColor} preset="bannerLarge" />
      </Box>
    ) : null,
  };
  const titleSize = isDesktop ? title_size_desktop : title_size_mobile;
  const subtitleSize = isDesktop ? subtitle_size_desktop : subtitle_size_mobile;

  return (
    <>
      {(title || subtitle) && (
        <Box
          display="grid"
          textAlign={['center', 'center', title_alignment]}
          gridGap="s"
          mx={['m', 'm', 'na']}
          alignItems="end"
          mb={['16px', '24px']}
        >
          {title && (
            <Text
              preset="bannerSmall"
              fontFamily={font_family_title ? font_family_title : 'Ogg-text'}
              fontWeight={'700'}
              fontSize={`${titleSize} !important`}
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text
              preset={['body', 'subheading']}
              color="BLACK"
              fontSize={`${subtitleSize} !important`}
              fontFamily={font_family_subtitle ? font_family_subtitle : 'MaisonNeue'}
              mb={isDesktop ? '' : '8px'}
            >
              {subtitle}
            </Text>
          )}
        </Box>
      )}
      {isDesktop ? (
        <Box height={height}>
          {video ? (
            fragments.video
          ) : (
            <Box display="grid" gridGap="s">
              <ImageWrapperLink id={`hp-banner-wrapper-${index}`} to={link} decoration="none">
                <Box
                  position="relative"
                  backgroundColor={backgroundColor}
                  display="flex"
                  justifyContent={image_position === 'full' ? 'initial' : image_position}
                >
                  <img src={url} alt="" width={width} height={height} />
                  <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    mx="auto"
                    display="grid"
                    textAlign={alignment}
                    gridTemplateAreas={getGridTemplateAreas()}
                    gridTemplateColumns={gridTemplateColumns}
                    gridAutoRows="100%"
                  >
                    <Box gridArea="space1" />
                    <Box gridArea="space2" />
                    <Box
                      gridArea="content"
                      width="100%"
                      height="100%"
                      display="grid"
                      gridAutoFlow="row"
                      gridAutoRows="1fr auto"
                      justifyContent="center"
                    >
                      <Box
                        display="grid"
                        alignSelf={
                          isFullWidth &&
                          (getGridTemplateAreas() === "'space1 content space2'" ||
                            text_position_for_double_image_in_type_full)
                            ? hasText
                              ? 'center'
                              : 'end'
                            : 'center'
                        }
                        marginBottom={
                          isFullWidth &&
                          (getGridTemplateAreas() === "'space1 content space2'" ||
                            text_position_for_double_image_in_type_full)
                            ? hasText
                              ? 'na'
                              : '40px'
                            : 'na'
                        }
                      >
                        <Box>{fragments.text}</Box>
                        {fragments.timer}
                        {isDesktopCtaWithText && (
                          <Box justifySelf={alignment} alignSelf="end" pt="xxl">
                            {fragments.cta}
                          </Box>
                        )}
                      </Box>
                      {!isDesktopCtaWithText && (
                        <Box justifySelf={alignment} alignSelf="end" pb="xxl">
                          {fragments.cta}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </ImageWrapperLink>
              {fragments.conditions}
            </Box>
          )}
        </Box>
      ) : (
        <Box display="grid" gridGap="s">
          <ImageWrapperLink id={`hp-banner-wrapper-${index}`} to={link} decoration="none">
            <Box
              backgroundColor={backgroundColor}
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              {hasText && (
                <Box pt={getTextCentered(text, banner)} pb="s">
                  {fragments.text}
                </Box>
              )}
              {hasText && !isMobileCtaOutside && (
                <div
                  css={css`
                    @media (min-width: ${breakpoints.S}px) {
                      padding-bottom: 16px;
                    }
                  `}
                >
                  {fragments.cta}
                </div>
              )}
              {fragments.timer}
              {video ? (
                <Box width={width} height={height}>
                  {fragments.video}
                </Box>
              ) : (
                <Box
                  backgroundImage={`url(${url})`}
                  backgroundSize="cover"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="top"
                  width={width}
                  height={height}
                  display="grid"
                  alignItems="end"
                  justifyContent="center"
                >
                  {!isMobileCtaOutside && !hasText && (
                    <div
                      css={css`
                        @media (min-width: ${breakpoints.S}px) {
                          padding-bottom: 16px;
                        }
                      `}
                    >
                      {fragments.cta}
                    </div>
                  )}
                </Box>
              )}
            </Box>
          </ImageWrapperLink>
          {fragments.conditions}
          {isMobileCtaOutside && fragments.cta}
        </Box>
      )}
    </>
  );
};
