import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router';

import { Box, Tabs, Text } from '../../../design-system';
import { opacities } from '../../common/constants';
import { useDevice } from '../../common/hooks/useDevice';
import {
  ERR_LOCATION,
  MSG_NO_STORE_FOUND,
  PAGE_TITLE,
  ERR_LOCATION_NOT_ACTIVE,
  LBL_DELIVERY_SEARCH_TITLE,
} from '../locale';
import { RootState } from '../../../store/rootReducer';
import { getAllStores, getStoresByLocation } from '../actions';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import { SubTitle } from '../../common/components/Custom';
import Search from './Search';
import {
  BASE_LOC,
  createMap,
  createPositionMarker,
  GEO_LOCATE_TIMEOUT_MS,
  removeDiacritics,
} from '../utils';
import Map from './Map';
import useGoogleMapsAutocomplete from '../../common/hooks/useGoogleMapsAutocomplete';
import { StoreAutoComplete } from './StoreAutoComplete';
import {
  Bounds,
  MapEvents,
  MapState,
  Position,
  StoresListType,
  Suggestion,
  ZoomLevels,
} from '../types';
import { StoreList } from './StoreList';
import ResultsTitle from './ResultsTitle';
import { ERR_REQUIRED } from '../../form/locale';
import useMarkers from '../hooks/useMarkers';
import usePrevious from '../../common/hooks/usePrevious';
import StoreServices from './StoreServices';
import { getAreaCoordinates, getAddress } from '../../api';
import { setStoreId } from '../../cart/actions';
import { closeModal } from '../../common/actions';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { ScrollbarWrapper } from '../../ecart/components/EresaSummary';

let areaLayer: google.maps.Polygon;

type Props = {
  search?: string;
  selectedStoreId?: string;
  toBePositioned?: boolean;
  isDelivery?: boolean;
};
export const StoreLocator = ({
  search = '',
  selectedStoreId = '',
  isDelivery,
  toBePositioned,
}: Props) => {
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useDevice();

  const cmsContent = useSelector((state: RootState) => state.cms.storelocator);
  const title = cmsContent?.title ?? '';
  const body = cmsContent?.body ?? '';
  const placeholder = cmsContent?.placeholder ?? '';
  const navigate = useNavigate();

  const { search: querySearch } = useLocation();
  const queryParams = new URLSearchParams(querySearch);
  const address = querySearch && queryParams.get('adresse') ? queryParams.get('adresse') : '';

  const [searchText, setSearchText] = React.useState(search);
  const [lastSearch, setLastSearch] = React.useState('');
  const [isPositioned, setIsPositioned] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');
  const [mapState, setMapState] = React.useState<MapState>({ loading: true });
  const [forceAutcompleteClosing, setForceAutcompleteClosing] = React.useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = React.useState(selectedStoreId);
  const [highlightedMarker, setHighlightedMarker] = React.useState('');
  const [currentPosition, setCurrentPosition] = React.useState<Position>();
  const [isLocating, setIsLocating] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(ZoomLevels.COUNTRY);
  const prevZoomLevel = usePrevious(zoomLevel);
  const [isFitBounds, setIsFitBounds] = React.useState(true);
  const [positionMarker, setPositionMarker] = React.useState<google.maps.Marker>();
  const [areaCoordinates, setAreaCoordinates] = React.useState([[]]);

  const {
    isFetching,
    storesByLocation: { stores, geoCoordinate },
  } = useSelector((state: RootState) => state.stores) ?? [];

  const mapRef = React.useRef<HTMLDivElement | null>(null);
  const searchRef = React.useRef<HTMLInputElement | null>(null);
  const storeListRef = React.useRef<HTMLDivElement | null>(null);
  const storeRefs = stores
    .sort((a, b) => {
      if (searchText.length === 0) {
        if (!a.address?.postalCode || !b.address?.postalCode) {
          return 0;
        }
        return parseInt(a.address?.postalCode, 10) < parseInt(b.address?.postalCode, 10) ? -1 : 1;
      }
      return 0;
    })
    .reduce((acc, _value, currentIndex) => {
      acc[currentIndex] = React.createRef();
      return acc;
    }, {});

  const loadStores = (
    {
      lat,
      lng,
      address = '',
      bounds,
    }: {
      lat?: number;
      lng?: number;
      address?: string;
      bounds?: Bounds;
    },
    isDelivery?: boolean
  ) => {
    dispatch(
      getStoresByLocation({
        params: {
          bounds,
          lat,
          lng,
          address,
        },
        isClickAndCollect: isDelivery,
      }) as unknown as UnknownAction
    );
    setSelectedMarker('');
    setErrMsg('');
  };

  React.useEffect(() => {
    if (address) {
      dispatch(
        getAllStores({ isClickAndCollect: Boolean(isDelivery) }) as unknown as UnknownAction
      );
      setSearchText(address);
      handleSearchOnMount(address);
    } else if (!isDelivery) {
      dispatch(getAllStores({}) as unknown as UnknownAction);
    }
    if (!title) {
      dispatch(loadCmsContent(CmsCustomTypes.storelocator) as unknown as UnknownAction);
    }
    return () => {
      dispatch(
        getAllStores({
          offlineMode: true,
          isClickAndCollect: isDelivery,
        }) as unknown as UnknownAction
      );
    };
  }, []);

  React.useEffect(() => {
    const listenerZoom = mapState.map?.addListener(MapEvents.zoomChanged, handleZoomChange);
    const listenerDrag = mapState.map?.addListener(MapEvents.dragend, handleDragEvent);

    return () => {
      listenerZoom && google.maps.event.removeListener(listenerZoom);
      listenerDrag && google.maps.event.removeListener(listenerDrag);
    };
  }, [mapState.map]);

  React.useEffect(() => {
    if (stores.length && selectedMarker) {
      const { maps, map } = mapState;
      const selectedStore = stores.filter((store) => store.id === selectedMarker);
      if (maps && map && selectedStore.length) {
        map.setZoom(ZoomLevels.CITY);
      }
    }
  }, [selectedMarker]);

  React.useEffect(() => {
    const fetchCoordinates = async (searchText) => {
      const results = await getAreaCoordinates(searchText);
      if (results && results.length) {
        const filteredResults = results.filter((item) =>
          ['administrative', 'postal_code'].includes(item['type'])
        );
        if (filteredResults.length) {
          setAreaCoordinates(filteredResults[0]['geojson']['coordinates']);
        }
        return setAreaCoordinates([]);
      }
      return setAreaCoordinates([]);
    };

    if (stores.length) {
      const { maps, map } = mapState;

      if (maps && map && geoCoordinate) {
        const position = {
          lat: geoCoordinate.latitude,
          lng: geoCoordinate.longitude,
        };
        const dbounds = new maps.LatLngBounds(position);

        if (isFitBounds) {
          searchText && fetchCoordinates(searchText);
          setCurrentPosition(position);
          map.fitBounds(dbounds);
          stores.forEach((store) => {
            dbounds.extend({
              lat: store?.routableCoordinate?.latitude || store?.displayCoordinate?.latitude,
              lng: store?.routableCoordinate?.longitude || store?.displayCoordinate?.longitude,
            });
          });
          map.fitBounds(dbounds);
          isMobile && map.getZoom() === 0 && map.setZoom(ZoomLevels.COUNTRY);
        }

        if (stores.length === 1) {
          const centerPosition = {
            lat: stores[0].displayCoordinate.latitude,
            lng: stores[0].displayCoordinate.longitude,
          };
          map.panTo(centerPosition);
        }
      }
    }
    if (storeListRef?.current) {
      storeListRef.current.scrollTop = 0;
    }
  }, [stores]);

  React.useEffect(() => {
    const { map } = mapState;

    if (map) {
      const polygonCoordinates: google.maps.LatLngLiteral[] =
        areaCoordinates[0] &&
        areaCoordinates[0].map((coordiante) => ({
          lng: coordiante[0],
          lat: coordiante[1],
        }));

      if (Array.isArray(polygonCoordinates)) {
        areaLayer && areaLayer.setMap(null);
        areaLayer = new google.maps.Polygon({
          paths: polygonCoordinates,
          strokeColor: '#e78981',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#fff',
          fillOpacity: 0.5,
        });

        areaLayer.setMap(map);
      }
    }
  }, [areaCoordinates]);

  React.useEffect(() => {
    if (stores.length && highlightedMarker) {
      const { maps, map } = mapState;
      const highlightedStore = stores.filter((store) => store.id === highlightedMarker);
      if (maps && map && highlightedStore.length) {
        const centerPosition = {
          lat: highlightedStore[0].displayCoordinate.latitude,
          lng: highlightedStore[0].displayCoordinate.longitude,
        };

        map.panTo(centerPosition);
      }
    }
  }, [highlightedMarker]);

  React.useEffect(() => {
    const initMap = async (element: HTMLDivElement) => {
      const { map, sessionToken, autocomplete, maps } = await createMap({
        center: { ...BASE_LOC },
        element,
      });
      setMapState({ loading: false, map, sessionToken, autocomplete, maps });
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
  }, [mapRef]);

  React.useEffect(() => {
    if (mapState.map && zoomLevel === prevZoomLevel - 1 && zoomLevel >= ZoomLevels.COUNTRY) {
      loadStoresInView();
    } else {
      setIsFitBounds(true);
    }
  }, [zoomLevel]);

  React.useEffect(() => {
    if (search) {
      setTimeout(() => {
        handleSearch(search);
      }, 500);
    }
  }, [search]);

  React.useEffect(() => {
    if (toBePositioned && mapState.map) {
      setTimeout(() => {
        handleLocate();
      }, 500);
    }
  }, [toBePositioned, mapState.map]);

  const loadStoresInView = () => {
    const getBounds = mapState.map?.getBounds();
    const getCenter = mapState.map?.getCenter();
    const bounds: Bounds = {
      northeast: {
        lat: getBounds?.getNorthEast().lat() || BASE_LOC.lat + 0.01,
        lng: getBounds?.getNorthEast().lng() || BASE_LOC.lng + 0.01,
      },
      southwest: {
        lat: getBounds?.getSouthWest().lat() || BASE_LOC.lat - 0.01,
        lng: getBounds?.getSouthWest().lng() || BASE_LOC.lng - 0.01,
      },
    };
    setIsFitBounds(false);
    loadStores({ lat: getCenter?.lat(), lng: getCenter?.lng(), bounds }, isDelivery);
  };

  const predictions = useGoogleMapsAutocomplete(
    mapState.sessionToken,
    mapState.autocomplete,
    searchText
  );

  const suggestionsList: Suggestion[] = predictions.map((prediction) => {
    return { name: prediction.description };
  });

  const handleHighlightedMarkerChange = (id: string) => {
    if (zoomLevel > 5) {
      setHighlightedMarker(id);
    }
  };
  const handleChange = (value: string) => {
    setForceAutcompleteClosing(false);
    setSearchText(value);
    if (!value) {
      dispatch(
        getAllStores({
          offlineMode: true,
          isClickAndCollect: isDelivery,
        }) as unknown as UnknownAction
      );
    }
    if (errMsg) {
      setErrMsg('');
    }
  };

  const handleLocate = () => {
    if (positionMarker) {
      positionMarker.setMap(null);
    }

    if (navigator.geolocation && mapState.map) {
      const { map } = mapState;
      setIsLocating(true);
      setSearchText('');
      navigator.geolocation.getCurrentPosition(
        async function (pos) {
          const { coords } = pos;
          const { latitude, longitude } = coords;
          const position = {
            lat: latitude,
            lng: longitude,
          };
          mapState.map?.setZoom(ZoomLevels.CITY);
          mapState.map?.setCenter(position);
          createPositionMarker({ map, position });
          setPositionMarker(positionMarker);
          setIsPositioned(true);
          setIsLocating(false);
          setCurrentPosition(position);
          const city = await getAddress(position.lat, position.lng);
          handleAutoComplete(
            `${city.address.road ?? ''} ${city.address.postcode ?? ''} ${city.address.city ?? ''}`
          );
        },
        function () {
          setErrMsg(ERR_LOCATION_NOT_ACTIVE);
          setIsLocating(false);
        },
        {
          timeout: GEO_LOCATE_TIMEOUT_MS,
        }
      );
    } else {
      setErrMsg(ERR_LOCATION);
    }
  };

  const handleZoomChange = () => {
    const zoom = mapState.map?.getZoom() ?? ZoomLevels.COUNTRY;
    setZoomLevel(zoom);
  };

  const handleDragEvent = () => {
    const zoom = mapState.map?.getZoom() ?? 0;
    if (zoom > ZoomLevels.COUNTRY) {
      loadStoresInView();
    }
  };

  const handleSearch = (searchTerm = searchText) => {
    if (searchTerm) {
      if (!isDelivery) {
        navigate({ search: `adresse=${searchTerm}` });
      }
      setIsFitBounds(true);
      loadStores({ address: removeDiacritics(searchTerm) }, isDelivery);
      setLastSearch(searchTerm);
      if (isPositioned) {
        setIsPositioned(false);
      }
    } else {
      setErrMsg(ERR_REQUIRED);
    }
  };

  const handleSearchOnMount = (searchTerm = searchText) => {
    if (searchTerm) {
      setIsFitBounds(true);
      loadStores({ address: removeDiacritics(searchTerm) }, isDelivery);
      setLastSearch(searchTerm);
      if (isPositioned) {
        setIsPositioned(false);
      }
    } else {
      setErrMsg(ERR_REQUIRED);
    }
  };

  const handleAutoComplete = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
    setForceAutcompleteClosing(true);
  };

  const handleStoreSelect = (id: string) => {
    dispatch(setStoreId(id));
    dispatch(closeModal());
  };

  useMarkers({
    map: mapState.map,
    stores,
    selectedMarker,
    highlightedMarker,
    onClick: setSelectedMarker,
  });

  const itemsListing = (
    <>
      {stores.length < 1 ? (
        !isFetching && (
          <Text my="m" color="ERROR">
            {MSG_NO_STORE_FOUND}
          </Text>
        )
      ) : (
        <Box
          overflowX="hidden"
          overflowY="scroll"
          ref={storeListRef}
          scrollBehavior="smooth"
          maxHeight={['400px', 'calc(100% - 22px)']}
          opacity={isFetching || isLocating ? opacities.LOADING : 1}
          pointerEvents={isFetching || isLocating ? 'none' : 'initial'}
        >
          <StoreList
            onClick={setSelectedMarker}
            onMapLinkClick={() => {}}
            highlightMarker={handleHighlightedMarkerChange}
            onSelect={handleStoreSelect}
            storeslistType={
              isDelivery ? StoresListType.DELIVERY_STORE : StoresListType.STORE_LOCATOR
            }
            stores={stores}
            selectedMarker={selectedMarker}
            selectedStoreId={selectedStoreId}
            storeRefs={storeRefs}
            currentPosition={currentPosition}
            predictions={predictions}
            searchText={searchText}
            isPositioned={isPositioned}
          />
        </Box>
      )}
    </>
  );

  const mapContainer = (
    <Box height={['400px', '100%']}>
      <Map mapRef={mapRef} />
    </Box>
  );

  const items = [
    {
      title: 'Liste',
      content: itemsListing,
    },
    {
      title: 'Carte',
      content: mapContainer,
    },
  ];

  const tabsCommonComponent = (
    <>
      <Box ref={searchRef} mb="m">
        <Search
          onIconClick={handleLocate}
          onChange={handleChange}
          onClick={handleSearch}
          value={searchText}
          errMsg={errMsg}
          placeholder={placeholder}
          isPositioned={isPositioned}
          setForceAutcompleteClosing={setForceAutcompleteClosing}
        />
        <StoreAutoComplete
          suggestionsList={suggestionsList}
          query={searchText}
          onClick={handleAutoComplete}
          forceAutcompleteClosing={forceAutcompleteClosing}
          isStoreLocator
          isPositionAbsolute
        />
      </Box>
      <ResultsTitle {...{ lastSearch, isPositioned }} nbResults={stores.length} />
    </>
  );
  const { loading: mapIsLoading } = mapState;
  const [showMapMobile, setShowMapMobile] = React.useState(false);

  return isDelivery ? (
    <Box
      display="flex"
      width="100%"
      height="466px"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile && stores.length === 1 ? 'initial' : 'space-between'}
      opacity={isFetching || isLocating ? opacities.LOADING : 1}
    >
      <Box display="flex" flexDirection="column" width={isMobile ? '100%' : 'calc(50% - 16px)'}>
        <Box ref={searchRef} mb="s" position="relative">
          <Box width="100%" mb="s" textAlign="left" fontSize="1.4rem" lineHeight="110%">
            {LBL_DELIVERY_SEARCH_TITLE}
          </Box>
          <Search
            onIconClick={handleLocate}
            onChange={handleChange}
            onClick={handleSearch}
            value={searchText}
            isPositioned={isPositioned}
            placeholder={placeholder}
            errMsg={errMsg}
            setForceAutcompleteClosing={setForceAutcompleteClosing}
            isDelivery
          />
          <StoreAutoComplete
            suggestionsList={suggestionsList}
            query={searchText}
            onClick={handleAutoComplete}
            forceAutcompleteClosing={forceAutcompleteClosing}
            isPositionAbsolute
          />
          <Box my="m">
            <ResultsTitle
              lastSearch={lastSearch}
              isPositioned={isPositioned}
              nbResults={stores.length}
              isEresaModal={isDelivery}
            />
          </Box>
          {isMobile ? (
            <Box width="100%" display="flex">
              <Box
                data-cy="ereservation-tab-show-list-mobile"
                width="50%"
                height="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderBottom={showMapMobile ? 'solid 1px #E6E6E6' : 'solid 1px #000'}
                fontSize="16px"
                fontWeight={showMapMobile ? 400 : 700}
                textAlign="center"
                onClick={() => setShowMapMobile(false)}
              >
                Liste
              </Box>
              <Box
                data-cy="ereservation-tab-show-map-mobile"
                width="50%"
                height="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderBottom={!showMapMobile ? 'solid 1px #E6E6E6' : 'solid 1px #000'}
                fontSize="16px"
                fontWeight={showMapMobile ? 700 : 400}
                textAlign="center"
                onClick={() => setShowMapMobile(true)}
              >
                Carte
              </Box>
            </Box>
          ) : (
            <ScrollbarWrapper
              pointerEvents={isFetching || isLocating ? 'none' : 'initial'}
              overflowX="hidden"
              maxHeight="325px"
              mb={isDesktop ? 'na' : 'm'}
              ref={storeListRef}
            >
              <StoreList
                storeslistType={StoresListType.ERESERVATION_STORE}
                onMapLinkClick={() => null}
                onClick={setSelectedMarker}
                onSelect={handleStoreSelect}
                highlightMarker={setHighlightedMarker}
                currentStore={selectedStoreId}
                isDelivery={isDelivery}
                stores={stores}
                selectedMarker={selectedMarker}
                storeRefs={storeRefs}
                currentPosition={currentPosition}
                isPositioned={isPositioned}
                predictions={predictions}
                searchText={searchText}
              />
            </ScrollbarWrapper>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        mt={showMapMobile ? 's' : 'na'}
        flexDirection="column"
        width={isMobile ? '100%' : 'calc(50% - 8px)'}
      >
        <Box
          height={isMobile ? '353px' : '466px'}
          display={isMobile && !showMapMobile ? 'none' : 'block'}
          position="relative"
        >
          {(isLocating || mapIsLoading) && <LoadingScreen />}
          <Map mapRef={mapRef} />
        </Box>
        {isMobile && (
          <ScrollbarWrapper
            pointerEvents={isFetching || isLocating ? 'none' : 'initial'}
            mt={showMapMobile ? 'l' : 'm'}
            overflowX="hidden"
            maxHeight="542px"
            ref={storeListRef}
          >
            <StoreList
              storeslistType={StoresListType.ERESERVATION_STORE}
              onClick={setSelectedMarker}
              onSelect={handleStoreSelect}
              onMapLinkClick={() => null}
              highlightMarker={setHighlightedMarker}
              currentStore={selectedStoreId}
              isDelivery={isDelivery}
              stores={stores}
              selectedMarker={selectedMarker}
              storeRefs={storeRefs}
              currentPosition={currentPosition}
              isPositioned={isPositioned}
              predictions={predictions}
              searchText={searchText}
            />
          </ScrollbarWrapper>
        )}
      </Box>
    </Box>
  ) : (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta property="og:title" content={PAGE_TITLE} />
      </Helmet>
      <Box my="l" mx="m" display="grid" gridGap="l">
        <Box mx="auto" maxWidth="600px" textAlign="center" display="grid" gridGap="l">
          <SubTitle>{title}</SubTitle>
          {!isMobile && <Text>{body}</Text>}
        </Box>
        {isMobile ? (
          <Box>
            <Tabs id="store-locator-tabs" items={items} commonComponent={tabsCommonComponent} />
          </Box>
        ) : (
          <>
            <Box display="grid" gridGap="24px" gridTemplateColumns="1fr 1.5fr" gridAutoRows="600px">
              <Box position="relative">
                <Box ref={searchRef} mb="m">
                  <Search
                    onIconClick={handleLocate}
                    onChange={handleChange}
                    onClick={handleSearch}
                    value={searchText}
                    isPositioned={isPositioned}
                    placeholder={placeholder}
                    errMsg={errMsg}
                    setForceAutcompleteClosing={setForceAutcompleteClosing}
                  />
                  <StoreAutoComplete
                    suggestionsList={suggestionsList}
                    query={searchText}
                    onClick={handleAutoComplete}
                    forceAutcompleteClosing={forceAutcompleteClosing}
                    isStoreLocator
                    isPositionAbsolute
                  />
                </Box>

                <Box height="calc(100% - 66px)">
                  <ResultsTitle
                    lastSearch={lastSearch}
                    isPositioned={isPositioned}
                    nbResults={stores.length}
                  />
                  {itemsListing}
                </Box>
              </Box>
              {mapContainer}
            </Box>
            <Box maxWidth="800px" mx="auto">
              <StoreServices withoutTitle />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
