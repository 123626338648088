import types from './actionTypes';
import { CatalogActions } from './actions';

import { State } from './types';
import { catalogState } from './state';

export default function catalog(state = catalogState, action: CatalogActions): State {
  switch (action.type) {
    case types.UPDATE_CATALOG:
      return {
        ...state,
        catalog: action.payload.hits,
        indexName: action.payload.indexName,
      };
    case types.SWITCH_TILE:
      return {
        ...state,
        tiles: action.payload,
      };
    case types.SET_LAST_POSITION_ID:
      return {
        ...state,
        lastPositionId: action.payload,
      };
    case types.SET_ALGOLIA_QUERY:
      return {
        ...state,
        algoliaQuery: action.payload,
      };
    case types.SET_IS_BANNER_ON_SCREEN:
      return {
        ...state,
        isBannerOnScreen: action.payload,
      };
    default:
      return state;
  }
}
