import * as React from 'react';

import { Box, Text, Icon, colors, StyledLink, Anchor } from '../../../design-system';
import { Order as OrderType } from '../types';
import { ORDER_STATUS, SHIPPING_TYPES } from '../locale';
import { formatPrice } from '../../common/utils';
import accountPaths from '../paths';
import paths from '../../routing/paths';
import locale from '../locale';
import { getTrackingNumbers } from '../utils';

const borderline = `1px solid ${colors.LIGHT}`;

type Props = {
  order: OrderType;
  onOrderClick: ({ orderNumber, migrated }: { orderNumber: number; migrated: boolean }) => void;
};

const OrderListItem = ({
  order: {
    orderNumber,
    shippingType,
    status,
    createdAt,
    total,
    tracking,
    trackingNumber,
    migrated,
  },
  onOrderClick,
}: Props) => {
  const handleOrderClick = () => {
    onOrderClick({ orderNumber, migrated });
  };

  const trackingNumbers = getTrackingNumbers({
    tracking: tracking?.map((item) => ({ trackingNumber: item })),
    legacyNumber: trackingNumber,
  });

  return (
    <>
      <Box
        display={['none', 'none', 'block']}
        borderLeft={borderline}
        borderBottom={borderline}
        borderTop={borderline}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Text>{new Date(createdAt).toLocaleDateString('fr-FR')}</Text>
      </Box>
      <Box
        borderLeft={[borderline, borderline, 'none']}
        borderBottom={borderline}
        borderTop={borderline}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Box display={['block', 'block', 'none']}>
          <Text withEllipsis>{orderNumber}</Text>
        </Box>
        <Box display={['none', 'none', 'block']}>
          <Text>{orderNumber}</Text>
        </Box>
      </Box>
      <Box
        borderBottom={borderline}
        borderTop={borderline}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Text>{formatPrice(total)}</Text>
      </Box>
      <Box
        display={['none', 'none', 'block']}
        borderBottom={borderline}
        borderTop={borderline}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Text>{shippingType && SHIPPING_TYPES[shippingType]}</Text>
      </Box>
      <Box
        borderBottom={borderline}
        borderTop={borderline}
        borderRight={[borderline, borderline, 'none']}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Text>{ORDER_STATUS[status]}</Text>
      </Box>
      <Box
        display={['none', 'none', 'block']}
        borderBottom={borderline}
        borderTop={borderline}
        py="l"
        px="m"
        mb="s"
      >
        {trackingNumbers.length > 1 ? (
          <Anchor id="link-order-tracking-view-more" type="button" onClick={handleOrderClick}>
            {locale.VIEW_MORE}
          </Anchor>
        ) : trackingNumbers.length > 0 ? (
          <StyledLink
            id="link-order-tracking"
            to={`${paths.ACCOUNT}${accountPaths.TRACKING}/${trackingNumbers[0]}`}
          >
            {trackingNumbers[0]}
          </StyledLink>
        ) : (
          ''
        )}
      </Box>
      <Box
        display={['none', 'none', 'block']}
        borderBottom={borderline}
        borderTop={borderline}
        borderRight={borderline}
        py="l"
        px="m"
        mb="s"
        onClick={handleOrderClick}
      >
        <Icon name="chevronRight" />
      </Box>
    </>
  );
};

export default OrderListItem;
